
import React, { useEffect, useMemo, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { t } from '../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { GetSharedFileByContactGuid } from '../../services/FileService';
import { StoreClass } from '../../store/Store';
import { useNavigate } from 'react-router-dom';
import FileIcon from '../../components/FileIcon'
function ProfileView(props: any) {
    
    console.log("getting data in the props", props.user.status)
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    let empId = localStorage.getItem('EmpGuid') as string;
    const dispatch = useDispatch();
    const history = useNavigate();
    const [sharedFiles, setSharedFiles] = useState([] as any[]);
    const [sharedFilesExist, setSharedFilesExist] = useState(false);
    const [showAbout, setShowAbout] = useState(false);
    const [showFiles, setShowFiles] = useState(false)
    const api_domian = process.env.REACT_APP_API_HOST as string;
    const api_port = process.env.REACT_APP_API_PORT as string;

    useEffect(() => {
        dispatch(GetSharedFileByContactGuid(empId, props.user.EmpGuid) as any);
    }, [props.user.EmpGuid])

    useEffect(() => {
        setSharedFiles(user.sharedFiles || []);
        if ((user.sharedFiles || []).length > 0) {
            setSharedFilesExist(true);
        }
        else {
            setSharedFilesExist(false);
        }
    }, [user.sharedFiles])

    const NavigateToFiles = () => {
        history({ pathname: '/files'});
        localStorage.setItem("contactGuid", props.user.EmpGuid);
    }

    const closeAbout = () => {
        console.log("ok")
        setShowAbout(!showAbout);
    }

    const closeFiles = () => {
        setShowFiles(!showFiles)
    }

    const handleClose = () =>{
        props.closeProfile()
        setShowAbout(false);
        setShowFiles(false)
    }
    return (<>
        <div className={props.openProfile ? "SideNav text-left" : "d-none"}>
            <div className="sideNavHeader">
                <div className="CloseNav" > <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('button.close')} onClick={handleClose} /> </div>
            </div>
            <div className="sideNavBody">
                <div className="row mt-4">
                    <div className="col-12 mb-2 text-light">{t('title.profile')}</div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mb-2"> <img className="memberProfilePicture" src={props.user.ProfileImageURL ? props.user.ProfileImageURL : "/assets/media/svg/profile/male-user.PNG"} /> </div>
                    <div className="col-12 text-center text-light">
                        <h6 className="m-0">{props.user.FirstName + ' ' + props.user.LastName}</h6>
                        <p className="text-muted mb-2">({props.user.IsOnline ?(props.user.status.length > 0 ? props.user.status:"Online") : "Offline"})</p>
                    </div>
                    {/* {empId === props.user.EmpGuid ? '' : (props.user.NoChat ? <div className="col-12 text-center memberProfileActions"> */}
                        {/* <img title="Files" src="/assets/media/svg/profile/media.svg" /> */}
                        {/* <img title="Calendar" onClick={props.toggleSchedule} src="/assets/media/svg/profile/calendar.svg" /> */}
                    {/* </div> : <div className="col-12 text-center memberProfileActions"> */}
                        {/* <img title={t('title.audio_call')} src="/assets/media/svg/profile/call.svg" onClick={props.audioCall} />
                        <img title={t('title.video_call')} src="/assets/media/images/collaborate/camera.svg" onClick={props.videoCall} />
                        <img title={t('chat.chat')} src="/assets/media/svg/profile/message.svg" onClick={props.openChat} /> */}
                        {/* <img title={t('title.files')} src="/assets/media/svg/profile/media.svg" /> */}
                        {/* <img title={t('calendar.calendar')} onClick={props.toggleSchedule} src="/assets/media/svg/profile/calendar.svg" /> */}
                    {/* </div> */}
                    {/* )} */}
                    <div className="col-12 p-0">
                        <hr className="mb-2" />
                    </div>
                    <div className="col-12 text-light MemberPPHeader" onClick={closeAbout}> {t('myprofile.about')}
                        <img className="float-right" src="/assets/media/svg/profile/downArrow.svg" />
                    </div>
                    <div className="col-12 text-light MemberPPBody">
                        <p className="text-white mb-2">{props.user.about || ''}</p>
                    </div>
                    {showAbout && (<div className="col-12 text-light MemberPPBody">
                        <p className="text-muted m-0">{t('myprofile.email')}</p>
                        <p className="text-white mb-2">{props.user.OfficeEmail || props.user.Email}</p>
                        {/* <p className="text-muted m-0">{t('myprofile.mobile')}</p>
                        <p className="text-white">{props.user.Mobile}</p> */}
                    </div>)}
                    <div className="col-12 p-0">
                        <hr className="mb-2" /> </div>
                    <div className="col-12 text-light MemberPPHeader" onClick={closeFiles}> {t('title.file')} <img className="float-right" src="/assets/media/svg/profile/downArrow.svg" /> </div>

                    {sharedFilesExist ? <div className="col-12 text-light MemberPPBody">
                        {showFiles && (<div className="row profileFilesThumbs mt-2">  {(sharedFiles || []).map((file: any) => {
                            const ft = file.FileName.split('.').pop().toUpperCase();
                            return (<div className="col-3 pr-0"><a title={file.FileName} target="_blank" href={`${api_domian}${api_port}/api/File/DownloadFile?FileGuid=${file.FileGuid}`}>
                                <FileIcon name={ft} /></a></div>)
                        })}</div>)}
                        <div className="row">
                            <div className="col-12 text-center">
                                <button type="button" onClick={NavigateToFiles} className="btn btn-SeaGreen btn-sm mt-2">{t('button.all_files')}</button>
                            </div>
                        </div>
                    </div> : <div className="col-12 text-light">
                        <div className="text-center text-muted">
                            <span>No Files!</span>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="sideNavFooter d-none">
                <div className="col-12 text-right mt-4"> <img className="c-pointer" src="/assets/media/svg/settings.svg" /> </div>
            </div>
        </div>

    </>
    );
}

export default React.memo(ProfileView);