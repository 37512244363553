import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  getEmpDetailsByEmpGuid,
  getOrgDetailsByOrgId,
  getUserStatus,
  Logout,
  UpdateuiComponentResponse,
} from "../../services/UserActions";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import WindowTitle from "../../layout/WindowTitle";
import { GetRoomSessionDetails } from "../../services/RoomsService";
import { getGroupsByEmpId } from "../../services/ContactsService";
import _ from "lodash";
import CreateRoom from "./CreateRoom";
import { StoreClass } from "../../store/Store";
import { t } from "../../i18n";
import ShowOrgFiles from "../orgFiles/ShowOrgFiles";
import * as T from '../../actions/Types';
import ComingSoonPopup from '../../layout/ComingsoonPopup';
import { GetCommingSoon } from "../../services/WellnessServices";

function time_convert(num: number) {
  if (num == 0) return num;
  var h = Math.floor(num / 3600);
  var m = Math.floor((num % 3600) / 60);
  var s = Math.floor((num % 3600) % 60);
  //  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  //   var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  var hDisplay = (h > 9 ? h : "0" + h) + ":";
  var mDisplay = m > 9 ? m : "0" + m;
  var sDisplay = s > 9 ? s : "0" + s;
  return h + ":" + mDisplay;
}
function formatDateTime() {
  return moment.utc(new Date()).local().format("dddd, MMMM DD");
}
const staticStatus = [
  { id: "1", color: "green", title: "Available" },
  { id: "2", color: "orange", title: "Away" },
  // { id: "3", color: "#04f1b2", title: "In Office" },
  { id: "4", color: "red", title: "Busy" },
  { id: "5", color: "gray", title: "Appear Offline" },
];

function getStatusColor(status: string) {
  const sts = staticStatus.filter((s: any) => s.title === status);
  if (sts.length > 0) return sts[0].color;
  return "gray";
}

function Pod(props: any) {
  let empId = localStorage.getItem("EmpGuid") || "";
  let orgId = localStorage.getItem("OrgGuid") || "";
  const { user } = useSelector((state: StoreClass) => ({
    user: state?.AuthReducer,
  }));
  const [defaultRooms, setDefaultRooms] = useState<any>({
    collaborate: {},
    conference: {},
    socialHangout: {},
    earnLearn: {},
    produce: {},
  });
  const [rooms, setRooms] = useState<any[]>([]); // get all custom rooms
  const [displayRooms, setDisplayRooms] = useState<any>([]); // splice to display only 3 rooms per page
  const [pages, setPages] = useState(1);
  const [curPageNo, setCurPageNo] = useState(1);
  const [usrStatus, setUsrStatus] = useState({
    msg: "No Loggedin",
    icon: "orange",
    firstName: "",
  });
  const [newroom, setNewroom] = useState(false);
  const history = useNavigate();
  const [showTitle, setShowTitle] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [onlineUsrs, setOnlineUsrs] = useState(0);
  const [roomStats, setRoomStats] = useState<any>({
    TotalSessionTime: 0,
    AverageSessionTime: 0,
  });
  const dispatch = useDispatch();
  const postRoomStatusInt = useRef<any>(0);
  const [showInductionFiles, setShowInductionFiles] = useState(0);
  const [unreadTotal, setUnreadTotal] = useState(0);
  const [onlineCounts, setOnlineCounts] = useState<any>({});
  const [showRooms, setShowRooms] = useState(false);
  const [showComingsoon, setComingsoon] = useState(false);
  const location = useLocation();
  const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const updateMousePosition = (ev: MouseEvent) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    useEffect(() => {
      console.log("#TEST POD");
      window.addEventListener("mousemove", updateMousePosition);

      return () => window.removeEventListener("mousemove", updateMousePosition);
    }, []);

    return mousePosition;
  };
  const { x, y } = useMousePosition();
  const [screenName,setScreenName] = useState("");
  const [fetchingCoomingSoonData, setFetchingCoomingSoonData] = useState([] as any);
  const [comingsoonDescription,setComingsoonDescription] = useState('')

  useEffect(() => {
    empId = localStorage.getItem("EmpGuid") || "";
    // dispatch(UpdateuiComponentResponse("saveAVSettingsResponse") as any);

    dispatch(getUserStatus(empId) as any);
    if (
      !user.orgData.OrgGuid ||
      user.orgData.OrgGuid === "00000000-0000-0000-0000-000000000000"
    ) {
      dispatch(getOrgDetailsByOrgId(orgId) as any);
    }
    if (!user.empData.EmpGuid || user.empData.EmpGuid === "") {
      dispatch(getEmpDetailsByEmpGuid(empId) as any);
    }
    if (user.UserTypeId == 1 && user?.EmailConfirmed == 0)
      setShowInductionFiles(1);
    else setShowInductionFiles(0);
    return () => {
      clearInterval(postRoomStatusInt.current);
    };
  }, []);

  useEffect(() => {

    if (user.Socket) {
      if (user.Socket.connected) {
        console.log("#TEST groupUpdated");
        user.Socket?.emit("groupUpdated");
      }
      else {
        console.log("#TEST NO groupUpdated");
        getRoomsStats();
        postRoomStatusInt.current = setInterval(getRoomsStats, 30000);
      }
    }
    else console.log('#TEST NOT User Socket')

  }, [user.Socket]);

  useEffect(() => {
    console.log("groups", user.groups);

    if (user.groups && user.groups.length > 0) {
      const publicGps = user.groups; //.filter((g:any)=>g.IsPrivate === false );
      if (publicGps.length == 0) return;
      const pageRooms = [];
      const defRoom: any = {};
      for (let k in publicGps) {
        const room = publicGps[k];
        if (room.IsDefault) {
          if (room.GroupName === "Earn & Learn")
            defRoom.earnLearn = {
              GroupName: room.GroupName,
              OnlineMembersCount: room.OnlineMembersCount,
              MembersCount: room.MembersCount,
              IsLocked: room.IsLocked,
              GroupGuid: room.GroupGuid,
            };
          if (room.GroupName === "Socialize & Hang")
            defRoom.socialHangout = {
              GroupName: room.GroupName,
              OnlineMembersCount: room.OnlineMembersCount,
              MembersCount: room.MembersCount,
              IsLocked: room.IsLocked,
              GroupGuid: room.GroupGuid,
            };
          if (room.GroupName === "Collaborate")
            defRoom.collaborate = {
              GroupName: room.GroupName,
              OnlineMembersCount: room.OnlineMembersCount,
              MembersCount: room.MembersCount,
              IsLocked: room.IsLocked,
              GroupGuid: room.GroupGuid,
            };
          if (room.GroupName === "Conference")
            defRoom.conference = {
              GroupName: room.GroupName,
              OnlineMembersCount: room.OnlineMembersCount,
              MembersCount: room.MembersCount,
              IsLocked: room.IsLocked,
              GroupGuid: room.GroupGuid,
            };
          if (room.GroupName === "Produce")
            defRoom.produce = {
              GroupName: room.GroupName,
              OnlineMembersCount: room.OnlineMembersCount,
              MembersCount: room.MembersCount,
              IsLocked: room.IsLocked,
              GroupGuid: room.GroupGuid,
            };
          //	pageRooms.push(room);
        }

        pageRooms.push(room);
      }
      console.log("defRoom", defRoom);
      console.log("pageRooms", pageRooms);

      setDefaultRooms({ ...defRoom });
      //setRooms(pageRooms);
      let cp = curPageNo;
      const IsNotDefault = user.groups.filter((grp: any) => {
        return !grp.IsDefault;
      });
      // if (cp > 1) {
      const disRooms = [];
      if (user.groups) {
        for (let k = 0; k < user.groups.length; k++) {
          const room = user.groups[k];
          if (room.GroupName !== "Conference" && room.GroupName !== "Produce") {
            disRooms.push(room);
          }
        }
      }
      setRooms(disRooms);

      setDisplayRooms(disRooms.slice((cp - 1) * 3, (cp - 1) * 3 + 3));
      // }
      // else
      // setDisplayRooms(pageRooms.splice(0, 3));

      const p = Math.ceil(disRooms.length / 3);
      setPages(p);
    }
  }, [user.groups]);

  useEffect(() => {
    if (
      !user.orgData.OrgGuid ||
      user.orgData.OrgGuid == "00000000-0000-0000-0000-000000000000"
    ) {
      dispatch(getOrgDetailsByOrgId(orgId) as any);
    }
  }, [user.orgData]);

  const showRoomMetrics = (rmId: string) => {
    console.log("room Id", rmId);
    console.log("rooms", defaultRooms);
    setShowTitle(false);
    setShowTooltip(true);
    if (user.roomStats && user.roomStats[rmId]) {
      setRoomStats({ ...user.roomStats[rmId] });
    } else {
      dispatch(GetRoomSessionDetails(rmId) as any);
    }

    if (user.groups && user.groups.length > 0) {
      for (let k in user.groups) {
        const room = user.groups[k];
        if (room.GroupGuid === rmId) setOnlineUsrs(room.OnlineMembersCount);
      }
    }
  };
  const hideRoomMetrics = () => {
    setShowTitle(true);
    setShowTooltip(false);
  };

  useEffect(() => {
    setUsrStatus({
      ...usrStatus,
      msg: user.status,
      icon: getStatusColor(user.status),
    });
  }, [user.status]);

  //open create new room side popup
  const showNewRoomPopup = () => {
    setNewroom(!newroom);
  };

  const getRoomsStats = () => {
    orgId = localStorage.getItem("OrgGuid") || "";

    let id = localStorage.getItem("EmpGuid") || "";
    if (id && id.length > 0) dispatch(getGroupsByEmpId(id) as any);
  };

  const userLogout = () => {
    clearInterval(postRoomStatusInt.current);
    dispatch({
      type: T.SELECTED_USER,
      payload: undefined
    })

    dispatch({
      type: T.OPEN_CALL,
      payload: false
    })
    dispatch(Logout(empId, history) as any);
  };

  const closeComingsoon = () => {
    setComingsoon(false);
  }

  const WellnessComming = () =>{ 
    const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'WELLNESS');
    if (filteredData.length > 0) {
      const firstItem = filteredData[0];
      setScreenName(firstItem.screenname);
      setComingsoonDescription(firstItem.description);
      setComingsoon(true);
  }
  }

  const hangoutRoomClick = () =>{
    const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'HANGOUT');
    if (filteredData.length > 0) {
      const firstItem = filteredData[0];
      setScreenName(firstItem.screenname);
      setComingsoonDescription(firstItem.description);
      setComingsoon(true);
  }
  }

  const collaborateClick = () =>{
    const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'COLLABORATION');
    if (filteredData.length > 0) {
      const firstItem = filteredData[0];
      setScreenName(firstItem.screenname);
      setComingsoonDescription(firstItem.description);
      setComingsoon(true);
  }
  }

  useEffect(() => {
    dispatch({
        type: T.ADD_LOADING
    })
    GetCommingSoon(null, null, 0)
        .then((resp: any) => {
            setFetchingCoomingSoonData([...resp.result ]); // Adjust based on whether result is array
            if (resp.status == "success") {
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
            else if (resp == "Error") {
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
        })
        .catch((e: any) => {
            dispatch({
                type: T.REMOVE_LOADING
            })

            return "error"
        })
    dispatch({
        type: T.REMOVE_LOADING
    })
}, [])

  return ( 
    <section
      className="bg-white overflow-hidden position-relative h-100"
      style={{
        backgroundImage: "url(" + user.orgData.BGURL + ")",
        backgroundSize: "cover",
      }}
    >
      <WindowTitle title="POD" />
      <CreateRoom
        show={newroom}
        close={showNewRoomPopup}
        defaultPrivate={true}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm-3 mt-5">
            {(user.orgData.LogoURL || "").length > 0 ? (
              <img
                src={user.orgData.LogoURL ? user.orgData.LogoURL : ""}
                className="PodMainLogo"
              />
            ) : (
              <img src={user.orgData.BrandLogo} className="PodMainLogo" />
            )}

            {
              showTitle ? (
                <>
                  <h1
                    className="font-weight-light mt-5 c-codet"
                    style={{ color: user.orgData.TagLineColor }}
                  >
                    {user.orgData.TagLine}
                  </h1>
                </>
              ) : (
                <h1
                  className="font-weight-light mt-5 c-codet"
                  style={{ color: user.orgData.TagLineColor }}
                >
                  {user.orgData.TagLine}
                </h1>
              )
             
            }
          </div>
          {(user.orgData.LogoURL || "").length > 0 ? (
            <img
              className="suteFLogo"
              src={user.orgData.BrandLogo}
              width="80px"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="col-12 row mt-5 m-0 mobile-block">
        <ul id="hexGrid">
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          {user.empData?.UserTypeId == 2 ? (
            <li className="hex  mobile-style">
              <Link className="hexIn hexLink hexBgImg" to="/admin">
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/dashboard-bg.jpg"
                />
                <p>
                  {" "}
                  <span className="align-self-center">
                    {t('pod.admin_dashboard')}
                  </span>{" "}
                </p>
              </Link>
            </li>
          ) : undefined}
          <li className="hex" style={{ marginTop: "-8%" }}>
            <Link className="hexIn hexAdd" to="/my-profile">
              <p>
                {" "}
                <span className="align-self-center">
                  {user.empData.ProfileImageURL ? (
                    <>
                      <img
                        className="hexImgContent userProfilePic"
                        src={user.empData.ProfileImageURL}
                      />{" "}
                      <br />
                    </>
                  ) : (
                    <>
                      <img
                        className="hexImgContent"
                        src="/assets/media/images/userProfileImg.PNG"
                      />{" "}
                      <br />
                    </>
                  )}
                  <span className="small p-0">
                    {user.empData.FirstName}{" "}
                    <span
                      className="hexUserStatus"
                      title={usrStatus.msg}
                      style={{ backgroundColor: usrStatus.icon }}
                    ></span>
                  </span>{" "}
                </span>
              </p>
            </Link>
          </li>
          <li className="hex" style={{ marginTop: "-8%" }}>
            <Link className="hexIn hexLink hexBgImg" to="/help-feedback">
              <p>
                {" "}
                <span className="align-self-center">
                  <span className="p-0">{t('pod.help_resources')}</span>
                </span>
              </p>
            </Link>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          {user.empData?.UserTypeId == 3 ? (
            <li className="hex  mobile-style" style={{ marginTop: "-0.6rem;" }}>
              <Link className="hexIn hexLink hexBgImg" to="/users">
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/EarnandLearn_POD.png"
                />
                <p>
                  {" "}
                  <span className="align-self-center">{t('pod.hr_admin_employees')}</span>{" "}
                </p>
              </Link>
            </li>
          ) : undefined}
          {user.empData?.UserTypeId == 3 ? (
            <li className="hex" style={{ marginTop: "-8%" }}>
              <Link className="hexIn hexLink hexBgImg" to="/companies">
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/EarnandLearn_POD.png"
                />
                <p>
                  {" "}
                  <span className="align-self-center">{t('pod.Companies')}</span>{" "}
                </p>
              </Link>
            </li>
          ) : undefined}
          {user.empData?.UserTypeId == 3 ? (
            <li className="hex" style={{ marginTop: "-8%" }}>
              <div className="hexIn hexAdd">
                <p>
                  <button className="align-self-center" onClick={userLogout}>
                    <svg
                      className="mb-3"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      height="40"
                    >
                      <path
                        d="M13.5 7.5l-3 3.25m3-3.25l-3-3m3 3H4m4 6H1.5v-12H8"
                        stroke="#fff"
                      ></path>
                    </svg>
                    <br /> <span className="small p-0">{t('pod.logout')}</span>{" "}
                  </button>
                </p>
              </div>
            </li>
          ) : undefined}
          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <>
              {" "}
              <li className="hex mobile-style">
                <Link
                  className="hexIn hexLink hexBgImg"
                  to="/files"
                  state={location.pathname}
                >
                  <img
                    className="hexHoverBg w-100"
                    src="/assets/media/images/files-bg.jpg"
                  />
                  <p>
                    {" "}
                    <span className="align-self-center">{t('pod.my_files')}</span>{" "}
                  </p>
                </Link>
              </li>
              <li className="hex" style={{ marginTop: "-1.5rem" }}>
                <Link className="hexIn hexLink hexBgImg" to="/myMeetings">
                  <img
                    className="hexHoverBg"
                    src="/assets/media/images/meetings-bg.jpg"
                  />
                  <p>
                    {" "}
                    <span className="align-self-center">{t('pod.my_meetings')}</span>{" "}
                  </p>
                </Link>
              </li>
              <li className="hex mr-t-1">
                <Link
                  className="hexIn hexLink hexBgImg"
                  to="/checkins"
                  state={location.pathname}
                >
                  <img
                    className="hexHoverBg"
                    src="/assets/media/images/checkins-bg.jpg"
                  />
                  <p>
                    {" "}
                    <span className="align-self-center">{t('pod.my_check-ins')}</span>{" "}
                  </p>
                </Link>
              </li>{" "}
            </>
          ) : undefined}
          <div className={"expand-popup pt-1 " + (showRooms ? "" : "d-none")}>
            <div
              className="cancel text-right"
              onClick={() => {
                setShowRooms(!showRooms);
              }}
            >
              <svg
                stroke="currentColor"
                fill="#fff"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
              </svg>
            </div>
            <div className="inner">
              <div className="public">
                <h5>Public Rooms</h5>
                <Link
                  to={`/conference/${defaultRooms.conference.GroupGuid}`}
                  onMouseLeave={hideRoomMetrics}
                  onMouseOver={() =>
                    showRoomMetrics(defaultRooms.conference.GroupGuid)
                  }
                >
                  {t('pod.conference_room')} &nbsp;
                  {defaultRooms.conference?.IsLocked ? (
                    <span>&#128274;</span>
                  ) : (
                    ""
                  )}
                </Link>

                {"1,2,".includes(user.empData?.UserTypeId)
                  ? rooms
                    .filter((r) => {
                      return r.IsDefault == true;
                    })
                    .map((rm: any, index: number) => {
                      return (
                        <Link
                          to={`/customrm/${rm.GroupGuid}`}
                          onMouseLeave={hideRoomMetrics}
                          onMouseOver={() => showRoomMetrics(rm.GroupGuid)}
                        >
                          {rm.DisplayName}{rm?.IsLocked ? (
                            <span>&#128274;</span>
                          ) : (
                            ""
                          )}
                        </Link>
                      );
                    })
                  : undefined}
              </div>
              <div className="private">
                <h5>{t('pod.private_rooms')}</h5>
                {"1,2,".includes(user.empData?.UserTypeId)
                  ? rooms
                    .filter((r) => {
                      return r.IsDefault == false;
                    })
                    .map((rm: any, index: number) => {
                      return (
                        <Link
                          to={`/customrm/${rm.GroupGuid}`}
                          onMouseLeave={hideRoomMetrics}
                          onMouseOver={() =>
                            showRoomMetrics(defaultRooms.conference.GroupGuid)
                          }
                        >
                          <p>  {rm.DisplayName}{rm?.IsLocked ? (
                            <span>&#128274;</span>
                          ) : (
                            ""
                          )}</p>
                        </Link>
                      );
                    })
                  : undefined}
              </div>
            </div>
          </div>
          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <>
              <li className="hex mobile-style mr-t-1">
                <Link className="hexIn hexLink hexBgImg" to="/produce">
                  <img
                    className="hexHoverBg"
                    src="/assets/media/images/office-hover-bg.png"
                  />
                  <p>
                    {" "}
                    <span className="align-self-center">{t('pod.office')}</span>{" "}
                  </p>
                </Link>
              </li>
              <li
                className="hex"
                //onMouseLeave={hideRoomMetrics}
                // onMouseOver={() =>
                // 	showRoomMetrics(defaultRooms.conference.GroupGuid)
                // }
                style={{ marginTop: "-0.7rem" }}
              >
                <Link
                  onClick={() => {
                    setShowRooms(!showRooms);
                  }}
                  className={
                    "hexIn hexLink hexBgImg " +
                    (defaultRooms.conference.GroupGuid ? "" : "d-none")
                  }
                  to="#"
                >
                  <img
                    className="hexHoverBg"
                    src="/assets/media/images/conference-hover-bg.jpg"
                  />
                  <p>
                    <span className="align-self-center">{t('pod.conference_room')}</span>
                  </p>
                </Link>
              </li>
              <li
                className="hex"
                //onMouseLeave={hideRoomMetrics}
                // onMouseOver={() => showRoomMetrics(defaultRooms.produce?.GroupGuid)}
                style={{ marginTop: "-0.7rem" }}
              >
                {/* <Link
                  className="hexIn hexLink hexBgImg"
                  to="/collaboration-hub"
                > */}
                <Link
                  className="hexIn hexLink hexBgImg"
                  onClick={collaborateClick}
                >
                  <img
                    className="hexHoverBg"
                    src="/assets/media/images/collaboration-hub-hover.jpg"
                  />
                  <p>
                    {/* <span className="align-self-center">{t("pod.produce")}</span> */}
                    <span className="align-self-center">{t('pod.collaboration_hub')}</span>
                  </p>
                </Link>
              </li>
              <li
                className="hex mobile-style"
                //onMouseLeave={hideRoomMetrics}
                // onMouseOver={() => showRoomMetrics(defaultRooms.produce?.GroupGuid)}
                style={{ marginTop: "-0.7rem" }}
              >
                {/* <Link
                  className="hexIn hexLink hexBgImg"
                  to="/assessment"
                  state={location.pathname}
                > */}
                <Link
                  className="hexIn hexLink hexBgImg"
                  onClick={WellnessComming}
                >
                  <img
                    className="hexHoverBg"
                    src="/assets/media/images/wellness-bg.png"
                  />
                  <p>
                    {/* <span className="align-self-center">{t("pod.produce")}</span> */}
                    <span className="align-self-center">{t('pod.wellness')}</span>
                  </p>
                </Link>
              </li>
              <li
                className="hex mr-t-1"
              //onMouseLeave={hideRoomMetrics}
              // onMouseOver={() =>
              // 	showRoomMetrics(defaultRooms.earnLearn.GroupGuid)
              // }
              >
                <Link
                  className="hexIn hexLink hexBgImg"
                  to={`/earn-learn`}
                >
                  <img
                    className="hexHoverBg"
                    src="/assets/media/images/lobby.png"
                  />
                  <p>
                    {" "}
                    <span className="align-self-center">{t('pod.lobby')}</span>{" "}
                  </p>
                </Link>
              </li>
            </>
          ) : undefined}
          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <li
              className="hex mr-t-1"
            //onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
            //
            >
              {/* <Link className="hexIn hexLink hexBgImg" to={`/hangoutroom`}> */}
              <Link className="hexIn hexLink hexBgImg" onClick={hangoutRoomClick}>

                <img
                  className="hexHoverBg"
                  src="/assets/media/images/SocializeAndHang_POD.jpg"
                />
                <p>
                  <span className="align-self-center">{t('pod.hangout_room')}</span>
                </p>
              </Link>
            </li>
          ) : undefined}
          {/* {"1,2,".includes(user.empData?.UserTypeId) ? (
            <li
              className="hex mobile-style"
              onMouseLeave={hideRoomMetrics}
              onMouseOver={() =>
                showRoomMetrics(defaultRooms.conference.GroupGuid)
              }
            >
              <Link
                className={
                  "hexIn hexLink hexBgImg " +
                  (defaultRooms.conference.GroupGuid ? "" : "d-none")
                }
                to={`/conference/${defaultRooms.conference.GroupGuid}`}
              >
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/Collaborate_POD.jpg"
                />
                <p>
                  <span className="align-self-center">
                    Conference Room{" "}
                    {defaultRooms.conference?.IsLocked ? (
                      <span>&#128274;</span>
                    ) : (
                      ""
                    )}
                  </span>
                </p>
              </Link>
            </li>
          ) : undefined}

          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <li className="hex" style={{ marginTop: "-9%" }}>
              <Link className="hexIn hexAdd" to="/pod">
                <p>
                  <span className="align-self-center">
                    <svg
                      onClick={prevRoomsPage}
                      className="lrArrows"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                        fill="#6264a5"
                      />
                    </svg>
                    <span className="h4 c-SeaGreen">
                      {curPageNo}/{pages.toFixed(0)}
                    </span>
                    <svg
                      onClick={nextRoomsPage}
                      className="lrArrows"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                        fill="#6264a5"
                      />
                    </svg>
                    <br />
                    <i className="small">{formatDateTime()}</i>
                  </span>
                </p>
              </Link>
            </li>
          ) : undefined}
          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <li
              className="hex"
              style={{ marginTop: "-9%" }}
              onMouseLeave={hideRoomMetrics}
              onMouseOver={() =>
                showRoomMetrics(defaultRooms.produce?.GroupGuid)
              }
            >
              <Link className="hexIn hexLink hexBgImg" to="/produce">
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/Produce-POD.png"
                />
                <p>
                  <span className="align-self-center">{t("pod.produce")}</span>
                </p>
              </Link>
            </li>
          ) : undefined}
          {"1,2,".includes(user.empData?.UserTypeId)
            ? displayRooms.map((rm: any, index: number) => {
                return (
                  <li
                    key={rm.GroupGuid}
                    className={"hex mobile-style-child-" + index}
                    onMouseLeave={hideRoomMetrics}
                    onMouseOver={() => showRoomMetrics(rm.GroupGuid)}
                  >
                    <Link
                      className="hexIn hexLink active"
                      to={`/customrm/${rm.GroupGuid}`}
                    >
                      <p>
                        <span className="align-self-center">
                          {rm.DisplayName}{" "}
                          {rm.IsLocked ? <span>&#128274;</span> : ""}
                          <br />
                          {rm.IsPrivate ? (
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12.8396"
                                cy="12.8401"
                                r="10.2"
                                fill="#31EFBC"
                              />
                              <circle
                                cx="10.8799"
                                cy="10.0801"
                                r="8.96"
                                fill="#83F5D7"
                              />
                              <circle
                                cx="12"
                                cy="12"
                                r="11.5"
                                stroke="#6264A5"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.1139 11.5262C13.9483 11.1043 14.5204 10.239 14.5204 9.24018C14.5204 7.82633 13.3742 6.68018 11.9604 6.68018C10.5465 6.68018 9.40039 7.82633 9.40039 9.24018C9.40039 10.2368 9.96994 11.1005 10.8013 11.5234L10.5867 12.5255C9.30256 11.9879 8.40039 10.7194 8.40039 9.24018C8.40039 7.27404 9.99426 5.68018 11.9604 5.68018C13.9265 5.68018 15.5204 7.27404 15.5204 9.24018C15.5204 10.7432 14.5889 12.0287 13.2717 12.5509L13.1139 11.5262Z"
                                fill="#6264A5"
                              />
                              <path
                                d="M11 11.5L10 18H14L13 11.5"
                                stroke="#6264A5"
                              />
                            </svg>
                          ) : (
                            ""
                          )}
                        </span>
                      </p>
                    </Link>
                  </li>
                );
              })
            : undefined}
          {"1,2,".includes(user.empData?.UserTypeId)
            ? _.times(3 - displayRooms.length, (n: number) => {
                return (
                  <li key={"" + n} className="hex">
                    <div
                      className="hexIn hexAdd c-pointer"
                      onClick={showNewRoomPopup}
                    >
                      <p>
                        <span className="align-self-center">
                          <svg
                            viewBox="0 0 15 15"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7 7V1h1v6h6v1H8v6H7V8H1V7h6z"
                              fill="#6264a5"
                            ></path>
                          </svg>
                        </span>
                      </p>
                    </div>
                  </li>
                );
              })
            : undefined} */}

          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>

          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <li className="hex mobile-none">
              <div className="hexIn hexAdd c-pointer">
                <p onClick={showNewRoomPopup}>
                  <span className="align-self-center">
                    <svg
                      viewBox="0 0 15 15"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 7V1h1v6h6v1H8v6H7V8H1V7h6z"
                        fill="#6264a5"
                      ></path>
                    </svg>
                  </span>
                </p>
              </div>
            </li>
          ) : undefined}
          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <li
              className="hex mobile-none"
            //onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
            // }
            >
              <Link className="hexIn hexLink hexBgImg" to={`/hangoutroom`}>
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/hangout-hover-bg.png"
                />
                <p>
                  <span className="align-self-center">{t('pod.hangout_room')}</span>
                </p>
              </Link>
            </li>
          ) : undefined}

          {"1,2,".includes(user.empData?.UserTypeId) ? (
            <li
              className="hex mobile-style mr-t-1"
              style={{ marginTop: "-1.5rem !important" }}
            >
              <div className="hexIn hexAdd">
                <p>
                  <button className="align-self-center" onClick={userLogout}>
                    <svg
                      className="mb-3"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      height="40"
                    >
                      <path
                        d="M13.5 7.5l-3 3.25m3-3.25l-3-3m3 3H4m4 6H1.5v-12H8"
                        stroke="#fff"
                      ></path>
                    </svg>
                    <br /> <span className="small p-0">{t('pod.logout')}</span>{" "}
                  </button>
                </p>
              </div>
            </li>
          ) : undefined}
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
          <li className="hex mobile-none cursor-default">
            <a className="hexIn"></a>
          </li>
        </ul>
      </div>
      <ul id="hexGrid" className="mobile-none">
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        {user.empData?.UserTypeId == 2 ? (
          <li className="hex  hex-active mobile-none">
            <Link className="hexIn hexLink hexBgImg" to="/admin">
              <img
                className="hexHoverBg"
                src="/assets/media/images/dashboard-bg.jpg"
              />
              <p>
                {" "}
                <span className="align-self-center">{t('pod.admin_dashboard')}</span>{" "}
              </p>
            </Link>
          </li>
        ) : (
          <li className="hex cursor-default">
            <a className="hexIn"></a>
          </li>
        )}
        <li className="hex">
          <Link className="hexIn hexAdd" to="/my-profile">
            <p>
              {" "}
              <span className="align-self-center">
                {user.empData.ProfileImageURL ? (
                  <>
                    <img
                      className="hexImgContent userProfilePic"
                      src={user.empData.ProfileImageURL}
                    />{" "}
                    <br />
                  </>
                ) : (
                  <>
                    <img
                      className="hexImgContent"
                      src="/assets/media/images/userProfileImg.PNG"
                    />{" "}
                    <br />
                  </>
                )}
                <span className="small p-0">
                  {user.empData.FirstName}{" "}
                  <span
                    className="hexUserStatus"
                    title={usrStatus.msg}
                    style={{ backgroundColor: usrStatus.icon }}
                  ></span>
                </span>{" "}
              </span>
            </p>
          </Link>
        </li>
        <li className="hex">
          <Link className="hexIn hexLink hexBgImg" to="/help-feedback">
          <img
                className="hexHoverBg"
                src="/assets/media/images/HelpandResources.jpg"
              />
            <p>
              {" "}
              <span className="align-self-center">
                <span className="p-0">{t('pod.help_resources')}</span>
              </span>
            </p>
          </Link>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>

        {user.empData?.UserTypeId == 3 ? (
          <li className="hex mobile-none">
            <Link className="hexIn hexLink hexBgImg" to="/users">
              <img
                className="hexHoverBg"
                src="/assets/media/images/EarnandLearn_POD.png"
              />
              <p>
                {" "}
                <span className="align-self-center">{t('pod.hr_admin_employees')}</span>{" "}
              </p>
            </Link>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex mobile-none">
            <Link className="hexIn hexLink hexBgImg" to="/companies">
              <img
                className="hexHoverBg"
                src="/assets/media/images/EarnandLearn_POD.png"
              />
              <p>
                {" "}
                <span className="align-self-center">{t('pod.companies')}</span>{" "}
              </p>
            </Link>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <div className="hexIn hexAdd">
              <p>
                <button className="align-self-center" onClick={userLogout}>
                  <svg
                    className="mb-3"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    height="40"
                  >
                    <path
                      d="M13.5 7.5l-3 3.25m3-3.25l-3-3m3 3H4m4 6H1.5v-12H8"
                      stroke="#fff"
                    ></path>
                  </svg>
                  <br /> <span className="small p-0">{t('pod.logout')}</span>{" "}
                </button>
              </p>
            </div>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <a className="hexIn"></a>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <a className="hexIn"></a>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <a className="hexIn"></a>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <a className="hexIn"></a>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <a className="hexIn"></a>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <a className="hexIn"></a>
          </li>
        ) : undefined}
        {user.empData?.UserTypeId == 3 ? (
          <li className="hex">
            <a className="hexIn"></a>
          </li>
        ) : undefined}
        {/* {"1,2,".includes(user.empData?.UserTypeId)
          ? displayRooms.map((rm: any) => {
              return (
                <li
                  key={rm.GroupGuid}
                  className="hex"
                  onMouseLeave={hideRoomMetrics}
                  onMouseOver={() => showRoomMetrics(rm.GroupGuid)}
                >
                  <Link
                    className="hexIn hexLink active"
                    to={`/customrm/${rm.GroupGuid}`}
                  >
                    <p>
                      <span className="align-self-center">
                        {rm.DisplayName}{" "}
                        {rm.IsLocked ? <span>&#128274;</span> : ""}
                        <br />
                        {rm.IsPrivate ? (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="12.8396"
                              cy="12.8401"
                              r="10.2"
                              fill="#31EFBC"
                            />
                            <circle
                              cx="10.8799"
                              cy="10.0801"
                              r="8.96"
                              fill="#83F5D7"
                            />
                            <circle cx="12" cy="12" r="11.5" stroke="#6264A5" />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.1139 11.5262C13.9483 11.1043 14.5204 10.239 14.5204 9.24018C14.5204 7.82633 13.3742 6.68018 11.9604 6.68018C10.5465 6.68018 9.40039 7.82633 9.40039 9.24018C9.40039 10.2368 9.96994 11.1005 10.8013 11.5234L10.5867 12.5255C9.30256 11.9879 8.40039 10.7194 8.40039 9.24018C8.40039 7.27404 9.99426 5.68018 11.9604 5.68018C13.9265 5.68018 15.5204 7.27404 15.5204 9.24018C15.5204 10.7432 14.5889 12.0287 13.2717 12.5509L13.1139 11.5262Z"
                              fill="#6264A5"
                            />
                            <path
                              d="M11 11.5L10 18H14L13 11.5"
                              stroke="#6264A5"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </span>
                    </p>
                  </Link>
                </li>
              );
            })
          : undefined}
        {"1,2,".includes(user.empData?.UserTypeId)
          ? _.times(3 - displayRooms.length, (n: number) => {
              console.log("displayRooms 2", n);
              return (
                <li key={"" + n} className="hex">
                  <div
                    className="hexIn hexAdd c-pointer"
                    onClick={showNewRoomPopup}
                  >
                    <p>
                      <span className="align-self-center">
                        <svg
                          viewBox="0 0 15 15"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7 7V1h1v6h6v1H8v6H7V8H1V7h6z"
                            fill="#6264a5"
                          ></path>
                        </svg>
                      </span>
                    </p>
                  </div>
                </li>
              );
            })
          : undefined} */}
        {"1,2,".includes(user.empData?.UserTypeId) ? (
          <>
            {" "}
            <li
              className="hex"
            //onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
            // }
            >
              <Link
                className="hexIn hexLink hexBgImg"
                to={`/files`}
                state={location.pathname}
              >
                <img
                  className="hexHoverBg w-100"
                  src="/assets/media/images/files-bg.jpg"
                />
                <p>
                  <span className="align-self-center">{t('pod.my_files')}</span>
                </p>
              </Link>
            </li>
            <li
              className="hex"
            // onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
            // }
            >
              <Link className="hexIn hexLink hexBgImg" to={`/myMeetings`}>
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/meetings-bg.jpg"
                />
                <p>
                  <span className="align-self-center">{t('pod.my_meetings')}</span>
                </p>
              </Link>
            </li>
            <li
              className="hex"
            // onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
            // }
            >
              <Link
                className="hexIn hexLink hexBgImg"
                to={`/checkins`}
                state={location.pathname}
              >
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/checkins-bg.jpg"
                />
                <p>
                  <span className="align-self-center">{t('pod.my_check-ins')}</span>
                </p>
              </Link>
            </li>
          </>
        ) : undefined}
        <div className={"expand-popup pt-1 " + (showRooms ? "" : "d-none")}>
          <div
            className="cancel text-right"
            onClick={() => {
              setShowRooms(!showRooms);
            }}
          >
            <svg
              stroke="currentColor"
              fill="#fff"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
            </svg>
          </div>
          <div className="inner">
            <div className="public">
              <h5>{t('pod.public_rooms')}</h5>
              <Link
                to={`/conference/${defaultRooms.conference.GroupGuid}`}
                onMouseLeave={hideRoomMetrics}
                onMouseOver={() =>
                  showRoomMetrics(defaultRooms.conference.GroupGuid)
                }
              >
                {t('pod.conference_room')}{" "}
                {defaultRooms.conference?.IsLocked ? (
                  <span>&#128274;</span>
                ) : (
                  ""
                )}
                <span> <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440z"></path></svg></span></span>
              </Link>
              {"1,2,".includes(user.empData?.UserTypeId)
                ? rooms
                  .filter((r) => {
                    return r.IsDefault == true;
                  })
                  .map((rm: any, index: number) => {
                    return (
                      <Link
                        to={`/customrm/${rm.GroupGuid}`}
                        onMouseLeave={hideRoomMetrics}
                        onMouseOver={() => showRoomMetrics(rm.GroupGuid)}
                      >
                        {rm.DisplayName}{rm?.IsLocked ? (
                          <span>&#128274;</span>
                        ) : (
                          ""
                        )}
                        <span> <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440z"></path></svg></span></span>
                      </Link>
                    );
                  })
                : undefined}
            </div>
            <div className="private">
              <h5>{t('pod.private_rooms')}</h5>
              {"1,2,".includes(user.empData?.UserTypeId)
                ? rooms
                  .filter((r) => {
                    return r.IsDefault == false;
                  })
                  .map((rm: any, index: number) => {
                    return (
                      <Link
                        to={`/customrm/${rm.GroupGuid}`}
                        onMouseLeave={hideRoomMetrics}
                        onMouseOver={() =>
                          showRoomMetrics(defaultRooms.conference.GroupGuid)
                        }
                      >
                        <p>     {rm.DisplayName}{rm?.IsLocked ? (
                          <span>&#128274;</span>
                        ) : (
                          ""
                        )}
                          <span> <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440z"></path></svg></span></span></p>
                      </Link>
                    );
                  })
                : undefined}
            </div>
          </div>
        </div>
        {/* <li className="hex">
          <a className="hexIn"></a>
        </li> */}
        {"1,2,".includes(user.empData?.UserTypeId) ? (
          <li
            className="hex"
          // onMouseLeave={hideRoomMetrics}
          // onMouseOver={() => showRoomMetrics(defaultRooms.produce?.GroupGuid)}
          >
            <Link className="hexIn hexLink hexBgImg" to="/produce">
              <img
                className="hexHoverBg"
                src="/assets/media/images/office-hover-bg.jpg"
              />
              <p>
                <span className="align-self-center">{t("pod.produce")}</span>
              </p>
            </Link>
          </li>
        ) : undefined}
        {"1,2,".includes(user.empData?.UserTypeId) ? (
          <>
            {" "}
            <li
              className="hex"
            //onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.conference.GroupGuid)
            // }
            >
              <Link
                onClick={() => {
                  setShowRooms(!showRooms);
                }}
                className={
                  "hexIn hexLink hexBgImg " +
                  (defaultRooms.conference.GroupGuid ? "" : "d-none")
                }
                to="#"
              >
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/conference-hover-bg.jpg"
                />
                <p>
                  <span className="align-self-center">{t('pod.conference_room')}</span>
                </p>
              </Link>
            </li>
            <li
              className="hex"
            // onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
            // }
            >
              {/* <Link
                className="hexIn hexLink hexBgImg"
                to={`/collaboration-hub`}
              > */}
              <Link
                  className="hexIn hexLink hexBgImg"
                 onClick={collaborateClick}
                >
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/collaboration-hub-hover.jpg"
                />
                <p>
                  <span className="align-self-center">{t('pod.collaboration_hub')}</span>
                </p>
              </Link>
            </li>
          </>
        ) : undefined}
      

        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
       
        {"1,2,".includes(user.empData?.UserTypeId) ? (
          <>
            <li
              className="hex"
            // onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
            // }
            >
              <Link
                className="hexIn hexLink hexBgImg"
                onClick={WellnessComming}
              >
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/wellness-bg.png"
                />
                <p>
                  <span className="align-self-center">{t('pod.wellness')}</span>
                </p>
              </Link>
            </li>

            <li
              className="hex mobile-none"
            // onMouseLeave={hideRoomMetrics}
            // onMouseOver={() =>
            // 	showRoomMetrics(defaultRooms.earnLearn.GroupGuid)
            // }
            >
              <Link
                className="hexIn hexLink hexBgImg"
                to={`/earn-learn`}
              >
                <img
                  className="hexHoverBg"
                  src="/assets/media/images/lobby.png"
                />
                <p>
                  {" "}
                  <span className="align-self-center">{t('pod.lobby')}</span>{" "}
                </p>
              </Link>
            </li>
          </>
        ) : undefined}
        {"1,2,".includes(user.empData?.UserTypeId) ? (
          <li
            className="hex"
          // onMouseLeave={hideRoomMetrics}
          // onMouseOver={() =>
          // 	showRoomMetrics(defaultRooms.socialHangout.GroupGuid)
          // }
          >
            {/* <Link className="hexIn hexLink hexBgImg" to={`/hangoutroom`}> */}
            <Link
                  className="hexIn hexLink hexBgImg"
                 onClick={hangoutRoomClick}
                >
              <img
                className="hexHoverBg"
                src="/assets/media/images/hangout-hover-bg.png"
              />
              <p>
                <span className="align-self-center">{t('pod.hangout_room')}</span>
              </p>
            </Link>
          </li>
        ) : undefined}

        {"1,2,".includes(user.empData?.UserTypeId) ? (
          <li className="hex">
            <div className="hexIn hexAdd">
              <p>
                <button className="align-self-center" onClick={userLogout}>
                  <svg
                    className="mb-3"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    height="40"
                  >
                    <path
                      d="M13.5 7.5l-3 3.25m3-3.25l-3-3m3 3H4m4 6H1.5v-12H8"
                      stroke="#fff"
                    ></path>
                  </svg>
                  <br /> <span className="small p-0">{t('pod.logout')}</span>{" "}
                </button>
              </p>
            </div>
          </li>
        ) : undefined}
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
        <li className="hex cursor-default">
          <a className="hexIn"></a>
        </li>
      </ul>
      {showTooltip ? (
        <span className="podTooltip" style={{ top: y - 40, left: x - 50 }}>
          <svg
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
          >
            <path
              d="M5.5 0a3.499 3.499 0 100 6.996A3.499 3.499 0 105.5 0zm-2 8.994a3.5 3.5 0 00-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 00-3.5-3.5h-4zm9 1.006H12v5h3v-2.5a2.5 2.5 0 00-2.5-2.5z"
              fill="currentColor"
            ></path>
            <path
              d="M11.5 4a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
              fill="currentColor"
            ></path>
          </svg>{" "}
          {onlineUsrs} {t('pod.online')}
        </span>
      ) : (
        <></>
      )}
      <ShowOrgFiles
        showFiles={showInductionFiles}
        type="Training"
        title="Onboarding"
      ></ShowOrgFiles>
      <ComingSoonPopup show={showComingsoon} screenName={screenName} close={closeComingsoon} message={comingsoonDescription}/>
    </section>

  );
}

export default Pod;
