import React, { useEffect, useRef } from "react";
import { t } from '../../i18n';
import { GetActivities, UpdateActivity } from '../../services/ActivityServices';
import { useDispatch, useSelector } from "react-redux";
import { StoreClass } from "../../store/Store";
import * as moment from 'moment';
import Moment from 'moment';

function fd(dateTime: string | undefined) {
    if (dateTime !== undefined) {
        return moment.utc(dateTime).local().format('DD MMM, yyyy h:mm A');
    }
}
function Activity(props: any) { 
    const {user} = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const dispatch = useDispatch();
    const lock = useRef(false);
    const empGuid = localStorage.getItem('EmpGuid') as string;
    const formattedDate = process.env.REACT_APP_DATE_FORMAT 

    useEffect(() => {
        if(!lock.current){
            dispatch(GetActivities(empGuid) as any);
        return ()=>{
            lock.current = false;
        }
         
    }
    console.log("getting activity dat afor checking",user.activity)
    },[])
    const getMessageType = (type: string)=>{
        switch(type){
            case "CALL_GROUP_INVITATION":
                return "Group call invitation";
            case "CALLING":
                 return "Miss call at";
            case "CALL_REJECTED":
                return "Call rejected at";
            case "CALL_ACCEPTED":
                return "Call Accepted at"
            case "CALENDAR_BLOCK":
                return "Your are invited to Event";
            case "REMINDER":
                return "Reminder: Event at ";
            default: 
                return "";
        }        
    }
    const getActivityIcon = (msgType: string)=>{
        let iconnm = '';
        switch(msgType){
            case 'FILE': 
                iconnm = 'file'; break;
            case 'FOLDER':
                iconnm = 'folder-share'; break;
            case "CALL":
                iconnm = 'incoming'; break;
            case "CALL_MISSED":
                 iconnm = 'missed-call'; break;
            case "CALL_REJECTED":
                iconnm = 'incoming'; break;
            case "CALL_ACCEPTED":
                iconnm = 'incoming'; break;
            case "CALENDAR_BLOCK":
                iconnm = 'schedule'; break;
            case "REMINDER":
                iconnm = 'schedule'; break;
            default: 
                iconnm = 'message';
        }
        return (<img src={`/assets/media/svg/activity/${iconnm}.svg`} />);
    }
    const closeActivityWindows = ()=>{
        props.handleShowActivity();        
    }

    const markViewed = (actId: any)=>{
        dispatch(UpdateActivity(actId) as any);
    }

    return (<>
    <div className={props.showActivity ? "SideNav text-left position-fixed" : "SideNav text-left position-fixed d-none"}>
        <div className="sideNavHeader">
            <div className="CloseNav" onClick={closeActivityWindows}> <img src="/assets/media/svg/x-close.svg" /> </div>
        </div>
        <div className="sideNavBody overflow-auto">
            <div className="row">
                <div className="col-12 mb-2 text-white">
                    <h6 className="m-0">{t('activity.activity')}</h6> </div>
            </div>
            <div className="row activityList">
                {user.activity && user.activity.map((act:any)=>{
                    //const dataJson =JSON.parse(act.Type);
                    return  (
                     <div onClick={()=>{markViewed(act.ActivityId)}} className={"col-sm-12 " + (act.Isviewed?'':'unread c-pointer')} key={act.Guid}>
                        <div className="CheckinRA d-flex">
                            <div className="CheckinRAIcon align-self-center"> {getActivityIcon(act.Type)} </div>
                            <div className="CheckinRADetails">
                                {/* <p>{}</p> */}
                                <p>{getMessageType(act.Status)} {Moment(fd(act.CreatedDt)).format(`${formattedDate} hh:mm A`)}</p>
                                <p>{(act.FromId == empGuid || act.FromId == empGuid.toLowerCase())? 'You':act.FirstName||'Unknown'}</p>
                            </div>
                            {/* <div className="CheckinRAActions align-self-center"> <img src="/assets/media/svg/checkin/openLink.svg" /> </div> */}
                        </div>
                    </div>
                )})}      
                {!user.activity || user.activity.length === 0? <div className="col-12 text-center pt-5 text-light">No activity found</div> :''}
            </div>

            {/*Pagination*/}
        </div>
    </div>
   
    </>);
}


export default Activity;