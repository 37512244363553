//import Avatar from "../../components/avatar"

import { formatTime2, isArabic } from "../common"
import { IChatItem } from "../types"
import { useSelector } from "react-redux"
import { useEffect, useMemo, useRef, useState } from "react"

import styles from "./transcript.module.scss"
import { StoreClass } from "../store/Store"
import { ILanguageItem } from "../services/rtm-types"
import ScrollToBottom from "react-scroll-to-bottom"
import { Link } from 'react-router-dom';

let lastScrollTop = 0

const TranscriptContent = () => {
    const languageSelect: ILanguageItem[] = [{
        source: 'en-US',
        target: [],
    }];

    //const recordLanguageSelect = useSelector((state: RootState) => state.global.recordLanguageSelect)
    // const languageSelect = useSelector((state: RootState) => state.global.languageSelect)

    //const subtitles = useSelector((state: StoreClass) => ({ user: state?.AuthReducer.sttSubtitles }));
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));

    const translate1List: any[] = [];
    const translate2List: any[] = [];
    const transcribe1: string = 'en-US';
    const transcribe2: string = '';
    const contentRef = useRef<HTMLElement>(null)
    const [humanScroll, setHumanScroll] = useState(false)

    const chatList: IChatItem[] = useMemo(() => {
        const reslist: IChatItem[] = []
        console.log("#STT chatList", user.sttSubtitles)
        user.sttSubtitles && user.sttSubtitles.forEach((el) => {
            if (el.lang == transcribe1) {
                const chatItem: IChatItem = {
                    userName: el.username,
                    content: el.text,
                    translations: [],
                    startTextTs: el.startTextTs,
                    textTs: el.textTs,
                    time: el.startTextTs,
                    profileurl:el.profileurl,
                }
                el.translations?.forEach((tran) => {
                    if (translate1List.includes(tran.lang)) {
                        const tranItem = { lang: tran.lang, text: tran.text }
                        chatItem.translations?.push(tranItem)
                    }
                })
                reslist.push(chatItem)
            } else if (el.lang == transcribe2) {
                const chatItem: IChatItem = {
                    userName: el.username,
                    content: el.text,
                    translations: [],
                    startTextTs: el.startTextTs,
                    textTs: el.textTs,
                    time: el.startTextTs,
                    profileurl:el.profileurl,
                }
                el.translations?.forEach((tran) => {
                    if (translate2List.includes(tran.lang)) {
                        const tranItem = { lang: tran.lang, text: tran.text }
                        chatItem.translations?.push(tranItem)
                    }
                })
                reslist.push(chatItem)
            }
        })
        return reslist.sort((a: IChatItem, b: IChatItem) => Number(a.time) - Number(b.time))
    }, [user.sttSubtitles])

    const onScroll = (e: any) => {
        const scrollTop = contentRef.current?.scrollTop ?? 0
        if (scrollTop < lastScrollTop) {
            setHumanScroll(true)
        }
        lastScrollTop = scrollTop
    }
    useEffect(() => {
        console.log("#TB useEffect")
    },[user.sttSubtitles])
    useEffect(() => {
        contentRef.current?.addEventListener("scroll", onScroll)

        return () => {
            contentRef.current?.removeEventListener("scroll", onScroll)
            lastScrollTop = 0
        }
    }, [contentRef])

    useEffect(() => {
        if (humanScroll) {
            return
        }
        if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight
        }
    }, [chatList, humanScroll])

    return (
        <ScrollToBottom className={styles.record} checkInterval={20}>
        <section  ref={contentRef}>
            {chatList.map((item, index) => (
                <div key={index} className={styles.item}>
                     <div className={styles.left}>
                     <img  style={{borderRadius:'50%',objectFit:'contain',border:'1px solid grey'}} width="32px" height="32px" src={item.profileurl ? item.profileurl : '/assets/media/svg/profile/profile_picture.PNG'} />
                    
                    </div> 
                    <div className={styles.right}>
                        <div className={styles.up}>
                            <div className={styles.userName}>{item.userName}</div>
                            <div className={styles.time}>{formatTime2(item.time)}</div>
                        </div>
                        <div className={styles.bottom}>
                            <div className={styles.content}>{item.content}</div>
                            {item.translations?.map((tran, index) => (
                                <div className={styles.translate} key={index}>
                                    <span className={styles.bold}>{`[${tran?.lang}]: `}</span>
                                    <span className={`${isArabic(tran?.lang) ? styles.arabic : ""}`}>
                                        {tran?.text}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </section>
        </ScrollToBottom>
    )
}

export default TranscriptContent
