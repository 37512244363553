import React from 'react';
import CheckinGrid from './CheckinGrid';
import CheckinsRightMenu from './CheckinsRightMenu';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

function CheckinHistory(props: any) {   
    return (
		<section className="bgCadet position-relative d-flex pt-4 minh-100">
        <div className="col-sm-8 popup-settings ml-auto">
				<CheckinsRightMenu path={window.location.pathname} />
            <div className="row">
                <div className="col-sm-10 m-auto popup-settings">
                <Link to="/checkins" className="mb-2 mb-block d-none">
                    <svg enable-background="new 0 0 64 64" height="40" viewBox="0 0 64 64" width="40" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path></svg>
                </Link>
                    <div className="card loginBox addTeamMemberBox mt-3 overflow-box">
                        <div className="card-body pl-4 pr-4">
                            <div className="col-12 p-0 d-flex mb-3">
                                <span className="tab-btn-parent  mb-block d-none">
                                <Link to="/checkins">
                                        <button type="button" className="btn btn-tab mr-2">Check-Ins</button>
                                    </Link>
                                    {/* <Link to="/CheckinHistory">
                                        <button type="button" className="btn btn-tab mb-block d-none active mr-2">Check-In history</button>
                                    </Link> */}
                                    <Link to="/TeamCheckinHistory">
                                        <button type="button" className="btn btn-tab mr-2">Team check-ins</button>
                                    </Link>
                                </span>
                            </div>
                            <div className="row m-auto">
                                <div className="col-12 mb-3 text-white p-0">
                                    <h5>Check-In History</h5> 
								</div>
                                <CheckinGrid selectEmp={-1} showZero={'FALSE'}></CheckinGrid>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
		</section>
    );
}

export default CheckinHistory;