import React, { useEffect, useState } from "react";
import { ShowToast } from "../../services/UserActions";
import { Table } from "../../components/Table";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as T from "../../actions/Types";
import { OnGoingStepChallenges } from "../../utils/types";
import { formatDateForInput } from "../../utils/helper";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import { GetChallenges, SaveNewChallenge, GetNewChallenges, SavestatusChallenges } from "../../services/WellnessServices";
import { globalStore } from "../../store/global_store";
import { removeSuccessStatus } from "../../services/ContactsService";
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { formatUtcDate } from "../../utils/helper";
import { Modal } from 'react-bootstrap';
import ConfirmPopup from "../../layout/ConfirmPopup";

var deleteText: string = '';
var deleteStepId: number;
interface Challenges {
    ChallengeId: number;
    OrgId: number;
    ChallengeGuid: string;
    ChallengeName: string;
    IsActive: boolean;
    CreatedBy: string;
    CreatedDate: string;
    UpdatedBy: string;
    UpdatedDate: string;
}



function NewChallengesList(props: any) {
    const OrgGuid = localStorage.getItem('OrgGuid');
    const [NewChallenges, setNewChallenges] = useState([] as any);
    const [challengesNew, SetChallengeNew] = useState([] as any)
    const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
    const [challengesList, SetChallenges] = useState([] as any);
    const EmpGuid = localStorage.getItem('EmpGuid') as string;
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [showCreateChallengePopup, setShowCreateChallengePopup] = useState(false);
    const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
    const formattedDate = process.env.REACT_APP_DATE_FORMAT

    // const [filteredChallenges, setFilteredChallenges] = useState([] as OnGoingStepChallenges[]);


    var staticdate = new Date();
    var staticEnddate = new Date();  // Create a new Date object with the current date and time
    staticEnddate.setDate(staticdate.getDate() + 1)
    const history = useNavigate();
    const formData = new FormData();
    const dispatch = useDispatch();
    const [challengeForm, setFormControls] = useState<any>({
        EmpGuid: EmpGuid || "",
        ChallengeGuid: "",
        Description: "",
        Requirement: "",
        Goal: "",
        StartDate: Moment().format('MM-DD-YYYY'),
        EndDate: Moment().add(1, 'days').format('MM-DD-YYYY'),
        MetricType: "sum",
        staringValue: 0,
        ChallengeType: "",
        StepId: 0,
        // enrollDate: new Date()
        // Moment(date).add(1, 'days').toDate();
    });


    console.log(NewChallenges)
    useEffect(() => {
        dispatch({
            type: T.ADD_LOADING
        })
        GetChallenges(OrgGuid)
            .then((resp: any) => {
                SetChallenges([...resp.result])
                if (resp.status == "success") {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
                else if (resp == "Error") {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: T.REMOVE_LOADING
                })

                return "error"
            })
        dispatch({
            type: T.REMOVE_LOADING
        })
    }, [])

    useEffect(() => {
        if (NewChallenges && NewChallenges.length > 0) {
            const updatedChallenges = NewChallenges.map((challenge: any, i: number) => ({
                ...challenge,
                TotalCount: NewChallenges.length,
                RowNumber: i + 1
            }));

            SetChallengeNew(updatedChallenges?.filter((newChallenge: any) =>
                newChallenge.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
                && newChallenge.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
            );

        } else
            SetChallengeNew([]);
    }, [NewChallenges]);

    useEffect(() => {
        if (NewChallenges && NewChallenges.length > 0) {
            const challenge = NewChallenges.filter((challenge: any) =>
                (challenge.ChallengeName.toLowerCase().includes(filesTableParams.Search.toLowerCase())) ||
                (challenge.ChallengeType.toLowerCase().includes(filesTableParams.Search.toLowerCase())) ||
                (challenge.IsActive ? 'live'.startsWith(filesTableParams.Search.toLowerCase())  
                         : 'inactive'.startsWith(filesTableParams.Search.toLowerCase()))
            );

            challenge.forEach((newChallenge: any, i: any) => {
                newChallenge.TotalCount = challenge.length, newChallenge.RowNumber = i + 1
            });

            SetChallengeNew(challenge.filter((newChallenge: any) =>
                newChallenge.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
                && newChallenge.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
            );
        }
    }, [filesTableParams])


    useEffect(() => {
        if (NewChallenges && NewChallenges.length === 0) {
            dispatch({
                type: T.ADD_LOADING
            })
            GetNewChallenges(OrgGuid)
                .then((resp: any) => {
                    setNewChallenges([...resp.result]);
                    if (resp.status === "success") {
                        dispatch({
                            type: T.REMOVE_LOADING
                        })
                    }
                    else if (resp === "Error") {
                        
                        dispatch({
                            type: T.REMOVE_LOADING
                        })
                    }
                })
                .catch((e: any) => {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })

                    return "error"
                })
            dispatch({
                type: T.REMOVE_LOADING
            })
        }
    }, [])


    const fetchingNewChallenges = () => {
        
        dispatch({
            type: T.ADD_LOADING
        })
        GetNewChallenges(OrgGuid)
            .then((resp: any) => {
                setNewChallenges([...resp.result]);
                if (resp.status === "success") {
                    
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
                else if (resp === "Error") {
                    
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: T.REMOVE_LOADING
                })

                return "error"
            })
        dispatch({
            type: T.REMOVE_LOADING
        })
    }


    function headerRender(NewChallenges: any) {
        // Check if any item in the list has a StepId
        const hasStepId = NewChallenges.some((challenge: any) => challenge.StepId);

        return hasStepId ? (
            <tr>
                <th>Type</th>
                <th>Description</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Status</th>
            </tr>
        ) : null;
    }

    const editEmployee = (e: any, row: any) => {
        setFormControls({
            ChallengeGuid: row.ChallengeGuid,
            Description: row.ChallengeName,
            Requirement: row.Requirement,
            Goal: row.Goal,
            StartDate: Moment(row.StartDate).format('MM-DD-YYYY'),
            EndDate: Moment(row.EndDate).format('MM-DD-YYYY'),
            MetricType: row.MetricType,
            ChallengeType: row.ChallengeType,
            StepId: row.StepId
        });
        setShowCreateChallengePopup(true);
    }
    const handleShowDeleteChallenge = (e: any, row: any) => {
        console.log(row)
        deleteText = row.IsActive ? "Inactive" : "Live";
        deleteStepId = row.StepId
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }

    function rowRender(row: OnGoingStepChallenges, index: number) {
        return (
            <tr key={index}>
                <td>{row.ChallengeType}</td>
                <td style={{ width: '42%' }}>{row.ChallengeName}</td>
                <td>{Moment(row.StartDate).format(formattedDate)}</td>
                <td>{Moment(row.EndDate).format(formattedDate)}</td>
                <td>{row.IsActive ? "Live" : "Inactive"}</td>
                <td className="text-right dropdown mobile-none">
                    <svg id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                    </svg>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                        {row.IsActive ? <a onClick={e => editEmployee(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Edit</a> : null}
                        <a onClick={e => handleShowDeleteChallenge(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg>{row.IsActive ? "Inactive" : "Live"}</a>
                    </div>
                </td>
            </tr>
        )
    }




    const handleFormControls = (e: React.ChangeEvent<any>, control: string) => {
        const value = e.target.value;
        setFormControls({ ...challengeForm, [control]: value });
    };

    const handleAggregationRadio = (e: React.ChangeEvent<any>, aggregate: string) => {
        setFormControls({ ...challengeForm, MetricType: aggregate });
    };

    const checkFormValues = (form: any) => {
        for (let key in form) {
            if (key === "ChallengeType") {
                continue;
            }
            if (form[key] === "" || form[key] === undefined || form[key] === null) {
                return false;
            }
        }
        return true;
    };

    const saveHandleNewChallenge = () => {
        dispatch({
            type: T.ADD_LOADING
        })
        SaveNewChallenge(formData)
            .then((resp: any) => {
                fetchingNewChallenges();
                if (resp.status === "success") {
                    dispatch(ShowToast({ type: 'Message', title: 'Status', message: 'Challenge created/updated successfully' }) as any)
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
                else if (resp === "Error") {
                    dispatch(ShowToast({ type: 'Message', title: 'Status', message: 'something Went wrong' }) as any)
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
            })
    }



    const saveChallengeFormData = () => {
        setIsSubmitted(true);
        if (!checkFormValues(challengeForm)) return;
        formData.append("EmpGuid", EmpGuid)
        formData.append("ChallengeGuid", challengeForm.ChallengeGuid);
        formData.append("Description", challengeForm.Description);
        formData.append("Requirement", challengeForm.Requirement);
        formData.append("Goal", challengeForm.Goal);
        let startDate = new Date(challengeForm.StartDate);
        if (!isNaN(startDate.getTime())) {
            const isoDate = startDate.toISOString().split('T')[0];
            formData.append("StartDate", isoDate);
        }
        const UTCStartTime = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes());

        formData.append('UTCStartTime', UTCStartTime.toString());

        let endDate = new Date(challengeForm.EndDate);
        if (!isNaN(endDate.getTime())) {
            const isoDate = endDate.toISOString().split('T')[0];
            formData.append("EndDate", isoDate);
        }
        const UTCEndTime = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes());

        formData.append('UTCEndTime', UTCEndTime.toString());
        formData.append('offset', new Date().getTimezoneOffset().toString());
        formData.append("MetricType", challengeForm.MetricType);
        formData.append('StepId', challengeForm.StepId === null || challengeForm.StepId === undefined ? '0' : challengeForm.StepId);
        saveHandleNewChallenge();
        resetAllControls();
        setShowCreateChallengePopup(false)
        setIsSubmitted(false);
    };



    const handleCloseChallengePopup = () => {
        resetAllControls();
        setShowCreateChallengePopup(false)
        setIsSubmitted(false);

    }

    const resetAllControls = () => {
        setFormControls({
            EmpGuid: EmpGuid || "",
            ChallengeGuid: "",
            Description: "",
            Requirement: "",
            Goal: "",
            StartDate: Moment().format('MM-DD-YYYY'),
            EndDate: Moment().add(1, 'days').format('MM-DD-YYYY'),
            MetricType: "sum",
            staringValue: 0,
            ChallengeType: "",
            // enrollDate: "",
        });
    };

    const handleStartDate = (date: any) => {
        if (date && date !== challengeForm.StartDate) {
            // Update the StartDate and automatically set EndDate to the next day
            const newEndDate = Moment(date).add(1, 'days').format('MM-DD-YYYY');
            setFormControls({
                ...challengeForm,
                StartDate: date,
                EndDate: newEndDate
            });
        }
    };

    const handleEndDate = (date: any) => {
        if (date && date !== challengeForm.EndDate) {
            setFormControls({ ...challengeForm, EndDate: date });
        }
    };

    const getNextDay = (date: any) => {
        return Moment(date).add(1, 'days').toDate();

    }

    const handleShowChallegePopup = () => {
        setShowCreateChallengePopup(true)
    }

    const handleCloseDeleteUser = (e: any, emp: any) => {
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }

    const deleteChallenge = () => {
        dispatch({
            type: T.ADD_LOADING,
        });
        SavestatusChallenges(deleteStepId)
            .then((resp: any) => {
                if (resp.status === "success") {
                    dispatch({
                        type: T.REMOVE_LOADING,
                    });
                    dispatch(ShowToast({ type: 'Message', title: 'Status', message: `Updated status  ${deleteText} successfully` }) as any)
                    dispatch(removeSuccessStatus() as any);

                } else if (resp === "Error") {
                    dispatch(ShowToast({ type: 'Message', title: 'Status', message: 'something Went wrong' }) as any)
                    dispatch(removeSuccessStatus() as any);
                    dispatch({
                        type: T.REMOVE_LOADING,
                    });
                }
                fetchingNewChallenges()
                dispatch({
                    type: T.REMOVE_LOADING,
                });
            });
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }




    return (<>
        <ConfirmPopup show={showConfirmDeleteUser} close={handleCloseDeleteUser} okHandle={deleteChallenge} message={"Do you want to " + deleteText.toLowerCase() + " this Challenges?"} />
        <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
            <div className="container-fluid">
                <div className="row mt-4 justify-content-center">
                    <Table
                        id='users'
                        data={challengesNew || []}
                        headerRender={() => headerRender(NewChallenges)}
                        rowRender={rowRender}
                        onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
                        onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
                        createLabel={'Create New Challenges'}
                        rootUrl={'/AdminChallenges'}
                        // onCreate={() => onCreate()}
                        onCreate={(e: any) => handleShowChallegePopup()}
                        header={"New wellness initiatives"}
                        subHeader=""
                        noDataMsg="Initiatives coming soon"
                        {...props}>
                    </Table>
                </div>
            </div>
            <Modal show={showCreateChallengePopup} onHide={handleCloseChallengePopup} backdrop="static">
                <Modal.Header closeButton title="Close" className="border-0">
                    <Modal.Title className="modal-title text-light">
                        <h5 className="modal-title">{challengeForm.StepId > 0 ? "Edit Wellness initiative" : "New Wellness initiative"}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="col-12 p-0">
                            <div className="challenge-form">
                                <div className="col-12 p-0">
                                    <h5>Select wellness initiative</h5>
                                    <select
                                        id="challenge-dropdown"
                                        className="form-control challengesview-input"
                                        onChange={(event) => handleFormControls(event, "ChallengeGuid")}
                                    >
                                        <option>{challengeForm.ChallengeType.length > 0 ? challengeForm.ChallengeType : "Select"}</option>
                                        {challengesList.map((challenge: Challenges) => (
                                            <option key={challenge.ChallengeGuid} value={challenge.ChallengeGuid}>
                                                {challenge.ChallengeName}
                                            </option>
                                        ))}
                                    </select>
                                    {isSubmitted && !challengeForm.ChallengeGuid ? (
                                        <div className="text-danger mt-2" style={{ fontSize: '12px' }}>Challenges is required</div>
                                    ) : ('')}

                                </div>
                                <div className="col-12 p-0 mt-3">
                                    <h5>Description</h5>
                                    <textarea
                                        id="challenge-description"
                                        className="form-control challenges-textarea"
                                        onChange={(event) => handleFormControls(event, "Description")}
                                        value={challengeForm.Description || ''}
                                    ></textarea>
                                    {isSubmitted && !challengeForm.Description ? (
                                        <div className="text-danger mt-2" style={{ fontSize: '12px' }}>Description is required</div>
                                    ) : ('')}

                                </div>
                                <div className="col-12 mt-3 p-0">
                                    <h5>Requirements</h5>
                                    <input
                                        type="text"
                                        id="challenge-requirements"
                                        className="form-control challengesview-input"
                                        onChange={(event) => handleFormControls(event, "Requirement")}
                                        value={challengeForm.Requirement || ''}
                                    />
                                    {isSubmitted && !challengeForm.Requirement ? (
                                        <div className="text-danger mt-2" style={{ fontSize: '12px' }}>Requirements is required</div>
                                    ) : ('')}

                                </div>
                                <div className="col-12 mt-3 p-0">
                                    <h5>Target / Goal</h5>
                                    <input
                                        type="text"
                                        id="challenge-target"
                                        className="form-control challengesview-input"
                                        onChange={(event) => handleFormControls(event, "Goal")}
                                        value={challengeForm.Goal || ''}
                                    />
                                    {isSubmitted && !challengeForm.Goal ? (
                                        <div className="text-danger mt-2" style={{ fontSize: '12px' }}>Target / Goal is required</div>
                                    ) : ('')}


                                </div>
                                <div className="col-12  row mt-3 m-0 p-0">
                                    {/* <div className="col-6 pl-0">
                            <h5>Start Date </h5>
                            <input
                            id="challenge-startdate"
                            className="form-control challengesview-input"
                            type="date"
                            onChange={(event) => handleFormControls(event, "StartDate")}
                            />
                        </div> */}
                                    <div className="col-12 row  m-0 p-0">
                                        <div className="col-6 pl-0">
                                            <h5>Start date </h5>
                                            <DatePicker
                                                className="SuteInputPrimary h40px"
                                                selected={challengeForm.StartDate ? new Date(challengeForm.StartDate) : null}
                                                onChange={date => handleStartDate(date)}
                                                name="startDate"
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                value={challengeForm.StartDate ? Moment(challengeForm.StartDate).format(formattedDate) : ""}
                                            />
                                        </div>
                                        <div className="col-6 pl-0">
                                            <h5>End date </h5>
                                            <DatePicker className="SuteInputPrimary h40px"
                                                selected={challengeForm.EndDate ? new Date(challengeForm.EndDate) : null}
                                                onChange={date => handleEndDate(date)}
                                                name="endDate"
                                                dateFormat="dd/MM/yyyy"
                                                minDate={getNextDay(challengeForm.StartDate)}
                                                value={challengeForm.EndDate
                                                    ? Moment(challengeForm.EndDate).format(formattedDate) : ""
                                                }
                                            />
                                        </div>
                                        {isSubmitted && (!challengeForm.StartDate || !challengeForm.EndDate) ? (
                                            <div className="text-danger mt-2" style={{ fontSize: '12px' }}>First date and Last date is required</div>
                                        ) : ('')}

                                    </div>
                                </div>
                                <div className="col-12  row mt-3 m-0 p-0">
                                    <div className="col-6 pl-0">
                                        <h5>Leader board aggregation</h5>
                                        <input
                                            type="radio"
                                            id="sum"
                                            name="fav_language"
                                            value={challengeForm.MetricType && challengeForm.MetricType.length > 0 ? challengeForm.MetricType : "sum"}
                                            checked={!challengeForm.MetricType || challengeForm.MetricType === "sum"}
                                            onChange={(event) => handleAggregationRadio(event, "sum")}
                                        />
                                        <label htmlFor="sum" className="color-white ml-2 mr-2">
                                            Sum
                                        </label>
                                        <input
                                            type="radio"
                                            id="average"
                                            name="fav_language"
                                            value={challengeForm.MetricType && challengeForm.MetricType.length > 0 ? challengeForm.MetricType : ""}
                                            checked={challengeForm.MetricType === "average"}
                                            onChange={(event) => handleAggregationRadio(event, "average")}
                                        />
                                        <label htmlFor="average" className="color-white ml-2">
                                            Average
                                        </label>
                                    </div>

                                    <div className="col-3 p-0">
                                        <button
                                            type="button"
                                            className="btn enroll-btn w-100"
                                            onClick={saveChallengeFormData}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    </>
    );
}
export default NewChallengesList;