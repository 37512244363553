import { empData } from '../interfaces/EmployeeData';
import axios from 'axios';
import JwtDecode from 'jwt-decode';
import * as T from '../actions/Types';
import { forEach } from 'lodash';
import { Socket } from "socket.io-client";
import { ITextItem, STTDataType } from '../types';

export interface ISttData {
    taskId?: string
    token?: string
    startTime?: number // ms
    duration?: number // ms
    status?: "start" | "end"
}
interface IState {
    loading: boolean;
    accounts?: any[];
    messages: any;
    messageCount: any;
    contacts?: any[];
    prefcontacts?: any[];
    groups?: any[];
    teamMems?: any[];
    CurRoomMems?: any[];
    isAuthenticated: boolean;
    error?: any;
    runningMeeting?: any;
    activeMeetings?: any[];
    meetingInfo?: any;
    signupResponse?: any;
    success?: string;
    orgData?: any;
    orgList?: any;
    empData?: any;
    empList?: any;
    empListReportingTo?: any;
    roomStats?: any;
    orgDataUpload?: any;
    profileLogoUpload?: any;
    avSettingsUpload?: any;
    status: string;
    statusMsg: string;
    updateUserProfile?: any;
    folderList?: any[];
    userFiles: any[];
    downloadFileResponse?: any;
    sus?: any;
    aus?: any;
    activity?: any[];
    sharedFiles?: any[];
    SharedToContactList?: any[];
    newMsgsCount: number;
    filesResponse: any;
    activityCount?: number;
    toast: any;
    pus?: any[];
    IP: string;
    checkins?: any[];
    orgFiles?: any[];
    EmailConfirmed: number;
    OrgGuid: string;
    UserTypeId: number;
    IsGuest: boolean;
    InCall: boolean;
    empGUID: string;
    Socket: Socket;
    SocketStatus: string;
    openCall: boolean;
    selectedUser: any;
    FirstName: string;
    InChat: boolean;
    selectedChatUser: any;
    sttData: ISttData;
    sttSubtitles: ITextItem[]
}

const defaultState: IState = {
    loading: false,
    success: "",
    orgData: {},
    empData: new empData(),
    orgDataUpload: {},
    profileLogoUpload: {},
    updateUserProfile: {},
    avSettingsUpload: {},
    status: 'Available',
    statusMsg: "Available",
    aus: {},
    sus: {},
    sharedFiles: [],
    messages: {},
    messageCount: {},
    SharedToContactList: [],
    newMsgsCount: 0,
    filesResponse: {},
    userFiles: [],
    pus: [],
    toast: {
        type: '',
        message: '',
        title: 'Message'
    },
    IP: '',
    IsGuest: false,
    InCall: false,
    OrgGuid: '',
    empGUID: '',
    Socket: undefined,
    isAuthenticated: CheckAuthentication(),
    SocketStatus: 'DEFAULT',
    openCall: false,
    selectedUser: undefined,
    FirstName: '',
    InChat: false,
    selectedChatUser: undefined,
    sttData: {status: "end",},
    sttSubtitles:[]
};

const AuthReducer = (state: IState = defaultState, action: any): IState => {

    switch (action.type) {
        case T.ADD_LOADING:
            return { ...state, loading: true };
        case T.REMOVE_LOADING:
            return { ...state, loading: false };
        case T.SET_AUTHENTICATED:
            console.log('SET_AUTHENTICATED', ...action.payload)
            return {
                ...state, isAuthenticated: true, ...action.payload
            }
        case T.SAVE_SUCCESS:
            return { ...state, loading: false, success: action.success }
        case T.REMOVE_SUCCESS:
            return { ...state, loading: false, success: "", error: null }
        case T.LOGIN_SUCCESS:  
                  console.log("#OBj LOGIN_SUCCESS")
            return {
                ...state, loading: false,
                isAuthenticated: true,
                OrgGuid: action.payload.OrgGuid,
                empGUID: action.payload.EmpGuid,
               FirstName: action.payload.FirstName,
              
                //...action.payload
            }
        case T.LOGIN_FAILURE:
            return { ...state, loading: false, error: { ...action.payload } };
        case T.LOGOUT:
            return { ...defaultState, isAuthenticated: false, OrgGuid: '', empGUID: '' }
        case T.GET_ACCOUNTS_SUCCESS:
            return { ...state, accounts: action.accounts };
        case T.GET_CONTACTS_SUCCESS:
            const conts = Array.isArray(action.contacts) ? action.contacts : action.contacts.result;
            conts.forEach((ele: any) => {
                const isp = state.pus?.filter((p: any) => { return p.id === ele.EmpGuid });
                if (isp && isp.length > 0)
                    console.log('alreadt pus')
                else
                    state.pus?.push({ "id": ele.EmpGuid, "atp": '' });

            });
            return { ...state, contacts: conts };
        case T.PREF_CONTACTS_SUCCESS:
            return { ...state, prefcontacts: Array.isArray(action.contacts) ? action.contacts : action.contacts.result };
        case T.GET_TEAMS_SUCCESS:
            return { ...state, groups: action.groups.result };
        case T.GET_TEAM_MEMBERS:
            return { ...state, teamMems: Array.isArray(action.contacts) ? action.contacts : action.contacts.result };
        case T.GET_MESSAGES_SUCCESS:
            return { ...state, messages: action.messages };
        case T.CREATE_MEETING:
            return { ...state, runningMeeting: action.payload };
        case T.ACTIVE_MEETINGS:
            return { ...state, activeMeetings: action.payload };
        case T.GET_MEETING_INFO:
            return { ...state, meetingInfo: action.payload };
        case T.CREATE_ACCOUNT_SUCCESS:
            return { ...state, loading: false, signupResponse: action.payload }
        case T.CREATE_ACCOUNT_FAILURE:
            return { ...state, loading: false, signupResponse: null, error: { ...action.payload } }
        case T.CREATE_GROUP_SUCCESS: {
            return { ...state, loading: false, signupResponse: action.payload }
        }
        case T.CREATE_GROUP_FAILURE: {
            return { ...state, loading: false, signupResponse: null, error: true }
        }
        case T.CREATE_ROOM_FAILURE:
            return { ...state, loading: false, error: { message: action.payload, msgType: 'RoomCreateError' } };
        case T.ROOM_STATS:
            return { ...state, loading: false, roomStats: { ...state.roomStats, ...action.payload } }
        case T.GET_ROOM_MEM_SUCCESS:
            return { ...state, loading: false, CurRoomMems: action.payload }
        case T.GET_ORG_DETAILS_SUCCESS:
            return { ...state, orgData: action.data };
        case T.GET_ORG_LIST_SUCCESS:
            return { ...state, orgList: action.data };
        case T.GET_ORG_LIST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case T.GET_EMP_DATA_SUCCESS:
            return { ...state, empData: action.data };
        case T.GET_EMP_LIST_SUCCESS:
            return { ...state, empList: action.data };
        case T.GET_EMP_LIST_REPORTINGTO_SUCCESS:
            return { ...state, empListReportingTo: action.data, success: "reporting-success" };
        case T.ORG_LOGO_UPLOAD_SUCCESS:
            return { ...state, loading: false, orgDataUpload: action.payload };
        case T.ORG_LOGO_UPLOAD_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case T.PROFILE_IMAGE_UPLOAD_SUCCESS:
            return { ...state, loading: false, profileLogoUpload: action.payload };
        case T.PROFILE_IMAGE_UPLOAD_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case T.ADD_AVSETTINGS_SUCCESS:
            return { ...state, loading: false, avSettingsUpload: action.payload }
        case T.GET_EMP_STATUS:
        case T.UPDATE_EMP_STATUS:
            return { ...state, ...action.payload }
        case T.UPDATE_USER_PROFILE_SUCCESS:
            return { ...state, loading: false, updateUserProfile: action.payload }
        case T.UPDATE_USER_PROFILE_FAILURE:
            return {
                ...state, loading: false, error: action.payload
            }
        case T.GET_FOLDER_SUCCESS:
            return {
                ...state, loading: false, folderList: action.payload
            }
        case T.SHARED_FILE_CONTACTS_GET_SUCCESS:
            return {
                ...state, loading: false, SharedToContactList: action.data
            }
        case T.SUTE_USER_STATUS: {
            return { ...state, sus: { ...state.sus, ...action.payload }, loading: false };
        }
        case T.AGORA_USER_STATUS: {
            return { ...state, aus: { ...state.aus, ...action.payload }, loading: false };
        }
        case T.GET_ACTIVITY_SUCCESS: {
            return { ...state, activity: action.payload, loading: false };
        }
        case T.UPDATE_ACTIVITY_SUCCESS: {
            const activitylist = state.activity || [];
            activitylist.map((a: any) => {
                if (a.ActivityId === action.payload.ActivityId) {
                    a.Isviewed = true;
                }
            });
            return { ...state, activity: [...activitylist], loading: false };
        }
        case T.SHARED_FILES_GET_SUCCESS: {
            return { ...state, sharedFiles: (action.data || {}).result || [], loading: false };
        }
        case T.GET_MSG_LIST: {
            state.messages[action.payload.peerId] = [];
            const msgs = [...action.payload.results, ...(state.messages[action.payload.peerId] || [])];
            return { ...state, messages: { ...state.messages, [action.payload.peerId]: msgs } }
        }
        case T.STORE_MSG:
            const msgs = state.messages[action.payload.peerId] || [];
            const exists = msgs.filter((m: any) => m.UUID == action.payload.msg.UUID);
            if (exists.length > 0) {
                return state;
            }
            msgs.push(action.payload.msg);
            state.messages[action.payload.peerId] = msgs;
            return { ...state, messages: { ...state.messages }, newMsgsCount: state.newMsgsCount + 1 };
        case T.DELETE_MSG: {
            const msgs1 = state.messages[action.payload.peerId] || [];
            const exists1 = msgs1.filter((m: any) => m.UUID !== action.payload.msgId);
            state.messages[action.payload.peerId] = exists1;
            return { ...state, messages: { ...state.messages } };
        }
        case T.GET_UNREAD_MSGS: {
            return { ...state, messageCount: { ...state.messageCount, ...action.payload } }
        }
        case T.UPDATE_MSG: {
            return { ...state, messageCount: { ...state.messageCount, [action.payload.peerId]: 0 } }
        }
        case T.USER_FILES_UPLOADED: {
            return { ...state, filesResponse: action.payload };
        }
        case T.GET_FILES_LIST: {
            return { ...state, userFiles: (action.payload || []), loading: false };
        }
        case T.USER_FILES_SENTMSG:
            return { ...state, filesResponse: "" };
        case T.CLEAR_STORE:
            return defaultState;
        case T.TOAST:
            return { ...state, toast: action.payload };
        case T.GET_IP_ADRESS:
            return { ...state, IP: action.payload };
        case T.CREATE_CHECKIN_SUCCESS:
            return { ...state, loading: false, success: action.payload };
        case T.CREATE_CHECKIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case T.GET_CHECKIN_SUCCESS:
            return { ...state, loading: false, checkins: action.payload };
        case T.CREATE_ORGFILE_SUCCESS:
            return { ...state, loading: false, success: action.payload };
        case T.CREATE_ORGFILE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case T.GET_ORGFILES_SUCCESS:
            return { ...state, loading: false, orgFiles: action.payload };
        case T.UPDATE_RATING_SUCCESS:
            return { ...state, loading: false, success: action.payload };
        case T.ADD_SOCKET:
            return { ...state, Socket: action.payload, SocketStatus: 'SOCKET' };
        case T.ADD_INCALL:
            return { ...state, InCall: action.payload };
        case T.OPEN_CALL:
            return { ...state, openCall: action.payload };
        case T.SELECTED_USER:
            return { ...state, selectedUser: action.payload };
        case T.IS_GUEST:
            return { ...state, IsGuest: action.payload };
        case T.SAVE_MSG:
            return { ...state, success: "Save message" };
        case T.ADD_INCHAT:
            return { ...state, InChat: action.payload };
        case T.SELECTED_CHAT_USER:
            return { ...state, selectedChatUser: action.payload };
        case T.SUCCESS:
            return { ...state, success: action.payload };
        case T.STTDATA:
            return { ...state, sttData: action.payload };
            case T.STTLIST:{
                console.log("#TB STTLIST")
            return { ...state, sttSubtitles: [...action.payload] };
            }
        default: return state;
    }
};

function CheckORG() {
    const org = localStorage.getItem('OrgGuid') || ''
    console.log('#TEST CheckORG ', org)
    return org;
}
function CheckEMP() {
    const emp = localStorage.getItem('EmpGuid') || ''
    console.log('#TEST CheckEMP ', emp)
    return emp;
}
function CheckAuthentication() {
    console.log('#TEST CheckAuthentication ')
    const authToken = undefined;// localStorage.webtoken;
    const zoomLoggedIn = localStorage.getItem('zo');
    const zoomToken = localStorage.getItem('zoomToken');
    if (authToken) {
        const decodedToken: any = JwtDecode(authToken);
        if (decodedToken.exp * 1000 < Date.now()) {
            //  store.dispatch(Logout());
            return false;
        } else {
            // chatClient.init(localStorage.getItem('EmpGuid')||'');
            //  store.dispatch({ type: SET_AUTHENTICATED });

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
            // store.dispatch(getUserData());           
            return true;
        }

    } else if (localStorage.getItem('msAccessToken') && localStorage.getItem('isAuthenticated')) {
        //store.dispatch({ type: SET_AUTHENTICATED });
        axios.defaults.headers.common['x-auth-token'] = localStorage.msAccessToken;
        return true;
    } else if (zoomLoggedIn !== null) {
        if (zoomLoggedIn === '1') {
            //checkZoomAuht();
            //store.dispatch({ type: SET_AUTHENTICATED });
            axios.defaults.headers.common['x-auth-token'] = zoomToken;
            return true;
        }
    }
    return false;
};

export default AuthReducer;
