import { useState, useEffect, useRef } from 'react';
import { getEmployeeDetailsById } from "../../services/UserActions";
import { t } from '../../i18n';
import UserChatComponent from '../../components/UserChatComponent';
import TimeSlotPopup from './TimeSlotPopup';
import { SaveMyActivity, GetToActivitie } from '../../services/ActivityServices';
import { useLocation } from 'react-router-dom';
import AgoraRTC, { IAgoraRTCClient, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import suteLogs from '../../utils/log';
import { RemoteStreamUser, iRemoteStreams, localStreamUser } from '../../interfaces/icall';
import RemotePlayer from '../rooms/remote-player';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../../store/Store';
import { Card, ListGroup } from 'react-bootstrap';
import { GetUnseenUserMessagesCount } from '../../services/ChatServices';
import * as T from '../../actions/Types';

export default function IncomingCallDialog(props: any) {
    const [video, setVideo] = useState(true);
    const [audio, setAudio] = useState(false);
    //  const [joined, setJoined] = useState(false);
    const joined = useRef(false);
    const [published, setPublished] = useState(false);
    const empId = localStorage.getItem('EmpGuid') || '';
    let currentId = localStorage.getItem('EmpId') || 0 as number;
    const userDisplayName = localStorage.getItem('FirstName');
    const [started, setStarted] = useState(false);
    const [ringTone] = useState(new Audio('/assets/audios/telephone-ring-01.mp3'));
    const [callerDtl, setCallerDtl] = useState<any>({});
    const [chatting, setChatting] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [time, setTime] = useState(0);
    const [timer, setTimer] = useState<any>(0);
    const [requestInvitation, setRequestInvitation] = useState<any>(undefined);
    let InCall = useRef<any>(0);
    const [gotReq, setGotReq] = useState(false);
    const dispatch = useDispatch();
    const [txt, setTxt] = useState("is calling you.");


    let localStream = useRef<localStreamUser>({
        streamID: 0,
        audio: false,
        video: false,
        screen: false,
        localAudioTrack: undefined,
        localVideoTrack: undefined,
        localDetails: { audio: 'YES', video: 'YES' },
        errAudio: false,
        errVideo: false,
        audioDescription: '',
        videoDescription: '',
    });

    const [localUser, setLocalUser] = useState<localStreamUser>(localStream.current);
    let [meetingId, setMeetingId] = useState('');
    let Lclient: IAgoraRTCClient = ({} as IAgoraRTCClient);
    let callringTimer: any = 0;
    const [client, setClient] = useState<any>(undefined);
    const [callType, setCallType] = useState<any>('video');
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const SuteLog = new suteLogs();
    const token = undefined;
    const appId = process.env.REACT_APP_AGORA_APP_ID as string;
    const [uid, setSid] = useState<string>('');
    let remoteUsers = useRef<RemoteStreamUser[]>([]);
    const [remoteStreams, setRemoteStreams] = useState<RemoteStreamUser[]>([]);
    const location = useLocation();
    let loc = useRef<string>('');
    const [emps, setEmps] = useState<any[]>([]);
    const [openChat, setOpenChat] = useState(false);
    const selectUser = useRef<any>(undefined);
    let effectDone = useRef<boolean>(false);

    useEffect(() => {

        return () => {
            console.log('#IN R', effectDone.current)
            if (effectDone.current) {
                if (user.Socket && user.Socket.connected)
                    user.Socket.removeListener("userCallStatus");
                if (localStream.current && (localStream.current.localAudioTrack || localStream.current.localVideoTrack))
                    leave().then(() => { });
            } else
                effectDone.current = true;
        }

    }, []);

    useEffect(() => {
        console.log('#IN useEffect ')
        if (user && user.isAuthenticated) {
            if (user && user.Socket && user.Socket.connected) {
                console.log('#IN userCallStatus ')
                user.Socket.on('userCallStatus', (Status: any) => {
                    console.log('#IN userCallStatus ', Status);
                    if (Status == 'CALLING') {
                        setGotReq(false);
                        setTxt("is calling you.")
                        GetCallDtls();
                    }
                    if (Status == 'CANCELED') {
                        setGotReq(false);
                        if (joined.current)
                            leaveMeeting();
                        else
                            cancelCaller();
                    }
                    if (Status == 'ENDED') {
                        setGotReq(false);
                        console.log('#in ENDED ',joined.current);
                        if (!joined.current)
                            cancelCaller();
                    }
                })
                console.log('#IN userNewMessage ')
                user.Socket.on('userNewMessage', (UserId: any) => {
                    console.log('#IN CHAT userMessage got', UserId)
                    dispatch(GetUnseenUserMessagesCount(empId) as any);
                })


            }
            // else if (user && user.Socket && user.Socket.disconnected)
            //     InCall.current = setInterval(GetCallDtls, 5000);
        }

        //    setInCall(tm);
        return () => {
            clearInterval(InCall.current);
        }
    }, [user.Socket]);

    useEffect(() => {
        loc.current = location.pathname
    }, [location]);

    useEffect(() => {
        if (requestInvitation) {
            if (requestInvitation.IsCancel)
                cancelCaller();
            else if (!gotReq)
                setDetails();
        }
    }, [requestInvitation]);

    const GetCallDtls = () => {
        console.log("#IN GetCallDtls", empId);
        if (user.isAuthenticated) {
            ///  if (loc.current.length > 0 && loc.current !== '/' && loc.current !== '/login' && loc.current !== '/forget-password' && loc.current.indexOf('/reset-password') == -1)
            GetToActivitie(empId).then((result) => {
                console.log("#IN after GetCallDtls", result);
                if (result.length > 0) {
                    setRequestInvitation(result[0]);
                }
            });

        }
    }

    useEffect(() => {
        setEmps(user.messageCount.emps);
        console.log('MESSAGE COUNT', user.messageCount);
    }, [user.messageCount]);

    const setDetails = async () => {
        if (loc.current.indexOf('/conference') == -1 && loc.current.indexOf('/customrm') == -1) {
            setGotReq(true);
            const remoteres = await getEmployeeDetailsById(requestInvitation.FromId);
            const remoteusers = remoteres.result;
            /// callringTimer = setTimeout(() => { autoCancelCall() }, 50000);
            if (remoteusers && remoteusers.EmployeeId > 0) {
                if (ringTone)
                    ringTone.play();
                dispatch({
                    type: T.ADD_INCALL,
                    payload: true
                })
                setCallerDtl({ ...callerDtl, EmployeeId: remoteusers.EmployeeId, name: remoteusers.FirstName, EmpGuid: remoteusers.EmpGuid, openDialog: true, ProfileImageURL: remoteusers.ProfileImageURL })
            }
        } else if (requestInvitation.Status == 'CALLING') {
            // clearInterval(InCall.current)
            requestInvitation.Status = 'IN_CALL';
            requestInvitation.IsRejected = true;
            SaveMyActivity(requestInvitation).then((res) => { });
        }
    }

    const toggleSchedule = () => {
        setShowSchedule(!showSchedule)
    }

    const setMettingId = (mId: any) => {
        setMeetingId(mId);
        meetingId = mId;
        GoJoin();
    }

    const GoJoin = () => {
        SuteLog.setLog("Conference [useEffect] " + empId);
        Lclient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        setClient(Lclient);
    }

    useEffect(() => {
        if (client) {
            SuteLog.setLog("peer calling joinRoom ")
            if (!joined.current) {
                SuteLog.setLog("Conference calling autoJoinRoom")
                //  autoJoinRoom();
                makeJoin(meetingId);

            }
        }
    }, [client]);

    const makeJoin = async (meetingId: any) => {
        console.log("#in makeJoin ")
        if (joined.current) {
            SuteLog.setLog("Conference calling justLeave ")
            await justLeave();
        }
        if (!joined.current) {
            await client.enableDualStream();
            subscribeEvents();
            let uuid = await client.join(appId, meetingId, token ? token : null, null);
            // client.getRTCStats()
            setSid(uuid as string);
            try {
                if (!localStream.current.localVideoTrack) {
                    localStream.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
                    localStream.current.errVideo = false;
                    localStream.current.video = true;
                    localStream.current.localDetails.video = "YES";
                    SuteLog.setLog("camera success");
                }

            } catch (error: any) {
                localStream.current.video = false
                localStream.current.localDetails.video = error.message;
                localStream.current.errVideo = true;
                localStream.current.videoDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
                SuteLog.setLog(" camera error: " + error);

            }

            try {

                //localStream.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true });
                if (!localStream.current.localAudioTrack) {
                    localStream.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true });// await AgoraRTC.createMicrophoneAudioTrack({ encoderConfig: "music_standard" });
                    localStream.current.errAudio = false;
                    localStream.current.audio = true;
                    localStream.current.localDetails.audio = "YES";
                    SuteLog.setLog(" Micro phone success");
                }

            } catch (error: any) {
                localStream.current.errAudio = true;
                localStream.current.audio = false;
                localStream.current.localDetails.audio = error.message;
                localStream.current.audioDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
                SuteLog.setLog(" Micro phone error: " + error);
            }

            localStream.current.streamID = uid;
            localStream.current.screen = false;
            // setJoined(true);
            joined.current = true;
            SuteLog.setLog(" SuteClient calling publish ");
            try {

                if (localStream.current.localAudioTrack) {
                    await client.publish([localStream.current.localAudioTrack]);
                }
                if (localStream.current.localVideoTrack) {
                    await client.publish([localStream.current.localVideoTrack]);
                    // delay(2000);
                    // localStream.current.localVideoTrack.setEnabled(false);
                }
                setTimeout(function () {
                    setLocalUser(localStream.current);
                    setTimer(calcDuration(+Date.now()));
                   
                }, 1000);

            } catch (error) {
                console.log(" publish error: " + error);
            }

            SuteLog.setLog(" SuteClient publish successfully");
        }

    }

    const subscribeEvents = () => {
        SuteLog.setLog(" SuteClient subscribeEvents");
        if (!client) return;
        client.on('user-joined', handleUserJoined);
        client.on('user-published', handleUserPublished);
        client.on('user-left', handleUserLeft);
        client.on("user-unpublished", handleUserUnpublished);
        // // client.on("stream-published", (evt:any)=>{//calls when local stream published
        // //   published = true;
        // // })
        client.on('network-quality', function (stats: any) {
            //alert('network quality');
            SuteLog.setLog('network quality ' + stats.uplinkNetworkQuality)
            SuteLog.setLog('network quality ' + stats.downlinkNetworkQuality)
            // setQualityDescriptors(stats.uplinkNetworkQuality, $('#uplink-quality-btn'), $('#uplink-quality-icon'))
            //  setQualityDescriptors(stats.downlinkNetworkQuality, $('#downlink-quality-btn'), $('#downlink-quality-icon'));
        });
        client.on("token-privilege-did-expire", function () {
            SuteLog.setLog("onTokenPrivilegeDidExpire")
        })

    }

    const unsubscribeEvents = () => {
        SuteLog.setLog(" SuteClient unsubscribeEvents");
        client.off('user-joined', handleUserJoined);
        client.off('user-published', handleUserPublished);
        client.off('user-left', handleUserLeft);
        client.off("user-unpublished", handleUserUnpublished)
        client.off("token-privilege-did-expire", function () {

        })

    };

    const handleUserPublished = (async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
        console.log("#in handleUserPublished " + user.uid + " " + mediaType);
        await client.subscribe(user, mediaType);
        if (mediaType === "audio") {
            if (user.audioTrack && !user.audioTrack.isPlaying) {
                user.audioTrack.play()
              }
          }


          let RemoteUser = remoteStreams.filter(u => { return u.uid == user?.uid });
          if(RemoteUser && RemoteUser.length > 0) {
            SetPublishedUser(user,RemoteUser[0]);
          }
          else
        await newUserAdded()

    });

    const SetPublishedUser = (newUser: IAgoraRTCRemoteUser, PublishedUsr: RemoteStreamUser) => {
        console.log("#Mstate SetPublishedUser", newUser?.uid)
        PublishedUsr.video = newUser ? newUser?.hasVideo : false;
        PublishedUsr.audio = newUser ? newUser?.hasAudio : false;
        PublishedUsr.stream.AudioTrack = newUser ? newUser.audioTrack : undefined;
        PublishedUsr.stream.VideoTrack = newUser ? newUser.videoTrack : undefined;
        PublishedUsr.stream.audio = newUser ? newUser.hasAudio : false;
        PublishedUsr.stream.video = newUser ? newUser.hasVideo : false;

       setRemoteStreams(  remoteStreams.map(ru => {
            return ru.uid == newUser?.uid ? PublishedUsr : ru
        })
       )
    }
    const handleUserJoined = async (user: any) => {
        console.log("#In UserJoined", new Date())
        console.log("#In  UserJoined " + user.uid);
        await newUserAdded()
    }
    const handleUserUnpublished = async (user: IAgoraRTCRemoteUser, mediaType: any) => {
        await client.unsubscribe(user, mediaType);
        let RemoteUser = remoteStreams.filter(u => { return u.uid == user?.uid });
          if(RemoteUser && RemoteUser.length > 0) {
            SetPublishedUser(user,RemoteUser[0]);
          }
          else
        await newUserAdded();
    };

    const handleUserLeft = async (user: IAgoraRTCRemoteUser) => {

        console.log("#IN handleUserLeft LEFT");
        timer && clearInterval(timer);
        setCallerDtl({ ...callerDtl, name: callerDtl.name + ' has left' });
        const rUsers = remoteStreams.filter(r => r.uid != user.uid);
        setRemoteStreams([...rUsers]);
       await leaveMeeting();
        setTimeout(function () {
            dispatch({type: T.ADD_INCALL,payload: false})
            setCallerDtl({ ...callerDtl, openDialog: false });
        }, 3000);
    }

    const newUserAdded = async () => {

        let TempUsers = [] as RemoteStreamUser[];
        let TempUser = {} as RemoteStreamUser;
        let rStream;

        if (client && client.remoteUsers && client.remoteUsers.length == 1) {

            if (callerDtl && callerDtl.EmployeeId && callerDtl.EmployeeId > 0) {
                const usr = client.remoteUsers[0];
                TempUser = {} as RemoteStreamUser
                rStream = {} as iRemoteStreams;
                rStream.uid = usr.uid
                rStream.VideoTrack = usr.videoTrack;
                rStream.AudioTrack = usr.audioTrack;
                rStream.audio = Boolean(usr.audioTrack ? true : false);
                rStream.video = Boolean(usr.videoTrack ? true : false);
                rStream.screen = false;
                TempUser.stream = rStream;
                TempUser.video = Boolean(usr.videoTrack ? true : false);
                TempUser.audio = Boolean(usr.audioTrack ? true : false);
                TempUser.screen = false;
                TempUser.uid = usr.uid;
                TempUser.pinned = false
                TempUser.EmpGuid = callerDtl.EmpGuid
                TempUser.profile = callerDtl;
                TempUser.FirstName = callerDtl.name
                TempUsers.push(TempUser);
                remoteUsers.current = TempUsers;
                setRemoteStreams([...TempUsers]);
            };


        }


    }

    async function justLeave() {
        SuteLog.setLog("Conference justLeave")
        await leave();
    }

    const leaveMeeting = async () => {
        console.log("#in Conference leaveMeeting")
        if (!joined.current)
            return;
        joined.current = false;
        await leave();
    }

    async function leave() {

        console.log("#in Leave");

        if (!localStream) {
            setCallerDtl({ ...callerDtl, openDialog: false });
            dispatch({
                type: T.ADD_INCALL,
                payload: false
            })
            return;

        }
        try {

            if (localStream.current.localVideoTrack) {
                localStream.current.localVideoTrack.stop()
                localStream.current.localVideoTrack.close()
            }

            if (localStream.current.localAudioTrack) {
                localStream.current.localAudioTrack.stop()
                localStream.current.localAudioTrack.close()
            }
            if (gotReq) {
                if (user.Socket && user.Socket.connected) {
                    console.log('#IN ENDED ', requestInvitation.FromId);
                    user.Socket.emit("userCallStatus", requestInvitation.FromId, 'ENDED');
                }
                requestInvitation.IsDeleted = true;
                requestInvitation.Status = 'CALL_ENDED';
                SaveMyActivity(requestInvitation).then((res) => { setGotReq(false); });
            }

            unsubscribeEvents();

            await client.leave().then(() => {
                console.log('#in client.leave')
                localStream.current =
                {
                    streamID: 0,
                    audio: false,
                    video: false,
                    screen: false,
                    localAudioTrack: undefined,
                    localVideoTrack: undefined,
                    localDetails: { audio: 'YES', video: 'YES' },
                    errAudio: false,
                    errVideo: false,
                    audioDescription: '',
                    videoDescription: '',
                }
                setStarted(false);
                setTime(0);
                // setJoined(false);
                joined.current = false;
                clearInterval(timer);
                setCallerDtl({ ...callerDtl, openDialog: false });
                setClient(undefined)
                dispatch({
                    type: T.ADD_INCALL,
                    payload: false
                })
            }).catch(() => {
                //if (navigation) {
                setClient(undefined)
                setCallerDtl({ ...callerDtl, openDialog: false });
                dispatch({
                    type: T.ADD_INCALL,
                    payload: false
                })
                // }
            });
        } catch (error) {
            //if (navigation) {
            setCallerDtl({ ...callerDtl, openDialog: false });
            dispatch({
                type: T.ADD_INCALL,
                payload: false
            })
            // navigation(0)
            // }

        }
    }

    const onAudioClick = async (evt: any) => {

        if (localStream.current.errAudio) {
            let inum = localStream.current.audioDescription == 'NOT_FOUND' ? 3 : 0;
            if (localStream.current.errVideo)
                inum = localStream.current.audioDescription == 'NOT_FOUND' ? 5 : 2;
            // seterrType(inum);
            // setShowCalErrs(true);
            return;
        }

        if (!localStream.current.localAudioTrack)
            return;
        SuteLog.setLog("Conference onAudioClick " + !localStream.current.audio)
        localStream.current.audio = !localStream.current.audio;
        if (localStream?.current.audio) {
            localStream.current.localAudioTrack?.setEnabled(true);

        } else {
            localStream.current.localAudioTrack?.setEnabled(false);
        }
        setLocalUser(localStream.current);
    }

    const onVideoClick = (evt: any) => {

        if (localStream.current.errVideo) {
            let inum = localStream.current.videoDescription == 'NOT_FOUND' ? 4 : 1;
            if (localStream.current.errAudio)
                inum = localStream.current.audioDescription == 'NOT_FOUND' ? 5 : 2;
            // seterrType(inum);
            // setShowCalErrs(true);
            return;
        }
        if (!localStream.current.localVideoTrack)
            return;
        SuteLog.setLog("Conference onVideoClick " + !localStream.current.video)
        //onPlayerClick('video', !localStream.current.video);
        localStream.current.video = !localStream.current.video;
        if (localStream?.current.video) {
           // localStream.current.localVideoTrack?.play(`dom-localUser`, { fit: 'cover' });
            localStream.current.localVideoTrack?.setEnabled(true)
        }
        else {
            //localStream.current.localVideoTrack?.stop();
            localStream.current.localVideoTrack?.setEnabled(false)
        }
        setLocalUser(localStream.current);
    }

    const delay = (ms: any) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    //-----------------------------------------------------------

    useEffect(() => {
        if (!started) {
            try {
                clearInterval(timer);
            } catch (e) {
                console.log(e);
            }
        } else {
            callringTimer && clearTimeout(callringTimer);
        }
    }, [started])

    const autoCancelCall = async () => {
        console.log('canceling metting');
        ringTone.pause();
        setStarted(false);
        setCallerDtl({ ...callerDtl, openDialog: false })
        callringTimer && clearTimeout(callringTimer);
        // }
    }

    const cancelCallRemote = async () => {
        console.log('callringTimer', callringTimer);
        ringTone.pause();
        setStarted(false);
        setCallerDtl({ ...callerDtl, openDialog: false });
        dispatch({
            type: T.ADD_INCALL,
            payload: false
        })
        requestInvitation.Status = 'CALL_REJECTED';
        requestInvitation.IsRejected = true;
        SaveMyActivity(requestInvitation).then((res) => {
            if (user.Socket && user.Socket.connected) {
                console.log('#IN REJECTED ', requestInvitation.FromId);
                user.Socket.emit("userCallStatus", requestInvitation.FromId, 'REJECTED');
            }
        });
        setGotReq(true);

    }

    const cancelCaller = async () => {
        setTxt("is cancelled call.")
        ringTone.pause();
        setStarted(false);
        setTimeout(function () {
            setCallerDtl({ ...callerDtl, openDialog: false });
            dispatch({
                type: T.ADD_INCALL,
                payload: false
            })
        }, 3000);
        if (requestInvitation) {
            requestInvitation.IsDeleted = true;
            SaveMyActivity(requestInvitation).then((res) => { });
        }

        setGotReq(false);
    }

    const calcDuration = (tm: number) => {
        let tmm = setInterval(() => {
            updateTime(+Date.now() - tm);
        }, 1000, tm)
        setTimer(tmm);

    }

    const updateTime = (t: number) => {
        setTime(t);
    }

    useEffect(() => {
        if (callerDtl.openDialog) {
        } else {
        }
    }, [callerDtl.openDialog]);

    const acceptCall = async (type: string) => {
        // autoJoinCall();
        callringTimer && clearTimeout(callringTimer);
        setCallType(type);
        setStarted(true);
        ringTone.pause();
        setTime(0);
        timer && clearInterval(timer._id);
        requestInvitation.Status = 'CALL_ACCEPTED';
        requestInvitation.IsAccepted = true;
        SaveMyActivity(requestInvitation).then((res) => {
            setGotReq(true);
            if (user && user.Socket && user.Socket.connected) {
                console.log('#IN ACCEPTED ', requestInvitation.FromId);
                user.Socket.emit("userCallStatus", requestInvitation.FromId, 'ACCEPTED');
            }

        });
        const mID = currentId > callerDtl.EmployeeId ? callerDtl.EmpGuid.split('-')[0] + empId.split('-')[0] : empId.split('-')[0] + callerDtl.EmpGuid.split('-')[0]
        setMettingId(mID);

    }

    const toggleChat = () => {
        setChatting(!chatting)
    }

    const onSltEmp = (emp: any) => {
        console.log("#CC onSltEmp", emp);
        dispatch({
            type: T.ADD_INCHAT,
            payload: false
        })
        setTimeout(() => {
               dispatch({
                   type: T.ADD_INCHAT,
                   payload: true
               });
               setChat(emp);
            } , 500)

    }


    const closeChat = (isClose: boolean) => {
        setOpenChat(false);
        if(isClose)
        dispatch({
            type: T.SELECTED_CHAT_USER,
            payload: undefined,
        })
        dispatch({
            type: T.ADD_INCHAT,
            payload: false
        })
        selectUser.current = undefined;
    }

    const setChat = (emp: any) => {
        setChatting(!chatting);
        //setSelectedUser(emp);
        selectUser.current = emp;
        setTimeout(() => {
            console.log("#IN openChat ", openChat);
            setOpenChat(true);
        }
            , 500);
        console.log("#CC 1", selectUser.current);
    }
    useEffect(() => {

        console.log("#CC", user.selectedChatUser);

        if (user.selectedChatUser && user.InChat && !selectUser.current) {
            setChat({ Id: user.selectedChatUser.EmpGuid, Name: user.selectedChatUser.FirstName + ' ' + user.selectedChatUser.LastName, Count: 1 });
        } else if (user.InChat == false) {
            closeChat(false);
        }

    }, [user.InChat])

    return (<>
        {
          !user.InChat &&  user.messageCount.IndTotal && user.messageCount.IndTotal > 0 ?
                <div className={'char-container'}>
                    <div className={'testOver'} onClick={() => toggleChat()}>
                        <div className="chatBubble bubble"><span>{user.messageCount.IndTotal}</span></div>
                        <img className="c-pointer" alt="Chat" src="/assets/media/svg/profile/message.svg" width="100%" />
                    </div>
                    {chatting ?
                        <Card className='chatCard'>
                            <ListGroup variant="flush">
                                {emps && emps.map(emp => {
                                    return (<ListGroup.Item onClick={() => onSltEmp(emp)}>{emp.Name}<div className="chatBubble"><span>{emp.Count}</span></div></ListGroup.Item>)
                                })
                                }

                            </ListGroup>
                        </Card>
                        : ''}
                </div> : ''}
        {openChat && selectUser.current ? <UserChatComponent openChat={openChat} closeChat={()=>{closeChat(true)}}
            selEmpName={selectUser.current.Name}
            selEmpGuid={selectUser.current.Id} /> : ''}

        {callerDtl.openDialog ? <div className={`SideNav text-left`}>
            <div className="d-flex pr-0 preCallPopup sideNavBody pl-0">
                <div className="align-content-start w-100">
                    <div className="videoCallBox col-12 text-center mb-2 mt-3 overflow-hidden" style={{ height: '24vh' }}>
                        {remoteStreams && remoteStreams.map((rsobj: any) => {
                            return (<RemotePlayer
                                domId={`dom-${rsobj.uid}`}
                                key={`${rsobj.uid}`}
                                id={`${rsobj.stream.uid}`}
                                account={''}
                                streamID={rsobj.uid}
                                stream={rsobj.stream}
                                video={rsobj.video}
                                audio={rsobj.audio}
                                local={true}
                                volume={35}
                                className={''}
                                profileImage={rsobj?.profile?.ProfileImageURL}
                            />)
                        })}
                        <> <div id='iccd' className={`agora-rtc-video fluidCard } ${Boolean(false) ? '' : 'd-none'} NoPinn`}>
                            <span className="participantName">{callerDtl?.FirstName}</span></div>
                            <div className={`agora-rtc-video ${Boolean(false) ? 'd-none' : ''} NoPinn`}>
                                <img className="m-auto" src={callerDtl?.ProfileImageURL ? callerDtl?.ProfileImageURL : "/assets/media/images/sute_user_default.png"} />
                                <span className="participantName">{callerDtl?.FirstName}</span>
                            </div>
                        </>
                    </div>
                    {!started ? <><div className="col-12 text-center text-light">
                        <h6 className="m-0">{callerDtl.name}</h6>
                        <p className="text-muted mb-2">{txt}</p>
                    </div></> : <><div className="col-12 text-center text-light">
                        <h6 className="m-0">{callerDtl.name}</h6>
                        {/* <p className="text-muted mb-2">{time > 3600000 ? moment.utc(time).format('H:m:ss') : moment.utc(time).format('m:ss')}</p> */}
                        {/* <p className="text-muted mb-2">{time}</p> */}
                    </div></>}

                    {!started ? <div className="col-12 text-center memberProfileActions">
                        <img onClick={() => { acceptCall('audio') }} src="/assets/media/svg/profile/call.svg" title={t('title.accept_audio_call')} alt="Accept Audio call" />
                        {/* <img onClick={() => { acceptCall('video') }} src="/assets/media/svg/profile/video-call.svg" title={t('title.accept_video_call')} alt="Accept Video call" /> */}
                        <img onClick={() => { cancelCallRemote() }} src="/assets/media/svg/reject-call.svg" title={t('title.reject_call')} alt="Reject call" />
                    </div> : <div className="col-12 text-center memberProfileActions">
                        {localStream.current.audio ? <img onClick={onAudioClick} title={t('title.mute')} src="/assets/media/images/collaborate/mic.svg" /> : <img onClick={onAudioClick} title={t('title.unmute')} src="/assets/media/images/collaborate/mic-inactive.svg" />}

                        {localStream.current.video ? <img onClick={onVideoClick} title={t('title.video_off')} src="/assets/media/images/collaborate/camera.svg" />
                            : localStream.current.errVideo ? <img className="inactived" src="/assets/media/images/collaborate/camera.svg" alt="" /> : <img onClick={onVideoClick} title={t('title.video_on')} src="/assets/media/images/collaborate/camera-inactive.svg" />
                        }
                        {/* <img onClick={toggleChat} title={t('chat.chat')} src="/assets/media/images/collaborate/chatting.svg" />
                        <img title={t('title.files')} src="/assets/media/svg/profile/media.svg" />
                        <img title={t('calendar.calendar')} onClick={toggleSchedule} src="/assets/media/svg/profile/calendar.svg" /> */}
                        <img title={t('title.end_call')} onClick={leaveMeeting} src="/assets/media/images/collaborate/leave.svg" alt="" />
                    </div>}
                    <div className="col-12 p-0">
                        <hr className="mb-2" />
                    </div>

                    <TimeSlotPopup EmpOfficeEmail={callerDtl.OfficeEmail} EmpGuid={callerDtl.EmpGuid} show={showSchedule} close={toggleSchedule} />
                </div>
            </div>
        </div> : ''}</>
    )
};
