import {
    apiSTTStopTranscription,
    apiSTTStartTranscription,
    apiSTTQueryTranscription,
    apiSTTUpdateTranscription,
    apiSTTAcquireToken,
    EXPERIENCE_DURATION,
  } from "../common"
  import { AGEventEmitter } from "../common/events"
  import { STTEvents, STTManagerStartOptions, STTManagerOptions, STTManagerInitData } from "./stt-types"
  import { RtmManager } from "./rtm-api"
  import { IRequestLanguages } from "../types"
import { ILanguageItem } from "./rtm-types"
  
  export class SttManager extends AGEventEmitter<STTEvents> {
    option?: STTManagerOptions
    userId: string | number = ""
    channel: string = ""
    rtmManager: RtmManager
    private _init: boolean = false
  
    get hasInit() {
      return this._init
    }
  
    constructor(data: STTManagerInitData) {
      super()
      const { rtmManager } = data
      this.rtmManager = rtmManager
    }
  
    setOption(option: STTManagerOptions) {
      console.log("#STT setOption ",option)
      this.option = option
    }
  
    removeOption() {
      this.option = undefined
    }
  
    async init({
      userId,
      channel,
      userName,
    }: {
      userId: string | number
      channel: string
      userName: string
    }) {
      this.userId = userId
      this.channel = channel
      console.log("#STT Init");
      await this.rtmManager.join({
        userId: userId + "",
        userName,
        channel,
      })
      this._init = true
      const languages: ILanguageItem[] = [];
      languages.push({
        source: 'en-US',
        target: [],
      });
     await this.startTranscription({languages});
    }
  
    async startTranscription(startOptions: STTManagerStartOptions) {
      console.log("#STT startTranscription");
      if (!this.hasInit) {
        return
       // throw new Error("please init first")
      }
      console.log("#STT startTranscription",this.hasInit );
      const { languages } = startOptions
      if (!languages.length) {
        return
      }
      // aquire lock
      await this.rtmManager.acquireLock()
      console.log("#STT startTranscription LOCK");
      try {
        // aquire token
        const data = await apiSTTAcquireToken({
          channel: this.channel,
          uid: this.userId,
        })
        const token = data.tokenName
        console.log("#STT startTranscription token");
        // api start
        const res = await apiSTTStartTranscription({
          uid: this.userId,
          channel: this.channel,
          languages: startOptions.languages,
          token,
        })
        const { taskId } = res
        console.log("#STT startTranscription taskId",taskId);
        this.setOption({
          token,
          taskId,
        })
        // set rtm metadata
        await Promise.all([
          this.rtmManager.updateLanguages(languages),
          this.rtmManager.updateSttData({
            status: "start",
            taskId,
            token,
            startTime: Date.now(),
            duration: EXPERIENCE_DURATION,
          }),
        ])
        console.log("#STT startTranscription start");
      } catch (err) {
        await this.rtmManager.releaseLock()
        //throw err
      }
      await this.rtmManager.releaseLock()
    }
  
    async stopTranscription() {
      if (!this.hasInit) {
       // throw new Error("please init first")
       return
      }
      const { taskId, token } = this.option || {}
      if (!taskId) {
        return
       // throw new Error("taskId is not found")
      }
      if (!token) {
        return
       // throw new Error("token is not found")
      }
      // aquire lock
      await this.rtmManager.acquireLock()
      try {
        // api stop
        await apiSTTStopTranscription({
          taskId,
          token,
          uid: this.userId,
          channel: this.channel,
        })
        // set rtm metadata
        await this.rtmManager.updateSttData({
          status: "end",
        })
      } catch (err) {
        await this.rtmManager.releaseLock()
        //throw err
      }
      await this.rtmManager.releaseLock()
    }
  
    async queryTranscription() {
      const { taskId, token } = this.option || {}
      if (!taskId) {
        throw new Error("taskId is not found")
      }
      if (!token) {
        throw new Error("token is not found")
      }
      // api query
      return await apiSTTQueryTranscription({
        taskId,
        token,
        uid: this.userId,
        channel: this.channel,
      })
    }
  
    async updateTranscription(options: { data: any; updateMaskList: string[] }) {
      const { data, updateMaskList } = options
      const { taskId, token } = this.option || {}
      if (!taskId) {
        throw new Error("taskId is not found")
      }
      if (!token) {
        throw new Error("token is not found")
      }
      // api update
      return await apiSTTUpdateTranscription({
        taskId,
        token,
        uid: this.userId,
        channel: this.channel,
        data,
        updateMaskList,
      })
    }
  
    /**
     *
     * startTime ms
     * duration ms
     */
    async extendDuration({ startTime, duration }: { startTime?: number; duration?: number }) {
      const data: any = {}
      if (startTime) {
        data.startTime = startTime
      }
      if (duration) {
        data.duration = duration
      }
      // set rtm metadata
      await this.rtmManager.updateSttData(data)
    }
  
    async destroy() {
      await this.rtmManager.destroy()
      this.option = undefined
      this.userId = ""
      this.channel = ""
      this._init = false
    }
  
    // ------------- private -------------
  }
  