import { getEmpDetailsByEmpGuid, uploadProfileImageAction, updateProfileImageByURL, UpdateUserProfile, UpdateuiComponentResponse, ShowToast } from '../../services/UserActions';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate,useNavigation } from 'react-router-dom';
import { imageService } from '../../services/ImageService';
import { removeSuccessStatus } from '../../services/ContactsService';
import { globalStore } from '../../store/global_store';
import './ProfileImageComponent.css';
import { StoreClass } from '../../store/Store';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { t } from '../../i18n';
import { Loader } from '../../components/Loader';
import { Modal } from 'react-bootstrap';
import { GetProfileAvatarsList } from "../../services/MediaService";

interface formError {
    message: string;
}
class UserProfile {
    FirstName: string = '';
    LastName: string = '';
    About: string = '';
    Phone: string = '';
    BirthDate: string = '';
    photoFile: any;
    photoFileName: string = '';
    photoFileImage: string = '';
    empGuid: string = '';
    IsAvatarSelected: boolean = false;
    selectedAvatarUrl: string = ''
}

function UserProfileComponent(props: any) {
    const [birthDate, setBirthDate] = useState(new Date());
    const [photoFile, setPhotoFile] = useState<any>(null);
    const [photoFileName, setPhotoFileName] = useState("");
    const [photoFileImage, setPhotoFileImage] = useState("");
    const [userProfile, setUserProfile] = useState(new UserProfile());
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const history = useNavigate();
    const dispatch = useDispatch();
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const [updateUserProfileResponse, setupdateUserProfileResponse] = useState('');
    const ref = useRef(false);
    const [errors, setErrors] = useState({} as formError);
    const media = useSelector((store: any) => store.MediaReducer);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(UpdateuiComponentResponse('UploadOrgDataResponse') as any);
        dispatch(getEmpDetailsByEmpGuid(empGuid)as any);
        // if (media.profileAvatarsList && media.profileAvatarsList.length == 0) {
            dispatch(GetProfileAvatarsList('avatar')as any);
        // }
    }, []);

    function fileSelectHandler(e: any) {
        e.preventDefault();
        const file = e.currentTarget.files[0];
        try {
            setUserProfile({ ...userProfile, photoFile: e.target.files[0], photoFileName: e.target.files[0].name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''), photoFileImage: URL.createObjectURL(e.target.files[0]) })
            setErrors({message: ''});
            // setPhotoFile(e.target.files[0]);
            // setPhotoFileName(e.target.files[0].name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
            // setPhotoFileImage(URL.createObjectURL(e.target.files[0]));
        } catch (err) {
            console.log(err);
        }
        setShow(false)
    }

    const changeStartDate = (date: any) => {
        setBirthDate(date);
        var value = formatDate(date)
        if (value != '') {
            setUserProfile({ ...userProfile, BirthDate: formatDate(date) })
        }
        else {
            return;
        }
    }

    function formatDate(date: any) {
        var fullDate = date;
        var twoDigitMonth = fullDate.getMonth() + 1;
        if (twoDigitMonth.toLocaleString().length == 1)
            twoDigitMonth = "0" + twoDigitMonth;
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.toLocaleString().length == 1)
            twoDigitDate = "0" + twoDigitDate;
        var currentDate = fullDate.getFullYear() + '-' + twoDigitMonth + '-' + twoDigitDate;
        return currentDate;
    }

    useEffect(() => {
        setupdateUserProfileResponse(user.updateUserProfile.message);
    }, [user.updateUserProfile, user.loading]);

    useEffect(() => {
        if (user.profileLogoUpload && user.profileLogoUpload.status === "success") {
            if (ref.current) {
                globalStore.showToast({
                    type: 'Message',
                    title: "Profile Image",
                    message: "Profile image updated successfully"
                });
                dispatch(removeSuccessStatus()as any);
                dispatch(getEmpDetailsByEmpGuid(empGuid)as any);
            }
            if (!ref.current) {
                console.log('running cartoonify...')
                imageService.postImage(user.profileLogoUpload.result[0], handleResp);
                ref.current = true;
            }
        }
    }, [user.profileLogoUpload, ref.current]);

    useEffect(() => {
        //setPhotoFileImage(user.empData.ProfileImageURL);
        if (user.empData) {
            if (user.empData.DateOfBirth == '') {
                setBirthDate(new Date());
            }
            else if (user.empData.DateOfBirth != undefined) {
                setBirthDate(moment.utc(user.empData.DateOfBirth + ' 00:00:00').toDate());
            }
            setUserProfile({ ...userProfile, empGuid: empGuid, photoFileImage: user.empData.ProfileImageURL, FirstName: user.empData.FirstName, LastName: user.empData.LastName, About: user.empData.About, Phone: user.empData.Mobile, BirthDate: user.empData.DateOfBirth });
        }
    }, [user.empData]);

    const handleResp = (resp: any) => {
        console.log('reqId', resp);
        imageService.checkByRequest(resp, getResultUrl)
    }

    const getResultUrl = (result: any) => {
        console.log(result);
        const reqId = result.image_process_response.request_id;
        if (result.image_process_response.status === 'OK') {
            console.log('saving url', result.image_process_response.result_url);
            dispatch(updateProfileImageByURL(result.image_process_response.result_url, empGuid)as any);
        } else {
            handleResp(reqId);
        }
    }

    const updateUserData = async (event: any) => {
        //event.preventDefault();
        if (userProfile.photoFileImage != '' || userProfile.photoFileImage == '') {
            if (userProfile.FirstName != '' && userProfile.LastName != '') {
                const empGuid = localStorage.getItem("EmpGuid");
                if ((userProfile.photoFileImage || !userProfile.photoFileImage) && userProfile.empGuid) {
                    ref.current = false;
                    const formData = new FormData();
                    formData.append('File', userProfile.photoFile);
                    formData.append('FileName', userProfile.photoFileName);
                    formData.append('FileImage', userProfile.photoFileImage);
                    formData.append('empGuid', empGuid || '');
                    formData.append('FirstName', userProfile.FirstName);
                    formData.append('LastName', userProfile.LastName);
                    formData.append('About', userProfile.About);
                    formData.append('Mobile', userProfile.Phone);
                    formData.append('BirthDate', userProfile.BirthDate);
                    dispatch(UpdateUserProfile(formData, props.redirectTo, history)as any);
                    setErrors({ message: '' });
                    //update image in local store
                    localStorage.setItem("ProfileImgUrl", userProfile.photoFileImage);
                    dispatch(ShowToast({ type: 'Message', title: 'Success', message: 'Saved successfully' }) as any);
                }
            }
            else {
                console.log("ERROR");
                setErrors({ message: 'First Name and Last Name should not be Empty' });
            }
        }
    }

    const openAvatarPopup = () => {
        setUserProfile({ ...userProfile, selectedAvatarUrl: userProfile.photoFileImage });
        handleShow();
    }

    const changeAvatar = (avatar: any) => {
        setUserProfile({ ...userProfile, selectedAvatarUrl: avatar.AvatarURL })
    }

    const onAvatarSave = () => {
        setUserProfile({ ...userProfile, photoFileImage: userProfile.selectedAvatarUrl })
        setErrors({message : ''})
        handleClose();
    }

    const ChangeAbout = (e: any) => {
        setUserProfile({ ...userProfile, About: e.target.value })
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const onchange = (e: any) => {
    //     const re = /^[0-9\b]+$/;
    //     if (e.target.value === '' || re.test(e.target.value)) {
    //         setUserProfile({
    //             ...userProfile, Phone: e.target.value
    //         })
    //     }
    // }

    const errormsg = (<div className="col-12"><div className="alert alert-danger text-center">
        <strong>Error!</strong> {errors.message}
    </div></div>);

    return (
        <div className="col-sm-7">
            <div className="row">
                <div className="col-sm-10 m-auto">
                    <div className="card loginBox mt-2 addTeamMemberBox">
                        <div className="card-body pl-5 pr-5">
                            <div className="col-12 p-0 d-flex">
                                <span className="tab-btn-parent">
                                    <Link to="/companyInfo">
                                        <button type="button" className="btn btn-tab mb-block d-none mr-2">Company profile</button>
                                    </Link>
                                    <Link to="/userProfile">
                                        <button type="button" className="btn btn-tab mb-block d-none active">Employee profile</button>
                                    </Link>

                                </span>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-4 mt-4 text-white">
                                    <h5>{t('signup.employee_profile')}</h5> </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-2 flexContainer">
                                    <div className="avatarHolder text-center OBhex">
                                        <div className="OBhexIn">
                                            {userProfile.photoFileImage ? null :
                                                <p>
                                                    <label className="align-self-center w-100">
                                                        <img src="assets/media/svg/onboarding/add-team-member.svg" style={{ filter: 'none' }} onClick={handleShow} />
                                                        <u className="OBhexTitle">{t('setupcomponents.profile_image')}</u>
                                                    </label>
                                                    {/* <input type="file" accept="image/*" id="addLogo" onChange={fileSelectHandler} /> */}
                                                </p>}
                                            {userProfile.photoFileImage !== null ? <>
                                                <img src={userProfile.photoFileImage} alt="0" className="logoImg" onClick={openAvatarPopup} />
                                                {/* <input type="file" accept="image/*" className="inputWithPhoto" id="addLogo" onChange={fileSelectHandler} />*/}
                                            </> : null}
                                        </div>
                                    </div>
                                </div>
                                {/* <Link to="/userProfile" className="col-12 mb-4 text-white text-center">{t('changepassword.change_password')}</Link> */}
                                <a className="col-12 mb-4 text-white text-center" onClick={openAvatarPopup} >Change Image</a>
                            </div>
                            <div className="row">
                                <div className="col-6 mb-4 text-white">
                                    {t('signup.first_name')}: <sup>*</sup>  <input type="text" className="form-control" placeholder={t('signup.first_name')} value={userProfile.FirstName} onChange={function (e) { setUserProfile({ ...userProfile, FirstName: e.target.value }); setErrors({ message: '' }) }} autoComplete="off" maxLength={20} />
                                </div>
                                <div className="col-6 mb-4 text-white">
                                    {t('signup.last_name')}: <sup>*</sup> <input type="text" className="form-control" placeholder={t('signup.last_name')} value={userProfile.LastName} onChange={function (e) { setUserProfile({ ...userProfile, LastName: e.target.value }); setErrors({ message: '' }) }} maxLength={20} />
                                </div>
                                {/* <div className="col-12 mb-4 text-white">
                                    {t('myprofile.mobile')}:  <input type="tel" className="form-control" placeholder={t('myprofile.mobile')} value={userProfile.Phone}
                                        onChange={(e: any) => onchange(e)}
                                        maxLength={10} />
                                </div> */}
                                {/* <div className="col-12 mb-4 text-white">
                                    {t('myprofile.dob')}:
                                <DatePicker className="form-control"
                                        selected={birthDate}
                                        onChange={changeStartDate}
                                        name="Birthdate"
                                        dateFormat="yyyy-MM-dd"
                                        maxDate={new Date()}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    <svg className="profileDatePick" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M3.5 0v5m8-5v5m-4 1v5M5 8.5h5m-8.5-6h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="currentColor"></path></svg>
                                </div> */}
                                <div className="col-12 mb-4 text-white">
                                    {t('myprofile.about')}:  <textarea className="form-control"  value={userProfile.About} onChange={ChangeAbout} style={{ height: '60px' }} minLength={2} maxLength={255}></textarea>
                                </div>
                                {errors.message && errormsg}
                                <div className="form-group col-6"><Link to="#" onClick={() => navigate(-1)}> <div className="btn btn-secondary btn-block font-weight-bold pl-5 pr-5 float-left  mt-4 mb-4">{t('button.cancel')}</div></Link></div>
                                <div className="form-group col-6"> <div onClick={updateUserData} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-left  mt-4 mb-4">{t('button.save')}{user.loading ? <Loader /> : null}</div> </div>
                                <p className="col-12 mb-4 text-white">{updateUserProfileResponse}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="modal-title text-light small">Change Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <ul className="emojiUL">
                            {(media.profileAvatarsList.result || []).length > 0 ? (media.profileAvatarsList.result || []).map((avatar: any, index: number) => (
                                <li className={userProfile.selectedAvatarUrl === avatar.AvatarURL ? "active" : ''}
                                    key={index}
                                    onClick={function () { changeAvatar(avatar) }}>
                                    <img className="emojiSingle" src={(process.env.REACT_APP_CDN_HOST || '') + (avatar.AvatarURL)} />
                                </li>)) : null}
                        </ul>
                        <div className="text-center float-right btn-sm align-self-center small btn-SeaGreen btn position-relative">Choose Your Own
                            <input type="file" accept="image/*" className="inputWithPhoto" id="addLogo" onChange={fileSelectHandler}  onClick={(e: any) => e.target.value = null}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button type="button" style={{ width: '80px' }} className="btn btn-secondary btn-sm" onClick={handleClose}>Cancel</button>
                    <button type="button" style={{ width: '80px' }} className="btn btn-SeaGreen btn-sm" onClick={onAvatarSave}>Ok</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UserProfileComponent;