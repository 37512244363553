import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import './SetupComponent.css';
import { getOrgDetailsByOrgId, uploadLogoAction, UpdateuiComponentResponse } from '../../services/UserActions';
import { connect, useDispatch } from 'react-redux';
import ColorPicker from '../onboarding/ColorPicker';
import { Loader } from '../../components/Loader';
import { url } from 'inspector';
import clsx from 'classnames';
import { t } from '../../i18n';
import { createNoSubstitutionTemplateLiteral } from 'typescript';

class OrgData {
    OrgGuid: string = "";
    OrgLogoFile: any;
    OrgLogoFileName: string = '';
    OrgLogoFileImage: string = '';
    OrgBGLogoFile: any;
    OrgBGLogoFileName: string = '';
    OrgBGLogoFileImage: string = '';
    OrgName: string = '';
    TagLine: string = '';
    PrimaryColor: string = '#fff';
    SecondaryColor: string = '#fff';
    TagLineColor: string = '#fff';
    BrandLogo: string = '';
    Style: string = '';
}

interface LocationState {
    [key: string]: any; 
}

var BrandLogos = ["/assets/media/images/sute-logo1.png", "/assets/media/images/sute-logo2.png", "/assets/media/images/sute-logo3.png", "/assets/media/images/sute-logo4.png", "/assets/media/images/sute-logo5.png", "/assets/media/images/sute-logo6.png"];
function SetupComponent(props: any) {
    const [pshow, setPshow] = useState(false);
    const [sshow, setSshow] = useState(false);
    const [orgData, setOrgData] = useState(new OrgData());
    const [orgDataUploadResposne, setorgDataUploadResposne] = useState('');
    const [isError, setIsError] = useState(false);
    const [formErrors, setFormErros] = useState<any>({});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const orgGuid = localStorage.getItem('OrgGuid');
    const location = useLocation();
    const locationRef = useRef<LocationState | null>(null); 
    locationRef.current = location.state as LocationState;


    const showPrime = () => {
        setPshow(!pshow);
    }

    const showSecond = () => {
        setSshow(!sshow);
    }
    
    const closeColorPick = () => {
        setPshow(false);
        setSshow(false);
    }



    useEffect(() => {
        //set this based on from where component is called
        //un comment this if its called from settings
        if (props.isSettings) {
            props.getOrgDetailsByOrgId(orgGuid);
        }
        else {
            const OrgGuid = searchParams.get("id");
            if (OrgGuid) {
                const orgData1 = getOrg(OrgGuid);
                setOrgData({ ...orgData, OrgGuid: orgData1.OrgGuid, OrgBGLogoFileImage: orgData1.BGURL, OrgName: orgData1.OrgName, TagLine: orgData1.TagLine, OrgLogoFileImage: orgData1.LogoURL, PrimaryColor: orgData1.PrimaryColor, SecondaryColor: orgData1.SecondaryColor, TagLineColor: orgData1.TagLineColor, BrandLogo: orgData1.BrandLogo });
            }
        }
    }, []);

    useEffect(() => {
        if (props.isSettings && props.user.orgData.OrgId) {
            setOrgData({ ...orgData, OrgGuid: props.user.orgData.OrgGuid, OrgBGLogoFileImage: props.user.orgData.BGURL, OrgName: props.user.orgData.OrgName, TagLine: props.user.orgData.TagLine, OrgLogoFileImage: props.user.orgData.LogoURL, PrimaryColor: props.user.orgData.PrimaryColor, SecondaryColor: props.user.orgData.SecondaryColor, TagLineColor: props.user.orgData.TagLineColor });
        }
    }, [props.user.orgData]);

    function getOrg(OrgGuid: any): any {
        const orgData1 = props.user.orgList.find((org: any) => org.OrgGuid === OrgGuid);
        return orgData1;
    }

    function orgLogoSelectHandler(e: any) {
        e.preventDefault();
        const file = e.currentTarget.files[0];
        try {
            setOrgData({ ...orgData, OrgLogoFileImage: (URL.createObjectURL(e.target.files[0])), OrgLogoFile: e.target.files[0], OrgLogoFileName: e.target.files[0].name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, '') });
        } catch (err) {

        }
    }

    function orgBGLogoSelectHandler(e: any) {
        e.preventDefault();
        const file = e.currentTarget.files[0];
        try {
            setOrgData({ ...orgData, OrgBGLogoFileImage: (URL.createObjectURL(e.target.files[0])), OrgBGLogoFile: e.target.files[0], OrgBGLogoFileName: e.target.files[0].name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, '') });
        } catch (err) {

        }
    }

    useEffect(() => {
        if (props.user.orgDataUpload.message == 'Data uploaded successfully.') {
            if (props.isSettings) {
                setorgDataUploadResposne('Company profile has been updated successfully');
                setIsError(false);
                dispatch(getOrgDetailsByOrgId(orgGuid) as any);
                setTimeout(() => {
                    const locationState = locationRef.current; // Access location state directly
                    navigation('/' + props.redirectTo, { state: locationState });
                }, 1000);
            }
            else if (props.isCreate) {
                if (orgData.OrgGuid) {
                    setorgDataUploadResposne('Company profile updated Successfully');
                    setIsError(false);
                }
                else {
                    setorgDataUploadResposne('Company profile created Successfully');
                    setIsError(false);
                }
                setTimeout(() => {
                    dispatch(UpdateuiComponentResponse('UploadOrgDataResponse') as any);
                    navigation('/' + props.redirectTo)
                }, 1000);
            }
            //reference method will be called when org added in user create screen
            else if (props.DataSubmited) {
                setorgDataUploadResposne('Company profile created Successfully');
                setIsError(false);
                props.DataSubmited(props.user.orgDataUpload);
            }
        }
    }, [props.user.orgDataUpload]);

    useEffect(() => {
        if (props.user.error) {
            setorgDataUploadResposne(props.user.error.message);
            setIsError(true);
            dispatch(UpdateuiComponentResponse('UploadOrgDataError') as any);
        }
    }, [props.user.error])

    const uploadOrgData = async (event: any) => {

        setorgDataUploadResposne('');
        event.preventDefault();

        if (orgData && orgData.OrgName) {
            setFormErros({ ...formErrors, 'OrgName': { cls: 'is-valid' } });
            const formData = new FormData();
            if (orgData.OrgGuid) {
                formData.append('OrgGuid', orgData.OrgGuid);
            }
            formData.append('PrimaryColor', orgData.PrimaryColor);
            formData.append('SecondaryColor', orgData.SecondaryColor);
            formData.append('OrgName', orgData.OrgName);
            formData.append('TagLine', orgData.TagLine);
            formData.append('OrgBGLogoFile', orgData.OrgBGLogoFile);
            formData.append('OrgBGLogoFileName', orgData.OrgBGLogoFileName);
            formData.append('OrgBGLogoFileImage', orgData.OrgBGLogoFileImage);
            formData.append('OrgLogoFile', orgData.OrgLogoFile);
            formData.append('OrgLogoFileName', orgData.OrgLogoFileName);
            formData.append('OrgLogoFileImage', orgData.OrgLogoFileImage);
            formData.append('TagLineColor', orgData.TagLineColor);
            formData.append('BrandLogo', orgData.BrandLogo);
            formData.append('Style', orgData.Style);
            dispatch(uploadLogoAction(formData) as any);
        }
        else
            setFormErros({ ...formErrors, 'OrgName': { cls: 'is-invalid' } });
        console.log("Ckecking for profile location", location.state)
    }

    const RemoveImage = (e: any, type: string) => {
        e.preventDefault();
        if (type == 'PROFILELOGO') {
            setOrgData({ ...orgData, OrgLogoFileImage: '', OrgLogoFile: '', OrgLogoFileName: '' })
        }
        else if (type == 'BGLOGO') {
            setOrgData({ ...orgData, OrgBGLogoFileImage: '', OrgBGLogoFile: '', OrgBGLogoFileName: '' })
        }
    }

    const OnBrandLogoSelect = (e: any, type: string, style: string) => {
        setOrgData({ ...orgData, BrandLogo: type, Style: style })
    }

   

    return (
        <div className="col-sm-8 popup-settings">
            <div className="row">
                <div className="col-sm-8 m-auto popup-settings">
                    <div className="card loginBox addTeamMemberBox mt-5 mb-5">
                        <div className="card-body pl-5 pr-5">
                            <div className="col-12 p-0 d-flex">
                                <span className="tab-btn-parent">
                                    <Link to="/companyInfo">
                                        <button type="button" className="btn btn-tab mb-block d-none active mr-2">Company profile</button>
                                    </Link>
                                    <Link to="/userProfile">
                                        <button type="button" className="btn btn-tab mb-block d-none">Employee profile</button>
                                    </Link>
                                </span>
                            </div>
                            {props.isSettings ?
                                <div className="row m-auto">
                                    <div className="col-12 mb-4 mt-4 text-white">
                                        <h5>{t('setupcomponents.company_setup')}</h5> </div>
                                </div> : undefined}
                            <div className='row'>
                                {orgDataUploadResposne ? <p className={clsx("alert mt-2 ", isError ? "alert-danger" : "alert-success")} style={{ "width": "100%" }}>{orgDataUploadResposne}</p> : ''}
                            </div>
                            <div className="row">
                                <div className="col-6 mb-4 flexContainer border-right border-secondary">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center">
                                            <div className="avatarHolder text-center OBhex" style={{ width: '100px', height: '117px' }}>
                                                <div className="OBhexIn">
                                                    {orgData.OrgLogoFileImage ? null :
                                                        <p>
                                                            <label htmlFor="addLogo" className="align-self-center w-100">
                                                                <img src="assets/media/svg/onboarding/add-team-member.svg" style={{ filter: 'none' }} />
                                                                <u className="OBhexTitle">{t('setupcomponents.add_logo')}</u>
                                                            </label>
                                                            <input type="file" accept="image/*" id="addLogo" onChange={orgLogoSelectHandler} onClick={(e: any) => e.target.value = null}/>
                                                        </p>}
                                                    {orgData.OrgLogoFileImage !== null ? <> <img src={orgData.OrgLogoFileImage} alt="0" className="logoImg" /> <input type="file" accept="image/*" className="inputWithPhoto" id="addLogo" onChange={orgLogoSelectHandler} onClick={(e: any) => e.target.value = null}/></> : null}
                                                </div>
                                            </div>
                                        </div>

                                        {orgData.OrgLogoFileImage && <div className="col-12"><p onClick={e => RemoveImage(e, 'PROFILELOGO')} className="mb-0 w-100 text-center text-muted c-pointer">{t('setupcomponents.remove_img')}</p></div>}

                                    </div>


                                </div>
                                <div className="col-6 mb-4">
                                    <div className={`backgroundImageHolder border-secondary m-auto small ${orgData.OrgBGLogoFileImage ? 'active' : ''}`} style={{ backgroundImage: 'url(' + orgData.OrgBGLogoFileImage + ')' }}>
                                        <input type="file" accept="image/*" id="addbg" onChange={orgBGLogoSelectHandler} onClick={(e: any) => e.target.value = null}/>
                                        <span>+ Add Background Image</span>
                                    </div>
                                    {orgData.OrgBGLogoFileImage && <p className="mb-0 w-100 text-center text-muted c-pointer" onClick={e => RemoveImage(e, 'BGLOGO')}>{t('setupcomponents.remove_img')}</p>}
                                </div>
                                <div className="col-12 mb-4">
                                    <p className="text-white mb-0">{t('setupcomponents.name')} <sup>*</sup></p>
                                    <input type="text" disabled={props.isSettings ? true : false} className={"form-control " + (formErrors['OrgName'] && formErrors['OrgName'].cls ? formErrors['OrgName'].cls : '')} placeholder={t('setupcomponents.name')} value={orgData.OrgName || ''} onChange={e => setOrgData({ ...orgData, OrgName: e.target.value })} />
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="row">
                                        <div className="col-11 mb-pl">
                                            <p className="text-white mb-0">{t('setupcomponents.tagline')} </p>
                                            <input type="text" className="form-control" placeholder={t('setupcomponents.tagline')} value={orgData.TagLine || ''} onChange={e => setOrgData({ ...orgData, TagLine: e.target.value })} />
                                        </div>
                                        <div className="col-1 align-self-center mb-p">
                                            <p className="text-white mb-0">&nbsp;</p>
                                            <span className="colorPickView c-pointer" onClick={showPrime} style={{ backgroundColor: orgData.TagLineColor }}></span>
                                        </div>
                                        {pshow && <div className="col-12 mt-2 c-pointer colorPanelHeader">
                                            <ColorPicker color={orgData.TagLineColor} onChange={(e: any) => setOrgData({ ...orgData, TagLineColor: e.hex })} show={pshow} onClose={closeColorPick} />
                                        </div>}
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <p className="text-white mb-0">{t('setupcomponents.brand_logo')}</p>
                                </div>
                                <div className="col-12">
                                    <div className="row suteLogoSel">
                                        {(BrandLogos || []).map((obj: any, index: number) => {
                                            return (
                                                <div key={index} className="col-4" onClick={e => OnBrandLogoSelect(e, obj, "")}>
                                                    <img className={clsx('img-fluid p-2', orgData.BrandLogo === obj ? 'active' : '')} src={obj} />
                                                </div>)
                                        })}

                                    </div>
                                </div> */}
                            </div>
                            <div className="form-group">
                                <button type='button' disabled={props.user?.orgDataUpload?.message == 'Data uploaded successfully.' ? true : false} onClick={uploadOrgData} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-left  mt-2 mb-2">{t('button.save')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    ui: state.uiReducer
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrgDetailsByOrgId: (orgGuid: any) => {
        dispatch(getOrgDetailsByOrgId(orgGuid));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupComponent);