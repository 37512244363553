import { useEffect, useState, useRef } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { formatTime2 } from "../../common/utils";

interface IProps {
    onCloseClick: () => void
    show: boolean
    chatStr: string
}

function TransModal(props: IProps) {

    const { show, onCloseClick, chatStr } = props;
    const [chatList, setChatList] = useState<any[]>([]);
    const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);

    useEffect(() => {
        //console.log("#TM: " + chatStr)
        if (chatStr.length < 4)
            return;
        let chats = [];
        
            chats = JSON.parse(chatStr);

        setChatList(chats);
        let strList = "";
        chatList.map((item) => {
            console.log("#TM: chats" + item.username);
            strList = strList + item.username + "\t" + formatTime2(item.time) + "\t" + item.text + "\n"
        })
        console.log("#TM: listy" + strList);
        return () => {

        }
    }, [show])


    return (
        <Modal show={show} onHide={onCloseClick} backdrop="static" className="text-light modal-border-radius meeting-summary-modal">
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="modal-title h5" id="confirmPopupLabel">Meeting summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 p-0">
                    <Tabs defaultActiveKey="second">
                        <Tab eventKey="first" title="Meeting summary">
                            <div className="col-12 row m-0 p-0 mt-3">                            
                                <div className="col-12 mt-3">
                            <p className="text-center"><h5>Coming soon</h5></p>
                            <p className="text-center fontsize-14">Are you interested in this upcoming feature?</p>
                            <p className="text-center">
                                <img src="https://em-content.zobj.net/source/apple/391/neutral-face_1f610.png" className={`emojy-img ${selectedEmoji === 'emoji1' ? 'select-emojy-img' : ''}`} onClick={() =>{setSelectedEmoji('emoji1')}} />
                                <img src="https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png" className={`emojy-img ${selectedEmoji === 'emoji2' ? 'select-emojy-img' : ''}`} onClick={() =>{setSelectedEmoji('emoji2')}} />
                                <img src="https://em-content.zobj.net/source/apple/391/grinning-face_1f600.png" className={`emojy-img ${selectedEmoji === 'emoji3' ? 'select-emojy-img' : ''}`} onClick={() =>{setSelectedEmoji('emoji3')}} />
                                <img src="https://em-content.zobj.net/source/apple/391/smiling-face-with-heart-eyes_1f60d.png" className={`emojy-img ${selectedEmoji === 'emoji4' ? 'select-emojy-img' : ''}`} onClick={() =>{setSelectedEmoji('emoji4')}} />
                            </p>
                        </div>
                            </div>
                        </Tab>
                        <Tab eventKey="second" title="Meeting transcription">
                            <div className="col-12 p-0 mt-3">
                                {
                                    chatList.map((item, index) => (<>
                                        <h6 className="transcription-test fontsize-14" id={"h_" + index}>
                                            <span>{item.username} </span>
                                            <span>{formatTime2(item.time)}</span>
                                        </h6>
                                        <p className="fontsize-14" id={"p_" + index}>
                                            {item.text}
                                        </p>
                                    </>))
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default TransModal;