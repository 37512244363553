import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import AgoraRTC, { IAgoraRTCClient, IAgoraRTCRemoteUser, ILocalVideoTrack, NetworkQuality } from 'agora-rtc-sdk-ng';
import { StoreClass } from "../../store/Store";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import suteLogs from "../../utils/log";
import { getGroupDetailsById, getGroupMemsAsync, getGroupsByEmpId, removeSuccessStatus } from "../../services/ContactsService";
import { AddEmployeeToRoom, AddGuestToRoom, RemoveGuestFromRoom, UpdateGuestLastActivity, UpdateLastActivityTime, leaveRoomById, updateLock, updateRoomSession } from "../../services/RoomsService";
import { RemoteStreamUser, iRemoteStreams, localStreamUser } from "../../interfaces/icall";
import './Call.css';
import './room.css';
import { t } from '../../i18n';
import RemotePlayer from "./remote-player";
import { globalStore } from "../../store/global_store";
import { getAsyncEmpDetailsByEmpGuid } from "../../services/UserActions";
import RoomloginPopup from "../../layout/RoomloginPopup";
import GroupChatComponent from "../../components/GroupChatComponent";
import Participants from "./Participants";
import ProfileView from "../myoffice/ProfileView";
import TimeSlotPopup from "../myoffice/TimeSlotPopup";
import CallPopup from "../../layout/callPopup";
import { Modal, ProgressBar, Dropdown, Overlay, Popover } from 'react-bootstrap';
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import RoomEdit from "./RoomEdit";
import Effects from "./bgPopup";
import { GetFromActivitie, GetToActivitie, SaveMyActivity } from "../../services/ActivityServices";
import { flushSync } from "react-dom";
import * as T from '../../actions/Types';
import io, { Socket } from "socket.io-client";
import { isChrome } from "react-device-detect";

import { RtmManager } from "../../services/rtm-api";
import { SttManager } from "../../services/stt-api";
import { ISttData } from "../../reducers/AuthReducer";
import { apiGetAgoraToken } from "../../common";
import { ITextstream, parser } from "../../services/parser"
import { ILanguageSelect, ITextItem } from "../../types";
import TranscriptContent from "../../components/TranscriptContent";
import Meetingfeedbackpopup from "../../layout/meetingfeedbackpopup";
import NetWork from "../../components/network/NetWork";

const rtmManager = new RtmManager()
const sttManager = new SttManager({
    rtmManager,
})

window.rtmManager = rtmManager
window.sttManager = sttManager

function MettingRoom(props: any) {

    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const { EMP } = useSelector((state: StoreClass) => ({ EMP: state?.EmployeeReducer }));

    let userDisplayName = useRef(localStorage.getItem('FirstName') as string);
    const [empId, setEmpId] = useState(localStorage.getItem('EmpGuid') as string);
    const guestEmpId = useRef<any>(undefined);
    const orgId = localStorage.getItem('OrgGuid') || '';
    const profileImage = user.empData.ProfileImageURL;
    const { meetingPId } = useParams();
    const SuteLog = new suteLogs();
    const [meetingId, setMeetingId] = useState<string>(meetingPId || '');
    const findRoom = useRef(false);
    //  const [roomDtls, setRoomDtls] = useState<any>({ });
    const roomDtls = useRef<any>({});
    let called = false;
    const history = useNavigate();
    const dispatch = useDispatch();
    const [fullScreen, setFullScreen] = useState(false);
    const [hasPinned, setHasPinned] = useState(false);
    /// const hasPinned = useRef(false);
    const [postRoomStatusInt, updateIntId] = useState<any>(0);
    const [token, setToken] = useState('');

    const appId = process.env.REACT_APP_AGORA_APP_ID as string;
    // const Lclient: IAgoraRTCClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    let Lclient: IAgoraRTCClient = ({} as IAgoraRTCClient);
    AgoraRTC.enableLogUpload();
    // Set the log output level as INFO
    AgoraRTC.setLogLevel(1);
    //const [client, setClient] = useState<any>();
    const target = useRef(null);
    const [client, setClient] = useState<any>(undefined);
    let localStream = useRef<localStreamUser>({
        streamID: 0,
        audio: false,
        video: false,
        screen: false,
        localAudioTrack: undefined,
        localVideoTrack: undefined,
        localDetails: { audio: 'YES', video: 'YES' },
        errAudio: false,
        errVideo: false,
        audioDescription: '',
        videoDescription: '',
    });
    const [localUser, setLocalUser] = useState<localStreamUser>(localStream.current);
    let screenStream = useRef<ILocalVideoTrack | undefined>(undefined)
    // const [screenStream, setscreenStream] = useState<ILocalVideoTrack>({} as ILocalVideoTrack);
    const [screenShared, setscreenShared] = useState<boolean>(false);
    //let screenShared = useRef<boolean>(false);
    const [joined, setJoined] = useState<boolean>(false);
    const [uid, setSid] = useState<string>('');
    //const [screenId, setScreenId] = useState<string>('');
    let screenId = useRef<string>('');
    //  const [screenClient, setScreenClient] = useState<IAgoraRTCClient>({} as IAgoraRTCClient);
    let screenClient = useRef<IAgoraRTCClient>({} as IAgoraRTCClient)
    let locked = useRef<any>(undefined);
    // const [locked, setLocked] = useState(false);
    const remoteStreams = useRef<RemoteStreamUser[]>([]);
    // let remoteUsers = useRef<RemoteStreamUser[]>([]);
    const [otherUsers, setOtherUsers] = useState<RemoteStreamUser[]>(() => [] as RemoteStreamUser[]);
    const [showWaiting, setShowWaiting] = useState(false);
    const [pWindow, setPwindow] = useState(false);
    const [remoteRequests, setRemoteRequests] = useState<any[]>([]);
    const [waitingMsg, setWaitingMsg] = useState("please wait connecting....");
    const [loginPopup, setLoginPopup] = useState(false);
    const [chatWindow, setChatWindow] = useState(false);
    const [newMsg, setNewMsg] = useState(false);
    const [selectUser, setSelectUser] = useState<any>({});
    const [profileWin, setProfileWin] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showCalErrs, setShowCalErrs] = useState(false);
    const [errType, seterrType] = useState(2);
    const [showWB, setShowWB] = useState(false);
    const [streamsInfo, setStreamsInfo] = useState<any>();
    const modelTestRef = useRef<HTMLDivElement>();
    const modelleaveRef = useRef<HTMLDivElement>();
    const [mics, setMics] = useState<MediaDeviceInfo[]>([]);
    const [cams, setCams] = useState<MediaDeviceInfo[]>([]);
    const [micName, setMicName] = useState('');
    const [camName, setCamName] = useState('');
    let volumeAnimation = useRef(0);
    const [count, setCount] = useState(0)
    const [show, setShow] = useState(false);
    const [reShow, setReShow] = useState(false);
    const [showleave, setShowleave] = useState(false);
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = useRef(0);
    const previousTimeRef = useRef(0);
    let [prvGuest, setPrvGuest] = useState('');
    const [bgWindow, setBgWindow] = useState(false);
    const [bgOpen, setBgOpen] = useState(false);
    const [bgEnable, setBgEnable] = useState(false);
    let processor = useRef<any>(null);
    const denoiser = new VirtualBackgroundExtension();
    AgoraRTC.registerExtensions([denoiser]);
    const [selectedBG, setSelectedBG] = useState('');
    const [btnText, setBtnText] = useState('');
    const [editPopup, setEditPoup] = useState(false);
    let lockinterval = useRef<any>(0);
    let unlockinterval = useRef<any>(0);
    let stopunlock = useRef<boolean>(false);
    const [requestLoked, setRequestLoked] = useState<any>(undefined);
    const [, updateState] = useState({});
    let justJoind = useRef<boolean>(true);
    const [hasAccess, setHasAccess] = useState(false);
    let initDone = useRef<boolean>(false);
    let effectDone = useRef<boolean>(false);
    let lockIds = useRef<any[]>([]);
    const [socket, setSocket] = useState<Socket | undefined>(undefined);
    let newUser = useRef<any>(undefined);
    const rta_url = process.env.REACT_APP_API as string;//
    const location = useLocation();
    const [showVideo, setShowVideo] = useState(false);
    const [duration, setDuration] = useState(0)
    const intervalTime = useRef<NodeJS.Timeout>();
    const [showTrans, setShowTrans] = useState(false);
    const [showfeedbackpopup, setShowFeedbackPopup] = useState(false);
    const [nwQuality, setNWQuality] = useState<NetworkQuality>();

    const genRandomUserId = (): number => {
        return 100000 + Math.floor(+Math.random() * 100000)
    }
    useEffect(() => {

        //  window.rtmManager.on("userListChanged", onRtmUserListChanged)
        window?.rtmManager.on("languagesChanged", onLanguagesChanged)
        window?.rtmManager?.on("sttDataChanged", onSttDataChanged)


        return () => {
            // window.rtmManager.off("userListChanged", onRtmUserListChanged)
            window?.rtmManager.off("languagesChanged", onLanguagesChanged)
            window?.rtmManager?.off("sttDataChanged", onSttDataChanged)

        }
    }, [])
    const onLanguagesChanged = (languages: ILanguageSelect) => {
        console.log("#STT onLanguagesChanged", languages)
        //dispatch(setLanguageSelect(languages))
    }
    // const onRtmUserListChanged = (list: ISimpleUserInfo[]) => {
    //     console.log("[test] onRtmUserListChanged", list)
    //     setRtmUserList(list)
    // }
    const onSttDataChanged = (data: ISttData) => {
        console.log("#STT onSttDataChanged", data)
        dispatch({ type: T.STTDATA, payload: data })
    }
    useEffect(() => {
        let timer: any
        console.log("#STT user.sttData")
        if (user.sttData.status == "start") {
            console.log("#STT user.sttData.status", user.sttData.status)
            //   timer = setInterval(async () => {
            //     const now = new Date().getTime()
            //     if (user.sttData?.startTime && user.sttData?.duration) {
            //       if (now - user.sttData?.startTime > user.sttData?.duration) {
            //         await window.sttManager.stopTranscription()
            //         return clearInterval(timer)
            //       }
            //     }
            //   }, 5000)
        }

        return () => {
            // timer && clearInterval(timer)
        }
    }, [user.sttData])

    useEffect(() => {
        console.log("#STT status", user?.sttData?.status)
        // if (isMounted) {
        if (user.sttData.status == "start") {
            console.log("#STT status IN")
            // dispatch(
            //   setRecordLanguageSelect({
            //     translate1List: [],
            //     translate2List: [],
            //   }),
            // )
            sttManager.setOption({
                taskId: user.sttData.taskId ?? "",
                token: user.sttData.token ?? "",
            })
            // dispatch(setSubtitles([]))
            //dispatch(addMessage({ content: t("setting.sttStart"), type: "success" }))
        } else if (user.sttData.status == "end") {
            sttManager.removeOption()
            //dispatch(setCaptionShow(false))
            //dispatch(addMessage({ content: t("setting.sttStop"), type: "success" }))
        }
        // }
        // do not put isMounted in the dependencies
    }, [user.sttData.status])
    useEffect(() => {
        console.log('Mstate E ', effectDone.current)
        dispatch({ type: T.STTLIST, payload: [] })
        if (!effectDone.current) {
            effectDone.current = true;
            window.onpopstate = (ev) => {
                if (user && user.Socket && user.Socket.connected) {
                    console.log('Mstate1 POP ')
                    user.Socket?.emit("popState");
                }
                else
                    console.log('Mstate1 NO POP ')
            }
            if (!user.isAuthenticated) {

                let IoSocket = (isChrome) ? io(rta_url, {
                    transports: ['websocket', 'polling', 'flashsocket'],
                    reconnectionAttempts: 5,
                    // autoConnect: false,
                }) : io(rta_url, { reconnectionAttempts: 5 })

                IoSocket.on("connect_error", (error) => {
                    console.log("#ROOM WFH ERROR ", error);
                    setSocket(IoSocket)
                    if (IoSocket.active) {
                        console.log("#ROOM WFH ERROR ACTIVE", error.message)
                        // temporary failure, the socket will automatically try to reconnect
                    } else {
                        console.log("#ROOM WFH ERROR INACTIVE", error.message);
                        // the connection was denied by the server
                        // in that case, `socket.connect()` must be manually called in order to reconnect
                        // console.log(error.message);
                    }
                });

                // setSocket( as any, );
                IoSocket.on('connect', function () {
                    setSocket(IoSocket)
                    console.log('#ROOM socket connected');
                });
                IoSocket.on("disconnect", (reason) => {
                    console.log('#ROOM socket DISconnected', reason);
                    history('/')
                });
            }
        }
        return () => {
            console.log('MstateE R', effectDone.current)
            if (effectDone.current) {
                if (localStream.current && (localStream.current.localAudioTrack || localStream.current.localVideoTrack))
                    leave().then(() => { });
                console.log("Mstate1 RETURN localStream", localStream);
                cancelAnimationFrame(requestRef.current);
                clearInterval(unlockinterval.current);
                clearInterval(lockinterval.current);
                if (user.Socket && user.Socket.connected)
                    user.Socket.removeListener("roomCall");
                dispatch({ type: T.SUCCESS, payload: '', })
                dispatch({ type: T.ADD_INCALL, payload: false })
                dispatch({ type: T.OPEN_CALL, payload: false })
                dispatch({ type: T.IS_GUEST, payload: false })
            }

        }
    }, []);

    useEffect(() => {
        (
            async () => {

                dispatch({ type: T.ADD_INCALL, payload: false });
                dispatch({ type: T.OPEN_CALL, payload: false });
                dispatch({ type: T.ADD_INCHAT, payload: false });;

                if (initDone.current)
                    return;
                initDone.current = true;
                dispatch({
                    type: T.ADD_INCALL,
                    payload: true
                })
                if (!user.isAuthenticated) {
                    SuteLog.setLog("Conference [useEffect] not isAuthenticated" + empId)
                    setLoginPopup(true);
                    return
                }

                const grp = await getGroupDetailsById(meetingId as string || '', empId, '');

                if (grp.result.GroupGuid == '00000000-0000-0000-0000-000000000000') {                    // dont access the group event not exits
                    setWaitingMsg("Room doesn't exist, please contact the administrator");
                    setBtnText(' OK ')
                    return
                }


                // setRoomDtls(grp.result);
                roomDtls.current = grp.result;
                // setIsConf(grp.result.DisplayName == 'Conference')

                if (grp.result.IsAccess) {
                    await checkLock(grp.result.IsLocked);
                    return;
                }

                if (user.isAuthenticated) {
                    if (grp.result.OrgGuid == orgId) {
                        const uname = (!user.isAuthenticated) ? userDisplayName.current : localStorage.getItem('FirstName') as string;
                        const eid = (!user.isAuthenticated) ? uname : empId;
                        // mediaTest();
                        // setShowWaiting(true);
                        setWaitingMsg("We are waiting for someone to let you in");
                        setBtnText("Cancel");
                        setPwindow(false);
                        sendJoinRequest(eid, uname, meetingId);
                    } else {
                        findRoom.current = false;
                        setWaitingMsg("Access denied, please contact the administrator");
                        setBtnText(' OK ')
                        return
                    }
                }
                //if(hasrecipent)
                //  const lockStatus = false
                //  locked.current = lockStatus
                // setLocked(lockStatus);
                //  SuteLog.setLog("Conference lockStatus " + lockStatus)


                // volumeAnimation.current = requestAnimationFrame(setVolumeWave);
                // requestRef.current = requestAnimationFrame(animate);

                // document.addEventListener('contextmenu',()=> print());
                // window.addEventListener('beforeunload', handleBeforeUnload);
                //window.addEventListener('popstate', handlePopState);
                const handleBeforeUnload = (event: any) => {
                    event.preventDefault();
                    // Custom logic to handle the refresh
                    // Display a confirmation message or perform necessary actions
                };
                // window.addEventListener('beforeunload', handleBeforeUnload);
                return async () => {
                    console.log("Mstate1 US2 ", joined, uid);
                    //--  suteClient.off('playerStatuschanged', playerStatusChangeEvent);
                    //  window.removeEventListener('beforeunload', handleBeforeUnload);

                    /// window.removeEventListener('beforeunload', handleBeforeUnload);
                    // cancelAnimationFrame(volumeAnimation.current)
                    // if (intervalTime.current) {
                    //     clearInterval(intervalTime.current)
                    // }
                }
            }
        )();

    }, []);


    const checkLock = async (islocked: boolean) => {
        locked.current = islocked;
        if (locked.current == true) {
            const uname = (!user.isAuthenticated) ? userDisplayName.current : localStorage.getItem('FirstName') as string;
            const eid = (!user.isAuthenticated) ? guestEmpId.current : empId;
            // mediaTest();
            setWaitingMsg("We are waiting for someone to let you in");
            setBtnText('Cancel')
            setPwindow(false);
            sendJoinRequest(eid, uname, meetingId);
            return
        } else {
            SuteLog.setLog("Conference calling makeJoin ")
            setHasAccess(true);
            setShow(true);
            mediaTest();
            return
        }
    }

    const getLockNotify = () => {

        if (meetingPId) {
            GetToActivitie(meetingPId).then((result) => {
                console.log(" GetToActivitie ");
                if (result.length > 0) {
                    result.forEach((res: any) => {

                        const msgt = res;
                        SuteLog.setLog("Conference getLockNotify " + msgt)
                        if (msgt.ToId && msgt.ToId === meetingPId) {
                            // if (msgt.Status === 'ROOM_LOCKED' || msgt.Status === 'ROOM_UNLOCK') {
                            //     locked.current = (msgt.Status === 'ROOM_LOCKED' ? true : false);
                            // } else 
                            if (msgt.Status === 'REQUEST_TO_JOIN' && user.isAuthenticated) {
                                setPwindow(true);
                                const reqs = remoteRequests.filter(req => req.empGuid !== msgt.FromId);
                                if (reqs.length < 1) {
                                    if (lockIds.current.length > 0) {
                                        const hasID = lockIds.current.filter(req => req == msgt.FromId)
                                        if (hasID.length > 0)
                                            return
                                    }
                                }
                                getAsyncEmpDetailsByEmpGuid(msgt.FromId).then((usrDtls: any) => {
                                    flushSync(() => {
                                        lockIds.current.push(msgt.FromId);
                                        setRemoteRequests([...remoteRequests, { empGuid: msgt.FromId, ProfileImageURL: usrDtls.ProfileImageURL, name: usrDtls.FirstName }]);
                                        if (!requestLoked)
                                            setRequestLoked(msgt);
                                    })

                                });
                            }
                            else {
                                const reqs = remoteRequests.filter(req => { return req.empGuid !== msgt.FromId });
                                setRemoteRequests([...reqs]);
                                lockIds.current = lockIds.current.filter(arrayItem => arrayItem !== msgt.FromId);
                            }
                        }

                    });
                }
                else {
                    flushSync(() => {
                        lockIds.current = [];
                        setRemoteRequests([]);
                    });
                }

            });
        }

    }

    useEffect(() => {
        if (client) {
            SuteLog.setLog("Conference calling joinRoom ")
            joinRoom();
        }
    }, [client]);

    const mediaTest = async () => {
        dispatch({ type: 'ADD_LOADING' });
        try {
            localStream.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
            localStream.current.video = true;
            localStream.current.errVideo = false
            localStream.current.localDetails.video = "YES";
            SuteLog.setLog("camera success");
            setShowVideo(true);
        } catch (error: any) {

            localStream.current.localDetails.video = error.message;
            localStream.current.video = false;
            localStream.current.errVideo = true;
            localStream.current.videoDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
            SuteLog.setLog(" camera error: " + error);
        }

        try {

            //localStream.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true });
            localStream.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true });
            localStream.current.audio = true;
            localStream.current.errAudio = false;
            localStream.current.localDetails.audio = "YES";
            SuteLog.setLog(" Micro phone success");
            
        } catch (error: any) {
            localStream.current.errAudio = true;
            localStream.current.audio = false;
            localStream.current.localDetails.audio = error.message;
            localStream.current.audioDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
            SuteLog.setLog(" Micro phone error: " + error);
        }
        if (localStream.current.localAudioTrack) {
            const mps = await AgoraRTC.getMicrophones();
            setMics(mps)
            if (mps.length > 0)
                setMicName(mps[0].label);
        }
        else
            setMicName(localStream.current.audioDescription?.includes("PERMISSION_DENIED") ? 'Microphone can\'t found' : 'Microphone access denied');

        if (localStream.current.localVideoTrack) {
            const cms = await AgoraRTC.getCameras();
            setCams(cms);
            if (cms.length > 0)
                setCamName(cms[0].label);
            localStream.current.localVideoTrack?.play('dom-localplayer');
        } else
            setCamName(localStream.current.videoDescription?.includes("PERMISSION_DENIED") ? 'Camera access denied' : 'Camera can\'t found');
        await delay(1000);
        dispatch({ type: 'REMOVE_LOADING' })
    }

    function onTestClose() {
        if (localStream.current.localVideoTrack) {
            //  localStream.current.localVideoTrack?.setEnabled(false);
            // localStream.current.localVideoTrack?.stop();
            localStream.current.localVideoTrack?.close();
        }
        if (localStream.current.localAudioTrack) {
            //  localStream.current.localAudioTrack?.setEnabled(false);
            //localStream.current.localAudioTrack?.stop();

            localStream.current.localAudioTrack?.close();
        }
        leaveMeeting();
        setShow(false);
        history(location.state ? location.state : "/pod");
    }

    const GoJoin = () => {
        SuteLog.setLog("Conference [useEffect] " + empId);
        setShow(false);
        setReShow(false);
        Lclient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        setClient(Lclient);
        if (user && user.isAuthenticated && user.Socket && user.Socket.connected) {
            console.log("#ROOM groupUpdated");
            user.Socket?.emit("groupUpdated");
        } else if (user.isAuthenticated) {
            lockinterval.current = setInterval(() => {
                getLockNotify();
            }, 5000)
        }
    }

    useEffect(() => {
        console.log("[user.success] ::" + user.success)
        if (user.success && user.success === 'AddEmployeeToRoom') {

            globalStore.showToast({
                type: 'Room',
                title: "Room Message",
                message: "Joined room meeting successfully"
            });
            dispatch(removeSuccessStatus() as any);
        }
        else if (user.success && user.success === 'AddScreenToRoom') {

            globalStore.showToast({
                type: 'Room',
                title: "Room Message",
                message: "Screen shared successfully"
            });

            dispatch(removeSuccessStatus() as any);
        }
        else if ("RemoveScreenshare" === user.success) {
            console.log("SSS ", user.success);
            globalStore.showToast({
                type: 'Room',
                title: "Room Message",
                message: "Screen sharing stoped successfully"
            });
            dispatch(removeSuccessStatus() as any);
            clearInterval(postRoomStatusInt._id);

            //  newUserAdded({});
        } else if ("UpdateRoomLock" === user.success) {
            console.log("#ROOM UpdateRoomLock");
            globalStore.showToast({
                type: 'Room',
                title: "Room Message",
                message: `Room ${locked.current ? "locked" : "unlocked"} successfully`
            });
            if (user && user.isAuthenticated && user.Socket && user.Socket.connected) {
                console.log("#ROOM groupUpdated");
                user.Socket?.emit("groupUpdated");
            }
            dispatch(removeSuccessStatus() as any);
        }
        return () => {
            dispatch({
                type: T.SUCCESS,
                payload: '',
            })
        }

    }, [user.success]);

    useEffect(() => {
        console.log("Mstate remoteStreams  " + remoteStreams.current.length);
        console.log("Mstate useEffect " + otherUsers.length)
    }, [otherUsers]);

    useEffect(() => {
        SuteLog.setLog("Conference joined effect " + JSON.stringify(localStream.current.localDetails));

        if (joined && meetingId && meetingId.length > 0) {
            const form = new FormData();
            form.append("GroupGuid", meetingId);
            form.append("AGSessionId", uid);
            form.append("PublishDetails", JSON.stringify(localStream.current.localDetails));
            if (user.isAuthenticated) {
                form.append("IpAdress", '');
                form.append("EmployeeGuid", empId);
                dispatch(AddEmployeeToRoom(form) as any);
                SuteLog.setLog("Conference joined dispatch AddEmployeeToRoom")
            } else {
                //let oldguest = localStorage.getItem('GuestName') as string;
                if (prvGuest && prvGuest.length > 0)
                    form.append("IpAdress", prvGuest);
                else
                    form.append("IpAdress", userDisplayName.current);
                form.append("ExternalUserName", userDisplayName.current);
                SuteLog.setLog("Conference joined dispatch AddGuestToRoom")
                dispatch(AddGuestToRoom(form) as any);
            }

        }

    }, [joined]);

    //  const leaveMeeting = async (emoji: any, feedback: any, rating: any) => {
    const leaveMeeting = async () => {

        console.log("Mstate1 leaveMeeting ", joined, uid);
        if (!joined)
            return;

        setWaitingMsg("Thank you");
        console.log("Mstate1 Thank you ", joined, uid);
        setHasAccess(false);
        leaveMeetingFeedbackpopup();
        await leave();
        await updateData('0', '', '0');

    }
    const updateData = async (emoji: any, feedback: any, rating: any) => {
        const form = new FormData();
        form.append("GroupGuid", meetingId);
        form.append("feedback", feedback);
        form.append("emoji", emoji);
        form.append("rating", rating);
        form.append("transcript", JSON.stringify(user.sttSubtitles));
        form.append("AGSessionId", uid);
        dispatch(RemoveGuestFromRoom(form) as any);
        // if (user.isAuthenticated) {
        //     form.append("EmployeeGuid", empId);
        //     dispatch(RemoveGuestFromRoom(form) as any);
        // } else {
        //     form.append("AGSessionId", uid);
        //     dispatch(RemoveGuestFromRoom(form) as any);
        // }
    }
    const pinnIngWindow = (strId: string) => {

        console.log("Mstate pinn BF map " + remoteStreams.current.length)
        remoteStreams.current?.map(stream => { stream.uid == strId ? stream.pinned = !stream.pinned : stream.pinned = false })
        console.log("Mstate pinn AF map " + remoteStreams.current.length)
        flushSync(() => {
            setOtherUsers([...remoteStreams.current]);
        });
        // forceUpdate();
        let streams = remoteStreams.current?.filter(e => { return true == e.pinned });
        // if (!streams)
        //     return;
        if (streams && streams.length > 0) {
            flushSync(() => {
                setHasPinned(true)
            });

        } else {
            setHasPinned(false);
        }

    }

    const onAudioClick = async (evt: any) => {

        if (localStream.current.errAudio) {
            let inum = localStream.current.audioDescription == 'NOT_FOUND' ? 3 : 0;
            if (localStream.current.errVideo)
                inum = localStream.current.audioDescription == 'NOT_FOUND' ? 5 : 2;
            seterrType(inum);
            setShowCalErrs(true);
            return;
        }

        if (!localStream.current.localAudioTrack)
            return;
        SuteLog.setLog("Conference onAudioClick " + !localStream.current.audio)
        //localM.audio = !localStream.current.audio;
        localStream.current.audio = !localStream.current.audio;
        if (localStream?.current.audio) {
            localStream.current.localAudioTrack?.setEnabled(true);
            //stream.localAudioTrack?.play();

        } else {
            // stream.localAudioTrack.stop();
            localStream.current.localAudioTrack?.setEnabled(false);
        }
        setLocalUser(localStream.current);
    }

    const onTestVideoClick = (evt: any) => {

        if (localStream.current.errVideo) {
            let inum = localStream.current.videoDescription == 'NOT_FOUND' ? 4 : 1;
            if (localStream.current.errAudio)
                inum = localStream.current.audioDescription == 'NOT_FOUND' ? 5 : 2;
            seterrType(inum);
            setShowCalErrs(true);
            return;
        }
        if (!localStream.current.localVideoTrack)
            return;

        setShowVideo(!showVideo)
        localStream.current.video = !localStream.current.video;
    }

    const onVideoClick = (evt: any) => {
        console.log("onVideoClick", hasPinned)
        if (localStream.current.errVideo) {
            let inum = localStream.current.videoDescription == 'NOT_FOUND' ? 4 : 1;
            if (localStream.current.errAudio)
                inum = localStream.current.audioDescription == 'NOT_FOUND' ? 5 : 2;
            seterrType(inum);
            setShowCalErrs(true);
            return;
        }
        SuteLog.setLog("#Conference onVideoClick " + !localStream.current.localVideoTrack)
        if (!localStream.current.localVideoTrack)
            return;
        SuteLog.setLog("#Conference onVideoClick " + !localStream.current.video)
        //onPlayerClick('video', !localStream.current.video);
        localStream.current.video = !localStream.current.video;
        if (localStream?.current.video) {
            console.log("#onVideoClick", true)
            localStream.current.localVideoTrack?.play(`dom-localUser`, { fit: 'cover' });
            localStream.current.localVideoTrack?.setEnabled(true)

        }
        else {
            //localStream.current.localVideoTrack?.stop();
            console.log("#onVideoClick", false)
            localStream.current.localVideoTrack?.setEnabled(false)
        }
        setLocalUser(localStream.current);
        // flushSync(() => {
        //     setOtherUsers([...remoteStreams.current]);
        // });
    }

    const SetPublishedUser = (newUser: IAgoraRTCRemoteUser, PublishedUsr: RemoteStreamUser) => {
        console.log("#Mstate OLD", newUser?.uid)
        PublishedUsr.video = newUser ? newUser?.hasVideo : false;
        PublishedUsr.audio = newUser ? newUser?.hasAudio : false;
        PublishedUsr.stream.AudioTrack = newUser ? newUser.audioTrack : undefined;
        PublishedUsr.stream.VideoTrack = newUser ? newUser.videoTrack : undefined;
        PublishedUsr.stream.audio = newUser ? newUser.hasAudio : false;
        PublishedUsr.stream.video = newUser ? newUser.hasVideo : false;
        console.log("#Mstate OLD BF")
        remoteStreams.current.map(ru => {
            return ru.uid == newUser?.uid ? PublishedUsr : ru
        })
        flushSync(() => {
            setOtherUsers([...remoteStreams.current]);
        });
    }

    const newUserAdded = async (newUser: IAgoraRTCRemoteUser) => {
        console.log("#Mstate newUserAdded " + newUser?.uid)
        if (!client) {
            return
        }

        let PublishUsr = remoteStreams.current.filter(u => { return u.uid == newUser?.uid })
        if (PublishUsr.length > 0) {
            SetPublishedUser(newUser, PublishUsr[0])

        } else {
            console.log("#Mstate NEW USER", new Date())
            let mUsers = await getGroupMemsAsync(meetingPId);

            console.log("#Mstate NEW USER", new Date())
            if (mUsers.length > 0) {
                if (newUser) {
                    console.log("#Mstate NEW USER")
                    let U = mUsers.filter((u: any) => { return u.AGSessionId == newUser?.uid });
                    if (U.length > 0) {
                        console.log("#Mstate NEW USER U")
                        let resUser = await setRemoteUser(U[0]);
                        if (resUser) {
                            console.log("#Mstate NEW 1 BF remove", screenId.current, newUser?.uid)
                            if (resUser.screen && (screenId.current != newUser?.uid)) {
                                remoteStreams.current?.map(stream => { stream.pinned = false });
                                resUser.pinned = true;
                                setHasPinned(true);
                                setFullScreen(true);
                            }
                            remoteStreams.current = remoteStreams.current.filter((prevItem) => { return prevItem.uid != resUser.uid })

                            remoteStreams.current.push(resUser);
                            flushSync(() => {
                                setOtherUsers([...remoteStreams.current]);
                            });

                            console.log("#Mstate NEW 1 AF push")
                        }
                    }
                } else {
                    let TempUsers = [] as RemoteStreamUser[];
                    console.log("Mstate  LOOP =>")
                    await setRempoteUsers(mUsers, TempUsers);
                    console.log("Mstate NEW ALL BF TOT =>", remoteStreams.current.length)
                    remoteStreams.current = TempUsers;
                    flushSync(() => {
                        setOtherUsers([...remoteStreams.current]);
                    });
                    console.log("Mstate NEW ALL AF TOT =>", remoteStreams.current.length)
                }
            }
        }

    }

    const setRemoteUser = async (res: any): Promise<RemoteStreamUser | undefined> => {
        if (res.EmpGuid == empId || res.FirstName == userDisplayName.current)
            if (res.IsScreenShared == false)
                return undefined;
        let rStream;
        let TempUser = {} as RemoteStreamUser;
        let usr: any;
        let isValid = true;
        let pinId: any = undefined;
        isValid = true;
        console.log("Mstate hasPinned", hasPinned);
        if (empId == null && userDisplayName.current == res.FirstName)
            isValid = false;
        if (isValid && (res.EmpGuid !== empId) || res.IsScreenShared) {
            console.log("Conference newUserAdded loop  AGSessionId " + res.AGSessionId)
            console.log("Mstate Client  LEngth " + client.remoteUsers.length)

            usr = client.remoteUsers.find((grp: any) => grp.uid == res.AGSessionId);

            TempUser = {} as RemoteStreamUser
            rStream = {} as iRemoteStreams;
            rStream.uid = res.AGSessionId;
            rStream.VideoTrack = usr ? usr.videoTrack : undefined;
            rStream.AudioTrack = usr ? usr.audioTrack : undefined;
            rStream.audio = usr ? usr.hasAudio : false;
            rStream.video = usr ? usr.hasVideo : false;
            rStream.screen = false;

            const tt = (res.PublishDetails && res.PublishDetails != 'undefined' && res.PublishDetails.length > 0) ? res.PublishDetails : `{"audio":"YES","video":"YES"}`;
            const pd = JSON.parse(tt);
            TempUser.errAudio = (pd && pd.audio !== 'YES') ? true : false;
            TempUser.errVideo = (pd && pd.video !== 'YES') ? true : false;
            TempUser.audioDescription = TempUser.errAudio ? ((pd.audio.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (pd.audio.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR'))) : '';
            TempUser.videoDescription = TempUser.errVideo ? ((pd.video.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (pd.video.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR'))) : '';

            TempUser.stream = rStream;
            TempUser.video = usr ? Boolean(usr.hasVideo) : false;
            TempUser.audio = usr ? Boolean(usr.hasAudio) : false;
            TempUser.screen = usr ? res.IsScreenShared : false;
            TempUser.uid = res.AGSessionId;
            TempUser.pinned = pinId == res.AGSessionId ? true : false;
            TempUser.EmpGuid = res.EmpGuid
            TempUser.profile = res;
            TempUser.status = res.status;

            TempUser.FirstName = res.FirstName + (res.IsScreenShared ? '(Screen)' : '');
            return TempUser;
        }
        return undefined;
    }

    const setRempoteUsers = async (resp: any[], TempUsers: RemoteStreamUser[]) => {
        if (resp && resp.length > 0) {
            return await resp.forEach(async res => {
                console.log("Mstate in LOOP 2 =>", res.AGSessionId)
                let resUser = await setRemoteUser(res);
                if (resUser) {
                    console.log("Mstate in LOOP 3 => " + resUser.uid)
                    TempUsers.push(resUser);
                }
            })
        }

    }

    const isPinned = (strId: string) => {
        let streams = remoteStreams.current?.filter(e => { return strId == e.uid });
        if (!streams)
            return false;
        if (streams.length > 0) {
            return streams[0].pinned;
        }
        return false;
    }

    const toggleChat = () => {
        SuteLog.setLog("Conference toggleChat ")
        setNewMsg(false);
        if (chatWindow) {
            setChatWindow(false);
        } else {
            setPwindow(false);
            setChatWindow(true);
            setBgWindow(false);
            setEditPoup(false);
            setShowTrans(false);
            setShowleave(false)
        }
    }

    const toggleWhiteboard = () => {
        // setShowWB(!showWB)
        SuteLog.print();
    }

    const toggleParticipants = () => {
        if (pWindow) {
            setPwindow(false);
        } else {
            setPwindow(true);
            setChatWindow(false);
            setEditPoup(false);
            setBgWindow(false);
            setShowTrans(false);
            setShowleave(false)
        }
    }

    const toggleFullScreen = () => {
        setShowleave(false);
        if (fullScreen) {
            setFullScreen(false);
        } else {
            closeSideBar();
            setFullScreen(true);
        }
    }

    const toggleLock = () => {
        SuteLog.setLog("Conference toggleLock ")
        if (user.isAuthenticated) {
            locked.current = !locked.current
            // setLocked(!locked);
            const form = new FormData();
            form.append("GroupGuid", meetingId);
            form.append("IsLocked", locked.current ? "true" : "false");
            form.append("EmpGuid", empId);
            dispatch(updateLock(form) as any);
        }
    }

    const closeSideBar = () => {
        setChatWindow(false);
        setPwindow(false);
        setShowleave(false)
    }

    const admitRequestedUsr = (empGuid: string) => {
        requestLoked.Status = 'ROOM_ADMIT';
        requestLoked.IsAccepted = true;
        SaveMyActivity(requestLoked).then((res) => { });
        if (user && user.Socket && user.Socket.connected) {
            user.Socket.emit('toCallerStatus', meetingId, 'ADMITTED', empGuid);
        }
        const reqs = remoteRequests.filter(req => { return req.empGuid !== empGuid });
        setRemoteRequests([...reqs]);
    }

    const rejectRequestedUsr = (empGuid: string) => {
        //  chatClient.sendNotification(empGuid, meetingId, "ROOM_REJECT");
        requestLoked.Status = 'ROOM_REJECT';
        requestLoked.IsRejected = true;
        SaveMyActivity(requestLoked).then((res) => { });
        if (user && user.Socket && user.Socket.connected) {
            user.Socket.emit('toCallerStatus', meetingId, 'REJECTED', empGuid);
        }
        const reqs = remoteRequests.filter(req => { return req.empGuid !== empGuid });
        setRemoteRequests([...reqs]);
    }

    const openProfile = (profile: any, empGuid: string, name: string, image: string) => {
        // if (user.isAuthenticated && empGuid !== '00000000-0000-0000-0000-000000000000') {
        //     if (!profile) {
        //         profile = { EmpGuid: empGuid, FirstName: name, LastName: '', ProfileImageURL: image, NoChat: true };
        //     }
        //     setSelectUser({ ...profile, NoChat: true });
        //     setProfileWin(true);
        // }
    }

    const closeProfile = () => {
        setProfileWin(false);
    }

    const toggleSchedule = () => {
        console.log('toggleSchedule', selectUser);
        setShowSchedule(!showSchedule)
    }

    async function stopScreenCall() {
        console.log("#SS stopScreenCall")
        stopScreen().then((result) => {
            if (!result)
                return;
            console.log("#SS 222")
            const form = new FormData();
            form.append("GroupGuid", meetingId);
            form.append("IsScreenShared", 'true');
            form.append('no_toast', 'true');
            if (user.isAuthenticated) {
                form.append("EmployeeGuid", empId);
                dispatch(leaveRoomById(form) as any);
            } else {
                ``
                form.append("AGSessionId", screenId.current);
                dispatch(RemoveGuestFromRoom(form) as any);
            }
            console.log("#SS stopScreenCall 33333")
        });
    }

    const addScreenToRoom = async () => {
        const form = new FormData();
        form.append("GroupGuid", meetingId);
        form.append("AGSessionId", screenId.current);
        form.append("IsScreenShared", 'true');
        if (user.isAuthenticated) {
            form.append("EmployeeGuid", empId);
            dispatch(AddEmployeeToRoom(form, true) as any);
        } else {
            form.append("ExternalUserName", userDisplayName.current);
            dispatch(AddGuestToRoom(form, true) as any);
        }
        SuteLog.setLog("Conference suteClient.screenShared dispatch add screen shared user to room")
    }


    const updateUserName = async (dn: string, email: string) => {
        userDisplayName.current = dn;
        SuteLog.setLog("Conference calling joinRoom " + dn)
        setPrvGuest(localStorage.getItem('GuestName') as string);
        guestEmpId.current = genUUID();
        setEmpId(guestEmpId.current);
        dispatch({
            type: T.IS_GUEST,
            payload: true
        })
        const roomResp = await getGroupDetailsById(meetingPId || '', '00000000-0000-0000-0000-000000000000', email);
        setLoginPopup(false);
        roomDtls.current = roomResp.result;
        await checkLock(roomResp.result.IsLocked)

    }

    async function joinRoom() {

        SuteLog.setLog("Conference joinRoom")
        if (!joined) {
            SuteLog.setLog("Conference calling autoJoinRoom")
            await autoJoinRoom();

        }
    }

    const autoJoinRoom = async () => {
        SuteLog.setLog("Conference autoJoinRoom ")
        SuteLog.setLog("Conference calling makeJoin ")
        await makeJoin(meetingId);
    }

    const sendJoinRequest = (empId: string, uname: string, mid: string) => {
        stopunlock.current = false;
        SaveMyActivity({ Type: "REQUEST_TO_JOIN", FromId: empId, ToId: meetingId, OrgGuid: orgId, Status: "REQUEST_TO_JOIN" }).then((res) => {
            if (user && user.Socket && user.Socket.connected) {
                user.Socket?.emit("lockCall", meetingId, empId, userDisplayName);
            }
            else {
                unlockinterval.current = setInterval(() => {
                    getfromLockNotify(empId);
                }, 2000)
            }
        });

    }

    const getfromLockNotify = (activityID: any) => {

        GetFromActivitie(activityID).then((result) => {
            console.log(" GetToActivitie ");
            if (result.length > 0) {
                const msgt = result[0];
                if (msgt.FromId === activityID) {
                    console.log("to user waiiting to join the call", msgt);
                    if (msgt.Status === 'ROOM_ADMIT' && stopunlock.current == false) {
                        stopunlock.current = true;
                        clearInterval(unlockinterval.current);
                        msgt.IsDeleted = true;
                        msgt.Status = 'ROOM_JOINED';
                        SaveMyActivity(msgt).then((res) => { });
                        setHasAccess(true);
                        setWaitingMsg("Accepted your request, joining into the room...");
                        setBtnText('')
                        setShow(true);
                        console.log("calling makeJoin 22222");
                        mediaTest();
                        // setShowWaiting(false);
                    } else if (msgt.Status === 'ROOM_REJECT' && stopunlock.current == false) {
                        stopunlock.current = true;
                        clearInterval(unlockinterval.current);
                        // setShowWaiting(false);
                        msgt.IsDeleted = true;
                        msgt.Status = 'ROOM_REJECT';
                        SaveMyActivity(msgt).then((res) => { });
                        setWaitingMsg("sorry, Denied to join the room");
                        setBtnText(' OK ')
                        setTimeout(function () {
                            history('/pod');
                        }, 3000);

                    }
                }

            }

        });

    }

    const makeJoin = async (meetingId: any) => {
        if (joined) {
            await justLeave();
        }
        if (!joined) {
            await client.enableDualStream();
            subscribeEvents();
            const UserMeetId = genRandomUserId();
            const agtoken = await apiGetAgoraToken({ channel: meetingId, uid: UserMeetId });
            setToken(agtoken);
            console.log('#AG after join', UserMeetId, token);

            let uuid = await client.join('a1d430bc89774c49b7b488045ad08b0d', meetingId, agtoken ? agtoken : null, null);
            console.log('#AG after uuid', uuid);
            setSid(uuid as string);
            if (user && user.Socket && user.Socket.connected) {
                console.log('#ROOM roomCall ', uuid, meetingId, user.isAuthenticated ? empId : '0000')
                user.Socket?.emit("roomCall", uuid, meetingId, user.isAuthenticated ? empId : '0000');
            }
            try {
                if (!localStream.current.localVideoTrack) {
                    localStream.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
                    localStream.current.errVideo = false
                    localStream.current.video = true
                    localStream.current.localDetails.video = "YES";
                    SuteLog.setLog("camera success");
                }

            } catch (error: any) {
                localStream.current.video = false
                localStream.current.localDetails.video = error.message;
                localStream.current.errVideo = true;
                localStream.current.videoDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
                SuteLog.setLog(" camera error: " + error);

            }

            try {

                if (!localStream.current.localAudioTrack) {
                    localStream.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true });// await AgoraRTC.createMicrophoneAudioTrack({ encoderConfig: "music_standard" });
                    localStream.current.errAudio = false;
                    localStream.current.audio = true;
                    localStream.current.localDetails.audio = "YES";
                    SuteLog.setLog(" Micro phone success");
                }

            } catch (error: any) {
                localStream.current.errAudio = true;
                localStream.current.audio = false;
                localStream.current.localDetails.audio = error.message;
                localStream.current.audioDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
                SuteLog.setLog(" Micro phone error: " + error);
            }

            localStream.current.streamID = uuid;
            localStream.current.screen = false;
            setJoined(true)

            SuteLog.setLog(" SuteClient calling publish ");
            try {

                if (localStream.current.localAudioTrack) {
                    if (!localStream.current.audio) {
                        // await localStream.current.localAudioTrack.setEnabled(true);
                        await client.publish([localStream.current.localAudioTrack]);
                        // await delay(100)
                        // await localStream.current.localAudioTrack.setEnabled(false);
                    } else {
                        await client.publish([localStream.current.localAudioTrack]);
                    }

                }
                console.log("#STT");
                sttManager.init({
                    userId: uuid + "",
                    userName: userDisplayName.current,
                    channel: meetingId
                });
                console.log("#11 localStream.current.video", localStream.current.video);
                if (localStream.current.localVideoTrack) {
                    if (localStream.current.video) {
                        localStream.current.localVideoTrack?.play(`dom-localUser`, { fit: 'cover' });
                        await client.publish([localStream.current.localVideoTrack]);
                    }
                    else {
                        await localStream.current.localVideoTrack.setEnabled(true);
                        await client.publish([localStream.current.localVideoTrack]);
                        await delay(100)
                        await localStream.current?.localVideoTrack?.setEnabled(false);
                    }

                }

                setLocalUser(localStream.current);

                console.log("Mstate:  JOINED " + client.remoteUsers.length);
            } catch (error) {
                console.log(" publish error: " + error);
            }

            SuteLog.setLog(" SuteClient publish successfully");
        }

    }

    const delay = (ms: any) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    async function justLeave() {
        SuteLog.setLog("Conference justLeave")
        await leave();
    }

    async function leave() {
        console.log("Mstate Leave");

        if (!localStream) {
            history('/pod');
            return;
        }
        if (user && user.Socket && user.Socket.connected) {
            console.log('#ROOM roomLeave ', uid);
            user.Socket?.emit("roomLeave", uid);
        }
        try {

            stopScreenCall().then(() => { });

            if (localStream.current.localVideoTrack) {
                 localStream.current.localVideoTrack.stop()
                localStream.current.localVideoTrack.close()
            }

            if (localStream.current.localAudioTrack) {
                 localStream.current.localAudioTrack.stop()
                localStream.current.localAudioTrack.close()
            }

            unsubscribeEvents();
           await window.sttManager.stopTranscription();
          //  await Promise.all([sttManager.destroy()])
            await client.leave().then(() => {
                localStream.current =
                {
                    streamID: 0,
                    audio: false,
                    video: false,
                    screen: false,
                    localAudioTrack: undefined,
                    localVideoTrack: undefined,
                    localDetails: { audio: 'YES', video: 'YES' },
                    errAudio: false,
                    errVideo: false,
                    audioDescription: '',
                    videoDescription: '',
                }
                setJoined(false);
                setShowleave(false);
                setReShow(false);
                setPwindow(false);
                setShowleave(false)

            }).catch(() => {
                setJoined(false);
                history('/pod');
            });
        } catch (error) {
            history('/pod');
        }
    }



    const handleUserPublished = (async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
        console.log("#Mstate UserPublished", new Date())
        console.log(" Mstate1 UserPublished " + user.uid + " " + mediaType);
        await client.subscribe(user, mediaType);
        if (mediaType === "audio") {
            if (user.audioTrack && !user.audioTrack.isPlaying) {
                user.audioTrack.play()
            }
        }

        let RemoteUser = otherUsers.filter(u => { return u.uid == user?.uid });
        if (RemoteUser && RemoteUser.length > 0) {
            SetPublishedUser(user, RemoteUser[0]);
        }
        else
            await newUserAdded(user)
    });

    const Handleinfo = (uid: any, msg: any) => {
        console.log("#Mstate Handleinfo", new Date())
        console.log(" Mstate1 Handleinfo " + uid + " " + msg);

    }
    const HandleUsers = (users: IAgoraRTCRemoteUser[]) => {
        console.log(" Mstate1 Users " + users.length);

    }

    const handleUserJoined = async (user: any) => {
        console.log("#Mstate UserJoined", new Date())
        console.log(" Mstate1 UserJoined " + user.uid);
        await newUserAdded(user)
    }

    const handleUserUnpublished = async (user: IAgoraRTCRemoteUser, mediaType: any) => {
        console.log("#Mstate Unpublished", new Date())
        console.log(" Mstate1 Unpublished " + user.uid + " " + mediaType);
        await client.unsubscribe(user, mediaType);
        let RemoteUser = otherUsers.filter(u => { return u.uid == user?.uid });
        if (RemoteUser && RemoteUser.length > 0) {
            SetPublishedUser(user, RemoteUser[0]);
        }
        else
            await newUserAdded(user)
    };

    const handleUserLeft = (user: IAgoraRTCRemoteUser, reason: string) => {
        console.log("Mstate1     Reasom", reason, user.uid);
        dispatch(updateRoomSession(user.uid) as any)
        console.log("Conference User left user length ", remoteStreams.current.length);
        let stillUsers = remoteStreams.current.filter(ru => { return ru.uid != user.uid });
        console.log("Conference User left still Users length ", stillUsers.length);

        console.log("Mstate LEFT BF hasPinned", hasPinned)
        let streams = stillUsers?.filter(e => { return true == e.pinned });

        if (streams && streams.length > 0) {
            setHasPinned(true)
        } else {
            setHasPinned(false);
        }
        console.log("Mstate LEFT AF hasPinned", hasPinned)
        remoteStreams.current = stillUsers;
        console.log("Mstate LEFT BF ")
        flushSync(() => {
            setOtherUsers([...remoteStreams.current]);
        });
        console.log("Mstate LEFT AF ", hasPinned)


    }

    const subscribeEvents = () => {
        SuteLog.setLog(" SuteClient subscribeEvents");
        if (!client) return;
        client.on("stream-message", streamMessage);
        client.on('user-joined', handleUserJoined);
        client.on('user-published', handleUserPublished);
        client.on('user-left', handleUserLeft);
        client.on("user-unpublished", handleUserUnpublished);
        client.on("user-info-updated", Handleinfo)
        client.on("published-user-list", HandleUsers)
        parser.on("textstreamReceived", (textstream) => {
            console.log("#STT textstreamReceived ", textstream);
            onTextStreamReceived(textstream);
        })
        client.on('network-quality', function (stats: NetworkQuality) {
            //alert('network quality');
            console.log('network quality ' + stats.uplinkNetworkQuality)
            console.log('network quality ' + stats.downlinkNetworkQuality)
            setNWQuality(stats);
        });
        client.on("token-privilege-did-expire", function () {
            SuteLog.setLog("onTokenPrivilegeDidExpire")
        })

    }
    const streamMessage = (Suid: any, stream: any) => {
        console.log("#STT stream-message", Suid, stream);
        parser.praseData(stream)
    }
    const updateSubtitles = (
        payload: any
        // { textstream: ITextstream; username: string }
    ) => {
        //  const { payload } = action
        const { textstream, username, profileUrl } = payload
        const { dataType, words } = textstream
        switch (dataType) {
            case "transcribe": {
                console.log("#TB dataType words", profileUrl, dataType, words)
                let textStr: string = ""
                let isFinal = false
                words.forEach((word: any) => {
                    textStr += word.text
                    if (word.isFinal) {
                        isFinal = true
                    }
                })
                console.log("#TB textStr", textStr)

                const st = user.sttSubtitles.findLast((el) => {
                    return el.uid == textstream.uid && !el.isFinal
                })
                console.log("#TB st", st)

                if (!st) {
                    const subtitle: ITextItem = {
                        dataType: "transcribe",
                        uid: textstream.uid,
                        username,
                        text: textStr,
                        lang: textstream.culture,
                        isFinal,
                        time: textstream.time + textstream.durationMs,
                        startTextTs: textstream.textTs,
                        textTs: textstream.textTs,
                        profileurl: profileUrl
                    }
                    console.log("#TB subtitle", subtitle)
                    const tempList = user.sttSubtitles
                    const nextIndex = tempList.length
                    tempList[nextIndex] = subtitle
                    console.log("#TB tempList", tempList)

                    dispatch({ type: T.STTLIST, payload: [...tempList] })
                } else {
                    st.text = textStr
                    st.isFinal = isFinal
                    st.time = textstream.time + textstream.durationMs
                    st.textTs = textstream.textTs
                    console.log("#TB st 2", st)
                }
                break
            }
            case "translate": {
                const st = user.sttSubtitles.findLast((el) => {
                    return (
                        el.uid == textstream.uid &&
                        (textstream.textTs >= el.startTextTs || textstream.textTs <= el.textTs)
                    )
                })
                if (!st) {
                    return
                }
                textstream.trans?.forEach(
                    (transItem: { lang: string; texts: any[]; isFinal: boolean }) => {
                        if (!st.translations) {
                            st.translations = []
                        }
                        const t = st.translations.findLast((el) => {
                            return el.lang == transItem.lang
                        })
                        if (!t) {
                            st.translations.push({ lang: transItem.lang, text: transItem.texts.join("") })
                        } else {
                            t.text = transItem.texts.join("")
                        }
                    },
                )
            }
        }
    }
    const onTextStreamReceived = (textstream: ITextstream) => {
        console.log("#STT UID", textstream.uid, uid, localUser.streamID);
        console.log("#STT onTextStreamReceived", remoteStreams.current);
        console.log("#STT profileImage", profileImage);
        let userName = userDisplayName.current;
        let imgUrl = profileImage;
        if (textstream.uid != localUser.streamID) {
            const targetUser = remoteStreams.current.find((u => u.uid == textstream.uid)) //simpleUserMap.get(Number(textstream.uid))
            console.log("#STT onTextStreamReceived targetUser", targetUser);
            if (targetUser) {
                userName = targetUser.FirstName ? targetUser.FirstName : '';
                imgUrl = targetUser.profile?.ProfileImageURL;
            }

        }
        // modify subtitle list
        console.log("#STT onTextStreamReceived targetUser", userName);


        updateSubtitles({ textstream, username: userName || "", profileUrl: imgUrl || undefined })
    }
    const unsubscribeEvents = () => {
        SuteLog.setLog(" SuteClient unsubscribeEvents");
        client.off('user-published', handleUserJoined);
        client.off('user-published', handleUserPublished);
        client.off('user-left', handleUserLeft);
        client.off("user-unpublished", handleUserUnpublished)
        client.off("stream-message", function () { alert("DDVP") })
        client.off("user-info-updated", function () { })
        client.off("token-privilege-did-expire", function () { })
    };

    const ScreenPublish = async () => {
        SuteLog.setLog(" SuteClient startScreen success  createScreenVideoTrack " + screenId.current);
        SuteLog.setLog(" SuteClient startScreen calling  publish " + screenId.current);
        try {
            screenStream.current = await AgoraRTC.createScreenVideoTrack({ encoderConfig: '1080p_1' }, 'disable');
            await addScreenToRoom();
            setscreenShared(true)
            if (!screenStream.current)
                return
            await screenClient && screenClient.current.publish(screenStream.current).catch((err: any) => {
                console.log("screen publish failed");

            })
            SuteLog.setLog(" SuteClient startScreen publish success for " + screenId.current);


            //   setscreenShared(true);
            console.log("screen published successfully");
            screenStream.current?.on('track-ended', () => {
                console.log("#SS conf -  startScreen track-ended  " + screenId.current)
                stopScreenCall().then(function () {
                    console.log("#SS conf track-ended stoped ScreenSharing  ")
                });
            });
        } catch (error) {

        }

    }

    const startScreenCall = async () => {
        SuteLog.setLog(" SuteClient startScreen");

        screenClient.current = await AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        if (!screenClient.current) return;
        // setScreenClient(Sclient);
        SuteLog.setLog(" SuteClient startScreen calling join");
        const UserscreenId = genRandomUserId();
        //const agtoken = await apiGetAgoraToken({ channel: meetingId, uid: UserscreenId });
        // setToken(agtoken);
        let Sid = await screenClient.current.join('a1d430bc89774c49b7b488045ad08b0d', meetingId, token, null);//, (uid: any)=> {
        console.log("#token SuteClient startScreen join success for " + Sid);
        screenId.current = Sid as string;
        //setScreenId(Sid as string);
        SuteLog.setLog(" SuteClient startScreen calling  createScreenVideoTrack " + Sid);
        ScreenPublish();

    };// end

    const stopScreen = async (): Promise<boolean> => {
        console.log("#SS stopScreen ")
        let res = false;
        if (screenStream && screenStream.current && screenClient.current) {
            console.log("#SS stopScreen 111")
            screenStream.current?.stop();
            screenStream.current?.close();
            await screenClient.current.leave();
            console.log("#SS stopScreen af leave")
            screenStream.current = undefined;
            setscreenShared(false);
            res = true;
        }
        return res;
    }

    const handleClosePopup = (e: any) => {
        setShowCalErrs(!showCalErrs);

    }

    async function getProcessorInstance() {
        if (!processor.current && localUser?.localVideoTrack) {
            processor.current = denoiser.createProcessor();
            try {
                await processor.current.init("./assets/wasms");
            } catch (e) {
                console.log("Fail to load WASM resource!"); return null;
            }
            localUser?.localVideoTrack?.pipe(processor.current).pipe(localUser?.localVideoTrack?.processorDestination);
        }
        return processor.current;
    }

    const onBGClick = () => {
        setBgWindow(!bgWindow);
        setPwindow(false);
        setChatWindow(false);
        setEditPoup(false);
        setShowTrans(false);
        setShowleave(false)
    }
    const onTransClick = () => {
        setShowTrans(!showTrans);
        setBgWindow(false);
        setPwindow(false);
        setChatWindow(false);
        setEditPoup(false);
        setShowleave(false)

    }
    const enableVirtualBackground = async () => {
        let process = await getProcessorInstance();
        if (process)
            await process.enable();
    };

    const disableVirtualBackground = async () => {
        setSelectedBG('');
        let processor = await getProcessorInstance();
        await processor.disable();
        setBgEnable(false);
    };

    const onColor = async (data: any) => {
        setSelectedBG(data)
        console.log("BG ", selectedBG.length);
        if (localUser.localVideoTrack) {
            let processor = await getProcessorInstance();
            try {
                processor.setOptions({ type: 'color', color: data });
                await processor.enable();
            } finally {

            }
            setBgEnable(true);

        }
    }

    const onBlur = async () => {
        setSelectedBG('BLUR')
        console.log("BG ", selectedBG);
        if (localUser.localVideoTrack) {
            let processor = await getProcessorInstance();
            try {
                processor.setOptions({ type: 'blur', blurDegree: 3 });
                await processor.enable();
            } finally {

            }
            setBgEnable(true);

        }
    }

    const onImage = async (data: any) => {
        setSelectedBG(data)
        console.log("BG ", selectedBG.length);
        const imgElement = document.createElement('img');
        imgElement.onload = async () => {
            let processor = await getProcessorInstance();
            try {
                processor.setOptions({ type: 'img', source: imgElement });
                await processor.enable();
            } finally {
            }
            setBgEnable(true);
        }
        imgElement.src = '/assets/media/images/effects/' + data;
    }

    const toggleEditRoom = () => {
        if (editPopup) {
            setPwindow(false);
        } else {
            setPwindow(false);
            setChatWindow(false);
            setEditPoup(true);
            setBgWindow(false);
            setShowTrans(false);
            setShowleave(false)
        }
    }

    const UpdateStatus = async () => {
        SuteLog.setLog("Conference postRoomStatus")

        const form = new FormData();
        form.append("GroupGuid", roomDtls.current.GroupGuid);
        if (user.isAuthenticated) {
            form.append("EmployeeGuid", empId);
            await UpdateLastActivityTime(form);
        } else {
            form.append("AGSessionId", uid);
            await UpdateGuestLastActivity(form);
        }
    }
    function genUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    const remotePlayers =
        Array.from(otherUsers).map((rsobj: RemoteStreamUser) => {
            if (isPinned(rsobj.uid)) {
                return (<RemotePlayer
                    domId={`dom-${rsobj.uid}`}
                    key={`${rsobj.uid}`}
                    id={`${rsobj.uid}`}
                    account={rsobj.FirstName || 'Loading...'}
                    streamID={rsobj.uid}
                    stream={rsobj.stream}
                    video={rsobj.video}
                    audio={rsobj.audio}
                    local={rsobj.FirstName == userDisplayName.current + '(Screen)'}
                    pinnIngWindow={pinnIngWindow}
                    className='screenShared'
                    profileImage={rsobj?.profile?.ProfileImageURL}
                />)
            } else if (!isPinned(rsobj.uid)) {
                return (<RemotePlayer
                    domId={`dom-${rsobj.uid}`}
                    key={`${rsobj.uid}`}
                    id={`${rsobj.stream.uid}`}
                    account={rsobj.FirstName || 'Loading...'}
                    streamID={rsobj.uid}
                    stream={rsobj.stream}
                    video={rsobj.video}
                    audio={rsobj.audio}
                    local={rsobj.FirstName == userDisplayName.current + '(Screen)'}
                    className={hasPinned ? "d-none" : ''}
                    pinnIngWindow={pinnIngWindow}
                    profileImage={rsobj?.profile?.ProfileImageURL}
                />

                )
            }
        })

    useEffect(() => {
        if (guestEmpId.current == undefined)
            return;

        if (roomDtls.current == undefined || roomDtls.current.OrgGuid.length == 0 || roomDtls.current.OrgGuid == '00000000-0000-0000-0000-000000000000')
            return;

        if (!socket)
            console.log('#TEST  SOCKET LESS');
        if (socket) {
            console.log('#TEST  SOCKET', socket.id);
            dispatch({
                type: T.ADD_SOCKET,
                payload: socket
            })
        }
        if (socket && socket.connected)
            console.log('#TEST SOCKET connected', socket.id);
        if (socket && socket.disconnected)
            console.log('#TEST SOCKET disconnected', socket.id);

        if (roomDtls.current.OrgGuid.length > 0 && guestEmpId.current.length > 0 && socket && socket.connected) {

            console.log('#TEST enterRoom')
            socket?.emit("enterRoom",
                {
                    empGuid: guestEmpId.current,
                    name: userDisplayName.current,
                    room: roomDtls.current.OrgGuid
                }
            );

        }

        return () => {

        }
    }, [roomDtls.current]);

    useEffect(() => {
        if (user && user.Socket && user.Socket.connected) {
            user.Socket.on('lockStatus', (status, userGuid, name) => {
                console.log("#ROOM  lockStatus " + status + " " + name);
                if (status === 'FromAdmit') {
                    setPwindow(true);
                    const reqs = remoteRequests.filter(req => req.empGuid == userGuid);
                    if (reqs.length === 0)
                        setRemoteRequests([...remoteRequests, { empGuid: userGuid, ProfileImageURL: "/assets/media/images/sute_user_default.png", name: name }]);
                } else if (status === 'ADMITTED') {
                    // const reqs = remoteRequests.filter(req => { return req.empGuid !== userGuid });
                    // setRemoteRequests([...reqs]);
                    setHasAccess(true);
                    setWaitingMsg("Accepted your request, joining into the room...");
                    setBtnText('')
                    setShow(true);
                    console.log("calling makeJoin 22222");
                    mediaTest();
                }
                else if (status === 'REJECTED') {
                    setWaitingMsg("sorry, Denied to join the room");
                    setBtnText(' OK ')
                    setTimeout(function () {
                        history('/pod');
                    }, 3000);
                }
                else if (status === 'DONE') {
                    const reqs = remoteRequests.filter(req => { return req.empGuid !== userGuid });
                    setRemoteRequests([...reqs]);
                }

            })
            user.Socket.on('userNewMessage', (UserId: any) => {
                console.log('#TEST userMessage got', UserId)
                if (UserId == meetingId) {
                    if (chatWindow == false) {
                        console.log('newMsg 2', newMsg, chatWindow);
                        setNewMsg(true);
                    }
                }
            })
        }
    }, [user.Socket]);

    useEffect(() => {
        console.log('#CONF eee', showVideo);
        if (localStream?.current?.localVideoTrack) {
            if (showVideo) {
                console.log("#CONF 3333 " + showVideo)
                localStream.current.localVideoTrack?.setEnabled(true);
                localStream.current.video = true;
            }
            else {
                console.log("#CONF 4444 " + showVideo)
                localStream.current.localVideoTrack?.setEnabled(false)
                localStream.current.video = false;
            }

            setLocalUser(localStream.current);
        }
    }, [showVideo])

    useEffect(() => {
        let intervalId = null;
        if (localStream.current.localAudioTrack && show) {
            intervalId = setInterval(() => {
                let val = 0;
                if (localStream.current.localAudioTrack)
                    val = localStream?.current?.localAudioTrack?.getVolumeLevel() * 100;
                setCount(val);
            }, 300);
        }
        else if (intervalId)
            clearInterval(intervalId);
        return () => {

        };
    }, [localStream.current.localAudioTrack, show]);

    const _pad = (num: number) => {
        return num.toString().padStart(2, "0")
    }
    const getRTCStats = () => {
        const status = client.getRTCStats()
        const duration = status?.Duration ?? 0
        setDuration(duration)
    }
    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60

        return `${_pad(minutes)}:${_pad(remainingSeconds)}`
    }

    const leaveMeetingFeedbackpopup = () => {
        setShowFeedbackPopup(true);
        setShowleave(false);
    }

    const leavePage = () => {
        if (user.isAuthenticated)
            history(location.state ? location.state : "/pod", { replace: true })
        else
            window.location.href = "/login";
    }

    const datafromfeedbackpopup = async (isLater: boolean, emoji: any, feedback: any, rating: any) => {
        if (!isLater)
            await updateData(emoji, feedback, rating);

        leavePage();
    }


    return (
        <section className={"Collaborate_PODBG " + (fullScreen ? "collabExpanded" : "") + " overflow-hidden position-relative h-100 "} style={{ "backgroundSize": "cover", "backgroundPosition": "center" }}>
            {
                hasAccess ?
                    <div className="d-flex h-100 text-center w-100">
                        <div className="align-self-center m-auto position-relative w-100 collabParentFrame">
                            {/* <hr className="line"></hr> */}
                            <div className="heading">{roomDtls.current.MeetingName ? roomDtls.current.MeetingName : ""}</div>
                            <div className="CollabFrame">
                                <div className="w-100 h-100 CollabMainCaller">
                                    <div className={(otherUsers?.length || 0) > 0 && !hasPinned ? `GroupCallCards pps pp${((otherUsers?.length || 1) + 1) > 4 ? 5 : ((otherUsers?.length || 1) + 1) == 4 ? 4 : ((4 % ((otherUsers?.length || 1) + 1)) == 0 ? 1 : 3)}` : "CollabCard"}>

                                        {joined && localUser ? <>
                                            <> <div id={`dom-localUser`} className={`agora-rtc-video fluidCard rotateY180deg} ${Boolean(localUser.video) ? '' : 'd-none'} `} hidden={hasPinned ? true : false}>

                                                <span className="network">
                                                    <NetWork level={nwQuality ? nwQuality?.uplinkNetworkQuality : 3}></NetWork>
                                                </span>
                                                <span className="participantName">{userDisplayName.current}</span></div>
                                                <div className={`agora-rtc-video ${Boolean(localUser.video) ? 'd-none' : ''}`} hidden={hasPinned ? true : false}>
                                                <span className="network">
                                                    <NetWork level={nwQuality ? nwQuality?.uplinkNetworkQuality : 3}></NetWork>
                                                </span>
                                                    <img className="m-auto" src={profileImage ? profileImage : "/assets/media/images/sute_user_default.png"} />
                                                    <span className="participantName">{userDisplayName.current}</span>
                                                </div>
                                            </>
                                        </> : ''
                                        }
                                        {remotePlayers}
                                    </div>
                                </div>
                                {/* <div className={remoteStreams && remoteStreams?.length > 0 ? `CollabMiniCaller` : "w-100 h-100 CollabMainCaller"}> */}

                            </div>
                            <ul className="CollabMenu" >
                                <li className={!localStream.current.audio ? "active" : "in-active"} title={t('title.toggle_audio_mute_unmute')} onClick={onAudioClick}>

                                    {
                                        localStream.current && localStream.current.errAudio ? <><span className="warning">?</span>
                                            <img className="actived" src="/assets/media/images/collaborate/mic-dead.svg" alt="" /></> : <img className="actived" src="/assets/media/images/collaborate/mic-inactive.svg" alt="" />
                                    }
                                    <img className="inactived" src="/assets/media/images/collaborate/mic.svg" alt="" />
                                </li>
                                <li className={!localStream.current.video ? "active" : "in-active"} onClick={onVideoClick} title="Toggle Video">
                                    {
                                        localStream.current && localStream.current.errVideo ? <><span className="warning">?</span>
                                            <img title={localStream.current.audioDescription} className="actived" src="/assets/media/images/collaborate/camera-dead.svg" alt="" /></> : <img className="actived" src="/assets/media/images/collaborate/camera-inactive.svg" alt="" />
                                    }
                                    <img className="inactived" src="/assets/media/images/collaborate/camera.svg" alt="" />
                                </li>
                                <li className={screenShared ? "active mobile-none" : "in-active  mobile-none"} title={t('title.screenshare')} onClick={() => { screenShared ? stopScreenCall() : startScreenCall() }}>
                                    <img className="inactived" src="/assets/media/images/collaborate/screenshare.png" alt="" />
                                    <img className="actived" src="/assets/media/images/collaborate/screenshare-inactive.svg" alt="" />
                                </li>

                                <li className={chatWindow ? "active" : "in-active"} title={t('title.toggle_chat_window')} onClick={toggleChat} >
                                    {!chatWindow && newMsg ? <span className="newMessageIndicator"></span> : ""}
                                    <img className="inactived" src="/assets/media/images/collaborate/chatting.png" alt="" />
                                    <img className="actived" src="/assets/media/images/collaborate/chatting-inactive.svg" alt="" />
                                </li>
                                {/* <li className={showWB ? "active" : "in-active"} title={t('title.whiteboard')} onClick={toggleWhiteboard}>
                                    <img className="inactived" src="/assets/media/images/collaborate/whiteboard.svg" alt="" />
                                    <img className="actived" src="/assets/media/images/collaborate/whiteboard-inactive.svg" alt="" />
                                </li> */}
                                <li className={pWindow ? "active" : "in-active"} title={t('title.toggle_participants_side_bar')} onClick={toggleParticipants}>
                                    <img className="inactived" src="/assets/media/images/collaborate/participants.png" alt="" />
                                    <img className="actived" src="/assets/media/images/collaborate/participants-inactive.svg" alt="" />
                                </li>
                                {/* {user.isAuthenticated ? <li className={!locked.current ? "active" : "in-active"} onClick={toggleLock}>
                                    <img className="inactived" src="/assets/media/images/collaborate/lock.svg" alt="" />
                                    <img className="actived" src="/assets/media/images/collaborate/lock-inactive.svg" alt="" />
                                </li> : ''} */}
                                <li className={fullScreen ? "active" : "in-active  mobile-none"} title={t('title.toggle_full_screen')} onClick={toggleFullScreen}>
                                    <img className="inactived" src="/assets/media/images/collaborate/expand.svg" alt="" />
                                    <img className="actived" src="/assets/media/images/collaborate/expand-inactive.svg" alt="" />
                                </li>
                                {/* <li className="active" onClick={onBGClick}>
                                <svg className="starSvg" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" ><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path><path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path></svg>
                            </li> */}
                                <li className={bgWindow ? "active" : "in-active"} onClick={onBGClick} title="Toggle background effects">
                                    <svg className="actived" id="changeColor" fill="#30D5C8" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="42" zoomAndPan="magnify" viewBox="0 0 375 374.9999" height="42" version="1.0"><defs><path id="pathAttribute" d="M 39.289062 17.113281 L 335.539062 17.113281 L 335.539062 357.613281 L 39.289062 357.613281 Z M 39.289062 17.113281 "></path></defs><path id="pathAttribute" d="M 39.292969 102.238281 L 39.292969 272.488281 L 187.414062 357.613281 L 335.535156 272.488281 L 335.535156 102.238281 L 187.414062 17.113281 L 39.292969 102.238281 M 327.511719 106.871094 L 327.511719 267.859375 L 187.414062 348.351562 L 47.316406 267.859375 L 47.316406 106.871094 L 187.414062 26.375 L 327.511719 106.871094 " fillOpacity="1" fillRule="nonzero"></path><g id="inner-icon" transform="translate(85, 85)"> <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="#30D5C8" className="bi bi-stars" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" id="mainIconPathAttribute"></path> </svg> </g>
                                    </svg>
                                    <svg className="inactived" id="changeColor" fill="#6264a5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="42" zoomAndPan="magnify" viewBox="0 0 375 374.9999" height="42" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><path id="pathAttribute" d="M 37.5 12.582031 L 343.5 12.582031 L 343.5 368.832031 L 37.5 368.832031 Z M 37.5 12.582031 " clipRule="nonzero"></path></defs><g><path id="pathAttribute" d="M 190.5 12.585938 L 343.5 101.644531 L 343.5 279.769531 L 190.5 368.828125 L 37.5 279.769531 L 37.5 101.644531 L 190.5 12.585938 " fillOpacity="1" fillRule="nonzero"></path></g><g id="inner-icon" transform="translate(95, 85)"> <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="#fff" className="bi bi-stars" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" id="mainIconPathAttribute"></path> </svg> </g></svg>
                                </li>
                                <li className={showTrans ? "active" : "in-active"} onClick={onTransClick} title="Chat transcript">
                                    <svg className="actived" id="changeColor" fill="#30D5C8" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="42" zoomAndPan="magnify" viewBox="0 0 375 374.9999" height="42" version="1.0"><defs><path id="pathAttribute" d="M 39.289062 17.113281 L 335.539062 17.113281 L 335.539062 357.613281 L 39.289062 357.613281 Z M 39.289062 17.113281 "></path></defs><path id="pathAttribute" d="M 39.292969 102.238281 L 39.292969 272.488281 L 187.414062 357.613281 L 335.535156 272.488281 L 335.535156 102.238281 L 187.414062 17.113281 L 39.292969 102.238281 M 327.511719 106.871094 L 327.511719 267.859375 L 187.414062 348.351562 L 47.316406 267.859375 L 47.316406 106.871094 L 187.414062 26.375 L 327.511719 106.871094 " fillOpacity="1" fillRule="nonzero"></path>
                                        <g id="inner-icon" transform="translate(85, 85)">
                                            <svg stroke="currentColor" fill="#30D5C8" stroke-width="0" viewBox="0 0 16 16" height="200px" width="180px" xmlns="http://www.w3.org/2000/svg"><path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"></path><path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"></path></svg> </g>
                                    </svg>
                                    <svg className="inactived" id="changeColor" fill="#6264a5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="42" zoomAndPan="magnify" viewBox="0 0 375 374.9999" height="42" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><path id="pathAttribute" d="M 37.5 12.582031 L 343.5 12.582031 L 343.5 368.832031 L 37.5 368.832031 Z M 37.5 12.582031 " clipRule="nonzero"></path></defs><g><path id="pathAttribute" d="M 190.5 12.585938 L 343.5 101.644531 L 343.5 279.769531 L 190.5 368.828125 L 37.5 279.769531 L 37.5 101.644531 L 190.5 12.585938 " fillOpacity="1" fillRule="nonzero"></path></g>
                                        <g id="inner-icon" transform="translate(95, 85)">
                                            <svg stroke="currentColor" fill="#fff" stroke-width="0" viewBox="0 0 16 16" height="200px" width="180px" xmlns="http://www.w3.org/2000/svg"><path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"></path><path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"></path></svg>
                                        </g></svg>
                                </li>

                                {/* <li className="time"></li>  */}
                                <span className="CollabSuteLogoClick" onClick={() => { setShowleave(!showleave) }}></span>
                                <button ref={target} className="CollabLeave" onClick={() => { setShowleave(!showleave) }}>
                                    <img src="/assets/media/images/collaborate/leave.png" alt="" />
                                </button>
                            </ul>


                        </div>
                        <Overlay
                            show={showleave}
                            target={target.current}
                            placement="top"
                            containerPadding={20}
                        >
                            <Popover id="popover-contained">
                                {/* <Popover.Header as="h3"> </Popover.Header> */}
                                <Popover.Body className="standed-bg">
                                    <button className="btn btn-SeaGreen" onClick={leaveMeeting}>
                                        Leave Meeting
                                    </button>
                                    &nbsp; &nbsp; <button className="btn btn-SeaGreen" onClick={() => { setShowleave(false) }}>
                                        Stay
                                    </button>
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </div> : <div className="d-flex h-100 text-center w-100">
                        <div className="align-self-center m-auto position-relative w-100 collabParentFrame">
                            <div className="">
                                <div className="w-100 h-100 CollabMainCaller">
                                    <h5 style={{ color: 'white' }}>{waitingMsg}</h5>
                                    <br /><br /><br />
                                    {
                                        btnText.length > 0 ? <button type="button" className="btn btn-SeaGreen" onClick={() => { history('/pod') }}> {btnText} </button> : ''
                                    }
                                </div></div></div></div>
            }
            {chatWindow ? <GroupChatComponent openChat={chatWindow} closeChat={toggleChat} userName={userDisplayName.current} uid={uid}
                teamId={meetingId} /> : ''}
            {pWindow ? <Participants meetingId={meetingId} closeSideBar={closeSideBar}
                roomName='Conference' userDisplayName={userDisplayName.current}
                remoteStreams={otherUsers} remoteReqs={remoteRequests} stream={localStream.current}
                admitRequestedUsr={admitRequestedUsr} openProfile={openProfile}
                rejectRequestedUsr={rejectRequestedUsr} /> : ""}
            {editPopup ? <RoomEdit Room={roomDtls.current} close={() => { setEditPoup(false) }} /> : ''}
            {/* {showWaiting ? <WaitingRoomPopup show={showWaiting} close={() => { setShowWaiting(false) }} message={waitingMsg} /> : ''} */}
            {showSchedule ? <TimeSlotPopup EmpOfficeEmail={selectUser?.OfficeEmail} EmpGuid={selectUser?.EmpGuid} show={showSchedule} close={toggleSchedule} /> : ''}

            {profileWin ? <ProfileView
                userStatus={user.aus[selectUser.EmpGuid] && user.aus[selectUser.EmpGuid] === 'ONLINE' ? user.sus[selectUser.EmpGuid] || 'Online' : 'Offline'}
                openProfile={profileWin} closeProfile={closeProfile} user={selectUser} toggleSchedule={toggleSchedule} /> : ''}
            <CallPopup show={showCalErrs} close={handleClosePopup} errType={errType} hasAudio={localStream.current.errAudio} hasCamera={localStream.current.errVideo} audioMsg={localStream.current.audioDescription} videoMsg={localStream.current.videoDescription} />
            <Modal ref={modelTestRef} show={show} size="lg" backdrop='static' className="text-light" onEscapeKeyDown={(e: any) => { e.preventDefault(); }}>
                <Modal.Body>
                    {/* <div className="border-bottom mb-4 pb-2 text-center">
                        <h5 className="border-secondary mb-0 modal-title text-center" id="confirmPopupLabel">{roomDtls && roomDtls.GroupName ? roomDtls.GroupName : "Default Room"}</h5>
                    </div> */}

                    <div className="row w-100">
                        <div className="col-6">
                            <div id={`dom-localplayer`} className={`test-video ${showVideo ? '' : 'd-none'} `} ></div>
                            <div className={`cam-card ${showVideo ? 'd-none' : ''} `}>
                                <h5>{localStream.current.errVideo ? (errType > 2 ? localStream.current.videoDescription  +' :  Check Camera in your device: Ensure that your device\'s Camera settings are configured correctly.Ensure that the Camera drivers are up to date and installed correctly on your device' : localStream.current.videoDescription+' : Check your browser or device permissions: Ensure that your browser or device is allowing the application to access your Camera') : 'Camera is Off'}</h5>
                            </div>
                        </div>
                        {bgWindow ? <div className="col-6">
                            <Effects disableVirtualBackground={disableVirtualBackground} selectedBG={selectedBG} onBlur={onBlur} onColor={onColor} onImage={onImage} />
                        </div>
                            :
                            <>
                                <div className={`col-6 ${Boolean(showWaiting) ? 'd-none' : ''} `} >
                                    <br />
                                    <h5 className="device-name">Microphone</h5>
                                    <p id="device-intro">&nbsp;</p>
                                    <p id="device-intro">Produce sounds to check if the mic works.</p>
                                    <ProgressBar now={count} striped variant="info" min={0} max={100} />

                                    <div>&nbsp;</div>
                                    <div className="input-group mb-3 mt-2">
                                        <div className="input-group-prepend">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    Mics
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {mics && mics.map((mic: MediaDeviceInfo) => {
                                                        return <Dropdown.Item onClick={() => setMicName(mic.label)}>{mic.label}</Dropdown.Item>
                                                    })
                                                    }

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <input type="text" className="mic-input form-control" aria-label="Text input with dropdown button" readOnly value={micName} />
                                    </div>
                                    <br /><br />
                                    <h5 className="device-name">Camera</h5>
                                    <p id="device-intro">Move in front of the camera to check if it works.</p>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    Cams
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {mics && cams.map((cam: MediaDeviceInfo) => {
                                                        return <Dropdown.Item onClick={() => setCamName(cam.label)}>{cam.label}</Dropdown.Item>
                                                    })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <input type="text" className="mic-input form-control" aria-label="Text input with dropdown button" readOnly value={camName} />
                                    </div>


                                </div>
                                <div className={`col-6 h-100 ${Boolean(showWaiting) ? '' : 'd-none'} `} style={{ textAlign: 'center', paddingTop: '20%' }}>
                                    <h5>{waitingMsg}</h5>
                                </div>
                            </>

                        }
                    </div>


                    <div className="row">
                        <div className="col-6">
                            <ul className="CollatMenu">
                                {/* <li className={!localStream.current.audio ? "active" : "in-active"} title={t('title.toggle_audio_mute_unmute')} onClick={onAudioClick}>
                                    {
                                        localStream.current && localStream.current.errAudio ? <><span className="warning">?</span><img title={localStream.current.audioDescription} className="actived" src="/assets/media/images/collaborate/mic-dead.svg" alt="" /></> : <img className="actived" src="/assets/media/images/collaborate/mic-dead.svg" alt="" />
                                    }
                                    <img className="inactived" src="/assets/media/images/collaborate/mic.svg" alt="" />
                                </li>
                                <li className={!showVideo ? "active" : "in-active"} onClick={onTestVideoClick}>
                                    {
                                        localStream.current && localStream.current.errVideo ? <><span className="warning">?</span><img title={localStream.current.videoDescription} className="actived" src="/assets/media/images/collaborate/camera-dead.svg" alt="" /></> : <img className="actived" src="/assets/media/images/collaborate/camera-dead.svg" alt="" />
                                    }
                                    <img className="inactived" src="/assets/media/images/collaborate/camera.svg" alt="" />
                                </li> */}
                                {showWaiting || localStream.current.errVideo ? '' : <li className="active" style={{ float: "right" }}  onClick={onBGClick}>
                                    <svg className="starSvg" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" ><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path><path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path></svg>
                                </li>
                                }
                            </ul>
                        </div>
                        <div className="col-6 mt-4 justify-content-end">
                            {showWaiting ? '' : <button type="button" className="btn btn-SeaGreen" disabled={localStream.current.errAudio || localStream.current.errVideo} onClick={() => GoJoin()}>Join Now</button>
                            } &nbsp;&nbsp;<button type="button" className="btn btn-SeaGreen" onClick={() => onTestClose()} id="closeContactspopup" data-dismiss="modal">Cancel</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal ref={modelleaveRef} show={reShow} backdrop='static' className="text-light" onEscapeKeyDown={(e: any) => { e.preventDefault(); }}>
                <Modal.Body>
                    <div className="mb-4 pb-2 text-center">
                        <h5 className="border-secondary mb-0 modal-title text-center" id="confirmPopupLabel"> You left the Conference Room</h5>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4">
                            <button type="button" className="btn btn-SeaGreen" onClick={() => GoJoin()}>Re Join</button>
                            &nbsp;&nbsp;<button type="button" className="btn btn-SeaGreen" onClick={() => history('/pod')} id="closeContactspopup" data-dismiss="modal">back to dashboard</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {!show && bgWindow ?
                <div className="SideNav" id="BGSideNav" style={{ zIndex: 11 }}>
                    <div className="sideNavHeader">
                        <div className="CloseNav">
                            <img onClick={onBGClick} src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('button.close')} />
                        </div>
                    </div>
                    <div className="sideNavBody">
                        <Effects disableVirtualBackground={disableVirtualBackground} selectedBG={selectedBG} onBlur={onBlur} onColor={onColor} onImage={onImage} />
                    </div>
                    <div className="sideNavFooter"></div>
                </div> : ''
            }
            {showTrans ?
                <div className="SideNav" id="BGSideNav" style={{ zIndex: 11 }}>
                    <div className="sideNavHeader">
                        <div className="CloseNav">
                            <img onClick={onTransClick} src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('button.close')} />
                        </div>
                    </div>
                    <div className="sideNavBody">
                        <TranscriptContent></TranscriptContent>
                    </div>
                    <div className="sideNavFooter"></div>
                </div> : ''
            }
            {showfeedbackpopup ? <Meetingfeedbackpopup show={showfeedbackpopup} datatomeetingfeedback={datafromfeedbackpopup} /> : ''}
            {loginPopup ? <RoomloginPopup close={() => { setLoginPopup(false) }} okHandle={(displayName: string, email: string) => updateUserName(displayName, email)} /> : ''}
        </section>
    )
}

export default MettingRoom;