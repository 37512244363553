import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Event } from 'microsoft-graph';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import clsx from 'classnames';
import { t } from '../../i18n';
import { EventInput } from '@fullcalendar/react';
import { getEvents } from "../../global/GraphService";
import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './Calendar.css';
import { useDispatch, useSelector } from "react-redux";
import { CheckUncheckEventCategories, CheckUncheckEventsEmail, DeleteCalenderAccountByAccountId, DeleteCalenderEventByEventId, DeleteGroupEventByEventId, getEmployeeCalanderEvents, getEventParticipantsByEventId, getGroupEventParticipantsByEventId, removeEventParticipants, SaveGoogleCalenderEvents, SyncExchangeCalendarEvents, SyncGoogleCalanderEvents, UpdateCalendarEventResponse, UpdateGoogleCalenderEvents, GetCalendarattachements, GetEmployeeAvalibilty, DeleteRecuringEvent, GetAllDaySingleEvent } from "../../services/CalenderSevice";
import { getGroupsByEmpId } from '../../services/ContactsService';
import GoogleLogin from "react-google-login";
import ConfirmPopup from '../../layout/ConfirmPopup';
import { globalStore } from "../../store/global_store";
import Moment from 'moment';
import { removeSuccessStatus } from "../../services/ContactsService";
import { v4 as uuidv4 } from 'uuid';
import MediaSoundTracks from "../myoffice/MediaSoundTracks";
import { gapi } from 'gapi-script';
import { loadAuth2 } from 'gapi-script';
import { ShowToast, GetContacts } from "../../services/UserActions";
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { getGroupMems } from "../../services/ContactsService";
import { useMemo } from 'react';


var startDate: string = '';
var endDate: string = '';
var startTime: string = '';
var endTime: string = '';
var participants: string[] = [];
var isEditable: boolean = true;
var allowSelect: boolean = true;
var deleteAccountId: number;
var staticdate: Date = new Date();
var selectedStartDate: Date = new Date();
var selectedEndDate: Date = new Date();
var selectedRREndDate: Date = new Date();

interface ContactsState {
    events: Event[];
}
interface calevent extends EventInput {
    desc: string;
    type: string;
    color: string;
    email: string;
    meetingLink: string;
    participants: string;
    eventHostId: number;
    GroupEvent: boolean;
    MediaAttachments: string;
    allDay: boolean;
    rrule?: any;
    endTime?: any;
    groupGuid: string;
    isRemainder: boolean;
    RecurrenceRule: string;
    TimeZone: string;
    shortZone: string;
    // isVideo: boolean;
    // isAudio: boolean;
    // isChat : boolean
}
interface calevents {
    cale: any[];
}
interface googleCalEvents {
    googlecalevents: any[];
}
class NewEvent {
    Type: string = '';
    EventName: string = '';
    EventDescription: string = '';
    StartDate: string = Moment(staticdate).format('MM-DD-YYYY');
    EndDate: string = Moment(staticdate).format('MM-DD-YYYY')
    StartTime: string = '12:00';
    EndTime: string = '12:00';
    StartMeridian: string = 'AM';
    EndMeridian: string = 'PM';
    IsAlldaySelected: boolean = false;
    IsSubmitted: boolean = false;
    AccessToken: string = '';
    Code: string = '';
    TimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    isRemainder: boolean = false;
    RecurrenceRule: string = '';
    RruleEvent: String = '';
    // isVideo: boolean = false;
    // isAudio: boolean = false;
    // isChat: boolean = false;
    EmpName: string = '';
    EmpGuid: string = '';
    EmpId: Number = 0;
    EmpEmail: string = '';
    Content: any[] = [];
    Name: string = '';
    Email: string = 'Sute';

    Id: string = '';
    meetingLink: string = '';
    participants: string[] = [];
    orgGuid: string = '';
    eventHostId: number = 0;
    GroupEvent: boolean = false;
    GroupGuid: string = '';
    AttachedContent: any[] = [];
    UntilRREnddate: string = Moment(staticdate).format('MM-DD-YYYY');
    shortZone: string = moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

}

function formatDateTime(dateTime: string | undefined) {
    if (dateTime !== undefined) {
        return moment.utc(dateTime).local().format('M/D/YY h:mm A');
    }
}

function Calendar(props: any) {
    const dispatch = useDispatch();
    const handleShowDelete = () => {
        if (createEvent.RecurrenceRule?.length > 4) {
            document.getElementById('rrulePopup')?.click();
        }
        else {
            setShowConfirmDelete(!showConfirmDelete);
            document.getElementById('eventCloseBtn')?.click();
        }
        setClickEditButton(false)
        setEndDatePopUp(false)
    }
    const handleShowAccountDelete = (e: any) => {
        dispatch(UpdateCalendarEventResponse('updateDeleteAccountResponse') as any);
        deleteAccountId = e;
        setShowConfirmAccountDelete(!showConfirmAccountDelete);
    }
    const myUserId = localStorage.getItem('EmpGuid');
    const myEmpEmail = localStorage.getItem('UserName');
    const myEmpId = localStorage.getItem('EmpId');
    const myOrgId = localStorage.getItem('OrgGuid');
    const myEmpName = localStorage.getItem("FirstName");
    const employee = useSelector((store: any) => store.EmployeeReducer);
    const media = useSelector((store: any) => store.MediaReducer);
    const user = useSelector((store: any) => store.AuthReducer)
    const [users, setUsers] = useState({ events: [] } as ContactsState);
    const [eves, setEves] = useState({ cale: [] } as calevents);
    const [groupEves, setGroupEves] = useState({ cale: [] } as calevents);
    const [empEvents, setEmpEvents] = useState([]);
    const [googleevents, setGoogleEvents] = useState({ googlecalevents: [] } as googleCalEvents);
    const [events, setEvents] = useState<any[]>(employee.events || []);
    const [createEvent, setcreateEvent] = useState(new NewEvent());
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmAccountDelete, setShowConfirmAccountDelete] = useState(false);
    const [UpdateEventStatus, setUpdateEventStatus] = useState('');
    const [meetingId, setMeetingId] = useState('');
    const [showMeetingLink, setShowMeetingLink] = useState(false);
    const [showParticipants, setShowParticipants] = useState(false);
    const [showMediaAttachments, setShowMediaAttachments] = useState(false);
    const [showMediaSoundTracks, setShowMediaSoundTracks] = useState(false);
    const [participant, setParticipant] = useState('');
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showCalendar, setShowCalendar] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [isSute, setisSute] = useState(false);
    const [attendes, setattendes] = useState([]);
    const typeaheadRef = useRef<any>(null);
    const daycalendarRef = useRef<FullCalendar>(null);
    const [options, setOptions] = useState<any[]>([]);
    const [myCalenderPopUp, setMyCalenderPopUp] = useState(false)
    const api_URL = process.env.REACT_APP_URL as string;
    const trimmedMeetingLink = createEvent.meetingLink.replace(/sute\.thoughtcubeit\.com/, "");
    const [disableSelectGroup, setDisableMeetingLink] = useState(false);
    const newGUID = uuidv4()
    const [newEvendGUID, SetnewEvendGUID] = useState(newGUID)
    const [Attachfile, setattachFile] = useState<any>([]);
    const [ExistingContent, setExistingContent] = useState<any>([]);
    const [adjustedEndDate, setAdjustedEndDate] = useState(false);
    const [adjEndDate, setAdjEndtime] = useState(new Date());
    const [rruleEvent, setRruleEvent] = useState('');
    //const baseUrl = 'sute.thoughtcubeit.com';
    const [pendingRRule, setPendingRRule] = useState('');
    const isFirstRender = useRef(true);

    const [groupMem, setGroupMem] = useState<any[]>([]);
    const [empAvalibilty, setEmpAvalibilty] = useState<any[]>([]);
    const [eventShowPopUp, setEventShowPopUp] = useState(false)
    const [descriptionPopUp, setDescriptionPopUp] = useState(false)
    const [endDatePopup, setEndDatePopUp] = useState(false)
    const [clickEditButton, setClickEditButton] = useState(false)
    const [handleOldEvent, setHandleOldEvent] = useState(new NewEvent());
    const location = useLocation();
    const loc = location.state;
    const [, setRerender] = useState(0);
    const isEdit = useRef(false)
    const OffSet = new Date().getTimezoneOffset();
    const formattedDate = process.env.REACT_APP_DATE_FORMAT
    const timeZones = moment.tz.names();



    const history = useNavigate();

    const sortedTimeZones = timeZones.sort((a: string, b: string) => {
        const offsetA = moment.tz(a).utcOffset();
        const offsetB = moment.tz(b).utcOffset();
        return offsetA - offsetB;
    });


    const dateClick = function (selectInfo: DateSelectArg) {
        isEdit.current = false
        allowSelect = true;
        selectedStartDate = new Date(selectInfo.startStr);
        selectedEndDate = new Date(selectInfo.startStr);
        selectedRREndDate = new Date(selectInfo.startStr);
        setAdjEndtime(new Date(selectedEndDate));
        setMeetingId('')
        SetnewEvendGUID(uuidv4())
        setShowMeetingLink(false);
        setcreateEvent(new NewEvent());
        setcreateEvent({ ...createEvent, StartTime: '11:00', EndTime: '11:30', StartMeridian: 'AM', EndMeridian: 'AM', EventName: '', EventDescription: '', participants: [], Content: [], EmpName: myEmpName ? myEmpName : '', EmpEmail: myEmpEmail ? myEmpEmail : '', orgGuid: myOrgId ? myOrgId : '', EmpId: new Number(myEmpId) ? new Number(myEmpId) : 0, Type: 'Event', EndDate: Moment(selectedEndDate).format('MM-DD-YYYY'), StartDate: Moment(selectedStartDate).format('MM-DD-YYYY'), Email: 'Sute', EmpGuid: myUserId ? myUserId : '', IsSubmitted: true, GroupGuid: '', isRemainder: false, meetingLink: '', RecurrenceRule: '', Id: '', UntilRREnddate: Moment(selectedRREndDate).format('MM-DD-YYYY'), TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, shortZone: moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z') })
        setGroupEves({ cale: eves?.cale });
        let a = document.getElementById('eventCreateBtn');
        a?.click();
        getExistingGroupEvents(selectedStartDate);
        setDisableMeetingLink(false)
        setShowParticipants(false)
        setUpdateEventStatus('')
        setAdjustedEndDate(false)
        setClickEditButton(false)
    }

    const handleEventClick = async (info: EventClickArg) => {

        console.log("handleEventClick", info)
        console.log("events", events)
        console.log(createEvent.RecurrenceRule)
        isEdit.current = true
        var todayDate = new Date(); //Today Date    
        // var end = info.event._def.extendedProps.setEnd;
        setShowEdit(info.event._def.extendedProps.email == 'Sute');
        setisSute(info.event._def.extendedProps.email == 'Sute');
        if (info.event._def.extendedProps.email != 'Sute') {
            setShowParticipants(true);
            setattendes(info.event._def.extendedProps.participants.split(', '));
            console.log("participants", attendes);
        }
        let atts = [];
        allowSelect = false;
        try {
            if (info.event._def.allDay) {
                const resp = await GetAllDaySingleEvent(info.event.id);

                if (resp.result !== "ERROR") {
                    console.log("All day event", resp.result);
                    startTime = formatAMPM(info.event.start);
                    endTime = formatAMPM(info.event._def.extendedProps.setEnd);
                    startDate = formatDate(new Date(resp.result.UTCStartTime)) + ' ' + startTime;
                    endDate = formatDate(new Date(resp.result.UTCEndTime)) + ' ' + endTime;
                    selectedStartDate = new Date(resp.result.UTCStartTime);
                    selectedEndDate = new Date(resp.result.UTCEndTime);

                }
            } else {
                startTime = formatAMPM(info.event.start);
                endTime = formatAMPM(new Date(info.event._def.extendedProps.setEnd));
                startDate = formatDate(info.event.start) + ' ' + startTime;
                endDate = formatDate(info.event.end) + ' ' + endTime;
                if (info.event.start !== null) {
                    setAdjEndtime(new Date(info.event.start));
                }
                selectedStartDate = info.event.start ? info.event.start : new Date();
                selectedEndDate = new Date(info.event._def.extendedProps.setEnd) ? new Date(info.event._def.extendedProps.setEnd) : new Date();
                // selectedRREndDate =  new Date(info.event._def.extendedProps.setUntilEnd) ? new Date(info.event._def.extendedProps.setUntilEnd) : new Date();

            }

            startTime.substr(0, startTime.indexOf(' ')); // "72"
            var mm = (startTime.substr(startTime.indexOf(' ') + 1)).toUpperCase(); // "tocirah sneab"
            // if (info.event._def.rrule) {
            //     endTime = formatAMPM(dayEvent.endTime);
            //     endDate = formatDate(dayEvent.endTime) + ' ' + endTime;
            // }
        }
        catch (e) { console.log(e) }
        // selectedStartDate = info.event.start ? info.event.start : new Date();
        // selectedEndDate = !info.event._def.allDay && new Date(info.event._def.extendedProps.setEnd) ? new Date(info.event._def.extendedProps.setEnd) : new Date();

        selectedRREndDate = (new Date(info.event._def.extendedProps.setUntilEnd) && info.event._def.extendedProps.RecurrenceRule?.length > 4) ? new Date(info.event._def.extendedProps.setUntilEnd) : selectedStartDate;


        if (info.event._def.extendedProps.meetingLink) {
            setShowMeetingLink(true);
            //SetSelectedGroup(true)
        }
        else {
            setShowMeetingLink(false);
            //SetSelectedGroup(false);
        }
        if (info.event._def.extendedProps.GroupEvent) {
            dispatch(getGroupMems(info.event._def.extendedProps.groupGuid) as any)
            // dispatch(getGroupEventParticipantsByEventId(info.event.id) as any);
        }
        // else {
        dispatch(getEventParticipantsByEventId(info.event.id) as any);
        GetCalendarattachements(info.event.id).then((resp: any) => {

            setExistingContent(resp.result);
            console.log("resp.result", resp.result);
        })
        // }
        if (info.event._def.extendedProps.MediaAttachments !== "[[]]" && info.event._def.extendedProps.MediaAttachments.length > 0) {
            setShowMediaAttachments(true);
            atts = JSON.parse(info.event._def.extendedProps.MediaAttachments);
            createEvent.Content = atts;
        }
        else {
            setShowMediaAttachments(false);
            createEvent.Content = [];
        }
        const updatedEvent = {
            ...createEvent,
            participants: info.event._def.extendedProps.participants.split(', '),
            IsAlldaySelected: info.event._def.allDay || false,
            GroupEvent: info.event._def.extendedProps.GroupEvent,
            EmpName: myEmpName || '',
            EmpEmail: myEmpEmail || '',
            orgGuid: myOrgId || '',
            EmpId: Number(myEmpId) || 0,
            StartDate: Moment(selectedStartDate).format('MM-DD-YYYY'),
            EndDate: Moment(selectedEndDate).format('MM-DD-YYYY'),
            EmpGuid: myUserId || '',
            Id: info.event.id,
            EventName: info.event.title,
            Type: info.event._def.extendedProps.type,
            Email: info.event._def.extendedProps.email,
            EventDescription: info.event._def.extendedProps.desc,
            StartTime: startTime.substr(0, startTime.indexOf(' ')),
            EndTime: endTime.substr(0, endTime.indexOf(' ')),
            StartMeridian: (startTime.substr(startTime.indexOf(' ') + 1)).toUpperCase(),
            EndMeridian: (endTime.substr(endTime.indexOf(' ') + 1)).toUpperCase(),
            meetingLink: info.event._def.extendedProps.meetingLink,
            IsSubmitted: false,
            eventHostId: info.event._def.extendedProps.eventHostId,
            GroupGuid: info.event._def.extendedProps.groupGuid,
            isRemainder: info.event._def.extendedProps.isRemainder,
            RecurrenceRule: info.event._def.extendedProps.RecurrenceRule,
            UntilRREnddate: info.event._def.extendedProps.setUntilEnd,
            selectedRREndDate: new Date(info.event._def.extendedProps.setUntilEnd) ? new Date(info.event._def.extendedProps.setUntilEnd) : new Date(),
            UTCRRStartTime: info.event._def.extendedProps.setUntilStart,
            TimeZone: info.event._def.extendedProps.TimeZone,
            shortZone: info.event._def.extendedProps.shortZone

        };
        setcreateEvent(updatedEvent);
        setHandleOldEvent(updatedEvent);
        (info.event._def.extendedProps.groupGuid != undefined && info.event._def.extendedProps.groupGuid != '') ? setDisableMeetingLink(true) : setDisableMeetingLink(false);
        setGroupEves({ cale: [] });
        setUpdateEventStatus('')
        if (info.event._def.extendedProps.RecurrenceRule?.length > 4) {
            setRruleEvent(info.event._def.extendedProps.RecurrenceRule);
            setClickEditButton(true);
        } else {
            setClickEditButton(false);
        }
    }



    useEffect(() => {
        const access_token = localStorage.getItem('msAccessToken');
        const ms_name = localStorage.getItem('first_name') || '';
        const ms_email = localStorage.getItem('email') || '';

        if (access_token) {
            getEvents(access_token).then(resp => {
                setUsers({ events: resp.value });
            });
            dispatch(SyncExchangeCalendarEvents(access_token, ms_email, ms_name, myUserId) as any);
        }
        dispatch(getEmployeeCalanderEvents(myUserId, OffSet) as any);
        if (!user.groups || user.groups.length == 0) {
            dispatch(getGroupsByEmpId(myUserId) as any);
        }
        if (myOrgId != null && myUserId != null) {
            dispatch(GetContacts(myOrgId, myUserId) as any)
        }
        dispatch(getGroupMems("00000000-0000-0000-0000-000000000000") as any)
    }, []);

    useEffect(() => {
        if (user.contacts && user.contacts.length > 0) {
            let uids: string[] = [];
            console.log(" cons 1", user.contacts);
            const options = user.contacts.filter((con: any) => {
                if (createEvent.participants.length > 0) {
                    const exists = createEvent.participants.filter((ext: any) => ext === con.OfficeEmail);
                    if (exists.length > 0)
                        return false;
                }
                if (myUserId === con.EmpGuid)
                    return false;
                return true;
            });
            console.log("options", options);
            setOptions(options);
        }
    }, [user.contacts, createEvent.participants])



    useEffect(() => {
        console.log("employee.eventParticipants", createEvent.meetingLink)
        if (employee.eventParticipants == '' || employee.eventParticipants) {
            const par = employee.eventParticipants.result as any[];
            //participants = (employee.eventParticipants.result || []).filter((m: { Email: string; }) => m.Email + " ");
            participants = par.map(m => { return m.Email });
            if (employee.eventParticipants) {
                console.log("createEvent.participants", participants);
                console.log("createEvent.participants2", createEvent.participants);
                setShowParticipants(true);
                setcreateEvent({ ...createEvent, participants: [...participants] });
                setHandleOldEvent({ ...handleOldEvent, participants: [...participants] })
            }
            else {
                setShowParticipants(false);
            }
            console.log("createEvent.participants3", createEvent.participants);
            let a = document.getElementById('eventViewBtn');
            a?.click();
            dispatch(removeEventParticipants() as any);
        }
        if (employee.deleteAccountResponse) {
            dispatch(getEmployeeCalanderEvents(myUserId, OffSet) as any);
            dispatch(UpdateCalendarEventResponse('updateDeleteAccountResponse') as any);
        }
        else if (employee.googleEvents) {
            dispatch(getEmployeeCalanderEvents(myUserId, OffSet) as any);
            dispatch(UpdateCalendarEventResponse('updatesyncgooglecalendarresponse') as any);
        }
        console.log("getting groups", user.groups)
    }, [employee.eventParticipants, employee.deleteAccountResponse, employee.googleEvents]);

    useEffect(() => {
        console.log("meetingId")
        if (meetingId != '') {

            const group = user.groups.filter((grp: any) =>
                grp.GroupGuid == meetingId
            );

            if (group[0].GroupName == "Conference")
                setcreateEvent({ ...createEvent, meetingLink: `${api_URL}/${group[0].GroupName}/${newEvendGUID}`, GroupGuid: meetingId })
            else
                setcreateEvent({ ...createEvent, meetingLink: `${api_URL}/customrm/${newEvendGUID}`, GroupGuid: meetingId })
            setShowMeetingLink(true);
        }
        else {
            setcreateEvent({ ...createEvent, meetingLink: '', GroupGuid: '' })
            setShowMeetingLink(false);
        }
    }, [meetingId]);


    useEffect(() => {
        console.log("employee.events", employee.events)
        let events: calevent[] = [];
        const c = ((employee.events || []).length > 0 ? (employee.events || []).forEach((obj: any) => {
            if (obj.Checked) {
                (employee.calendarCategories || []).forEach((type: any) => {
                    if (type.Checked) {

                        (obj.Events || []).forEach((event: any) => {


                            if (event.EventCategory == type.CategoryName) {
                                let recArr = '';

                                if (event.RecurrenceRule && event.RecurrenceRule !== "null") {
                                    // recArr = JSON.parse(event.RecurrenceRule)[0];
                                }
                                let startDate = new Date(event.UTCStartTime);
                                let endDate = new Date(event.UTCEndTime);
                                if (startDate.getDate() !== endDate.getDate() && (startDate.getHours() <= endDate.getHours() || event.IsAllDayEvent) && obj.Email == "Sute" && (event.RecurrenceRule == "NONE" || event.RecurrenceRule == "" || event.RecurrenceRule == null)) {

                                    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
                                        let startOfDay = new Date(date);
                                        let endOfDay = new Date(date);

                                        (event.IsAllDayEvent) ? endOfDay.setHours(23) : endOfDay.setHours(endDate.getHours());
                                        (event.IsAllDayEvent) ? endOfDay.setMinutes(59) : endOfDay.setMinutes(endDate.getMinutes());


                                        let dayEvent: calevent = {
                                            allDay: event.IsAllDayEvent,
                                            id: event.Id,
                                            title: event.Summary,
                                            start: startOfDay,
                                            end: endOfDay,
                                            setEnd: endOfDay,
                                            desc: event.Description,
                                            type: event.EventCategory,
                                            color: obj.Color,
                                            email: event.Email,
                                            meetingLink: event.MeetingLink,
                                            participants: event.participants,
                                            eventHostId: event.EventHostId,
                                            GroupEvent: event.GroupEvent,
                                            MediaAttachments: event.AttachmentsContent,
                                            groupGuid: event.GroupdGuid,
                                            isRemainder: event.IsRemainder,
                                            isVideo: event.IsVideo,
                                            isAudio: event.IsAudio,
                                            isChat: event.IsChat,
                                            RecurrenceRule: event.RecurrenceRule,
                                            setUntilStart: event.UTCRRStartTime,
                                            TimeZone: event.TimeZone,
                                            shortZone: event.ShortZone
                                        };

                                        events.push(dayEvent);
                                    }

                                } else if (!event.IsAllDayEvent && startDate.getDate() !== endDate.getDate() && startDate.getHours() >= endDate.getHours() && (event.RecurrenceRule == "NONE" || event.RecurrenceRule == "" || event.RecurrenceRule == null)) {
                                    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
                                        let startOfDay = new Date(date);
                                        let endOfDay = new Date(date);

                                        startOfDay.setHours(startDate.getHours());
                                        startOfDay.setMinutes(startDate.getMinutes());
                                        endOfDay.setDate(date.getDate() + 1);
                                        endOfDay.setHours(endDate.getHours());
                                        endOfDay.setMinutes(endDate.getMinutes());

                                        if (endDate.getDate() === date.getDate()) {
                                            break;
                                        }
                                        let dayEvent: calevent = {
                                            allDay: event.IsAllDayEvent,
                                            id: event.Id,
                                            title: event.Summary,
                                            start: startOfDay,
                                            end: endOfDay,
                                            setEnd: endOfDay,
                                            desc: event.Description,
                                            type: event.EventCategory,
                                            color: obj.Color,
                                            email: event.Email,
                                            meetingLink: event.MeetingLink,
                                            participants: event.participants,
                                            eventHostId: event.EventHostId,
                                            GroupEvent: event.GroupEvent,
                                            MediaAttachments: event.AttachmentsContent,
                                            groupGuid: event.GroupdGuid,
                                            isRemainder: event.IsRemainder,
                                            isVideo: event.IsVideo,
                                            isAudio: event.IsAudio,
                                            isChat: event.IsChat,
                                            RecurrenceRule: event.RecurrenceRule,
                                            TimeZone: event.TimeZone,
                                            shortZone: event.ShortZone
                                        };

                                        events.push(dayEvent);
                                    }
                                }
                                else {
                                    let dayEvent: calevent = {
                                        allDay: event.IsAllDayEvent, id: event.Id, title: event.Summary,
                                        start: (!event.IsAllDayEvent && obj.Email == "Sute") ? new Date(event.UTCStartTime) : new Date(event.UTCStartTime),
                                        end: (!event.IsAllDayEvent && obj.Email == "Sute") ? new Date(event.UTCEndTime) : new Date(event.UTCEndTime),
                                        setEnd: (!event.IsAllDayEvent && obj.Email == "Sute") ? new Date(event.UTCEndTime) : new Date(event.UTCEndTime),
                                        desc: event.Description, type: event.EventCategory, color: obj.Color,
                                        email: event.Email, meetingLink: event.MeetingLink,
                                        participants: event.participants, eventHostId: event.EventHostId, GroupEvent: event.GroupEvent,
                                        MediaAttachments: event.AttachmentsContent,
                                        setDates: ((!event.IsAllDayEvent) ? new Date(event.UTCStartTime) : event.StartsAt, (!event.IsAllDayEvent) ? new Date(event.UTCEndTime) : event.EndsAt),
                                        groupGuid: event.GroupdGuid, isRemainder: event.IsRemainder, isVideo: event.IsVideo,
                                        isAudio: event.IsAudio, isChat: event.IsChat, RecurrenceRule: event.RecurrenceRule,
                                        setUntilEnd: event.RecurrenceRule.legth > 4 ? event.UntilRREnddate : event.UTCRREndTime,
                                        setUntilStart: event.UTCRRStartTime,
                                        TimeZone: event.TimeZone,
                                        shortZone: event.ShortZone
                                        //rrule:'DTSTART:' + getRRuleTime(event.UTCStartTime) + '\n' + recArr[0],
                                        // endTime: new Date(event.UTCEndTime)
                                    }
                                    console.log("EVENT ", dayEvent)

                                    events.push(dayEvent);
                                }
                            }
                        });
                    }
                });
            }
        }) : undefined);
        setEves({ cale: events });
        if ((employee.events || []).length > 0)
            setEvents([...employee.events]);
        console.log('events...:', events)

    }, [employee.events, employee.calendarCategories]);

    const getRRuleTime = (dt: any) => {

        //20210922T113000
        let dtt = new Date(dt);
        let str = dtt.getFullYear().toString();
        if (dtt.getMonth() < 10)
            str = str + "0" + dtt.getMonth().toString();
        else
            str = str + dtt.getMonth().toString();
        if (dtt.getDate() < 10)
            str = str + "0" + dtt.getDate().toString();
        else
            str = str + dtt.getDate().toString();
        str = str + "T";
        if (dtt.getHours() < 10)
            str = str + "0" + dtt.getHours().toString();
        else
            str = str + dtt.getHours().toString();
        if (dtt.getMinutes() < 10)
            str = str + "0" + dtt.getMinutes().toString();
        else
            str = str + dtt.getMinutes().toString();
        str = str + "00";
        console.log(str);
        return str;

    }
    useEffect(() => {
        console.log("user.success")
        if (user.success === 'Event Updated') {
            globalStore.showToast({
                type: 'Message',
                title: "Event",
                message: "Event Updated Successfully."
            });

            document.getElementById('eventCloseBtn')?.click();
            dispatch(getEmployeeCalanderEvents(myUserId, OffSet) as any)
            dispatch(removeSuccessStatus() as any);
        }
        else if (user.success === 'Event Created') {
            globalStore.showToast({
                type: 'Message',
                title: "Event",
                message: "Event Created Successfully."
            });

            document.getElementById('eventCloseBtn')?.click();
            dispatch(getEmployeeCalanderEvents(myUserId, OffSet) as any)
            dispatch(removeSuccessStatus() as any);
        }
        else if (user.success === 'Event Deleted') {
            globalStore.showToast({
                type: 'Message',
                title: "Event",
                message: "Event Deleted Successfully"
            });

            dispatch(getEmployeeCalanderEvents(myUserId, OffSet) as any)
            dispatch(removeSuccessStatus() as any);
        }
    }, [user.success]);
    useEffect(() => {
        if (selectedStartDate.getDate() === adjEndDate.getDate()) {
            const [Starthour, StartMinute] = createEvent.StartTime.split(':').map(Number);
            const [Endhour, EndMinute] = createEvent.EndTime.split(':').map(Number);
            if (createEvent.StartMeridian == createEvent.EndMeridian && !createEvent.IsAlldaySelected && (Starthour > Endhour || (Starthour === Endhour && StartMinute > EndMinute))) {
                selectedEndDate = new Date(selectedStartDate.getTime() + (24 * 60 * 60 * 1000));
                setcreateEvent({ ...createEvent, EndDate: Moment(selectedEndDate).format('MM-DD-YYYY') });
            } else if (createEvent.StartMeridian == "PM" && createEvent.EndMeridian == "AM" && !createEvent.IsAlldaySelected) {
                selectedEndDate = new Date(selectedStartDate.getTime() + (24 * 60 * 60 * 1000));
                setcreateEvent({ ...createEvent, EndDate: Moment(selectedEndDate).format('MM-DD-YYYY') });
            } else {
                selectedEndDate = selectedEndDate = new Date(selectedStartDate.getTime());
                setcreateEvent({ ...createEvent, EndDate: Moment(selectedEndDate).format('MM-DD-YYYY') });
            }
        }
    }, [createEvent.StartDate, createEvent.StartMeridian, createEvent.EndMeridian, createEvent.EndTime, createEvent.StartTime])

    useEffect(() => {
        let startHours: any = parseInt(createEvent.StartTime.split(":")[0]);
        var startminutes = createEvent.StartTime.split(":")[1];
        if (createEvent.StartMeridian == 'PM') {
            if (startHours !== 12) {
                startHours += 12;
            }
        }
        let endHours: any = parseInt(createEvent.EndTime.split(":")[0]);
        var endminutes = createEvent.EndTime.split(":")[1];
        if (createEvent.EndMeridian == 'PM') {
            if (endHours !== 12) {
                endHours += 12;
            }
        }
        let startDate = selectedStartDate;
        startDate.setHours(startHours);
        startDate.setMinutes(Number(startminutes));
        startDate.setSeconds(0);
        const UTCStartTime = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes());
        let endDate = selectedEndDate;
        endDate.setHours(endHours);
        endDate.setMinutes(Number(endminutes));
        endDate.setSeconds(0);
        const UTCEndTime = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), (createEvent.IsAlldaySelected) ? 23 : endDate.getUTCHours(), (createEvent.IsAlldaySelected) ? 59 : endDate.getUTCMinutes());
        console.log("participant", participant)
        if (createEvent.participants?.length > 1) {
            GetEmployeeAvalibilty(createEvent.participants.toString(), UTCStartTime, UTCEndTime, (createEvent.GroupGuid == '') ? '00000000-0000-0000-0000-000000000000' : createEvent.GroupGuid, createEvent.IsAlldaySelected, myOrgId as string).then((resp: any) => {
                if (resp.status === "success") {
                    console.log("resp.result", resp.result);
                    setEmpAvalibilty(resp.result);
                }
            })
        }
    }, [createEvent, participant]);
    const changeStartDate = (date: any) => {
        if (selectedStartDate.getTime() === adjEndDate.getTime()) {
            setAdjEndtime(new Date(date));
        }
        selectedStartDate = date;
        if (new Date(Moment(selectedStartDate).format('MM-DD-YYYY')) > new Date(Moment(selectedEndDate).format('MM-DD-YYYY')))
            selectedEndDate = date;

        if (new Date(Moment(selectedStartDate).format('MM-DD-YYYY')) > new Date(Moment(selectedRREndDate).format('MM-DD-YYYY')))
            selectedRREndDate = date;

        setcreateEvent({ ...createEvent, StartDate: Moment(date).format('MM-DD-YYYY'), EndDate: Moment(selectedEndDate).format('MM-DD-YYYY'), UntilRREnddate: Moment(selectedRREndDate).format('MM-DD-YYYY') });
        getExistingGroupEvents(selectedStartDate);
    }
    const changeEndDate = (date: any) => {
        selectedEndDate = date;
        setAdjEndtime(new Date(date));
        setcreateEvent({ ...createEvent, EndDate: Moment(date).format('MM-DD-YYYY') });
    }
    const changeStartTime = (time: any) => {
        const timeValue = time.target.value; // "07:00 PM"
        const [hourMinute, meridian] = timeValue.split(' '); // Split by space to get ["07:00", "PM"]

        // Update state accordingly
        setcreateEvent({
            ...createEvent,
            StartTime: hourMinute, // "07:00"
            StartMeridian: meridian // "PM"
        });

        // Check if start time is greater than end time
        if (compareTimes(hourMinute, meridian, createEvent.EndTime, createEvent.EndMeridian) === 1) {
            setUpdateEventStatus('Start time cannot be equal or greater than end time.');
        } else {
            setUpdateEventStatus(''); // Clear any previous error message
        }
    };

    // Function to handle change in end time input
    const changeEndTime = (time: any) => {
        const timeValue = time.target.value; // "07:00 PM"
        const [hourMinute, meridian] = timeValue.split(' '); // Split by space to get ["07:00", "PM"]

        // Update state accordingly
        setcreateEvent({
            ...createEvent,
            EndTime: hourMinute, // "07:00"
            EndMeridian: meridian // "PM"
        });

        // Check if end time is less than start time
        if (compareTimes(createEvent.StartTime, createEvent.StartMeridian, hourMinute, meridian) === 1) {
            setUpdateEventStatus('End time cannot be equal or less than start time.');
        } else {
            setUpdateEventStatus(''); // Clear any previous error message
        }
    };

    // Function to compare two times with AM/PM consideration
    const compareTimes = (time1: string, meridian1: string, time2: string, meridian2: string): number => {
        // Convert time to 24-hour format for comparison
        let [hour1, minute1] = time1.split(':').map(Number);
        let [hour2, minute2] = time2.split(':').map(Number);

        // Adjust hours based on meridian (AM/PM)
        if (meridian1 === 'PM' && hour1 !== 12) hour1 += 12;
        if (meridian2 === 'PM' && hour2 !== 12) hour2 += 12;
        if (meridian1 === 'AM' && hour1 === 12) hour1 = 0;
        if (meridian2 === 'AM' && hour2 === 12) hour2 = 0;

        // Compare times
        if (hour1 > hour2) {
            return 1; // time1 is greater
        } else if (hour1 < hour2) {
            return -1; // time2 is greater
        } else {
            // Hours are the same, compare minutes
            if (minute1 > minute2) {
                return 1; // time1 is greater
            } else if (minute1 < minute2) {
                return -1; // time2 is greater
            } else {
                // Minutes are also the same, compare meridians
                if (meridian1 === 'PM' && meridian2 === 'AM') {
                    return 1; // time1 is greater (PM > AM)

                } else if (meridian1 === meridian2 && hour1 === hour2) {
                    return 1 // time2 is greater
                } else {
                    return 0; // times are equal
                }
            }
        }
    };

    console.log(createEvent.EndTime, createEvent.EndMeridian)

    const onChangeType = (e: any, type: string) => {
        e.preventDefault();
        setcreateEvent({ ...createEvent, Type: type })
    }

    const eventNameChange = (name: any) => {
        setUpdateEventStatus('');
        setcreateEvent({ ...createEvent, EventName: name.target.value, })
    }

    const eventDescriptionChange = (name: any) => {
        setcreateEvent({ ...createEvent, EventDescription: name.target.value })
    }

    // const changeStartMeridian = (meridian: any) => {
    //    setcreateEvent({ ...createEvent, StartMeridian: meridian.target.value })
    // }

    const changeEndMeridian = (meridian: any) => {
        const selectedMeridian = meridian.target.value;

        if (selectedMeridian === "AM" && createEvent.EndTime === "00:30") {
            if (!adjustedEndDate) {
                setAdjustedEndDate(true);
                const newEndDate = new Date(selectedEndDate);
                newEndDate.setDate(newEndDate.getDate() + 1);
                changeEndDate(newEndDate);
            }
        } else if (selectedMeridian === "PM" && createEvent.EndTime === "00:30") {
            if (adjustedEndDate) {
                setAdjustedEndDate(false);

                const newEndDate = new Date(selectedEndDate);
                newEndDate.setDate(newEndDate.getDate() - 1);
                changeEndDate(newEndDate);
            }
        } else {
            setAdjustedEndDate(false);
        }
        setcreateEvent({ ...createEvent, EndMeridian: selectedMeridian });
    };

    const selectEmail = (email: any) => {
        email.preventDefault();
        setcreateEvent({ ...createEvent, Email: email.target.value })
    }

    const handleShowEdit = () => {
        let a = document.getElementById('eventCreateBtn');
        a?.click();
    }

    const deleteEvent = () => {

        if (createEvent.GroupEvent) {
            dispatch(DeleteGroupEventByEventId(createEvent.Id) as any);
            handleShowDelete();
            // handleClose();
            // document.getElementById('eventCloseBtn')?.click();
        }
        else {
            dispatch(DeleteCalenderEventByEventId(createEvent.Id, myEmpId ? myEmpId : '', myEmpEmail ? myEmpEmail : '') as any);
            handleShowDelete();
            // handleClose();
            // document.getElementById('eventCloseBtn')?.click();
        }

    }

    const deleteAccount = () => {
        dispatch(DeleteCalenderAccountByAccountId(deleteAccountId) as any);
        handleShowAccountDelete(0);
    }

    const Clientid = '580059194632-vk2m6t85u966ijnnh0p975k874m9vqd5.apps.googleusercontent.com';
    const scope = 'profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly';
    useEffect(() => {

        /*const initClient = () => {
            gapi.client.init({
            clientId: Clientid,
            scope: 'profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly'
          });
       };
        gapi.load('client:auth2',initClient);*/
        let auth2 = loadAuth2(gapi, Clientid, scope);
    }, []);

    const responseGoogle = (response: any) => {
        console.log(" google response: " + JSON.stringify(response));
        if (response.code) {//setcreateEvent({ ...createEvent, AccessToken: response.accessToken, Email: response.profileObj.email, Name: response.profileObj.name });
            setcreateEvent({ ...createEvent, Code: response.code });
            dispatch(SyncGoogleCalanderEvents(response.code, myUserId ? myUserId : '') as any);
        }
    }

    const onRuleChange = (event: any) => {
        const rVal = event.target.value;
        console.log(event.target.value)
        let rRule = 'NONE';
        if (rVal == '1')
            rRule = 'RRULE:FREQ=DAILY';
        else if (rVal == '2')
            rRule = 'RRULE:FRQ=WEEKLY;BY=' + moment(createEvent.StartDate).format('dd');
        else if (rVal == '3')
            rRule = 'RRULE:FRQ=MONTHLY;BY=' + Math.ceil(moment(createEvent.StartDate).date() / 7) + '::' + moment(createEvent.StartDate).format('dd')
        else if (rVal == '4')
            rRule = 'RRULE:FRQ=YEARLY;BY=' + moment(createEvent.StartDate).format('MMM::DD');
        else if (rVal == '5')
            rRule = 'RRULE:FRQ=WEEKLY;BY=MO,TU,WE,TH,FR';
        // if (rRule != rruleEvent && !createEvent.IsSubmitted 
        //     // && rRule != 'NONE'
        // ) {
        //     document.getElementById('rruleChangePopup')?.click();
        //     setPendingRRule(rRule);
        //     return;
        // }
        if (rVal == "0" || rRule == 'NONE') {
            setClickEditButton(false)
        } else {
            setClickEditButton(true)
        }
        console.log("#RRULE", createEvent.RecurrenceRule)
        setcreateEvent({ ...createEvent, RecurrenceRule: rRule });
        console.log('RRULE', rRule);
    }

    const handleConfirmation = (confirmed: boolean) => {
        if (confirmed) {
            //  setcreateEvent({ ...createEvent, RecurrenceRule: pendingRRule });
            setClickEditButton(false)
        }
    }

    const getSelectedValue = useMemo(() => {
        const memoizedFunction = (recurrenceRule: string) => {
            if (recurrenceRule.startsWith('RRULE:FREQ=DAILY')) {
                return '1';
            } else if (recurrenceRule.startsWith('RRULE:FRQ=WEEKLY;BY=MO,TU,WE,TH,FR')) {
                return '5';
            }
            else if (recurrenceRule.startsWith('RRULE:FRQ=WEEKLY;BY=')) {
                return '2';
            } else if (recurrenceRule.startsWith('RRULE:FRQ=MONTHLY;BY=')) {
                return '3';
            } else if (recurrenceRule.startsWith('RRULE:FRQ=YEARLY;BY=')) {
                return '4';
            } else {
                return '0'; // Default value
            }
        };

        return memoizedFunction;
    }, []);


    const getMonthRule = () => {
        const n = Math.ceil(moment(createEvent.StartDate).date() / 7)
        let str = "";
        if (n == 1)
            str = '1st'
        else if (n == 2)
            str = '2nd'
        else if (n == 3)
            str = '3rd'
        else if (n == 4)
            str = '4th'
        else if (n == 5)
            str = '5th'
        return "Monthly on the " + str + " " + moment(createEvent.StartDate).format('dddd')
    }


    const GenerateMeetingLink = (event: any) => {
        //event.preventdefault();

        if (event.target.value === "Select") {
            setMeetingId("");
            setcreateEvent({
                ...createEvent, GroupEvent: false, GroupGuid: ''
                // ,isVideo:false,isAudio:false,isChat:false
            });
            dispatch(getGroupMems("00000000-0000-0000-0000-000000000000") as any)
        }
        else {
            setMeetingId(event.target.value);
            dispatch(getGroupMems(event.target.value) as any)
            setcreateEvent({
                ...createEvent, GroupEvent: true, GroupGuid: event.target.value
                // ,isVideo:true,isAudio:true,isChat:true
            });
        }
        // if (event.target.value === "Select") {
        //     SetSelectedGroup(false)
        // }
        // else{
        //     SetSelectedGroup(true)      
        // }
    }

    const selectedTimeZones = (event: any) => {
        const timezones = event.target.value
        const [timezone, shortzone] = timezones.split(',');
        setcreateEvent({ ...createEvent, TimeZone: timezone, shortZone: shortzone })
    }

    function deepEqual(obj1: any, obj2: any): boolean {
        if (obj1 === obj2) {
            return true;
        }

        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
            return false;
        }

        if (Array.isArray(obj1) && Array.isArray(obj2) && obj1.every(item => typeof item === 'string') && obj2.every(item => typeof item === 'string')) {
            // Create sorted copies of both arrays and compare them
            return obj1.slice().sort().join(',') === obj2.slice().sort().join(',');
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    const setdateString = (num: number): string => {
        return (((num) + "").length == 1 ? ("0" + (num)) : num + "");
    }
    const datetoString = (strdate: Date): string => {
        return "" + strdate.getFullYear() + "-" + setdateString(strdate.getMonth() + 1) + "-" + setdateString(strdate.getDate()) + " " + setdateString(strdate.getHours()) + ":" + setdateString(strdate.getMinutes()) + ":00"
    }
    const datetoStringUnix = (strdate: Date, zone: string): number => {
        return moment.tz(datetoString(strdate), zone).utc().unix();
    }

    const saveEvent = (e: any) => {
        e.preventDefault();
        if (createEvent.EventName == '' || createEvent.Email == 'Select') {
            setUpdateEventStatus('Please fill all the mandatory fields');
            return;
        }
        if (compareTimes(createEvent.StartTime, createEvent.StartMeridian, createEvent.EndTime, createEvent.EndMeridian) === 1) {
            setUpdateEventStatus('Please check Start time and End time');
            return
        }
        if (!handleTimeDateValidation()) {
            setUpdateEventStatus('Start date cannot be greater than end date');
            return;
        }
        const newEventObj: any = createEvent;
        let startHours: any = parseInt(createEvent.StartTime.split(":")[0]);
        var startminutes = createEvent.StartTime.split(":")[1];

        if (createEvent.StartMeridian == 'PM') {
            //startHours = startHours + 12;
            if (startHours !== 12) {
                startHours += 12;
            }
        }
        let endHours: any = parseInt(createEvent.EndTime.split(":")[0]);
        var endminutes = createEvent.EndTime.split(":")[1];
        if (createEvent.EndMeridian == 'PM') {
            if (endHours !== 12) {
                endHours += 12;
            }
        }
        let startDate = selectedStartDate;
        startDate.setHours(startHours);
        startDate.setMinutes(Number(startminutes));
        startDate.setSeconds(0);
        if (newEventObj.IsAlldaySelected == true) {
            const dt = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 24, 0);
            newEventObj.UTCStartTime = datetoStringUnix(dt, newEventObj.TimeZone);
        }
        else
            newEventObj.UTCStartTime = datetoStringUnix(startDate, newEventObj.TimeZone);
        let endDate = createEvent.IsAlldaySelected ? selectedEndDate : selectedStartDate;
        //newEventObj.UTCRRStartTime = newEventObj.UTCStartTime;

        endDate.setHours(endHours);
        endDate.setMinutes(Number(endminutes));
        endDate.setSeconds(0);
        if (newEventObj.IsAlldaySelected == true) {
            const dte = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59);
            newEventObj.UTCEndTime = datetoStringUnix(dte, newEventObj.TimeZone);
        }
        else
            newEventObj.UTCEndTime = datetoStringUnix(endDate, newEventObj.TimeZone);

        let UntilendDate;
        if (newEventObj.IsAlldaySelected) {
            UntilendDate = selectedEndDate;
        } else {
            UntilendDate = createEvent.RecurrenceRule.length > 0 ? selectedRREndDate : selectedEndDate;
        }

        UntilendDate.setHours(endHours);
        UntilendDate.setMinutes(Number(endminutes));
        UntilendDate.setSeconds(0);
        newEventObj.UTCRREndTime = datetoStringUnix(UntilendDate, newEventObj.TimeZone);
        newEventObj.offset = moment.tz(newEventObj.TimeZone).utcOffset() * -1;
        newEventObj.AttachmentsContent = JSON.stringify(createEvent.Content);
        createEvent.Id = !createEvent.Id ? newEvendGUID : createEvent.Id;
        if (meetingId != "" && createEvent.Id == '') {
            const SuteAccount = employee.events.filter((account: any) => (account.Email == 'Sute'));
            if (SuteAccount && SuteAccount.length > 0) {
                const Events = SuteAccount[0].Events.filter((event: any) => (dateRangeOverlaps(event.UTCStartTime, event.UTCEndTime, newEventObj.UTCStartTime, newEventObj.UTCEndTime)));
                if (Events && Events.length > 0) {
                    setUpdateEventStatus('Please check time slot');
                    return;
                }
            }
        }
       
            const formData = new FormData();

            for (const key in newEventObj) {
                if (Array.isArray(newEventObj[key])) {
                    continue;
                } else {
                    if (key === 'StartDate' || key === 'EndDate' || key === 'UntilRREnddate') {

                        const date = new Date(newEventObj[key]);
                        if (!isNaN(date.getTime())) {

                            const isoDate = date.toISOString().split('T')[0];
                            formData.append(key, isoDate);
                        }
                    }
                    else {
                        formData.append(key, newEventObj[key]);
                    }

                }
            }
            if (newEventObj.participants.length > 0) {
                for (const mail of newEventObj.participants) {
                    formData.append('participants', mail);
                }
            }
            else {
                formData.append('participants', '');
            }
            for (const file of Attachfile) {
                formData.append('AttachedContent', file, file.name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
            }
            if (createEvent.IsSubmitted)
                formData.append('ExistingContentId', "0");
            else {
                if (ExistingContent.length > 0) {
                    const attachmentIdsString = ExistingContent.map((obj: any) => obj.AttachementId).join(',');
                    formData.append('ExistingContentId', attachmentIdsString);
                }
                if (createEvent.RecurrenceRule.length <= 4) {
                    setcreateEvent({ ...createEvent, RecurrenceRule: '' })
                }
            }
            dispatch(SaveGoogleCalenderEvents(formData) as any);
            setattachFile([]);
            setExistingContent([])
            setClickEditButton(false)
        

        // if (!createEvent.IsSubmitted && createEvent.Id != '' && createEvent.EmpGuid != '') {
        //     const formData = new FormData();

        //     for (const [key, value] of Object.entries(createEvent)) {
        //         if (Array.isArray(value)) {
        //             continue;
        //         } else {
        //             if (key === 'StartDate' || key === 'EndDate' || key === 'UntilRREnddate') {

        //                 const date = new Date(value);
        //                 if (!isNaN(date.getTime())) {

        //                     const isoDate = date.toISOString().split('T')[0];
        //                     formData.append(key, isoDate);
        //                 }
        //             } else {
        //                 formData.append(key, newEventObj[key]);
        //             }
        //             // formData.append(key, value);
        //         }
        //     }
        //     if (createEvent.participants.length > 0) {
        //         for (const mail of createEvent.participants) {
        //             formData.append('participants', mail);
        //         }
        //     }
        //     else {
        //         formData.append('participants', '');
        //     }
        //     for (const file of Attachfile) {
        //         formData.append('AttachedContent', file, file.name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
        //     }
        //     if (ExistingContent.length > 0) {
        //         const attachmentIdsString = ExistingContent.map((obj: any) => obj.AttachementId).join(',');
        //         formData.append('ExistingContentId', attachmentIdsString);
        //     }
        //     if (createEvent.RecurrenceRule.length <= 4) {
        //         setcreateEvent({ ...createEvent, RecurrenceRule: '' })
        //     }
        //     dispatch(UpdateGoogleCalenderEvents(formData) as any);
        //     setattachFile([]);
        //     setExistingContent([])
        //     setClickEditButton(false)
        // }

        setcreateEvent({ ...createEvent, StartTime: '11:00', EndTime: '11:30', StartMeridian: 'AM', EndMeridian: 'AM', EventName: '', EventDescription: '', participants: [], Content: [], EmpName: myEmpName ? myEmpName : '', EmpEmail: myEmpEmail ? myEmpEmail : '', orgGuid: myOrgId ? myOrgId : '', EmpId: new Number(myEmpId) ? new Number(myEmpId) : 0, Type: 'Event', EndDate: Moment(selectedEndDate).format('MM-DD-YYYY'), StartDate: Moment(selectedStartDate).format('MM-DD-YYYY'), Email: 'Sute', EmpGuid: myUserId ? myUserId : '', IsSubmitted: true, GroupGuid: '', IsAlldaySelected: false, isRemainder: false, RruleEvent: '', UntilRREnddate: Moment(selectedRREndDate).format('MM-DD-YYYY') })
        setDisableMeetingLink(false)
        setDescriptionPopUp(false)
        setEndDatePopUp(false)
        setClickEditButton(false)
        //document.getElementById('rrulePopupCloseBtn')?.click()
    }

    function handleTimeDateValidation() {
        const { StartDate, StartTime, StartMeridian, EndDate, EndTime, EndMeridian, UntilRREnddate, IsAlldaySelected } = createEvent;

        function convertTo24Hour(time: string, meridian: string) {
            let [hours, minutes] = time.split(":").map(Number);
            if (meridian === "PM" && hours !== 12) {
                hours += 12;
            } else if (meridian === "AM" && hours === 12) {
                hours = 0;
            }
            return { hours, minutes };
        }

        function parseDateTime(date: string, time: string, meridian: string) {
            const { hours, minutes } = convertTo24Hour(time, meridian);
            const [month, day, year] = date.split("-").map(Number);
            return new Date(year, month - 1, day, hours, minutes);
        }

        const startDateTime = new Date(Moment(StartDate).format('MM-DD-YYYY'))
        let endDateTime = new Date(Moment(UntilRREnddate).format('MM-DD-YYYY'))
        if (IsAlldaySelected)
            endDateTime = new Date(Moment(EndDate).format('MM-DD-YYYY'))
        if (createEvent.RecurrenceRule.length < 1)
            return true
        if (endDateTime >= startDateTime) {
            return true;
        } else {
            return false;
        }
    }

    const onParticipantEnter = (e: any) => {
        const prefcontacts = user.prefcontacts?.filter((prefcontact: any) =>
            (prefcontact.FirstName + ' ' + prefcontact.LastName).toLowerCase().includes(e.target.value))
        setParticipant(prefcontacts as any);

    }
    const onSelectParticipant = (email: string, empGuid: string) => {
        setParticipant(email as any);
    }
    const onAddParticipants = () => {
        let val = ''
        try {
            val = typeaheadRef.current?.inputNode?.value;
            const IsAlreadyParticipant = createEvent?.participants.includes(val)
            if (IsAlreadyParticipant) {
                typeaheadRef.current.clear();
            }
            else {
                setParticipant(val as any)
            }
        } catch (error) {

        }


    }
    useEffect(() => {
        if (participant && participant.length > 0) {
            if (validateEmail(participant)) {
                if (createEvent.participants.length <= 0) {
                    setcreateEvent({ ...createEvent, participants: [...createEvent.participants, myEmpEmail ? myEmpEmail : '', participant] });
                }
                else {
                    setcreateEvent({ ...createEvent, participants: [...createEvent.participants, participant] });
                }
                setParticipant("");
                typeaheadRef.current.clear();
            }
        }
    }, [participant])


    const removeParticipant = (index: number) => {
        const list = createEvent.participants;
        list.splice(index, 1);
        setcreateEvent({ ...createEvent, participants: list });
        if (createEvent.participants.length <= 1 && createEvent.participants[0] == myEmpEmail) {
            list.splice(0, 1);
        }
        if (user.contacts && user.contacts.length > 0) {
            const options = user.contacts.filter((con: any) => {
                if (list.length > 0) {
                    const exists = list.filter((ext: any) => ext === con.OfficeEmail);
                    if (exists.length > 0)
                        return false;
                }
                if (myUserId === con.EmpGuid)
                    return false;
                return true;
            });
            setOptions(options);
        }
    }

    const validateEmail = (email: string) => {
        var validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return validEmail.test(email);
    }

    function onEmailCheckUncheck(e: any, obj: any) {
        dispatch(CheckUncheckEventsEmail(obj.Email, e.target.checked) as any);
    }

    function onCalendarCategoryChecked(e: any, obj: any) {
        dispatch(CheckUncheckEventCategories(obj.CategoryId, e.target.checked) as any)
    }

    function onAllDayChecked(event: any) {
        const isChecked = event.target.checked;
        setcreateEvent((prevState) => ({
            ...prevState,
            RecurrenceRule: isChecked ? '' : prevState.RecurrenceRule,
            IsAlldaySelected: isChecked || false
        }));
        console.log("Getting date and time", createEvent.IsAlldaySelected)
        console.log(clickEditButton)
        console.log(createEvent)
    }

    function formatAMPM(date: any) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        if (hours !== 12) {
            hours = hours % 12;
            // hours = hours ? hours : 12; // the hour '0' should be '12'
            if (hours.toLocaleString().length == 1) {
                hours = "0" + hours;
            }
        }
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    function formatDate(date: any) {
        var fullDate = date;
        var twoDigitMonth = fullDate.getMonth() + 1;
        if (twoDigitMonth.toLocaleString().length == 1)
            twoDigitMonth = "0" + twoDigitMonth;
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.toLocaleString().length == 1)
            twoDigitDate = "0" + twoDigitDate;
        var currentDate = fullDate.getFullYear() + '-' + twoDigitMonth + '-' + twoDigitDate;
        return currentDate;
    }

    const addContentClick = () => {

        setShowMediaSoundTracks(!showMediaSoundTracks);
        setShowAddPopup(true);
    }

    const closeAddContentClick = () => {

        setShowMediaSoundTracks(!showMediaSoundTracks);
        setShowAddPopup(false);
        document.body.style.overflow = 'hidden';
    }

    const AddMediaAttachments = (e: any) => {

        setShowAddPopup(false);
        console.log(e);
        setcreateEvent({ ...createEvent, Content: e })

    }

    const removeMediaAttachments = (index: number) => {
        const list = createEvent.Content;
        list.splice(index, 1);
        setcreateEvent({ ...createEvent, Content: list });
    }
    const closeCalendarWindows = () => {
        setShowCalendar(!showCalendar);
    }

    const CopyURL = (url: any) => {
        navigator.clipboard.writeText(url);
        dispatch(ShowToast({ type: 'Message', title: 'Copy URL', message: 'URL Copied.' }) as any);
    }

    function allDayFields(showAllDayFields: boolean) {
        if (showAllDayFields) {
            return (<div className="row  col-12 pl-0 m-0">
                <div className="col-sm-6 pr-1">
                    <div className="form-group mb-1 calender-date-picker">
                        <label className="col-form-label">{t('calendar.start_date')}:</label>
                        <DatePicker className="SuteInputPrimary h40px"
                            selected={selectedStartDate}
                            onChange={date => changeStartDate(date)}
                            name="startDate"
                            dateFormat="dd-MM-yyyy"
                            minDate={new Date()}
                            value={Moment(selectedStartDate).format(formattedDate)}
                            showIcon
                            toggleCalendarOnIconClick
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group mb-1 calender-date-picker">
                        <label className="col-form-label">{t('calendar.end_date')}:</label>
                        <DatePicker className="SuteInputPrimary h40px"
                            selected={selectedEndDate}
                            onChange={date => changeEndDate(date)}
                            name="startDate"
                            dateFormat="dd-MM-yyyy"
                            minDate={selectedStartDate}
                            value={Moment(selectedEndDate).format(formattedDate)}
                            showIcon
                            toggleCalendarOnIconClick
                        />
                    </div>
                </div>
            </div>
            )
        } else {
            return (<>
                <div className="row col-12 pl-0 m-0">
                    <div className="col-sm-6 pr-1">
                        <div className="form-group mb-1  calender-date-picker">
                            <label className="col-form-label">Start date:</label>
                            <DatePicker className="SuteInputPrimary h40px"
                                selected={selectedStartDate}
                                onChange={changeStartDate}
                                name="startDate"
                                dateFormat="dd-MM-yyyy"
                                minDate={new Date()}
                                disabled={createEvent.Id != '' && createEvent.RecurrenceRule.length > 4}
                                value={Moment(selectedStartDate).format(formattedDate)}
                                showIcon
                                toggleCalendarOnIconClick
                            />
                        </div>
                    </div>
                    <div className="col-sm-3 pl-0">
                        <div className="form-group mb-1">
                            <label className="col-form-label">{t('calendar.start_time')}:</label>
                            <div className="row">
                                <div className="col-sm-12 pr-1">
                                    <select className="SutetimePrimary h40px radiusright0" value={`${createEvent.StartTime} ${createEvent.StartMeridian}`} onChange={changeStartTime} >
                                        <option value="00:00 AM">12:00  <span>AM</span></option>
                                        <option value="00:30 AM">12:30  <span>AM</span></option>
                                        <option value="01:00 AM">01:00  <span>AM</span></option>
                                        <option value="01:30 AM">01:30  <span>AM</span></option>
                                        <option value="02:00 AM">02:00  <span>AM</span></option>
                                        <option value="02:30 AM">02:30  <span>AM</span></option>
                                        <option value="03:00 AM">03:00  <span>AM</span></option>
                                        <option value="03:30 AM">03:30  <span>AM</span></option>
                                        <option value="04:00 AM">04:00  <span>AM</span></option>
                                        <option value="04:30 AM">04:30  <span>AM</span></option>
                                        <option value="05:00 AM">05:00  <span>AM</span></option>
                                        <option value="05:30 AM">05:30  <span>AM</span></option>
                                        <option value="06:00 AM">06:00  <span>AM</span></option>
                                        <option value="06:30 AM">06:30  <span>AM</span></option>
                                        <option value="07:00 AM">07:00  <span>AM</span></option>
                                        <option value="07:30 AM">07:30  <span>AM</span></option>
                                        <option value="08:00 AM">08:00  <span>AM</span></option>
                                        <option value="08:30 AM">08:30  <span>AM</span></option>
                                        <option value="09:00 AM">09:00  <span>AM</span></option>
                                        <option value="09:30 AM">09:30  <span>AM</span></option>
                                        <option value="10:00 AM">10:00  <span>AM</span></option>
                                        <option value="10:30 AM">10:30  <span>AM</span></option>
                                        <option value="11:00 AM">11:00  <span>AM</span></option>
                                        <option value="11:30 AM">11:30  <span>AM</span></option>
                                        <option value="12:00 PM">12:00  <span>PM</span></option>
                                        <option value="12:30 PM">12:30  <span>PM</span></option>
                                        <option value="01:00 PM">01:00  <span>PM</span></option>
                                        <option value="01:30 PM">01:30  <span>PM</span></option>
                                        <option value="02:00 PM">02:00  <span>PM</span></option>
                                        <option value="02:30 PM">02:30  <span>PM</span></option>
                                        <option value="03:00 PM">03:00  <span>PM</span></option>
                                        <option value="03:30 PM">03:30  <span>PM</span></option>
                                        <option value="04:00 PM">04:00  <span>PM</span></option>
                                        <option value="04:30 PM">04:30  <span>PM</span></option>
                                        <option value="05:00 PM">05:00  <span>PM</span></option>
                                        <option value="05:30 PM">05:30  <span>PM</span></option>
                                        <option value="06:00 PM">06:00  <span>PM</span></option>
                                        <option value="06:30 PM">06:30  <span>PM</span></option>
                                        <option value="07:00 PM">07:00  <span>PM</span></option>
                                        <option value="07:30 PM">07:30  <span>PM</span></option>
                                        <option value="08:00 PM">08:00  <span>PM</span></option>
                                        <option value="08:30 PM">08:30  <span>PM</span></option>
                                        <option value="09:00 PM">09:00  <span>PM</span></option>
                                        <option value="09:30 PM">09:30  <span>PM</span></option>
                                        <option value="10:00 PM">10:00  <span>PM</span></option>
                                        <option value="10:30 PM">10:30  <span>PM</span></option>
                                        <option value="11:00 PM">11:00  <span>PM</span></option>
                                        <option value="11:30 PM">11:30  <span>PM</span></option>
                                    </select>
                                </div>
                                {/* <div className="col-sm-6 pl-1">
                                <select className="SutetimePrimary h40px radiusleft0" value={createEvent.StartMeridian} onChange={changeStartMeridian}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>  */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 pl-0">
                        <div className="form-group mb-1">
                            <label className="col-form-label">{t('calendar.end_time')}:</label>
                            <div className="row">
                                <div className="col-sm-12 pr-1">
                                    <select className="SutetimePrimary h40px radiusright0" value={`${createEvent.EndTime} ${createEvent.EndMeridian}`} onChange={changeEndTime}>
                                        <option value="00:00 AM">12:00  <span>AM</span></option>
                                        <option value="00:30 AM">12:30  <span>AM</span></option>
                                        <option value="01:00 AM">01:00  <span>AM</span></option>
                                        <option value="01:30 AM">01:30  <span>AM</span></option>
                                        <option value="02:00 AM">02:00  <span>AM</span></option>
                                        <option value="02:30 AM">02:30  <span>AM</span></option>
                                        <option value="03:00 AM">03:00  <span>AM</span></option>
                                        <option value="03:30 AM">03:30  <span>AM</span></option>
                                        <option value="04:00 AM">04:00  <span>AM</span></option>
                                        <option value="04:30 AM">04:30  <span>AM</span></option>
                                        <option value="05:00 AM">05:00  <span>AM</span></option>
                                        <option value="05:30 AM">05:30  <span>AM</span></option>
                                        <option value="06:00 AM">06:00  <span>AM</span></option>
                                        <option value="06:30 AM">06:30  <span>AM</span></option>
                                        <option value="07:00 AM">07:00  <span>AM</span></option>
                                        <option value="07:30 AM">07:30  <span>AM</span></option>
                                        <option value="08:00 AM">08:00  <span>AM</span></option>
                                        <option value="08:30 AM">08:30  <span>AM</span></option>
                                        <option value="09:00 AM">09:00  <span>AM</span></option>
                                        <option value="09:30 AM">09:30  <span>AM</span></option>
                                        <option value="10:00 AM">10:00  <span>AM</span></option>
                                        <option value="10:30 AM">10:30  <span>AM</span></option>
                                        <option value="11:00 AM">11:00  <span>AM</span></option>
                                        <option value="11:30 AM">11:30  <span>AM</span></option>
                                        <option value="12:00 PM">12:00  <span>PM</span></option>
                                        <option value="12:30 PM">12:30  <span>PM</span></option>
                                        <option value="01:00 PM">01:00  <span>PM</span></option>
                                        <option value="01:30 PM">01:30  <span>PM</span></option>
                                        <option value="02:00 PM">02:00  <span>PM</span></option>
                                        <option value="02:30 PM">02:30  <span>PM</span></option>
                                        <option value="03:00 PM">03:00  <span>PM</span></option>
                                        <option value="03:30 PM">03:30  <span>PM</span></option>
                                        <option value="04:00 PM">04:00  <span>PM</span></option>
                                        <option value="04:30 PM">04:30  <span>PM</span></option>
                                        <option value="05:00 PM">05:00  <span>PM</span></option>
                                        <option value="05:30 PM">05:30  <span>PM</span></option>
                                        <option value="06:00 PM">06:00  <span>PM</span></option>
                                        <option value="06:30 PM">06:30  <span>PM</span></option>
                                        <option value="07:00 PM">07:00  <span>PM</span></option>
                                        <option value="07:30 PM">07:30  <span>PM</span></option>
                                        <option value="08:00 PM">08:00  <span>PM</span></option>
                                        <option value="08:30 PM">08:30  <span>PM</span></option>
                                        <option value="09:00 PM">09:00  <span>PM</span></option>
                                        <option value="09:30 PM">09:30  <span>PM</span></option>
                                        <option value="10:00 PM">10:00  <span>PM</span></option>
                                        <option value="10:30 PM">10:30  <span>PM</span></option>
                                        <option value="11:00 PM">11:00  <span>PM</span></option>
                                        <option value="11:30 PM">11:30  <span>PM</span></option>
                                    </select>
                                </div>
                                {/* <div className="col-sm-6 pl-1">
                                <select className="SutetimePrimary h40px radiusleft0" value={createEvent.EndMeridian} onChange={changeEndMeridian}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>  */}
                            </div>
                        </div>
                    </div>
                    {/* {endDatePopup &&(<div className="col-sm-3 pl-0 ">
                        <div className="form-group mb-1">
                            <label className="col-form-label">{t('calendar.end_date')}:</label>
                            <DatePicker className="SuteInputPrimary h40px"
                                selected={selectedEndDate}
                                onChange={date => changeEndDate(date)}
                                name="startDate"
                                dateFormat="dd-MM-yyyy"
                                minDate={selectedStartDate}
                            />
                        </div>
                    </div>)} */}
                </div>
                <div className="row col-12 p-0 m-0">
                </div>
            </>
            )

        }
    }

    const getExistingGroupEvents = (selectedStartDate: any) => {
        let calendarApi = daycalendarRef.current?.getApi();
        calendarApi?.gotoDate(selectedStartDate);
    }

    function dateRangeOverlaps(a_start: any, a_end: any, b_start: any, b_end: any) {
        if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
        if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
        if (b_start < a_start && a_end < b_end) return true; // a in b
        return false;
    }


    // checking end time is greater than  start time 

    function isEndTimeAfterStartTime() {
        if (createEvent.IsAlldaySelected) {
            if (new Date(createEvent.EndDate) >= new Date(createEvent.StartDate)) {
                return false;
            }
            else {
                return true;
            }
        }
        if (new Date(createEvent.EndDate) === new Date(createEvent.StartDate)) {
            const [startHour, startMinute] = createEvent.StartTime.split(":").map(Number);
            const [endHour, endMinute] = createEvent.EndTime.split(":").map(Number);

            const startHour24 = startHour % 12 + ((createEvent.StartMeridian == "PM") ? 12 : 0);
            const endHour24 = endHour % 12 + ((createEvent.EndMeridian == "PM") ? 12 : 0);

            if (endHour24 > startHour24 || (endHour24 === startHour24 && endMinute >= startMinute)) {
                return false;
            } else {
                return true;
            }
        }

    }

    const handleMyCalenderClose = () => {
        setMyCalenderPopUp(!myCalenderPopUp)
    }

    const handleClose = () => {
        dispatch(getGroupMems("00000000-0000-0000-0000-000000000000") as any)
        setcreateEvent({ ...createEvent, IsAlldaySelected: false, RruleEvent: '', TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, shortZone: moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z') })

        setDisableMeetingLink(false);
        setExistingContent([]);
        setattachFile([]);
        setDescriptionPopUp(false)
        setEndDatePopUp(false)
        setClickEditButton(false)
        setEventShowPopUp(false)
    }

    const handleRemainderChange = (event: any) => {

        setcreateEvent({ ...createEvent, isRemainder: event.target.checked || false })
        //setcreateEvent({ ...createEvent, IsAlldaySelected: event.target.checked || false })
    }

    const closeEditTab = () => {
        setShowParticipants(false)
        setDisableMeetingLink(false)
        setcreateEvent({ ...createEvent, participants: [], meetingLink: '', IsAlldaySelected: false })
        setExistingContent([])
        setClickEditButton(false)
    }

    useEffect(() => {
        console.log("Data getting of Participants", user.teamMems);
        if (user?.teamMems) {
            // Extract OfficeEmails from teamMems and set them as participants
            const participantEmails = user.teamMems.map((mem: any) => mem.OfficeEmail);
            setcreateEvent({ ...createEvent, participants: participantEmails });
        }
    }, [user?.teamMems]);

    const handleAttachedFile = (event: any) => {
        const files = event.target.files;
        // console.log("files", files);
        if (files) {
            const filesArray = Array.from(files);
            setattachFile([...Attachfile, ...filesArray]);
        }
    };

    const removeAttachedFile = (index: number) => {
        const updatedFiles = [...Attachfile];
        updatedFiles.splice(index, 1);
        setattachFile(updatedFiles);
    };

    const removeExistingFile = (index: number) => {
        const updatedFiles = [...ExistingContent];
        updatedFiles.splice(index, 1);
        setExistingContent(updatedFiles);
    }

    const deleteRecuringEvent = () => {
        const newEventObj: any = createEvent;
        let startHours: any = parseInt(createEvent.StartTime.split(":")[0]);
        var startminutes = createEvent.StartTime.split(":")[1];
        if (createEvent.StartMeridian == 'PM') {
            if (startHours !== 12) {
                startHours += 12;
            }
        }
        let endHours: any = parseInt(createEvent.EndTime.split(":")[0]);
        var endminutes = createEvent.EndTime.split(":")[1];
        if (createEvent.EndMeridian == 'PM') {
            if (endHours !== 12) {
                endHours += 12;
            }
        }
        let startDate = selectedStartDate;
        startDate.setHours(startHours);
        startDate.setMinutes(Number(startminutes));
        startDate.setSeconds(0);
        newEventObj.UTCStartTime = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes());
        let endDate = selectedEndDate;
        endDate.setHours(endHours);
        endDate.setMinutes(Number(endminutes));
        endDate.setSeconds(0);
        newEventObj.UTCEndTime = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), (newEventObj.IsAlldaySelected) ? 23 : endDate.getUTCHours(), (newEventObj.IsAlldaySelected) ? 59 : endDate.getUTCMinutes());
        let untilDate = selectedRREndDate;
        untilDate.setHours(endHours);
        untilDate.setMinutes(Number(endminutes));
        untilDate.setSeconds(0);
        newEventObj.UTCRREndTime = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), untilDate.getUTCHours(), untilDate.getUTCMinutes());

        newEventObj.offset = new Date().getTimezoneOffset();
        newEventObj.AttachmentsContent = JSON.stringify(createEvent.Content);

        const formData = new FormData();

        for (const [key, value] of Object.entries(createEvent)) {
            if (Array.isArray(value)) {
                continue;
            } else {
                if (key === 'StartDate' || key === 'EndDate' || key === 'UntilRREnddate') {

                    const date = new Date(value);
                    if (!isNaN(date.getTime())) {

                        const isoDate = date.toISOString().split('T')[0];
                        formData.append(key, isoDate);
                    }
                } else {
                    formData.append(key, newEventObj[key]);
                }
                // formData.append(key, value);
            }
        }
        if (createEvent.participants.length > 0) {
            for (const mail of createEvent.participants) {
                formData.append('participants', mail);
            }
        }
        else {
            formData.append('participants', '');
        }
        for (const file of Attachfile) {
            formData.append('AttachedContent', file, file.name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
        }
        if (ExistingContent.length > 0) {
            const attachmentIdsString = ExistingContent.map((obj: any) => obj.AttachementId).join(',');
            formData.append('ExistingContentId', attachmentIdsString);
        }

        dispatch(DeleteRecuringEvent(formData) as any);
        setattachFile([]);
        setExistingContent([])

        setcreateEvent({ ...createEvent, StartTime: '11:00', EndTime: '11:30', StartMeridian: 'AM', EndMeridian: 'AM', EventName: '', EventDescription: '', participants: [], Content: [], EmpName: myEmpName ? myEmpName : '', EmpEmail: myEmpEmail ? myEmpEmail : '', orgGuid: myOrgId ? myOrgId : '', EmpId: new Number(myEmpId) ? new Number(myEmpId) : 0, Type: 'Event', EndDate: Moment(selectedEndDate).format('MM-DD-YYYY'), StartDate: Moment(selectedStartDate).format('MM-DD-YYYY'), Email: 'Sute', EmpGuid: myUserId ? myUserId : '', IsSubmitted: true, GroupGuid: '', IsAlldaySelected: false, isRemainder: false, RruleEvent: '', UntilRREnddate: Moment(selectedRREndDate).format('MM-DD-YYYY') })
        setDisableMeetingLink(false)
        document.getElementById('rrulePopupCloseBtn')?.click();
    }

    const handleChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setcreateEvent((prevEvent) => ({
            ...prevEvent,
            RruleEvent: value,
        }));
    }

    const handleDescription = () => {
        setDescriptionPopUp(!descriptionPopUp)
    }

    const handleEvents = () => {
        setEventShowPopUp(!eventShowPopUp)
    }

    // const changeReccuringEndDate = (date: any) => {
    //     selectedEndDate = date;
    //     setAdjEndtime(new Date(date));
    //     setcreateEvent({ ...createEvent, EndDate: Moment(date).format('MM-DD-YYYY') });
    // }
    const currentDate = new Date(); // Get the current date

    const eventDate = new Date(createEvent.StartDate); // Assuming startsAt is the event start date

    // Function to check if the event is today or in the future
    const isCurrentOrFutureDate = (eventDate: any) => {
        // Reset time to 00:00:00 for accurate date comparison
        const currentDateWithoutTime = new Date(currentDate.setHours(0, 0, 0, 0));
        const eventDateWithoutTime = new Date(eventDate.setHours(0, 0, 0, 0));

        return eventDateWithoutTime >= currentDateWithoutTime;
    };

    const changeReccuringEndDate = (date: any) => {
        selectedRREndDate = date;
        // setAdjEndtime(new Date(date));
        setcreateEvent({ ...createEvent, UntilRREnddate: Moment(date).format('MM-DD-YYYY') });

    }

    const handleDeleteClose = () => {
        setcreateEvent({ ...createEvent, RruleEvent: '' })
    }

    useEffect(() => {
        if (createEvent.IsAlldaySelected == true) {
            setClickEditButton(false)
        } else if (createEvent.RecurrenceRule?.length > 0 && createEvent.RecurrenceRule.toUpperCase() != "NONE")
            setClickEditButton(true)
        // const endDate = new Date();
        const endDate = Moment(createEvent.EndDate).format('YYYY-MM-DD');
        const selectedRREndDateStr = Moment(createEvent.StartDate).format('YYYY-MM-DD');
        if (createEvent.Id != '' && clickEditButton == true && selectedRREndDateStr == endDate && createEvent.RecurrenceRule.length == 0) {
            setClickEditButton(false)
        }
    }, [createEvent.IsAlldaySelected, clickEditButton])

    const handleClickMeetingLink = (e: any) => {
        e.preventDefault();
        closeEditTab();
        // history(createEvent.meetingLink)
        history(createEvent.meetingLink)

        // window.location.href = createEvent.meetingLink;
    };

    return (
        <>
            <section className="myProfilePage position-relative d-flex">
                <button type="button" id="eventCreateBtn" data-target="#eventCreate" data-toggle="modal" style={{ opacity: 0, pointerEvents: "none" }}></button>
                <button type="button" id="eventViewBtn" data-target="#eventView" data-toggle="modal" style={{ opacity: 0, pointerEvents: "none" }}></button>
                <button type="button" id="rrulePopup" data-target="#rrule" data-toggle="modal" style={{ opacity: 0, pointerEvents: "none" }}></button>
                <button type="button" id="rruleChangePopup" data-target="#rruleChange" data-toggle="modal" style={{ opacity: 0, pointerEvents: "none" }}></button>
                {/* <button type="button" id="eventCloseBtn" data-dismiss="modal" style={{ opacity: 0 }}></button> */}
                <div className="open-sidemenu" onClick={closeCalendarWindows}>
                    <img src="/assets/media/images/calender.png" />
                </div>
                <div className={showCalendar ? "SideNav text-left d-none position-fixed" : "SideNav text-left position-fixed d-block"}>
                    <div className="sideNavHeader">
                        <div className="CloseNav" title="Close"> <img src="assets/media/svg/x-close.svg" onClick={closeCalendarWindows} /> </div>
                    </div>
                    <div className="sideNavBody">
                        <div className="row mt-4">
                            <div className="col-12 mb-4 text-white">
                                <h6 className="m-0">{t('calendar.calendar')}</h6> </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-light MemberPPHeader" onClick={handleMyCalenderClose}>{t('calendar.my_calendars')}  <img className="float-right" src="/assets/media/svg/profile/downArrow.svg" /> </div>
                            {myCalenderPopUp && (<div className="col-12">
                                {(employee.calendarCategories || []).length > 0 ? (employee.calendarCategories || []).map((obj: any, index: number) => {
                                    return (
                                        <div key={index} className="custom-control custom-checkbox text-muted">
                                            <input type="checkbox" checked={obj.Checked} value={obj.Checked} onChange={(e: any) => onCalendarCategoryChecked(e, obj)} className="custom-control-input" />
                                            <label className="custom-control-label label-after">{obj.CategoryName}</label>
                                        </div>)
                                }) : undefined}
                            </div>)}

                            <div className="col-12 text-light MemberPPHeader mt-3 dropdown"
                            > {t('calendar.other_calendars')}
                                <img className="float-right" src="/assets/media/svg/profile/downArrow.svg" /> <a className="float-right" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img className="mr-1 mt-1 c-pointer" title="sign in with Google" width="18" src="/assets/media/svg/plus-icon.svg" /></a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" style={{ transform: "translate3d(25px, 22px, 0px)" }}>
                                    <div className="dropdown-item position-relative small" style={{ padding: "0 10px" }}>
                                        {/* <img src="/assets/media/images/btn_google_signin_light_focus_web@2x.png" height="28px" /> */}
                                        <h5 className="google-text">
                                            <span>
                                                <img className="google-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png" />
                                            </span>Sign in with google
                                        </h5>
                                        <GoogleLogin
                                            clientId="580059194632-vk2m6t85u966ijnnh0p975k874m9vqd5.apps.googleusercontent.com"
                                            buttonText="Sync Calendar"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            className="googleCalBtn"
                                            theme="light"
                                            scope="profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly"
                                            cookiePolicy={'single_host_origin'}
                                            prompt="consent"
                                            accessType="offline"
                                            responseType="code"
                                        /></div>
                                    {/* <a className="dropdown-item small" ><img src="/assets/media/svg/outlook.svg" height="18px" /> Microsoft</a> */}
                                </div>

                            </div>
                            <div className="col-12">
                                {(employee.events || []).length > 0 ? (employee.events || []).map((obj: any, index: number) => {
                                    if (obj.IsShow) {
                                        return (
                                            <div key={index} className="custom-control custom-checkbox text-muted">
                                                <input type="checkbox" checked={obj.Checked} value={obj.Checked} onChange={(e: any) => onEmailCheckUncheck(e, obj)} className="custom-control-input" />
                                                <label className="custom-control-label calAccId"><span className="calColorIndicator" style={{ backgroundColor: obj.Color }}></span><span className="oc-mail" title={obj.Email}>{obj.Email}</span></label>
                                                <svg onClick={(e) => handleShowAccountDelete(obj.AccountId)} className="float-right c-pointer mt-1 removeCal" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M1.5 1.5l12 12m-12 0l12-12" stroke="red"></path></svg>
                                                {/* <label className="custom-control-label"><span className="calColorIndicator" style={{ backgroundColor: obj.Color }}></span><span className="oc-mail" title={obj.Email}>{obj.Email}</span></label>
                                                <img onClick={(e) => handleShowAccountDelete(obj.AccountId)} className="float-right c-pointer" width="15" src="/assets/media/svg/deleteGroup.svg"></img> */}
                                            </div>)
                                    }
                                }) : undefined}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row mt-4 row-mobile">
                        <div className="col-10 pr-5 text-light pb-3 mobile-width">
                            <div className="cRoomHeader">
                                <div className="cRsuteLogo align-self-center">
                                    <Link to={loc ? loc : "/produce"}><img src="/assets/media/images/logo.png" /></Link>
                                </div>
                                <div className="cRTitle">
                                    <h5>{t('calendar.scheduling')}</h5>
                                    {/* <p>{t('calendar.all_calendars')}</p> */}
                                </div>
                            </div>
                            {/* <HeaderTitle title="Calendar" textLight={false} /> */}
                            <FullCalendar

                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                }}

                                initialView='dayGridMonth'
                                editable={true}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                weekends={true}
                                events={eves.cale}
                                initialEvents={eves.cale} // alternatively, use the `events` setting to fetch from a feed
                                select={dateClick}
                                // eventContent={renderEventContent} // custom render function
                                eventClick={handleEventClick}
                            /* eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                             you can update a remote database when these fire:
                             eventAdd={function(){}}
                             eventChange={function(){}}
                             eventRemove={function(){}}
                             */
                            />
                        </div>
                    </div>
                </div>
                <div className="modal fade small" id="eventCreate" role="dialog" aria-hidden="true" data-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content text-white">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{(!createEvent.IsSubmitted && createEvent.Id != '' && createEvent.EmpGuid != '') ? t('calendar.edit_event') : t('calendar.create_new_event')}</h5>
                                <button type="button" id="eventCloseBtn" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                    {/* <span aria-hidden="true" >&times;</span> */}
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            {/*<button type="button" id="Event" onClick={e => onChangeType(e, 'Event')} className={clsx('btn btn-sm mr-2 ', createEvent.Type === 'Event' ? 'btn-SeaGreen' : 'btn-outline-success')}>{t('button.event')}</button>
                                            <button disabled type="button" id="Task" onClick={e => onChangeType(e, 'Task')} className={clsx('btn btn-sm mr-2 ', createEvent.Type === 'Task' ? 'btn-SeaGreen' : 'btn-outline-success')}>{t('button.task')}</button>
                                            <button disabled type="button" id="Reminder" onClick={e => onChangeType(e, 'Reminder')} className={clsx('btn btn-sm mr-2 ', createEvent.Type === 'Reminder' ? 'btn-SeaGreen' : 'btn-outline-success')}>{t('button.reminder')}</button> */}
                                            {/* 
                                            {allowSelect ? <select onChange={selectEmail} value={createEvent.Email} className="SuteInputPrimary h40px float-right emailSelectionDD">
                                          
                                                <option value="select">{t('calendar.select')}</option>
                                                <option value="Sute">Sute</option>
                                                {(employee.events || []).map((obj: any, index: number) => {
                                                    if (obj.IsShow) {
                                                        return (<option key={index} value={obj.Email}>{obj.Email}</option>)
                                                    }
                                                })}
                                            </select> : <select onChange={selectEmail} value={createEvent.Email} className="SuteInputPrimary h40px float-right emailSelectionDD">
                                                return (<option value={createEvent.Email}>{createEvent.Email}</option>)
                                            </select>
                                            } */}
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 p-0 text-right" >
                                            <svg className="calender-side" onClick={handleEvents} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M496 288H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-128H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"></path></svg>
                                        </div>
                                        <div className="col-sm-7 p-0">
                                            <div className="form-group mb-1 col-sm-12">
                                                {/* <label className="col-form-label">{t('calendar.event_name')}:</label> */}
                                                <input type="text" placeholder="Event name" className="SuteInputPrimary h40px w-90" value={createEvent.EventName} onChange={eventNameChange} /><sup>*</sup>
                                            </div>
                                            <div className="form-group mb-1 col-sm-12">
                                                <label className="col-form-label">{t('calendar.event_description')}
                                                    <a><svg className="fontsize-26 ml-2" onClick={handleDescription} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M112,40a8,8,0,0,0-8,8V64H24A16,16,0,0,0,8,80v96a16,16,0,0,0,16,16h80v16a8,8,0,0,0,16,0V48A8,8,0,0,0,112,40ZM24,176V80h80v96ZM248,80v96a16,16,0,0,1-16,16H144a8,8,0,0,1,0-16h88V80H144a8,8,0,0,1,0-16h88A16,16,0,0,1,248,80ZM88,112a8,8,0,0,1-8,8H72v24a8,8,0,0,1-16,0V120H48a8,8,0,0,1,0-16H80A8,8,0,0,1,88,112Z"></path></svg></a>
                                                </label>
                                                {createEvent.EventDescription.length > 0 ? <textarea className="SuteInputPrimary h50px" value={createEvent.EventDescription} onChange={eventDescriptionChange}></textarea> : (descriptionPopUp && (<textarea className="SuteInputPrimary h50px" value={createEvent.EventDescription} onChange={eventDescriptionChange}></textarea>))}
                                            </div>
                                            <div className="form-group mb-1 col-sm-12 row m-0">
                                                <div className="col-md-6 mt-2 pt-1">
                                                    {/* <div style={{ marginTop: '10px' }}> */}
                                                    <label className="listhead listhead-checkbox mr-3 float-left">
                                                        <input type="checkbox" name="allDayChecked" disabled={isEdit.current} onChange={e => onAllDayChecked(e)} checked={createEvent.IsAlldaySelected} />
                                                        <div className="control_indicator" ></div>
                                                    </label>
                                                    <span className="align-middle">{t('calendar.all_day')}</span>
                                                    {/* </div> */}
                                                </div>
                                                <div className="col-md-6 mt-2 pt-1">
                                                    <label className="listhead listhead-checkbox mr-3 float-left">
                                                        <input type="checkbox" name="isRemainder" onChange={e => handleRemainderChange(e)} checked={createEvent.isRemainder} />
                                                        <div className="control_indicator"></div>

                                                    </label>
                                                    <span className="align-middle">Reminder</span>
                                                </div>
                                            </div>
                                            {allDayFields(createEvent.IsAlldaySelected)}
                                            {
                                                createEvent.IsAlldaySelected ? '' :
                                                    <div className="form-group mb-1 col-sm-12 row m-0" style={{ paddingRight: "0px" }}>
                                                        <div className="col-md-6 mt-2 pl-0">
                                                            <select className="SuteInputPrimary h40px float-right" onChange={onRuleChange} value={getSelectedValue(createEvent.RecurrenceRule)}>
                                                                <option value="0">Does not repeat</option>
                                                                <option value="1">Daily</option>
                                                                <option value="2">Every {moment(createEvent.StartDate).format('dddd')}</option>
                                                                <option value="3">{getMonthRule()}</option>
                                                                <option value="4">Annually on {moment(createEvent.StartDate).format('MMMM D')} </option>
                                                                <option value="5">Every weekday (Monday to Friday)</option>
                                                            </select>
                                                        </div>
                                                        {clickEditButton && (<div className="col-6 row m-0 pl-0">
                                                            <div className="col-3 m-auto p-0">
                                                                <label className="col-form-label">Until :</label>
                                                            </div>
                                                            <div className="col-9 m-auto pr-0  calender-date-picker" style={{ paddingLeft: "0px" }}>
                                                                <DatePicker className="SuteInputPrimary h40px"
                                                                    selected={selectedRREndDate}
                                                                    onChange={date => changeReccuringEndDate(date)}
                                                                    //  value={createEvent.UntilRREnddate ? Moment(createEvent.UntilRREnddate).format('DD-MM-YYYY') : ""}
                                                                    name="startDate"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    minDate={selectedStartDate}
                                                                    value={Moment(selectedRREndDate).format(formattedDate)}
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                />
                                                            </div>
                                                        </div>)}
                                                    </div>
                                            }
                                            <div className="form-group mb-2 mt-2 col-sm-12">
                                                {/* <label className="col-form-label">Meeting:</label> */}
                                                <div className="row">
                                                    <div className="col-sm-10">
                                                        <select onChange={GenerateMeetingLink} className="SuteInputPrimary h40px float-right" value={createEvent.GroupGuid} disabled={disableSelectGroup}
                                                        //disabled={employee.events && employee.events.some((group: any) => group.EventId > 0)}
                                                        >
                                                            <option value="Select">Select meeting</option>
                                                            {user.groups && user.groups.length > 0 ?
                                                                // (user.groups?.GroupTypeId.length > 0 ?
                                                                user.groups.sort((a: any, b: any) => a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase() ? 1 : -1).map((obj: any, index: number) => {
                                                                    //console.log("group123",obj);
                                                                    return (<option key={index} value={obj.GroupGuid}>{obj.DisplayName}</option>)
                                                                    // }) : "select") 
                                                                }) : undefined}
                                                        </select>
                                                    </div>
                                                    {showMeetingLink && createEvent.Id !== '' && createEvent.EmpGuid !== '' ?
                                                        <div className="col-2 p-0 row m-0 url-copy">
                                                            <div className='d-flex'>
                                                                {/* <Link className="startMettingLink" to={createEvent.meetingLink} title={!showMeetingLink ? "" : "Click hear to start meeting"} onClick={handleClickMeetingLink}>
                                                                    <span>
                                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
                                                                    </span>
                                                                </Link> */}
                                                                <Link className="startMettingLink" to="" onClick={e => CopyURL(createEvent.meetingLink)} title={!showMeetingLink ? '' : "Copy the link"}>
                                                                    <span>
                                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg></span></Link>
                                                            </div>
                                                        </div> : null}
                                                </div>
                                                {/* <div className={createEvent.Id !== '' && createEvent.EmpGuid !== '' ? '' : 'disable-user-events'}>
                                                    {showMeetingLink ?
                                                        <label className="col-form-label"><Link className="startMettingLink" to={createEvent.meetingLink}><b>{t('calendar.start_meeting')}</b></Link> {t('calendar.copy_url')}<br></br> <Link onClick={e => CopyURL(createEvent.meetingLink)} >{createEvent.meetingLink} </Link></label> : <></>}
                                                </div> */}
                                            </div>
                                            <div className="form-group mb-2 mt-2 col-sm-12">
                                                {/* <label className="col-form-label">Meeting:</label> */}
                                                <div className="row">
                                                    <div className="col-sm-10">
                                                        <select onChange={selectedTimeZones} className="SuteInputPrimary h40px float-right" value={createEvent.TimeZone + "," + createEvent.shortZone}>
                                                            {/* <option value={Intl.DateTimeFormat().resolvedOptions().timeZone}>{Intl.DateTimeFormat().resolvedOptions().timeZone+' '+abbreviation}</option> */}
                                                            {
                                                                sortedTimeZones.map((tz: any, index: number) => {
                                                                    const Datezone = moment.tz(new Date(), tz);
                                                                    const offset = moment.tz(tz).format('Z');
                                                                    const abbreviation = Datezone.format('z');
                                                                    if (abbreviation.charAt(0) === '-' || abbreviation.charAt(0) === '+') {
                                                                        return;
                                                                    }
                                                                    return (<option key={index} value={tz + ',' + abbreviation}> {`(UTC${offset}) ${tz}`}</option>)
                                                                })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {selectedGroup && (<div className="form-group mb-1 col-sm-12 row m-0">
                                                <div className="col-md-4 mt-2 pt-1">
                                                    <label className="listhead listhead-checkbox mr-3 float-left">
                                                        <input type="checkbox" name="videoChecked" onChange={handleVideoChange}  checked={createEvent.isVideo} />
                                                        <div className="control_indicator"></div>
                                                    </label>
                                                    <span className="align-middle">Video</span>
                                                </div>
                                                <div className="col-md-4 mt-2 pt-1">
                                                    <label className="listhead listhead-checkbox mr-3 float-left">
                                                        <input type="checkbox" name="audioChecked" onChange={handleAudioChange}  checked={createEvent.isAudio} />
                                                        <div className="control_indicator"></div>
                                                    </label>
                                                    <span className="align-middle">Audio</span>
                                                </div>
                                                <div className="col-md-4 mt-2 pt-1">
                                                    <label className="listhead listhead-checkbox mr-3 float-left">
                                                        <input type="checkbox" name="chatChecked" onChange={handleChatChange}  checked={createEvent.isChat} />
                                                        <div className="control_indicator"></div>
                                                    </label>
                                                    <span className="align-middle">Chat</span>
                                                </div>
                                            </div>)} */}
                                        </div>
                                        <div className="col-sm-5 pl-0">
                                            {/* <div className="form-group col-sm-12  mb-n1">
                                                <label className="col-form-label">{t('calendar.participants')}:</label>
                                            </div>  */}
                                            <div className="form-group position-relative mb-0 col-sm-12 ParticipantsSearch addTeamMemberBox">
                                                <Typeahead
                                                    flip={true}
                                                    ref={typeaheadRef}
                                                    id="async-calc"
                                                    labelKey="OfficeEmail"
                                                    minLength={0}
                                                    options={options}
                                                    placeholder="choose or add Participants"
                                                    renderMenuItemChildren={(option, { text }, idex) => (
                                                        <div className="contactsSearch float-left p-2 w-100" title={(option as any).OfficeEmail}>
                                                            <div className="float-left">
                                                                <svg className={(option as any).IsOnline ? "ParticipantImage c-pointer active" : 'ParticipantImage c-pointer'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                                    <defs>
                                                                        <pattern id="01" patternUnits="userSpaceOnUse" width="100" height="100">
                                                                            <image xlinkHref="/assets/media/images/sute_user_default.png" x="-25" width="150" height="100" />
                                                                        </pattern>
                                                                    </defs>
                                                                    <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#01)"></polygon>
                                                                </svg>
                                                            </div>
                                                            <div className="float-left" style={{ width: 'calc(100% - 55px)' }} onClick={() => onSelectParticipant((option as any).OfficeEmail, (option as any).EmpGuid)}>
                                                                <p className="ParticipantName"><span className="PNel"><Highlighter search={""}>{(option as any).FirstName}</Highlighter></span>
                                                                    {/*     {user.aus[(option as any).EmpGuid]&&user.aus[(option as any).EmpGuid]==='ONLINE'? 
                                         <img onClick={() => sendNotice((option as any).EmpGuid)} title="Direct Call" alt="Direct Call" src="/assets/media/svg/profile/call.svg" />:
                                         <img onClick={() => sendInvitation((option as any).OfficeEmail, (option as any).EmpGuid)} title="Chat" alt="Chat" src="/assets/media/svg/profile/message.svg" />}
                                    */}
                                                                </p>
                                                                <p className="ParticipantDesignation">{(option as any).OfficeEmail}</p>
                                                            </div>
                                                        </div>)
                                                    }
                                                />
                                                {/* <input className="SuteInputPrimary h40px" type="text" value={participant} onChange={onParticipantEnter} /> */}
                                                <span className="inviteBtn" onClick={onAddParticipants}>{t('button.add')}</span>
                                            </div>

                                            {/* {(createEvent.GroupGuid == '' && createEvent.GroupEvent == true) ? null : (user?.teamMems || []).map((mem: any, index: any) => {
                                                    return <div className="teamEmailHolder" key={index}>{mem.OfficeEmail}</div>
                                                })} */}
                                            {/* {(createEvent.participants || []).map((e = '') => e.trim()).filter(e => e).map((obj: any, index: number) => {
                                                    return (
                                                        <div className="teamEmailHolder" key={index}>{obj}{obj != myEmpEmail ? <img className="c-pointer" onClick={(e) => removeParticipant(index)} src="assets/media/svg/onboarding/x-close.svg" /> : <>(Me)</>}</div>)
                                                })} */}
                                            {((createEvent.participants).map((e = '') => e.trim()).filter(e => e)).map((email, index) => {
                                                // const isParticipantInTeam = user?.teamMems?.some((member: any) => member.OfficeEmail === email);


                                                //// const isParticipantAndTeamMember =  isParticipantInTeam && createEvent.participants.includes(email);


                                                // if (!isParticipantAndTeamMember) {
                                                const participant = empAvalibilty.find(participant => participant.Email === email);
                                                console.log(" AV ", email, participant)
                                                let Acolor = 'white'
                                                if (email !== myEmpEmail)
                                                    Acolor = participant ? (participant.IsAvailiable == true ? 'green' : 'red') : 'gray';

                                                return (
                                                    <div className="col-sm-12 addTeamEmails mt-1">
                                                        <div className="teamEmailHolder" key={index}>
                                                            {<svg stroke="currentColor" fill={Acolor} stroke-width="0" viewBox="0 0 24 24" className="text-2xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17 2H7C4.243 2 2 4.243 2 7v10c0 2.757 2.243 5 5 5h10c2.757 0 5-2.243 5-5V7c0-2.757-2.243-5-5-5z"></path></svg>}
                                                            {" "}
                                                            {email}
                                                            {email !== myEmpEmail && (
                                                                <img
                                                                    className="c-pointer"
                                                                    onClick={() => removeParticipant(index)}
                                                                    src="assets/media/svg/onboarding/x-close.svg"
                                                                    alt="Remove"
                                                                />
                                                            )}
                                                            {email === myEmpEmail && <>(Me)</>}
                                                        </div>
                                                    </div>
                                                );
                                                // }
                                                return null;
                                            })}

                                            <div className="form-group mb-1 col-sm-12  float-left" style={{ display: 'none' }}>
                                                <label className="col-form-label c-pointer" onClick={addContentClick}><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 6.5L1.328 9.672a2.828 2.828 0 104 4L8.5 10.5m2-2l3.172-3.172a2.829 2.829 0 00-4-4L6.5 4.5m-2 6l6-6" stroke="currentColor"></path></svg> Attach Content</label>
                                            </div>
                                            <div className="col-sm-12 pl-0 addTeamEmails">
                                                {(createEvent.Content || []).map((obj: any, index: number) => {
                                                    return (
                                                        <div className="teamEmailHolder" key={index}>{obj.TrackName} <img className="c-pointer" onClick={(e) => removeMediaAttachments(index)} src="assets/media/svg/onboarding/x-close.svg" alt="close" /></div>)
                                                })}
                                            </div>
                                            <div className="form-group mb-1 col-sm-12 row m-0 w-100">

                                                <div className="col-md-12 mt-2 pt-1 p-0 row m-0">

                                                    <div className="col-12 p-0">
                                                        <span className="attach-files" title="Add attachment" >
                                                            <label htmlFor="input-id"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M208 64h66.75a32 32 0 0 1 22.62 9.37l141.26 141.26a32 32 0 0 1 9.37 22.62V432a48 48 0 0 1-48 48H192a48 48 0 0 1-48-48V304"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M288 72v120a32 32 0 0 0 32 32h120"></path><path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M160 80v152a23.69 23.69 0 0 1-24 24c-12 0-24-9.1-24-24V88c0-30.59 16.57-56 48-56s48 24.8 48 55.38v138.75c0 43-27.82 77.87-72 77.87s-72-34.86-72-77.87V144"></path></svg></label>
                                                            <input type="file" name="input-name" onClick={(e: any) => e.target.value = null} id="input-id" multiple onChange={handleAttachedFile} className="d-none" />
                                                            <span className="attachment-text">Attachments</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                {(Attachfile.length > 0 || ExistingContent?.length > 0) ? <div className="uploaded-files">
                                                    {Attachfile.map((file: any, index: any) => (
                                                        <span key={index} className="attached-doc" title={file.name}>
                                                            <b>{file.name}</b>
                                                            <span onClick={() => { removeAttachedFile(index) }}>
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeWidth="0"
                                                                    viewBox="0 0 15 15"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    ))}

                                                    {ExistingContent.map((file: any, index: any) => (
                                                        <span key={index} className="attached-doc" title={file.FileName}>
                                                            <b>{file.FileName}</b>
                                                            <span onClick={() => { removeExistingFile(index) }}>
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeWidth="0"
                                                                    viewBox="0 0 15 15"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    ))}
                                                </div> : null}

                                            </div>

                                        </div>
                                        {eventShowPopUp ?
                                            (
                                                <div className="col-sm-5 p-0 calender-click">
                                                    <FullCalendar

                                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                        headerToolbar={{
                                                            left: 'prev,next',
                                                        }}
                                                        height={350}
                                                        initialView='timeGridDay'
                                                        selectable={true}
                                                        selectMirror={true}
                                                        dayMaxEvents={true}
                                                        weekends={true}
                                                        events={groupEves.cale}
                                                        initialEvents={groupEves.cale}
                                                        ref={daycalendarRef}
                                                    />
                                                </div>
                                            ) : null}
                                    </div>
                                </form>
                            </div>
                            <div className="text-danger ml-2 fw-bold">{UpdateEventStatus}</div>
                            <div className="modal-footer text-center">
                                {(!createEvent.IsSubmitted) ? <button type="button" onClick={() => { (createEvent.RecurrenceRule.length > 4) ? handleShowDelete() : setShowConfirmDelete(true) }} className="btn btn-SeaGreen">{t('button.delete')}</button> : null}
                                <button type="button" onClick={saveEvent} className="btn btn-SeaGreen">{t('button.save')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal small"
                    id="eventView"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-bs-keyboard="false"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="color-white">{createEvent.EventName}</h5>
                                <button type="button" title="Close" id="eventCloseBtn" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={closeEditTab}>
                                    {/* <span aria-hidden="true" >&times;</span> */}
                                </button>
                            </div>
                            <div className="modal-body text-white">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {/* <span className="eventMTitle" title={createEvent.EventName}>{createEvent.EventName}</span> */}
                                        {showEdit && createEvent.eventHostId.toString() == myEmpId ? <span className="roomActions dropdown float-right d-flex"><svg className="align-self-center c-pointer dropdown-toggle rotate90deg text-center w-100" type="button" id="roomActionsDD" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="var(--SeaGreen)" /></svg>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="roomActionsDD">
                                                {createEvent.eventHostId.toString() == myEmpId && isCurrentOrFutureDate(eventDate) ? <a onClick={handleShowEdit} className="dropdown-item" data-dismiss="modal">{t('button.edit')}</a> : <></>
                                                }
                                                {createEvent.eventHostId.toString() == myEmpId ? <a onClick={handleShowDelete} className="dropdown-item c-pointer" data-dismiss="modal">{t('button.delete')}</a> : createEvent.eventHostId.toString() != myEmpId && !createEvent.GroupEvent ? <a onClick={handleShowDelete} className="dropdown-item c-pointer" data-dismiss="modal">{t('button.delete')}</a> : <></>}
                                            </div>
                                        </span> : ''}
                                        <ConfirmPopup show={showConfirmDelete} close={handleShowDelete} okHandle={deleteEvent} message="Do you want to delete this event?" />
                                        <ConfirmPopup show={showConfirmAccountDelete} close={handleShowAccountDelete} okHandle={deleteAccount} message="Do you want to delete this account?" />

                                        <p title={t('calendar.start_date')}><svg className="calVIcon" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="24"><path d="M3.5 0v5m8-5v5m-10-2.5h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="#fff"></path></svg> <span className="eventVDesc">{Moment(startDate).format(`${formattedDate} hh:mm A`)}</span></p>
                                        <p title={t('calendar.end_date')}><svg className="calVIcon" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="24"><path d="M3.5 0v5m8-5v5M5 8.5l2 2 3.5-4m-9-4h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="#fff"></path></svg> <span className="eventVDesc">{Moment(endDate).format(`${formattedDate} hh:mm A`)}</span></p>
                                        <p title={t('title.description')}><svg className="calVIcon" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="24"><path fillRule="evenodd" clipRule="evenodd" d="M15 4H0V3h15v1zM6 8H0V7h6v1zm3 4H0v-1h9v1z" fill="#fff"></path></svg> <span className="eventVDesc" dangerouslySetInnerHTML={{ __html: createEvent.EventDescription }}></span></p>

                                        {showMeetingLink ?
                                            <div className="col-12 p-0 row m-0 url-copy">
                                                <p title={t('calendar.meeting_link')}><svg className="calVIcon" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="24"><path d="M4.5 6.5L1.328 9.672a2.828 2.828 0 104 4L8.5 10.5m2-2l3.172-3.172a2.829 2.829 0 00-4-4L6.5 4.5m-2 6l6-6" stroke="#fff"></path></svg></p>
                                                <div className={createEvent.Id !== '' && createEvent.EmpGuid !== '' ? ' ' : 'disable-user-events'}>
                                                    {/* <Link className="startMettingLink" to={createEvent.meetingLink} title={!showMeetingLink ? "" : "Click hear to start meeting"} onClick={handleClickMeetingLink}>
                                                        <span>
                                                            <svg className="valign-baseline" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
                                                        </span></Link> */}
                                                    <Link className="startMettingLink" to="" onClick={e => CopyURL(createEvent.meetingLink)} title={!showMeetingLink ? '' : "Copy the link"}><span><svg className="valign-baseline" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg></span></Link>
                                                </div>
                                            </div> : null}
                                        {showParticipants ? <p title={t('calendar.participants')}><svg className="calVIcon" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="24"><path d="M10.5 14.49v.5h.5v-.5h-.5zm-10 0H0v.5h.5v-.5zm14 .01v.5h.5v-.5h-.5zM8 3.498a2.499 2.499 0 01-2.5 2.498v1C7.433 6.996 9 5.43 9 3.498H8zM5.5 5.996A2.499 2.499 0 013 3.498H2a3.499 3.499 0 003.5 3.498v-1zM3 3.498A2.499 2.499 0 015.5 1V0A3.499 3.499 0 002 3.498h1zM5.5 1A2.5 2.5 0 018 3.498h1A3.499 3.499 0 005.5 0v1zm5 12.99H.5v1h10v-1zm-9.5.5v-1.996H0v1.996h1zm2.5-4.496h4v-1h-4v1zm6.5 2.5v1.996h1v-1.997h-1zm-2.5-2.5a2.5 2.5 0 012.5 2.5h1a3.5 3.5 0 00-3.5-3.5v1zm-6.5 2.5a2.5 2.5 0 012.5-2.5v-1a3.5 3.5 0 00-3.5 3.5h1zM14 13v1.5h1V13h-1zm.5 1H12v1h2.5v-1zM12 11a2 2 0 012 2h1a3 3 0 00-3-3v1zm-.5-3A1.5 1.5 0 0110 6.5H9A2.5 2.5 0 0011.5 9V8zM13 6.5A1.5 1.5 0 0111.5 8v1A2.5 2.5 0 0014 6.5h-1zM11.5 5A1.5 1.5 0 0113 6.5h1A2.5 2.5 0 0011.5 4v1zm0-1A2.5 2.5 0 009 6.5h1A1.5 1.5 0 0111.5 5V4z" fill="#fff"></path></svg>
                                            {isSute ? (participants || []).map(P => { return (<span className="eventVDesc">{P}</span>) }) : (attendes || []).map(P => { return (<span className="eventVDesc">{P}</span>) })}
                                        </p> : <></>}

                                        {!showMediaAttachments ? <> <p title={t('calendar.attachments')}>
                                            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="24"><path d="M13.5 3.5h.5v-.207l-.146-.147-.354.354zm-3-3l.354-.354L10.707 0H10.5v.5zm-3 4l.4-.3a.5.5 0 00-.9.3h.5zm.3.4l.4-.3-.4.3zm4.7 9.1h-10v1h10v-1zM2 13.5v-12H1v12h1zm11-10v10h1v-10h-1zM2.5 1h8V0h-8v1zm7.646-.146l3 3 .708-.708-3-3-.708.708zM2.5 14a.5.5 0 01-.5-.5H1A1.5 1.5 0 002.5 15v-1zm10 1a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zM2 1.5a.5.5 0 01.5-.5V0A1.5 1.5 0 001 1.5h1zM6 11a1 1 0 01-1-1H4a2 2 0 002 2v-1zm1-1a1 1 0 01-1 1v1a2 2 0 002-2H7zM6 9a1 1 0 011 1h1a2 2 0 00-2-2v1zm0-1a2 2 0 00-2 2h1a1 1 0 011-1V8zm1-1.5V10h1V6.5H7zM8 7V4.5H7V7h1zm-.9-2.2l.3.4.8-.6-.3-.4-.8.6zm.3.4A4.5 4.5 0 0011 7V6a3.5 3.5 0 01-2.8-1.4l-.8.6z" fill="currentColor"></path></svg> <span className="eventVDesc">{(createEvent.Content || []).map((obj: any, index: number) => {
                                                return (<><a key={index} href={obj.TrackURL} className="text-light" target="_blank">{obj.TrackName}</a><br /></>)
                                            })}</span></p>
                                        </> : <></>}
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer justify-content-center">
                                {/* <button type="button" className="btn btn-danger" data-dismiss="modal">Delete</button> */}
                                <button type="button" className="btn btn-SeaGreen" data-dismiss="modal" onClick={closeEditTab}>{t('button.close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <MediaSoundTracks tracks={createEvent.Content || []} show={showAddPopup} close={closeAddContentClick} AddMediaContent={(e: any) => AddMediaAttachments(e)} />
            </section>


            <div className="modal fade show" id="rrule" role="dialog" aria-hidden="true" data-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content text-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Do you want to delete?</h5>
                            <button type="button" id="rrulePopupCloseBtn" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleDeleteClose}>
                                {/* <span aria-hidden="true">&times;</span> */}
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group">
                                    <label>
                                        <input type="radio" name="" value="this event" checked={createEvent.RruleEvent === 'this event'} onChange={handleChangeEvent} /> This Event
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <input type="radio" name="" value="this and following" checked={createEvent.RruleEvent === 'this and following'} onChange={handleChangeEvent} /> This and following
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <input type="radio" name="" value="all events" checked={createEvent.RruleEvent === 'all events'} onChange={handleChangeEvent} /> All Events
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-center">
                            <button onClick={(e) => {
                                deleteRecuringEvent();
                                setcreateEvent({ ...createEvent, RruleEvent: '' })
                            }} type="button" disabled={createEvent.RruleEvent.length === 0} className="btn approve-btn">
                                {/* {t('button.yes')} */}
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                            </button>
                            <button type="button" onClick={(e) => {
                                setcreateEvent({ ...createEvent, RruleEvent: '' });
                            }} className="btn reject-btn" data-dismiss="modal">
                                {/* {t('button.no')} */}
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="rruleChange" role="dialog" aria-hidden="true" data-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content text-white">
                        <div className="modal-header">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Do you want to delete?</h5>
                            <button type="button" id="rruleChangePopupCloseBtn" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body">
                            <div>
                                {createEvent.RecurrenceRule === handleOldEvent.RecurrenceRule ?
                                    <div className="form-group">
                                        <label>
                                            <input type="radio" name="" value="this event" checked={createEvent.RruleEvent === 'this event'} onChange={handleChangeEvent} /> This Event
                                        </label>
                                    </div> : null}
                                <div className="form-group">
                                    <label>
                                        <input type="radio" name="" value="this and following" checked={createEvent.RruleEvent === 'this and following'} onChange={handleChangeEvent} /> This and following
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <input type="radio" name="" value="all events" checked={createEvent.RruleEvent === 'all events'} onChange={handleChangeEvent} /> All Events
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-center">
                            <button type="button" onClick={(e) => {
                                setcreateEvent({ ...createEvent, RruleEvent: '' });
                            }} className="btn btn-secondary" data-dismiss="modal">{t('button.cancel')}</button>
                            <button type="button" onClick={(e) => { handleConfirmation(true) }} disabled={createEvent.RruleEvent.length === 0} data-dismiss="modal" className="btn btn-danger">{t('button.yes')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calendar;

