// https://doc.shengwang.cn/api-ref/rtc/javascript/interfaces/networkquality


export interface IconProps {
    width?: number
    height?: number
    color?: string
  }

interface INetworkIconProps extends IconProps {
  level?: number
}

export const NetworkIcon = (props: INetworkIconProps) => {
  const { level, ...rest } = props
  switch (level) {
    case 0:
      return  <img src="/assets/media/network/disconnected.svg" alt="" />
    case 1:
      return <img src="/assets/media/network/excellent.svg" alt="" />
    case 2:
      return <img src="/assets/media/network/good.svg" alt="" />
    case 3:
      return <img src="/assets/media/network/average.svg" alt="" />
    case 4:
      return <img src="/assets/media/network/average.svg" alt="" />
    case 5:
      return <img src="/assets/media/network/poor.svg" alt="" />
    case 6:
      return <img src="/assets/media/network/disconnected.svg" alt="" />
    default:
      return <img src="/assets/media/network/disconnected.svg" alt="" />
  }
}
