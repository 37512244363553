import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import CheckinsRightMenu from './CheckinsRightMenu';
import { saveCheckin } from '../../services/CheckinsServices';
import { removeSuccessStatus } from "../../services/ContactsService";
import { ShowToast } from "../../services/UserActions";
import { StoreClass } from "../../store/Store";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import { Modal } from 'react-bootstrap';
import { ReactComponent as Flag } from './flag.svg';

interface formError {
	message: string;
}

interface Checkin {
	CheckinId: number;
	EmployeeGuid: string;
	Problems: string;
	Emoji: string;
}

interface data {
	txt: string | null;
	File: any;
	Flag: string;
	FileName: string;
}

function Checkins(props: any) {

	const [openEmoji, setOpenEmoji] = useState('');
	const [openFlag, setOpenFlag] = useState('');
	const handleEmojiClose = () => setOpenEmoji('');
	const handleFlagClose = () => setOpenFlag('');
    const [isSaveInprogress, setSaveInprogress] = useState(false);


	const dispatch = useDispatch();
	const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));

	const [errors, setErrors] = useState({} as formError);
	const errormsg = (<div className='row'> <div className="col-12"><div className="alert alert-danger text-center">
		{errors.message}
	</div></div></div>);

	const [values, setValues] = useState({} as Checkin);
	const [plans, setPlans] = useState<any[]>([]);
	const [progresses, setProgresses] = useState<data[]>([]);
	const [reportingList, setReportingList] = useState<any>([] as any);
	const [parentReportingList, setParentReportingList] = useState([] as any);
	//const [optionsEmpListReportingTo,setOptionsEmpListReportingTo] = useState<any>([]);
	const [selectedFlag, setSelectedFlag] = useState<string | null>(null);

	let empId = localStorage.getItem('EmpGuid') as string;
	let emojis = [
		{ image: "https://em-content.zobj.net/source/apple/391/neutral-face_1f610.png", title: "Annoyed" },
		{ image: "https://em-content.zobj.net/source/apple/391/sleepy-face_1f62a.png", title: "Sleepy" },
		{ image: "https://em-content.zobj.net/source/apple/391/smirking-face_1f60f.png", title: "Worried " },
		{ image: "https://em-content.zobj.net/source/apple/391/smiling-face_263a-fe0f.png", title: "Excited" },
		{ image: "https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png", title: "Funny" },
		{ image: "https://em-content.zobj.net/source/apple/391/grinning-face_1f600.png", title: "Happy"},
		{ image: "https://em-content.zobj.net/source/apple/391/smiling-face-with-heart-eyes_1f60d.png", title: "Cool" }
	];
	let flags = ["red", "orange", "yellow", "green"];
	const MAXFILESIZE = process.env.REACT_APP_MAXFILESIZE as string;





	const handleSelectEmoji = (emoji: string) => {
		setValues({ ...values, Emoji: emoji });
		setOpenEmoji('');
	};

	const handleSelectFlag = (flag: string) => {
		if (openFlag.match(/planflag*/)) {
			plans[Number(openFlag.replace('planflag', ''))].Flag = flag;
		}
		if (openFlag.match(/progressflag*/)) {
			progresses[Number(openFlag.replace('progressflag', ''))].Flag = flag;
		}
		setOpenFlag('');
		setSelectedFlag(flag)
	};

	const handleChange = (e: any) => {
		e.persist();
		if (e.target.value)
			setValues(values => ({
				...values,
				[e.target.name]: e.target.value.replace(/<(.|\n)*?>/g, '')
			}));
		else
			setValues(values => ({
				...values,
				[e.target.name]: null
			}));

		setErrors({ message: '' });
	};

	const handleChangeData = (data: data) => (e: any) => {
		e.target.value=e.target.value.trimStart();
		e.persist();
		if (e.target.value)
			data.txt = e.target.value.replace(/<(.|\n)*?>/g, '');
		else
			data.txt = null;
		setErrors({ message: '' });
	};

	useEffect(() => {
		dispatch(removeSuccessStatus() as any);
		console.log("removeSuccessStatus")
		setValues({ ...values, EmployeeGuid: empId, Emoji: "https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png" });
		setPlans([{ Flag: "white" } as data]);
		setProgresses([{ Flag: "white" } as data]);
	}, []);

	function fileSelectHandler(e: any, index: number, isplans: boolean) {

		setErrors({ message: '' });
		if (e.currentTarget.files.length > 0 && (e.currentTarget.files[0].size / 1024) / 1024 > Number(MAXFILESIZE)) {
			setErrors({ message: "File maximum size " + MAXFILESIZE + " MB" });
			e.target.value = null;
		}
		e.preventDefault();
		if (isplans)
			setPlans([
				...plans.slice(0, index),
				{
					...plans[index],
					File: e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : '',
					FileName: e.currentTarget.files.length > 0 ? e.currentTarget.files[0].name : '',
				},
				...plans.slice(index + 1)
			]);
		else
			setProgresses([
				...progresses.slice(0, index),
				{
					...progresses[index],
					File: e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : '',
					FileName: e.currentTarget.files.length > 0 ? e.currentTarget.files[0].name : '',
				},
				...progresses.slice(index + 1)
			]);
	}

	function validateData() {
		let errrs = [] as any;
		let required = [] as any;
		setErrors({ message: '' });

		const filteredplan = plans.filter((plan) => {
            if(plan.txt || plan.FileName)
			{
				return plan;
			} 
		 }
		)
		if(filteredplan.length === 0)
		{
			required.push('plan');
		}
        
		const filteredprogress = progresses.filter((progresses) => {
            if(progresses.txt || progresses.FileName)
			{
				return progresses;
			} 
		 }
		)
		if(filteredprogress.length === 0)
		{
			required.push('progresses');
		}
		// plans.forEach((plan, index) => {
		// 	console.log(plan,'planssss')
		// 	if (plan.txt || plan.txt === '') {
		// 	    return;
		// 	}
		// 	required.push('plan');
		// });

		// progresses.forEach((progress, index) => {
		// 	console.log(progress,'progress')
		// 	if (progress.txt || progress.txt === '') {
		// 		return;
		// 	}
		// 	required.push('progress');
		// });

		if (!values.Problems || values.Problems.length === 0 || values.Problems === '') {
			//formErrors["FirstName"] = { cls: 'is-invalid', edited: true } ;
			//errrs.push('Plan');
			console.log(values.Problems,'values problemsss')
			required.push('Problems');
		}

		/*if (errrs.length == 0 && required.length == 0) {
			props.signupAction(values, props.history);
		} else {*/
		if (errrs.length > 0 || required.length >= 3) {
			var error: string = '';
			if (errrs.length > 1)
				error = errrs.join(', ') + " are invalid!";
			else if (errrs.length == 1)
				error = errrs[0] + " is invalid!";
			if (required.length >= 3)
				error = "Please enter at least any one of the field " + '\n\r';

			setErrors({ message: error });
			return false;
		}
		else
			return true;
	}

	function addPlan() {
		setErrors({ message: '' });
		setPlans([...plans, { Flag: "white" ,txt :''} as data]);
	}
	function removePlan(idx: number) {
		setErrors({ message: '' });
		const fplans = plans.filter((plan: any, index: number) => index != idx);
		setPlans([...fplans]);
	}
	function addProgress() {
		setErrors({ message: '' });
		setProgresses([...progresses, { Flag: "white",txt :''} as data]);
	}
	function removeProgress(idx: number) {
		setErrors({ message: '' });
		const fprogresses = progresses.filter((progress: any, index: number) => index != idx);
		setProgresses([...fprogresses]);
	}
	const saveData = async (event: any) => {
		event.preventDefault();
		const formData = new FormData();
		let plancount = 0;
		plans.forEach((plan, index) => {
			if(plan.txt === '' && plan.File === undefined )
			{
                return;
			}
			formData.append(`Plans[${plancount}].txt`, plan.txt ||'');
			formData.append(`Plans[${plancount}].Flag`, plan.Flag == "white" ? '' : plan.Flag);
			if (plan.File) {
				formData.append(`Plans[${plancount}].File`, plan.File);
			}
            plancount++;
		});
		let progresscount = 0;
		progresses.forEach((progress, index) => {
			if(progress.txt === '' && progress.File === undefined )
			{
				return;
			}
			formData.append(`Progresses[${progresscount}].txt`, progress.txt || '');
			formData.append(`Progresses[${progresscount}].Flag`, progress.Flag === "white" ? '' : progress.Flag);
			if (progress.File) {
				formData.append(`Progresses[${progresscount}].File`, progress.File);
			}
			progresscount ++;
		});
		if (validateData()) {
			setSaveInprogress(true);
			formData.append('EmployeeGuid', values.EmployeeGuid);
			if (values.Problems)
				formData.append('Problems', values.Problems);
			formData.append('Emoji', values.Emoji);

			dispatch(saveCheckin(formData) as any);
			setTimeout(() => {
				setSaveInprogress(false);
			}, 2000);
		}
	}

	useEffect(() => {

		if (user.success && user.success === "checkin success") {
			console.log("user.success", user.success)
			let toast = {
				type: 'Message',
				title: "Status",
				message: 'Checkin created successfully.'
			};
			dispatch(ShowToast(toast) as any);
			dispatch(removeSuccessStatus() as any);
			setValues({ EmployeeGuid: empId, Emoji: 'https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png' } as Checkin);
			setPlans([{ Flag: "white" } as data]);
			setProgresses([{ Flag: "white" } as data]);
		}
	}, [user.success]);

	useEffect(() => {
		setErrors({ message: user.error?.message });
		console.log("reporting is render are not", parentReportingList.length)
	}, [user.error, parentReportingList]);

	const removeExistingFile = (plan: data, index : number, fieldType: 'plans' | 'progresses') => {
		
		if (fieldType === 'plans') {
			
		const updatedPlans = plans.map((item, idx) => {
			if (idx === index && item.FileName === plan.FileName) {
				return { ...item, FileName: "" ,File: null}; // Clear the FileName for the matching item
			  }
		  return item;
		});
	  
		setPlans(updatedPlans);}
		
		if (fieldType === 'progresses') {
		const updatedProgresses = progresses.map((item, idx) => {
			if (idx === index && item.FileName === plan.FileName) {
				return { ...item, FileName: "",File: null }; // Clear the FileName for the matching item
			  }
		  return item;
		});
	  
		setProgresses(updatedProgresses);}

		if (fieldType === 'plans') {
		const planFileInput = document.getElementById(`Plan_${index}`) as HTMLInputElement;
  		if (planFileInput) {
   		 planFileInput.value = ''; // Reset the Plan file input value
  		}}

		// Reset file input element
		if (fieldType === 'progresses') {
		const fileInput = document.getElementById(`Progress_${index}`) as HTMLInputElement;
		if (fileInput) {
		  fileInput.value = ''; // Reset the file input value
		}}
	  };
	  
	return (
		<section className="bgCadet position-relative d-flex pt-4 pb-4 minh-100">
			<div className="col-sm-8 popup-settings ml-auto">
				<CheckinsRightMenu path="checkins" ReportingListMenu={setParentReportingList} />
				{errors.message && errormsg}
				<div className="row">
					<div className="col-sm-10 m-auto popup-settings">
					<Link to="/pod" className="mb-2 mb-block d-none">
                    <svg enable-background="new 0 0 64 64" height="40" viewBox="0 0 64 64" width="40" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path></svg>
                </Link>
						<div className="card loginBox addTeamMemberBox mt-5 mb-5 mobile-mr">
							<div className="card-body pl-5 pr-5">
								<div className="col-12 p-0 d-flex mb-3">
                                <span className="tab-btn-parent mb-block d-none">
                                {/* <Link to="/checkins">
                                        <button type="button" className="btn btn-tab mb-block d-none active mr-2">Check-Ins</button>
                                    </Link> */}
                                    <Link to="/CheckinHistory">
                                        <button type="button" className="btn btn-tab mr-2">Check-In history</button>
                                    </Link>
                                    <Link to="/TeamCheckinHistory">
                                        <button type="button" className="btn btn-tab">Team check-ins</button>
                                    </Link>
                                </span>
                            </div>
								<div className="row m-auto">
									<div className="col-6 text-white pl-0">
										<h5>Add Check-In</h5>
									</div>
									{/* {parentReportingList.length > 0 && (
										<>
											<div className="col-2 mb-4 mt-4 text-white pl-0">
												<p>Access By:</p>
											</div>
											<div className="col-4 mb-4 mt-4 text-white pl-0">
												<h6>{parentReportingList}</h6>
											</div>
										</>
									)} */}

									{(user?.empData?.ReportingName?.trim().length > 0)?<>
											<div className="col-6 mb-4 mt-4 text-white pl-0">
												<h6>Access By: {user?.empData?.ReportingName}</h6>
											</div>
										</>:<></>}
									<div className="col-12  text-white pl-0 mt-2">
										<h6>How do you feel? <span className="mr-2 c-pointer" onClick={() => setOpenEmoji('emoji')}><img width="25px" src={values.Emoji} /></span>
										</h6>
										<h6 className="mt-3">Plans and objectives:</h6>
									</div>
									{(plans || []).map((plan: data, index: number) => {
										return (
											<div className="col-12 row m-0 p-0 mt-2">
												<div className="col-8 pl-0">
													<input name="Plan" value={plan.txt || ''} onChange={handleChangeData(plan)} required maxLength={100} type="text" className="checkin-input SuteInputPrimary h-100"  />

												</div>
												<div className="col-1">
													<button type="button" className="upload-btn">
														<span className="ml-1"><span><svg className="fontsize-26" stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15.534 4.466c1.024 0 2.05.39 2.829 1.169 1.561 1.561 1.561 4.098 0 5.656l-7.071 7.072c-.778.779-1.804 1.17-2.828 1.17s-2.049-.391-2.828-1.17c-1.56-1.559-1.56-4.098 0-5.656l.807-.807c-.004.805.25 1.524.701 2.125l-.094.096c-.78.779-.78 2.049 0 2.828.39.39.901.584 1.414.584s1.024-.195 1.414-.584l2.535-2.535 4.537-4.537c.778-.779.778-2.049 0-2.828-.392-.39-.904-.584-1.417-.584-.512 0-1.023.195-1.413.584l-4.535 4.537c-.128.127-.146.275-.146.354 0 .076.019.226.146.353.099.099.228.147.356.147.127 0 .254-.049.352-.146l2.122-2.121 1.414-1.414c.392.392.586.902.586 1.414 0 .511-.194 1.021-.584 1.41l-2.124 2.125c-.486.487-1.127.729-1.768.729s-1.28-.244-1.769-.729c-.472-.474-.731-1.101-.731-1.769 0-.67.261-1.297.732-1.77l4.534-4.535c.779-.779 1.805-1.168 2.829-1.168m0-2c-1.604 0-3.11.623-4.242 1.755l-7.069 7.073c-1.133 1.131-1.757 2.638-1.757 4.242s.624 3.11 1.757 4.243c1.131 1.132 2.639 1.755 4.241 1.755s3.11-.624 4.242-1.757l7.071-7.071c1.133-1.131 1.757-2.638 1.757-4.242 0-1.603-.623-3.11-1.755-4.241-1.133-1.134-2.64-1.757-4.245-1.757z"></path></svg></span>
														<input type="file" className="inputWithPhoto" name={"Plan_" + index} id={"Plan_" + index} onChange={(e) => fileSelectHandler(e, index, true)} onClick={(e: any) => e.target.value = null}/></span></button>
												</div>
												<div className="col-1 mt-auto mb-auto">
													<button type="button" className="upload-btn">
														<span className={`flag-bubble ${plan.Flag}`} width="25px" height="25px" onClick={() => setOpenFlag('planflag' + index)} fill={plan.Flag} 
														
														></span>
													</button>
												</div>
												{plans.length > 1 ?
													<div className="col-1 pl-0">
														<button onClick={() => removePlan(index)} type="button" className="upload-btn plus-btn"><span><FaMinus /></span></button>
													</div> : ''
												}
												{index == plans.length - 1 ?
													<div className="col-1 pl-0">
														<button onClick={addPlan} type="button" className="upload-btn plus-btn"><span><FaPlus /></span></button>
													</div> : ''
												}
												 {plan.FileName  && <div className="col-12 p-0">
													<span className="file-upload-text mt-2">{plan.FileName}
													  <span onClick={() => removeExistingFile(plan,index,"plans")}>
														<svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path></svg>
													  </span>
													</span>
												</div>}
											</div>
										)
									})}
									<div className="col-12  text-white pl-0 mt-2">
										<h6 className="mt-2">Progress updates:</h6>
									</div>
									{(progresses || []).map((progress: data, index: number) => {
										return (
											<div className="col-12 row m-0 p-0 mt-2">
												<div className="col-8 pl-0">
													<input name="Progress" value={progress.txt || ''} onChange={handleChangeData(progress)} maxLength={100} type="text" className="checkin-input SuteInputPrimary h-100" />
												</div>
												<div className="col-1">
													<button type="button" className="upload-btn"><span className="ml-1">
													<span><svg className="fontsize-26" stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15.534 4.466c1.024 0 2.05.39 2.829 1.169 1.561 1.561 1.561 4.098 0 5.656l-7.071 7.072c-.778.779-1.804 1.17-2.828 1.17s-2.049-.391-2.828-1.17c-1.56-1.559-1.56-4.098 0-5.656l.807-.807c-.004.805.25 1.524.701 2.125l-.094.096c-.78.779-.78 2.049 0 2.828.39.39.901.584 1.414.584s1.024-.195 1.414-.584l2.535-2.535 4.537-4.537c.778-.779.778-2.049 0-2.828-.392-.39-.904-.584-1.417-.584-.512 0-1.023.195-1.413.584l-4.535 4.537c-.128.127-.146.275-.146.354 0 .076.019.226.146.353.099.099.228.147.356.147.127 0 .254-.049.352-.146l2.122-2.121 1.414-1.414c.392.392.586.902.586 1.414 0 .511-.194 1.021-.584 1.41l-2.124 2.125c-.486.487-1.127.729-1.768.729s-1.28-.244-1.769-.729c-.472-.474-.731-1.101-.731-1.769 0-.67.261-1.297.732-1.77l4.534-4.535c.779-.779 1.805-1.168 2.829-1.168m0-2c-1.604 0-3.11.623-4.242 1.755l-7.069 7.073c-1.133 1.131-1.757 2.638-1.757 4.242s.624 3.11 1.757 4.243c1.131 1.132 2.639 1.755 4.241 1.755s3.11-.624 4.242-1.757l7.071-7.071c1.133-1.131 1.757-2.638 1.757-4.242 0-1.603-.623-3.11-1.755-4.241-1.133-1.134-2.64-1.757-4.245-1.757z"></path></svg></span>
														<input type="file" className="inputWithPhoto" name="Progress_" id={'Progress_' + index} onChange={(e) => fileSelectHandler(e, index, false)} onClick={(e: any) => e.target.value = null}/></span></button>
												</div>
												<div className="col-1 mt-auto mb-auto">
													<button type="button" className="upload-btn">
														{/* <span>+</span> */}
														<span className={`flag-bubble ${progress.Flag}`} width="25px" height="25px" onClick={() => setOpenFlag('progressflag' + index)} fill={progress.Flag} 
														//style={{backgroundColor:`${progress.Flag}`}}
														></span>
													</button>
												</div>
												{progresses.length > 1 ?
													<div className="col-1 pl-0">
														<button onClick={() => removeProgress(index)} type="button" className="upload-btn plus-btn"><span><FaMinus /></span></button>
													</div> : ''
												}
												{index == progresses.length - 1 ?
													<div className="col-1 pl-0">
														<button onClick={addProgress} type="button" className="upload-btn plus-btn"><span><FaPlus /></span></button>
													</div> : ''
												}
												{progress.FileName  && <div className="col-12 p-0">
													<span className="file-upload-text mt-2">{progress.FileName}
														<span onClick={() => removeExistingFile(progress,index,"progresses")}>
																<svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path></svg>
														</span>
												    </span>
												</div>}
											</div>
										)
									})}
									<div className="col-12  text-white pl-0 mt-2">
										<h6 className="mt-2">Questions or concerns:</h6>
									</div>
									<div className="col-12 row m-0 p-0">
										<div className="col-12 pl-0">
											<input name="Problems" value={values.Problems || ''} onChange={handleChange} maxLength={100} type="text" className="checkin-input SuteInputPrimary"  style={{ height: '35px' }} />
										</div>
									</div>
								</div>
								<div className="form-group mt-3">
									<button onClick={saveData} type='button' disabled={isSaveInprogress} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-left  mt-2 mb-2">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={openEmoji != '' ? true : false} onHide={handleEmojiClose}>
				<Modal.Header closeButton className="border-0">
					<Modal.Title className="modal-title h5 text-white">Select Emoji</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-12">
						 <ul className="emojiUL">
							{emojis.map((emoji: any) => {
								return (
									<li  className={emoji.image === values.Emoji ? "active" : ''} onClick={function () { handleSelectEmoji(emoji.image) }}>
										<img className="emojiSingle" src={emoji.image} title={emoji.title} />
									</li>
								)
							})}
						</ul> 						                       
					</div>
				</Modal.Body>
				<Modal.Footer className="border-0">
					<button type="button" style={{ width: '80px' }} className="btn btn-SeaGreen" onClick={handleEmojiClose}>Cancel</button>
				</Modal.Footer>
			</Modal>
			<Modal show={openFlag != '' ? true : false} onHide={handleFlagClose} size="sm"  backdrop="static"  keyboard={false}>
				<Modal.Header closeButton className="border-0">
					<Modal.Title className="modal-title text-light small">Select Flag</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<div className="col-12 text-center">
					<div className="emoji-buttons">
						{flags.map((flag: any, index: number) => {
						// Array of static classes for the buttons
						const staticClasses = ['bubble-red', 'bubble-orange', 'bubble-yellow', 'bubble-green'];
						
						// Get the class for the current button (ensure it matches the length of flags)
						const staticClass = staticClasses[index % staticClasses.length]; // Ensure loop if more flags than classes

						// Determine if the flag is active
						const isActive =
							flag === plans[Number(openFlag.replace('planflag', ''))]?.Flag ||
							flag === progresses[Number(openFlag.replace('progressflag', ''))]?.Flag;

							let filterindex : any;
							let filterflag : any;
							if(openFlag === 'planflag'+Number(openFlag.replace('planflag', '')))
							{
								filterindex = Number(openFlag.replace('planflag', ''));
							    filterflag = plans[filterindex]?.Flag;
							}
							else
							{
								filterindex = Number(openFlag.replace('progressflag', ''))
								filterflag = progresses[filterindex]?.Flag;
							}
							
							const filteredColor = staticClasses.filter( sc => sc === filterflag)
							//const isFlagInStaticClasses = staticClasses.some(staticClass => staticClass === flag);		
						   // const isSelected = staticClass ===  fla;
							const isSelected = staticClass === filteredColor.toString();
						//const isSelected = staticClass === selectedFlag;
						return (
							<button
							key={flag} // Unique key for each button
							className={`flag-bubble-popup ${staticClass} ${isActive || isSelected ? 'flag-bubble-popup-select' : ''}`} // Add active/selected class // Combine static and dynamic classes
							onClick={() => handleSelectFlag(staticClass)}
							>				
							</button>
						);
						})}
					</div> 
				</div>
				</Modal.Body>
				<Modal.Footer className="border-0">
					<button type="button" style={{ width: '80px' }} className="btn btn-secondary btn-sm" onClick={handleFlagClose}>Cancel</button>
				</Modal.Footer>
			</Modal>
		</section>
	);
}


export default Checkins;