import React, { useEffect, useState } from 'react';
import ChatTranscrit from "../pages/myoffice/ChatTranscrit";
import ChatInputPanel from '../pages/myoffice/ChatInputPanel';
//import { chatClient } from "../services/chat-api";
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../store/Store';
import { StoreMsg, SaveChat, GetMessagesListForGroup, UpdateAsSeen, saveFilesForUser, GetUnseenGroupMessagesCount, SaveGuestChat } from '../services/ChatServices';
import { v4 as uuidv4 } from 'uuid';
import SuteFilesPopup from './SuteFilesPopup';
import emojiRegex from 'emoji-regex';
//import { suteClient } from '../services/sute-rtc-api';
////import File from '../pages/myoffice/Files';
////import SuteFilesPopup from '../components/SuteFilesPopup';
function getGuid() {
    return uuidv4();
}
function GroupChatComponent(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [value, setValue] = useState<string>('');
    const [messages, setMessages] = useState<any[]>([]);
    const [typingUser, setTypingUser] = useState('');
    const [msgSound] = useState(new Audio('/assets/audios/microbounce.mp3'));
    const empId = localStorage.getItem('EmpGuid') || '';
    const userDisplayName = localStorage.getItem('FirstName');
    const [canPublish, setCanPublish] = useState(true);// user typing publish
    const [saveFile, setSaveFile] = useState({} as File);
    const [scrollEnd, setScrollEnd] = useState(0);
    const dispatch = useDispatch();
    const reqGetmore = React.useRef(true);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [ssFiles, setSsFiles] = useState<any[]>([]);//sute selected files
    const [displayFiles, setDisplayFiles] = useState<any[]>([]);
    const sendingFiles = React.useRef(false);
    const [openFilesPopup, setOpenFilesPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [postMsgsInt, setPostMsgsInt] = useState<any>(0);

    useEffect(() => {
        if (user.success && user.success === "Save message") {
            console.log('#TEST user.success ')
            if (user && user.Socket && user.Socket.connected) {
                console.log('#TEST GroupMessage ')
                user.Socket?.emit("GroupMessage", props.teamId);
            }
            else
                console.log('#TEST NO SOCKET ')
            user.success = ""
        }
    }, [user.success]);

    useEffect(() => {

        const prevMsg = user.messages[props.teamId];
        setLoading(false);
        if (prevMsg && prevMsg.length > 0) {
            //  if(messages.length != prevMsg.length) 
            setMessages([...prevMsg]);
            reqGetmore.current && setScrollEnd(1 + scrollEnd);
            markAsSeen(prevMsg[prevMsg.length - 1].TimeStamp);
            // if (prevMsg.length < 50) {
            //     getMoreMessage(prevMsg[0].TimeStamp);
            // }
        }
        else {
            setMessages([]);
        }
        reqGetmore.current = true;
    }, [user.messages]);

    // useEffect(() => {
    //     const prevMsg = user.messages[props.selEmpGuid];
    //     if (prevMsg && prevMsg.length < 10) {
    //         getMoreMessage(prevMsg[0].TimeStamp);
    //     }
    // }, [props.openChat])

    const handleChange = (event: any) => { setValue(event.target.value) }

    const GetMegs = () => {
        dispatch(GetMessagesListForGroup(user.isAuthenticated ? empId : props.uid, props.teamId, 0, !user.isAuthenticated) as any);
    }

    useEffect(() => {
        if (!props.teamId) return;
        setMessages([]);
        const prevMsg = user.messages[props.teamId];
        setLoading(true);
        dispatch(GetMessagesListForGroup(user.isAuthenticated ? empId : props.uid, props.teamId, 0, !user.isAuthenticated) as any);
        setTimeout(() => {
            setLoading(false);
        }, 5000);
        if (user && user.Socket && user.Socket.connected) {
            user.Socket.on('userNewMessage', (UserId: any) => {
                console.log('#TEST userMessage got', UserId)
                GetMegs();
            })
        } else {
            if (!prevMsg || prevMsg.length == 0) {
                setLoading(true);
                setPostMsgsInt(setInterval(GetMegs, 5000))
               
            } else {
                setMessages([...prevMsg]);
                setScrollEnd(1 + scrollEnd);
            }
        }


        return () => {
            clearInterval(postMsgsInt);

        }
    }, [props.teamId]);

    const markAsSeen = (ts: string) => {
        const form = new FormData();
        form.append('TimeStamp', ts);
        form.append('ToGuid', user.isAuthenticated ? empId : props.uid);
        form.append('GroupGuid', props.teamId);
        if (user.isAuthenticated) {
            dispatch(UpdateAsSeen(form, props.teamId) as any);

           // dispatch(GetUnseenGroupMessagesCount(empId) as any);
        }
    }

    const emojiChange = (val: string) => {
        setValue(val);
    }

    const getstring = (msg: string) => {
        const regex = emojiRegex();
        regex.lastIndex = 0;
        const matches = msg.match(regex) || [];
        const emojis = matches.map((match) => match.trim());
        let native = '' as any
        let code = '';
        let idx = 0;
        emojis.forEach(emoji => {
            native = emoji as any
            code = native.codePointAt(0).toString(16);
            idx = msg.indexOf(native);
            if (idx == 0) {
                msg = '_EMOJ_' + code + '_EMOJ_' + msg.substring(idx + emoji.length);
            }
            else {
                msg = msg.substring(0, idx) + '_EMOJ_' + code + '_EMOJ_' + msg.substring(idx + emoji.length);
            }
        })
        return Linkify(msg);
    }

    const Linkify = (children: any) => {
        const words = children.split(' ')
        const formatedWords = words.map((w: any, i: any) => addMarkup(w))
        const html = formatedWords.join(' ')
        return html;
    }
    const isUrl = (word: any) => {
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
        return word.match(urlPattern)
    }
    const addMarkup = (word: any) => {
        return isUrl(word) ?
            `_LINK_${word}_LINK_` :
            word
    }
    const sendMessage = (msg: string) => {
        if (selectedFiles && selectedFiles.length > 0) {
            sendingFiles.current = true;
            uploadtoServer();
        } else {
            const msgId = getGuid();
            msg = getstring(msg);
            const jsonMsg: any = { text: msg, UUID: msgId };
            if (ssFiles && ssFiles.length > 0) {
                jsonMsg.files = ssFiles;
            }
            const msgStr = JSON.stringify(jsonMsg);
            //chatClient.sendChannelMessage(msgStr, props.teamId).then((retMsg: any) => {
            // if (!retMsg || retMsg === 'error') {
            //     console.log('error in sending message', chatClient._logined);
            //     return;
            // }
            const retMsg = { text: msg, messageType: "TEXT", SenderName: 'me', SenderGuid: empId, TimeStamp: Date.now().toString() };

            const form = new FormData();
            if (user.isAuthenticated) {
                form.append('Message', msgStr);
                form.append('GroupGuid', props.teamId);
                form.append('FromGuid', empId);
                form.append('TimeStamp', retMsg.TimeStamp);
                form.append('UUID', msgId);
                form.append('IsGroupChat', "true");
                dispatch(StoreMsg({ ...retMsg, SenderGuid: empId, UUID: msgId }, props.teamId) as any);
                dispatch(SaveChat(form) as any);
            } else {
                form.append('Message', msgStr);
                form.append('GroupGuid', props.teamId);
                //  form.append('FromGuid', empId);
                form.append('Fromid', props.uid);
                form.append('UUID', msgId);
                form.append('TimeStamp', retMsg.TimeStamp);
                form.append('IsGroupChat', "true");
                dispatch(StoreMsg({ ...retMsg, SenderGuid: props.uid, UUID: msgId }, props.teamId) as any);
                dispatch(SaveGuestChat(form) as any);
            }
            //  });
            setSsFiles([]);
            setValue('');
        }
    }

    useEffect(() => {
        if (user.filesResponse && sendingFiles.current) {
            const msgId = getGuid();
            const jsonMsg: any = { text: value, UUID: msgId };
            jsonMsg.files = user.filesResponse.map((f: any) => {
                return {
                    fileId: f.FileId,
                    url: f.FileURL,
                    filename: f.FileName
                }
            });
            const msgStr = JSON.stringify(jsonMsg);
            // chatClient.sendChannelMessage(msgStr, props.teamId).then((retMsg: any) => {
            const retMsg = { text: value, messageType: "TEXT", SenderName: 'me', SenderGuid: empId, TimeStamp: Date.now().toString() };

            const form = new FormData();
            form.append('Message', msgStr);
            form.append('GroupGuid', props.teamId);
            form.append('FromGuid', empId);
            form.append('TimeStamp', retMsg.TimeStamp);
            form.append('UUID', msgId);
            form.append('IsGroupChat', "true");
            dispatch(StoreMsg({ ...retMsg, SenderGuid: empId, UUID: msgId }, props.teamId) as any);
            dispatch(SaveChat(form) as any);
            //  });
            setValue('');
            sendingFiles.current = false;
        }
    }, [user.filesResponse]);

    const typingHandler = () => {
        if (canPublish) {
            //  chatClient.sendNotification(props.teamId || '', userDisplayName || '');
            setCanPublish(false);
            setTimeout(() => {
                setCanPublish(true);
            }, 2000);
        }
    }
    const getMoreMessage = (ts: any) => {
        console.log('timestapm to get more messages', ts);
        reqGetmore.current = false;
        // dispatch(GetMessagesListForGroup(user.isAuthenticated ?  empId : props.uid, props.teamId, ts,!user.isAuthenticated) as any);
        setSelectedFiles([]);
        setDisplayFiles([]);
    }
    const uploadtoServer = () => {
        const form = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            form.append(`file`, selectedFiles[i], selectedFiles[i].name);
        }
        form.append('employeeGuid', empId);
        dispatch(saveFilesForUser(form) as any);
    }
    function onUpload(files: any) {      
        files.forEach((f: any) => {
            sendMessage('_MEDIA_' + f + '_MEDIA_');
        });
    }
    function removeFile(fn: string, sute: boolean) {
        if (!sute) {
            const filFiles = selectedFiles.filter((sf: any) => sf.name !== fn);
            setSelectedFiles([...filFiles]);
            const afiles = filFiles.map((f: any) => {
                return { fileId: f.name, fileName: f.name, sute: false }
            })
            setDisplayFiles(afiles);
        } else {
            const filFiles = ssFiles.filter((sf: any) => sf.filename !== fn);
            setSsFiles(filFiles);
            const afiles = filFiles.map((f: any) => {
                return { fileId: f.fileId, fileName: f.filename, sute: true }
            })
            setDisplayFiles(afiles);
        }
    }
    const suteSelectFiles = (sfiles: any) => {
        setSsFiles(sfiles);
        const afiles = sfiles.map((f: any) => {
            console.log(' SUTEFiles', f.url)
            sendMessage('_MEDIA_' + f.url + '_MEDIA_');
            //  return { fileId: f.fileId, fileName: f.filename, sute: true }
        })
        //  setDisplayFiles(afiles);
    }
    const handleFilePopup = () => {
        setOpenFilesPopup(!openFilesPopup);
    }

    return (<>
        {props.openChat ? <div className={"SideNav text-left"} id="ChattingSideNav">
            <div className="sideNavHeader">
                <div className="CloseNav" >
                    <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" onClick={props.closeChat} />
                </div>
            </div>
            <div className="sideNavBody">
                <div className="row mt-4">
                    <div className="col-12 mb-2 text-light">
                        {props.teamName} </div>
                </div>
                {!loading ? <> <ChatTranscrit members={user.contacts} messages={messages} typingUser={typingUser}
                    getmore={(ts: any) => { getMoreMessage(ts) }} scrollEnd={scrollEnd} peerId={props.teamId} uid={props.uid} userName={props.userName} />
                    <ChatInputPanel
                        value={value}
                        sendMessage={sendMessage}
                        handleChange2={emojiChange}
                        handleChange={handleChange} typing={typingHandler}
                        selectedFiles={displayFiles}
                        onUpload={onUpload} removeFile={(fn: any, sute: boolean) => removeFile(fn, sute)}
                        handleFilePopup={handleFilePopup} /></> : <div className="row mt-4">
                    <div className="col-12 mb-2 spinDiv"><br /><br /><svg className="spinLoad2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="45" height="45"><path d="M8 1V.5H7V1h1zM7 4.5V5h1v-.5H7zm1 6V10H7v.5h1zM7 14v.5h1V14H7zM4.5 7.995H5v-1h-.5v1zm-3.5-1H.5v1H1v-1zM14 8h.5V7H14v1zm-3.5-1.005H10v1h.5v-1zM7 1v3.5h1V1H7zm0 9.5V14h1v-3.5H7zM4.5 6.995H1v1h3.5v-1zM14 7l-3.5-.005v1L14 8V7zM2.147 2.854l3 3 .708-.708-3-3-.708.708zm10-.708l-3 3 .708.708 3-3-.708-.708zM2.854 12.854l3-3-.708-.708-3 3 .708.708zm6.292-3l3 3 .708-.708-3-3-.708.708z" fill="currentColor"></path></svg></div></div>}
                {openFilesPopup ? <SuteFilesPopup onSubmit={suteSelectFiles} show={openFilesPopup} close={handleFilePopup} /> : ''}
            </div>
            <div className="sideNavFooter d-none">
                <div className="col-12 text-right mt-4">
                    <img className="c-pointer" src="/assets/media/svg/settings.svg" />
                </div>
            </div>
        </div> : ''}</>
    );
}

export default GroupChatComponent;