import React, { useEffect, useState } from 'react'
//import {createRoom, getOrgRooms} from '../../services/RoomsService';
import { useDispatch, useSelector } from 'react-redux';
import { globalStore } from '../../store/global_store';
import { removeSuccessStatus, createGroup, getGroupsByEmpId } from "../../services/ContactsService";
import { StoreClass } from '../../store/Store';
import { t } from '../../i18n';




function CreateRoom(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [values, setValues] = useState<any>({"Name":'', "Description": '', "Type": props.defaultPrivate, Color: "#30D5C8" });
    const orgId = localStorage.getItem('OrgGuid') as string;
    const empId = localStorage.getItem('EmpGuid') as string;
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({ Name: '', Description: '' });
 
    useEffect(() => {
        if (user.success && user.success === 'SaveGroupWithEmp') {
            globalStore.showToast({
                type: 'message',
                title: "New Room",
                message: "New Room created successfully"
            });
            dispatch(removeSuccessStatus() as any);
            dispatch(getGroupsByEmpId(empId) as any);
            props.close();
        }
    }, [user.success]);


    useEffect(() => {
        if (user.error && user.error.msgType === 'RoomCreateError') {
            globalStore.showToast({
                type: 'Error',
                title: "Room Creation",
                //message: "user.error.message"
                message: 'Another room with same name already exists.'
            });
            dispatch(removeSuccessStatus() as any);
        }
    }, [user.error]);

    const selectColor = (code: string) => {
        setValues({ ...values, Color: code });
    }


    const handleChange = (e: any) => {
        e.target.value=e.target.value.trimStart();
        e.persist();
         const { name, value } = e.target;

         if(name === 'Name')
            setErrors({...errors,Name:''})

        // if (name === 'Name') {
        //     if (!value.trim()) {
        //         setErrors({ ...errors, Name: 'Enter a valid Room Name' });
        //     } else {
        //         setErrors({ ...errors, Name: '' });
        //     }
        // }

        // console.log("Name:", name);
        // console.log("Value:", value);

        // if (name === 'Description') {
        //     if (!value.trim()) {
        //         setErrors({ ...errors, Description: 'Please enter a Description' });
        //     } else {
        //         setErrors({ ...errors, Description: '' });
        //     }
        // }
    
        setValues((prevValues: any) => ({
            ...prevValues,
            [name]: value,
        }));       
    };
   
     
    const createRoom = () => {
        
        if(values.Name === '')
        {
            setErrors({ ...errors, Name: 'Enter a valid Room Name' });
            return;
        }
        if (values.Name.length === 0) {
            console.log("Name or Description is empty. Data will not be dispatched.");
            return; // Exit the function early
        }
    
        console.log("getting data Description", values.Description, values.Name);
    
        const form = new FormData();
        form.append("GroupName", values.Name);
        form.append("description", values.Description);
        form.append("IsPrivate", values.Type);
        form.append("Color", values.Color);
        form.append("OrgGuid", orgId);
        form.append("empGuid", empId);
    
        if (localStorage.getItem("msAccessToken")) {
            form.append("teamsAccessToken", localStorage.getItem("msAccessToken") as string);
        }
    
        dispatch(createGroup(form) as any);
        setValues({ "Name":'',"Description": '', "Type": props.defaultPrivate, Color: "#30D5C8" });
        //Clear any error messages related to Name and Description
        setErrors({ Name: '', Description: '' });

    }
    

    const handleClose = () => {
        props.close();
        // Clear the Name and Description fields by resetting the values state
        setValues({ "Name":'',"Description": '', "Type": props.defaultPrivate, Color: "#30D5C8" });
        // Clear any error messages related to Name and Description
        setErrors({ Name: '', Description: '' });

    }

    return (<>{props.show ?
        <div className="SideNav z-index-h">
            <div className="sideNavHeader">
                <div className="CloseNav" > <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('title.close')} onClick={handleClose}/> </div>
            </div>
            <div className="sideNavBody">
                <div className="row mt-4">
                    <div className="col-12 mb-2 text-light">
                        <svg onClick={props.close} enableBackground="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                            <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" />
                        </svg> {t('createroom.create_room')}</div>
                </div>
                <div className="row mt-3">

                    <div className="col-12 mb-2">
                        <p className="text-muted m-0"> {t('createroom.room_name')}</p>
                        <input type="text" className="SuteInputPrimary fs12" name="Name" onChange={(e) => handleChange(e)}  />
                        {errors.Name && <span className="text-danger">{errors.Name}</span>}
                    </div>
                    <div className="col-12 mb-2 position-relative">
                        <p className="text-muted m-0">{t('title.description')}</p>
                        <textarea maxLength={150} onChange={(e) => handleChange(e)} name="Description" className="SuteInputPrimary fs12" style={{ "height": "80px" }}></textarea>
                        {errors.Description && <span className="text-danger">{errors.Description}</span>}
                    </div>
                    {/* <div className="col-12 mb-2">
                        <button type="button" id="public" onClick={e => onChangeType(e, false)} className={'btn btn-sm mr-2 ' + (!values.Type ? 'btn-SeaGreen' : 'btn-outline-success')}>{t('button.public')}</button>
                        <button type="button" id="Private" onClick={e => onChangeType(e, true)} className={'btn btn-sm mr-2 ' + (values.Type ? 'btn-SeaGreen' : 'btn-outline-success')}>{t('button.private')}</button>
                    </div> */}
                    {/* <div className="col-12 mb-2">
                        <p className="text-muted m-0">{t('createroom.background_color')}</p>
                        <ul className="roomBGcolors">
                            <li onClick={() => { selectColor("#30D5C8") }} className={`${values.Color === '#30D5C8' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG1.svg" /></li>
                            <li onClick={() => { selectColor("#a58a62") }} className={`${values.Color === '#a58a62' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG2.svg" /></li>
                            <li onClick={() => { selectColor("#a56262") }} className={`${values.Color === '#a56262' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG3.svg" /></li>
                            <li onClick={() => { selectColor("#9da562") }} className={`${values.Color === '#9da562' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG4.svg" /></li>
                            <li onClick={() => { selectColor("#9662a5") }} className={`${values.Color === '#9662a5' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG5.svg" /></li>
                        </ul>
                    </div> */}
                    <div className="col-12 mb-2 mt-1">
                        <button onClick={createRoom} className="btn-SeaGreen btn-block btn-sm btn fw600 fs12">{t('createroom.create_room')}</button>
                    </div>
                </div>
             </div>
            {/* <div className="sideNavFooter">
                <div className="col-12 text-right mt-4"> <img className="c-pointer" src="/assets/media/svg/settings.svg" /> </div>
            </div> */}
            {/* <div className="sideNavFooter" onClick={handleSettingsClick}>
                <div className="col-12 text-right mt-4">
                    <img className="c-pointer" src="/assets/media/svg/settings.svg" alt="Settings" />
                </div>
            </div>
            {editPopup && <RoomEdit  close={() => setEditPopup(false)} />} */}
        </div> : ""}</>
    );
}

export default CreateRoom;
