import { Table } from "../../components/Table";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import ConfirmPopup from "../../layout/ConfirmPopup";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowToast, getOrgGroups, getEmpDetailsByEmpGuid, DeleteOrgGroups, SaveGroups } from "../../services/UserActions";
import { StoreClass } from "../../store/Store";
import { Modal } from "react-bootstrap";
import * as T from "../../actions/Types";


var deleteText: string = '';

function Groups() {
    const [GroupList, setGroupList] = useState([]);
    const [groupTableParams, setgroupTableParams] = useState(new FilesTableBody());
    const dispatch = useDispatch();
    const [showNew, setShowNew] = useState(false);
    const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
    const [apiStatus, setApiStatus] = useState('');
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const [groups, setGroups] = useState<any>([]);
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const defaultGroup = { 'BatchName': '', 'BatchGroupId': 0, 'Description': '', 'OrgId': 0, "IsDeleted": false }
    const [sltGroup, setSltGroup] = useState(defaultGroup);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isError, setIsError] = useState(false);
    const [isSaveInprogress, setSaveInprogress] = useState(false);

    useEffect(() => {
        if (!user.empData.EmpGuid || user.empData.EmpGuid === '') {
            dispatch(getEmpDetailsByEmpGuid(empGuid) as any);
        }

    }, [])

    useEffect(() => {
        if (user.empData.OrgId || user.empData.OrgId > 0) {
            setSltGroup({
                ...sltGroup,
                OrgId: user.empData.OrgId
            });
            getOrgGroups(user.empData.OrgId).then((groups) => {
                setGroups(groups);
            });
        }
    }, [user.empData])
    useEffect(() => {
        if (groups.length > 0) {
            groups.forEach((grp: any, i: any) => {
                grp.TotalCount = groups.length, grp.RowNumber = i + 1
            });

            // setGroupList(groups.filter((grp: any) =>
            //     (grp.RowNumber <= 8)));
            setGroupList(groups?.filter((grp: any) =>
                grp.RowNumber > ((groupTableParams.PageNo - 1) * groupTableParams.PageSize)
                && grp.RowNumber <= (groupTableParams.PageNo * groupTableParams.PageSize))
            );
        }
        else
            setGroupList(groups);
    }, [groups])


    useEffect(() => {
        // props.getEmpDetailsList(filesTableParams, empGuid);
        if (groups.length > 0) {
            const grps = groups.filter((grp: any) =>
                //(GroupList && props.user.empData.UserTypeId == 3 &&  emp.OrgName.toLowerCase().includes(filesTableParams.Search.toLowerCase())) ||
                grp.BatchName.toLowerCase().includes(groupTableParams.Search.toLowerCase()) ||
                grp.Description.toLowerCase().includes(groupTableParams.Search.toLowerCase()) ||
              
                (grp.IsDeleted ?'inactive'.startsWith(groupTableParams.Search.toLowerCase()): 'live'.startsWith(groupTableParams.Search.toLowerCase()))
            );

            grps.forEach((grp: any, i: any) => {
                grp.TotalCount = grps.length, grp.RowNumber = i + 1
            });

            setGroupList(grps.filter((grp: any) =>
                grp.RowNumber > ((groupTableParams.PageNo - 1) * groupTableParams.PageSize)
                && grp.RowNumber <= (groupTableParams.PageNo * groupTableParams.PageSize))
            );
        }
    }, [groupTableParams])


    const editEmployee = (e: any, row: any) => {
        setSltGroup(row);
        setShowNew(true);
    }

    function CreateGroup() {
        setSltGroup(defaultGroup);
        setShowNew(true);
    }

    const handleShowDeleteUser = (e: any, grp: any) => {
        deleteText = grp.IsDeleted ? "Live" : "Inactive";
        setSltGroup(grp);
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }

    const handleCloseDeleteUser = () => {
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }
    const deleteUser = () => {
        DeleteOrgGroups(sltGroup.BatchGroupId, !sltGroup.IsDeleted).then(() => {
            dispatch(ShowToast({ type: 'Message', title: "Status", message: sltGroup.IsDeleted ? 'Live team successfully' : "Inactived team successfully" }) as any);

            getOrgGroups(user.empData.OrgId).then((groups) => {
                setGroups(groups);
            });
        });
        setShowConfirmDeleteUser(!showConfirmDeleteUser);
    }

    // const handleDesc = (e: any) => {
    //     //console.log(e.target.name, e.target.value);
    //     //e.persist();
    //     setSltGroup({
    //         ...sltGroup, // Copy the old fields
    //         Description: e.target.value // But override this one
    //     });

    // }

    const handleDesc = (e: any) => {
        const inputText = e.target.value;

        // Check if the input exceeds 200 characters
        if (inputText.length <= 200) {
            setSltGroup({
                ...sltGroup,
                Description: inputText
            });
        } else {
            // If the input exceeds 200 characters, truncate it
            const truncatedText = inputText.slice(0, 200);
            setSltGroup({
                ...sltGroup,
                Description: truncatedText
            });
        }
    }

    const handleChange = (e: any) => {
        e.target.value = e.target.value.trimStart();
        console.log(e.target.name, e.target.value);
        //e.persist();
        let value = e.target.value;
        setSltGroup({
            ...sltGroup, // Copy the old fields
            BatchName: e.target.value // But override this one
        });

        const validFirstname = value.match(/^([A-Za-z ])+$/);
        setFormErrors({ ...formErrors, 'GroupName': { cls: validFirstname ? 'is-valid' : 'is-invalid', edited: true } });
    }

    const SubmitHandler = (e: any) => {
        e.preventDefault();
        setSaveInprogress(true);
        let errors = [];

        setApiStatus('');

        if (!sltGroup.BatchName || sltGroup.BatchName.length === 0) {
            formErrors["BatchName"] = { cls: 'is-invalid', edited: true };
            errors.push('Group Name');
        }

        if (errors.length === 0) {
            dispatch({ type: T.ADD_LOADING }); // Start loading

            SaveGroups({
                "BatchName": sltGroup.BatchName,
                "Description": sltGroup.Description,
                "BatchGroupId": sltGroup.BatchGroupId,
                "OrgId": user.empData.OrgId
            }).then(() => {
                dispatch({ type: T.REMOVE_LOADING }); // Stop loading

                dispatch(ShowToast({
                    type: 'Message',
                    title: "Saved team",
                    message: sltGroup.BatchGroupId > 0 ? " updated team successfully" : "saved team successfully"
                }) as any);

                getOrgGroups(user.empData.OrgId).then((groups) => {
                    setGroups(groups);
                });

                setShowNew(false);
                setSaveInprogress(false);

            }).catch(error => {
                dispatch({ type: T.REMOVE_LOADING }); // Stop loading on error
                setSaveInprogress(false);

                // Handle error
            });
        } else {
            var error: string = '';
            error = errors[0] + " is invalid!";
            setApiStatus(error);
            setIsError(true);
            setSaveInprogress(false);
        }
    }


    function headerRender() {
        return (
            <tr>
                <th>Team name</th>
                <th>Description</th>
                <th>Status</th>
                <th className="mobile-none"></th>
            </tr>);
    }
    const handleClose = () => setShowNew(false);
    function rowRender(row: any, index: number) {
        return (<tr key={index}>
            <td style={{ width: 180 }}>{row.BatchName}</td>
            <td>{row.Description}</td>
            <td style={{ width: 80 }}>{row.IsDeleted ? "Inactive" : "Live"}</td>
            <td className="text-right dropdown mobile-none" >
                <svg id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                </svg>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    {!row.IsDeleted ? <a onClick={e => editEmployee(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Edit</a> : null}
                    <a onClick={e => handleShowDeleteUser(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> {row.IsDeleted ? "Live" : "Inactive"}</a>
                </div>
            </td>
        </tr>);
    }

    return (<>
        <ConfirmPopup show={showConfirmDeleteUser} close={handleCloseDeleteUser} okHandle={deleteUser} message={"Do you want to " + deleteText.toLowerCase() + " this team?"} />
        <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
            <div className="container-fluid">
                {apiStatus ? <p className="alert alert-danger col-2 ml-auto mr-auto mt-3 text-center">{apiStatus}</p> : ''}
                <div className="row mt-4 justify-content-center">
                    <Table
                        id='users'
                        data={GroupList || []}
                        headerRender={headerRender}
                        rowRender={rowRender}
                        onSearch={(value: string) => setgroupTableParams({ ...groupTableParams, Search: value || '' })}
                        onPageChange={(value: number) => setgroupTableParams({ ...groupTableParams, PageNo: value || 1 })}
                        createLabel={'Create team'}
                        rootUrl="/admin"
                        onCreate={() => CreateGroup()}
                        header={'Teams'}
                        subHeader=""
                        noDataMsg="No team found"
                    >
                    </Table>
                </div>
            </div>
            <Modal show={showNew} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="modal-title text-light">{sltGroup.BatchGroupId > 0 ? 'Edit Team' : 'Create team'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={SubmitHandler}>
                        <div className="card mt-2 loginBox">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="text-light m-0">{'Team name'} <sup>*</sup></label>
                                            <input value={sltGroup.BatchName || ''} onChange={handleChange} className={"form-control " + (formErrors['BatchName'] && formErrors['BatchName'].cls ? formErrors['BatchName'].cls : '')} type="text" name="GroupName" autoComplete="off" minLength={1} maxLength={50} required />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="text-light m-0">{'Description'}</label>
                                            <textarea className="form-control" value={sltGroup.Description} onChange={handleDesc} name="Desc" rows={4} />
                                        </div>
                                    </div>

                                </div>

                                {/* <label className="text-light medium m-0"  > NOTE: Group Name is mandatory</label> */}
                                <div className="form-group mt-4 text-center">
                                    <button disabled={isSaveInprogress} type="submit" className="btn btn-SeaGreen font-weight-bold">{'Save'}

                                    </button>

                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </section>


    </>
    );
}

export default Groups;

