import React, { useState, useEffect, useRef, Fragment } from 'react';
import { getGroupMems, removeFromGroup, removeSuccessStatus, getGroupsByEmpId, DeleteGroup, createTeamsMeetingLink } from "../../services/ContactsService";
import { connect } from 'react-redux';
import { globalStore } from '../../store/global_store';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from "../../store/Store";
import ConfirmPopup from '../../layout/ConfirmPopup';
import AddMemberPopup from './AddMemberPopup';
//import { chatClient } from '../../services/chat-api';
import * as moment from 'moment';
import { t } from '../../i18n';
import Calendar from 'react-calendar';
import GroupEvents from './GroupEvents';
import RoomEdit from '../rooms/RoomEdit';
import Moment from 'moment';
import GroupTimeSlotPopup from './GroupTimeSlot';

function formatDateTime(date: Date) {
    return moment.utc(date).local().format('DD/MM/YYYY');
}
const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer
}); ``

const mapDispatchToProps = (dispatch: any) => ({
    GetGroupMems: (stateobj: any) => {
        dispatch(getGroupMems(stateobj))
    }, GetGroups: (empId: any) => {
        dispatch(getGroupsByEmpId(empId));
    },
    RemoveFromGroup: (params: any) => {
        dispatch(removeFromGroup(params))
    }, removeSuccessStatus: () => {
        dispatch(removeSuccessStatus());
    }, DeleteGroup: (params: any) => {
        dispatch(DeleteGroup(params))
    }
});

function GroupFormInfo(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [teamMemCount, setTeamMemCount] = useState(0);
    const [showMembers, setTeamMembers] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showConfirmExit, setShowConfirmExit] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showRemoveMember,setShowRemoveMember] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const [editPopup, setEditPopup] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [removeMemberGuid,setRemoveMemberGuid] = useState('')
    const empId = localStorage.getItem('EmpGuid') || '';
    const orgId = localStorage.getItem('OrgGuid');
    const userDisplayName = localStorage.getItem('FirstName') as string;
    const minDate: Date = new Date();
    const [selectedDate, setSelectedDate] = useState(new Date());
    var selected: string;
    const formattedDate = process.env.REACT_APP_DATE_FORMAT 

    useEffect(() => {
        if (props.showInfo) {
            setTeamMembers(false);
            setShowEvents(false);
        }
        console.log("Checking status for display Name", props.groupInfo)

    }, [props.showInfo]);

    useEffect(() => {
        if (props.user.success && props.user.success === 'RemoveMemberFromGroup') {
            globalStore.showToast({
                type: 'Message',
                title: "Removed Contact",
                message: "Removed contact from room successfully"
            });
            props.removeSuccessStatus();
            props.GetGroupMems(props.groupInfo.GroupGuid);
            props.GetGroups(empId);
        } else if (props.user.success && props.user.success === 'RemoveGroup') {
            globalStore.showToast({
                type: 'Message',
                title: "Delete Room",
                message: "Deleted room successfully"
            });
            props.removeSuccessStatus();
            props.GetGroups(empId);
        }
    }, [props.user.success]);

    useEffect(() => {
        if (props.groupInfo && props.groupInfo.GroupGuid) {
            props.GetGroupMems(props.groupInfo.GroupGuid);
            setShowEvents(false);
            setTeamMembers(false);
            setEditPopup(false);
        }
    }, [props.groupInfo]);

    useEffect(() => {
        console.log("props.user.teamMems",props.user.teamMems)
        if (props.user.teamMems && props.user.teamMems.length > 0) {
            setTeamMemCount(props.user.teamMems.length -1);
        } else {
            setTeamMemCount(0);
        }
    }, [props.user.teamMems]);
    

    useEffect(()=>{
        if(props.groupInfo && props.groupInfo.GroupGuid){
            props.GetGroupMems(props.groupInfo.GroupGuid);
        }
    },[showAddPopup])

    const handleShowMembers = () => { setTeamMembers(!showMembers); }
    const handleShowEvents = () => { setShowEvents(!showEvents); }
    const handleShowExit = () => { setShowConfirmExit(!showConfirmExit); }
    const handleShowDelete = () => { setShowConfirmDelete(!showConfirmDelete); }

    const handleRemoveMember = (empId: string) =>{ 
        setShowRemoveMember(!showRemoveMember)
        setRemoveMemberGuid(empId)
    }

    const exitGroup = () => {
        const form = new FormData();
        form.append("groupGuid", props.groupInfo.GroupGuid || "");
        form.append("empGuid", empId);
        if (localStorage.getItem("msAccessToken"))
            form.append("teamsAccessToken", localStorage.getItem("msAccessToken") as string);
        props.RemoveFromGroup(form);
        props.toggleInfo();
    }

    const deleteGroup = () => {
        const form = new FormData();
        form.append("groupGuid", props.groupInfo.GroupGuid);
        if (localStorage.getItem("msAccessToken"))
            form.append("teamsAccessToken", localStorage.getItem("msAccessToken") as string);
        props.DeleteGroup(form);
        handleShowDelete();
        props.toggleInfo();
    }

    const removeFromGroup = (empId: string) => {
        const form = new FormData();
        form.append("groupGuid", props.groupInfo.GroupGuid || "");
        form.append("empGuid", empId);
        if (localStorage.getItem("msAccessToken"))
            form.append("teamsAccessToken", localStorage.getItem("msAccessToken") as string);
        props.RemoveFromGroup(form);
        setShowRemoveMember(!showRemoveMember)
    }

    const closeAddMember = () => {
        setShowAddPopup(false);
        props.GetGroupMems(props.groupInfo.GroupGuid);
    }
    const createMeetingwithMsTeams = async () => {
        console.log(props.groupInfo);
        let accesToken = localStorage.getItem('msAccessToken') || '';
        const data: any = await createTeamsMeetingLink(accesToken, props.groupInfo.Id);
        // chatClient.sendChannelCallMsg(data.joinWebUrl, props.groupInfo.id, userDisplayName, props.groupInfo.GroupName, 'MSCALL').then(() => {
        //     window.open(data.joinWebUrl, '_blank');
        // });

    }

    const handleSettingsClick = () => {
        //console.log("Checking status for display Name",props.groupInfo)
        //props.toggleInfo(false)
        setEditPopup(true);
        //props.toggleInfo(false)
    };

    const toggleDescription = () => {
        console.log("ok",props.groupInfo)
        setShowDescription(!showDescription);
 
    }


   const handleClose = () =>{
    setShowEvents(false);
    setTeamMembers(false);
    setEditPopup(false);
    props.toggleInfo();
    toggleDescription();
    }

    return (<>

        {props.showInfo ?
            <div className="SideNav z-index-h text-left">
                <div className="sideNavHeader">
                    <div className="CloseNav">
                        <img onClick={handleClose} src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('title.close')} /> </div>
                </div>
                <div className="sideNavBody">
                    {<div className="row mt-4">
                        <div className="col-12 mb-2 text-light">{t('groupforminfo.room_info')}</div>
                    </div>}
                    {!showMembers?
                        (<div className="row">
                            <div className="col-12 text-center mb-2"> <img className="memberProfilePicture" src="/assets/media/images/group-thumbnail.png" /> </div>
                            <div className="col-12 text-center text-light">
                                <h6 className="m-0">{props.groupInfo.GroupName}</h6>
                                <p className="text-muted mb-2">Created: {Moment(props.groupInfo.CreatedDate).format(formattedDate)}</p>
                            </div>
                            <div className="col-12 p-0">
                                <hr className="mb-2" /> </div>
                            {/* <div className="col-12 text-light MemberPPHeader"> {t('title.description')} <img className="float-right" src="/assets/media/svg/profile/downArrow.svg" /> </div>
                            <div className="col-12 text-light MemberPPBody">
                                <p className="text-muted m-0">{props.groupInfo.description}</p>
                            </div> */}
                            <div className="col-12 text-light MemberPPHeader" onClick={toggleDescription}>
                                {t('title.description')}
                                <img className="float-right" src="/assets/media/svg/profile/downArrow.svg" alt="Toggle Description" />
                            </div>
                            {showDescription && (
                                <div className="col-12 text-light MemberPPBody">
                                    <p className="text-muted m-0">{props.groupInfo.Description}</p>
                                </div>
                            )}
                            <div className="col-12 p-0">
                                <hr className="mb-2" /> </div>
                            <div className="col-12 text-light MemberPPHeader"> {` ${t('groupforminfo.members')}  (${teamMemCount})`}
                                <img onClick={handleShowMembers} alt="Group members" className="float-right c-pointer" width="18" src="/assets/media/svg/group-participants.svg" />
                            </div>

                            <div className="col-12 p-0">
                                <hr className="mb-2" />
                            </div>
                            <div className="col-12 text-light MemberPPHeader"> {t('groupforminfo.room_events')}
                                <img onClick={handleShowEvents} alt="Group members" className="float-right c-pointer" width="22" src="/assets/media/svg/calSel.svg" />
                            </div>
                            <div className="col-12 p-0">
                                <hr className="mb-2" />
                            </div>
                            {props.groupInfo.GroupTypeName === 'Microsoft Teams' ? <>
                                <div className="col-12 text-light MemberPPHeader" onClick={createMeetingwithMsTeams}> {t('groupforminfo.create_team _meet')}<img className="float-right c-pointer" width="18" src="/assets/media/svg/teamMeet.svg" />
                                </div>
                                <div className="col-12 p-0">
                                    <hr className="mb-2" /> </div></> : ''}
                            <div onClick={handleShowExit} className="col-12 text-danger MemberPPHeader"> {t('groupforminfo.exit_room')}
                                <img alt="Exit Group" className="float-right c-pointer" width="18" src="/assets/media/svg/ExitGroup.svg" />
                                <ConfirmPopup show={showConfirmExit} close={handleShowExit} okHandle={exitGroup} message={t('groupforminfo.exit_message')} />
                            </div>

                            <div className="col-12 p-0">
                                <hr className="mb-2" /> </div>
                            <div className="col-12 text-danger MemberPPHeader" onClick={handleShowDelete}> {t('groupforminfo.delete_room')} <img className="float-right c-pointer" width="18" src="/assets/media/svg/deleteGroup.svg" />
                                <ConfirmPopup show={showConfirmDelete} close={handleShowDelete} okHandle={deleteGroup} message={t('groupforminfo.delete_message')} />
                            </div>

                            {/* {editPopup ? <RoomEdit Room={props.groupInfo} close={() => setEditPopup(false)} />: ''} */}

                        </div>) : <div className="row mt-3" style={{ "overflow": "auto", height: "80%", alignContent: "flex-start" }}>
                            <div className="col-12 mb-2 text-light">
                                <svg onClick={handleShowMembers} enableBackground="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                                    <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path>
                                </svg>{`${t('groupforminfo.members')}  (${teamMemCount})`}<svg onClick={() => { setShowAddPopup(true) }}  className="c-pointer float-right mt-n1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" data-toggle="modal" data-target="#addGroupMember"><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="#fff"></path> <title>Add Members</title></svg>
                            </div>
                           {(showAddPopup)?<AddMemberPopup existingMem={props.user.teamMems} groupId={props.groupInfo.GroupGuid} teamId={props.groupInfo.Id} show={showAddPopup} close={closeAddMember} />:null}
                            {props.user.teamMems && props.user.teamMems.map(
                                function (event: any) {                                
                                    return (
                                        empId == event.EmpGuid ? <></> :
                                        <div className="col-12 pb-1 pt-1 contactsLoop border-bottom border-secondary" key={event.EmpGuid}>
                                            <div className="float-left">
                                            <svg className={"ParticipantImage c-pointer "+ (event.IsOnline ? (event.status.length > 0 ?  event.status.toLowerCase(): 'available') : 'Offline')} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <defs>
                                                        <pattern id={"p" + event.EmpGuid} patternUnits="userSpaceOnUse" width="100" height="100">
                                                            <image xlinkHref={event.ProfileImageURL ? event.ProfileImageURL : "/assets/media/images/sute_user_default.png"} x="-25" width="150" height="100" />

                                                        </pattern>
                                                    </defs>
                                                    <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#p${event.EmpGuid})`}></polygon>
                                                </svg>
                                            </div>
                                            <div className="float-left">
                                                <p className="ParticipantName">{event?.FirstName + ' ' + event?.LastName}</p>
                                                <p className="ParticipantDesignation">
                                                    <img onClick={() =>handleRemoveMember(event.EmpGuid)} className="c-pointer" title="Remove Member" alt="Remove Member" src="/assets/media/svg/profile/remove-member.svg" />
                                                </p>
                                                <ConfirmPopup show={showRemoveMember} close={handleRemoveMember}     okHandle={() => removeFromGroup(removeMemberGuid)} message={'Do you want to delete this member ?'} />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        // </div>) : (<GroupEvents handleShowEvents={handleShowEvents} GroupGuid={props.groupInfo.GroupGuid} showEvents={showEvents}></GroupEvents>)
                    }
                </div>
                <div className="sideNavFooter" onClick={handleSettingsClick}>
                    <div className="col-12 text-right mt-4">
                        <img className="c-pointer" title="Settings" src="/assets/media/svg/settings.svg" alt="Settings" />
                    </div>
                </div>
            </div> : ""}
        {editPopup && <RoomEdit Room={props.groupInfo}   handleClose={handleClose} close={() => setEditPopup(false)}  />}
        {showEvents && (<GroupTimeSlotPopup GroupGuid={props.groupInfo.GroupGuid} show={showEvents} close={handleShowEvents} />)}
    </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupFormInfo);