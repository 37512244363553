import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { StoreClass } from "../../store/Store";
import { GetOrgFiles } from "../../services/OrgServices";
import ViewFile from "../../components/ViewFile";

function InductionFiles(props: any) {
  const dispatch = useDispatch();
  let orgGuid: any = localStorage.getItem("OrgGuid");
  const [showOrgFiles, setShowOrgFiles] = useState<boolean>(false);
  const [orgFileIndex, setOrgFileIndex] = useState<number>(0);
  const [orgFile, setOrgFile] = useState<any>({});
  const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
  const [orgArray, setOrgArray] = useState<any[] | undefined>(undefined);
  const [orgNewArray, setOrgNewArray] = useState<any[]>([]);
  const [isFirstTimeClicked, setIsFirstTimeClicked] = useState<boolean>(false);

  useEffect(() => {
    dispatch(GetOrgFiles(orgGuid, props.type, true) as any);
  }, []);
  
  useEffect(() => {
      if (props.showFiles > 0) {
        setShowOrgFiles(true);
      }

      if (props.showFiles > 0 && user.orgFiles && user.orgFiles.length > 0) {
        const today = new Date();
        today.setHours(23, 59, 59, 999);

        const OrgFilesArray = user.orgFiles
          .filter((item: any) => {
            const expiryDate = new Date(item.ExpiryDate);
            expiryDate.setHours(23, 59, 59, 999);
            return item.ExpiryDate === null || expiryDate.getTime() >= today.getTime();
          })
          .sort((a: any, b: any) => a.Sequence - b.Sequence);
         processOrgArray(OrgFilesArray);
         setOrgArray(OrgFilesArray);
      } else {
        setOrgArray([]);
        setOrgNewArray([]);
      }
  }, [props.showFiles,props.type, user.orgFiles, orgGuid]);

  const processOrgArray = (orgArray: any[]) => {
    const newArray: any[] = [];

    orgArray.forEach((item: any) => {
      if (item.BackgroundImageUrl || item.Content) {
        newArray.push({ ...item, currentSlide: 0 });
      }
  
      if (item.FileUrl) {
        newArray.push({ ...item, currentSlide: 1 });
      }
    });
    console.log("orgNewArray 1",newArray )
    setOrgNewArray(newArray);
  };

  useEffect(() => {
    if(orgArray)
    processOrgArray(orgArray);
    console.log("orgNewArray",orgNewArray)
    updateOrgFileIndex(1)
  }, [orgArray]);

  const updateOrgFileIndex = (fileIndex: number) => {
    setOrgFileIndex(fileIndex);
    const OrgFile = orgNewArray.filter((org: any, index: number) => index + 1  === fileIndex);
    setOrgFile(OrgFile[0] || {});
  };

  const closeModal = (status: boolean) => {
    
    const userTypeId = Number(user?.UserTypeId);
    const emailConfirmed = Number(user?.EmailConfirmed);

   if (userTypeId === 1 && emailConfirmed === 0) {
        user.EmailConfirmed = 1;
      }

    setShowOrgFiles(status);
    setIsFirstTimeClicked(false);
  };

  const handleNext = () => {
    if (!isFirstTimeClicked) setIsFirstTimeClicked(true);    
      const newIndex = orgFileIndex + 1;
      if (newIndex <= orgNewArray.length) {
        updateOrgFileIndex(newIndex);
      }

  };

  const handlePrevious = () => {
    if (isFirstTimeClicked && orgFileIndex === 1) setIsFirstTimeClicked(false);
   
      const newIndex = orgFileIndex - 1;
      if (newIndex > 0) {
        updateOrgFileIndex(newIndex);
      }
  };

  const getBackgroundImageUrl = (url: string) => {
    if (url && url.startsWith('https')) {
      return url;
    } else {
      return (process.env.REACT_APP_CDN_HOST || "") + "/" + url;
    }
  };

  const renderContent = () => {
    
    console.log("orgFile",orgFile)
    if (!orgFile || Object.keys(orgFile).length === 0) {
      return null; // Return null if orgFile is undefined or empty to avoid errors
    }

    if (orgFile.currentSlide == 0) {
      if (orgFile.Type === "bulletin" && (orgFile?.BackgroundImageUrl || orgFile?.Content)) {
        return (
          <div
            className="bulletin-dynamic-bg"
            style={{ backgroundImage: `url(${getBackgroundImageUrl(orgFile?.BackgroundImageUrl)})` }}
            dangerouslySetInnerHTML={{ __html: orgFile?.Content }}
          />
        );
      }
    } else if (orgFile.currentSlide === 1 ) {
      return (
        <>
          <h5 dangerouslySetInnerHTML={{ __html: orgFile?.Title }} />
          <ViewFile file ={orgFile}/>
        </>
      );
    }
    return null
  };

  return (
    <Modal show={showOrgFiles} onHide={() => closeModal(false)} backdrop="static">
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="modal-title h5 text-white">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body> {orgNewArray.length >= 1 ?
        <div className="col-12">
          <div className="col-12 mb-3 text-white p-0">{renderContent()}</div>
        </div>
        : orgArray && orgArray.length === 0 && <p className="text-white">{`No ${props.title} found`}</p>}
      </Modal.Body> 
      <Modal.Footer className="border-0">
        {orgNewArray.length > 1 && orgFileIndex > 1 && (
          <button type="button" className="btn btn-SeaGreen" onClick={handlePrevious}>
            Previous
          </button>
        )}
        {orgNewArray.length > 0 && orgFileIndex < orgNewArray.length && (
          <button type="button" style={{ width: "80px" }} className="btn btn-SeaGreen" onClick={handleNext}>
            Next
          </button>
        )}
        <button type="button" style={{ width: "80px" }} className="btn btn-SeaGreen" onClick={() => closeModal(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default InductionFiles;
