import React, { useEffect, useRef, useState } from "react";
import { t } from '../../i18n';
import * as T from "../../actions/Types";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEmpDetailsList, sendEmails, ShowToast } from "../../services/UserActions";
import { StoreClass } from "../../store/Store";
import { title } from "process";

const mapStateToProps = (state:any) => ({
    orgId: state.AuthReducer.orgData.OrgId,
    loading: state.AuthReducer.loading,
});

// Assuming you have an action to dispatch (optional)
const mapDispatchToProps = (dispatch:any) => ({
    showToast: () => dispatch(ShowToast({
        type: 'status',
        message: 'Invite sent successfully',
        title: 'success'
    }))
});

function InvitePeople(props: any) {
    const media = useSelector((store: any) => store.MediaReducer);
    const history = useNavigate();
    const dispatch = useDispatch();
    const [mails,setMails] = useState<string>("");
    const [showToastText,setShowToastText]=useState('');
    const [isValid,setIsValid]=useState(true)
    const [apiStatus, setApiStatus] = useState('');
    const [apiResponse,setApiResponse] = useState('')
    const [enterMail,setEnterMail] = useState('')
    
    const accounts_api_port = process.env.REACT_APP_GOOGLE_REDIRECT_URL as string;

    const onHandleChange = (e: any) => {
          setEnterMail("")
        const inputValue = e.target.value;
        console.log(inputValue)
        setMails(inputValue)
        if(validateEmail(inputValue))
        {
           setIsValid(false)
           setMails(inputValue)
        }
        else{
            setIsValid(true)
        }
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email.trim()); 
    }

    const onSubmit =()=> {
       const Redirect = `${accounts_api_port}/registration`
       const emails = mails.split(',');
       let validEmails: string[] = [];
       let invalidEmails: string[] = [];

        emails.forEach((email: string) => {
        if (validateEmail(email.trim())) {
            validEmails.push(email.trim()); 
        } else {
            invalidEmails.push(email.trim()); 
        }
        });
        if (invalidEmails.length > 0) {
        console.log("Invalid emails:", invalidEmails.join(', '));
           const invalidEmailString = invalidEmails.join(',');
           if(invalidEmailString === null || invalidEmailString === "" )
           {
            setEnterMail('Please enter mail')
           }
           else{
           setApiStatus(invalidEmailString +' '+'not a valid email')
           }
           return
        }
        
        setApiStatus('')
        const validEmailString = validEmails.join(',');
        let inviteObj = {
            Redirection: Redirect,
            EmailId: validEmailString,
            orgid:props.orgId
        };
        console.log(inviteObj);
       dispatch({ type: T.ADD_LOADING });
        sendEmails(inviteObj).then((resp: any) => {
            if (resp.message === "Success") {
                setApiStatus('')
                setMails('')
                dispatch({ type: T.REMOVE_LOADING });
                dispatch(ShowToast({
                                 type: 'status',
                                message: "Invite sent successfully",
                                 title: 'Success'
                        }) as any);
            } else if (resp.message === "alreadyRegister") {
                const  existsMails =  resp.result.map((m:any)=>m.EmailId)
                setApiStatus(existsMails + ' already registered')
                dispatch({ type: T.REMOVE_LOADING });
            }
            else if(resp.message === 'Error Occurred')
            {
                setMails('')
                dispatch({ type: T.REMOVE_LOADING });
                dispatch(ShowToast({
                    type: 'status',
                   message: "Error occurred",
                    title: 'Error'
           }) as any);
            }
        })
        .catch((e: any) => {
            dispatch({ type: T.REMOVE_LOADING });
            console.error(e);
        });
      }

    return (<>
        <section className="myProfilePage position-relative h-100">
            <div className="container-fluid pl-4 pr-4">
                <div className="col-sm-7 p-0 pb-2 pt-3 m-auto">
                    <div className="cRoomHeader pt-2 mb-0">
                        <div className="cRsuteLogo align-self-center">
                            <img src="/assets/media/images/logo.png" onClick={()=>history('/users')} className="c-pointer"/>
                        </div>
                        <div className="cRTitle p-3">
                            <h5>Invite people to sute</h5>
                            {/* <p>All Partners</p> */}
                        </div>
                    </div>
                    <div className="col-12 p-0">
                        <div className="card mt-2 loginBox">
                            <div className="card-body">
                            {apiStatus || enterMail ? <p className="alert mt-2 alert-danger">{apiStatus || enterMail}</p> : ''}
                                <label className="color-white">To:</label>
                                <textarea className="form-control-inner" placeholder="name@gmail.com" style={{ height: "100px" }} onChange={onHandleChange} value={mails}></textarea>
                                <label className="color-white">Note: Invite multiple people by using a comma to separate email ID's.
                                   
                                </label>
                                <button type="submit"
                                        className = "btn btn-SeaGreen font-weight-bold float-right"  onClick={()=>onSubmit()}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(InvitePeople);