import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { getEmployeeCalanderEvents } from "../../services/CalenderSevice";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { GetEventPartcipants } from "../../services/CalenderSevice";
import * as T from "../../actions/Types";
import FileIcon from "../../components/FileIcon";
import { formatUtcDate } from "../../utils/helper";
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { t } from '../../i18n';
import { Modal } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { execFile } from "child_process";
import TransModal from "./transcriptModal";

function myMeetings() {
    const media = useSelector((store: any) => store.MediaReducer);
    const employee = useSelector((store: any) => store.EmployeeReducer);
    const dispatch = useDispatch();
    const myUserId = localStorage.getItem('EmpGuid');
    const EmpId = localStorage.getItem('EmpId');
    const [meetingDate, setMeetingDate] = useState(new Date());
    const [eves, setEves] = useState<any>([]);
    const [participant, setParticipant] = useState<any>([]);
    const formattedDate = process.env.REACT_APP_DATE_FORMAT
    const location = useLocation();
    const selectedDate = new Date(meetingDate);
    const [showTrans, setShowTrans] = useState(false);
    const [eveScript, setEveScripts] = useState('');
    
    //const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        dispatch(getEmployeeCalanderEvents(myUserId, new Date().getTimezoneOffset()) as any);
    }, [])



    useEffect(() => {
        if (employee?.events?.length > 0) {
            const eve: any[] = [];
            const eventId: string[] = [];
            const attendes: any[] = [];
            let attachments: any[] = [];

            employee?.events?.forEach((obj: any) => {
                (obj.Events || []).forEach((event: any) => {
                    const eventStart = new Date(event.UTCStartTime);
                    const eventEnd = new Date(event.UTCEndTime);

                    const eventStartYear = eventStart.getFullYear();
                    const eventStartMonth = eventStart.getMonth();
                    const eventStartDay = eventStart.getDate();

                    const meetingDateObj = new Date(meetingDate);
                    const meetingYear = meetingDateObj.getFullYear();
                    const meetingMonth = meetingDateObj.getMonth();
                    const meetingDay = meetingDateObj.getDate();

                    if (event.IsAllDayEvent) {
                        // Check if meetingDate is within the event's span
                        if (
                            (meetingDateObj >= eventStart && meetingDateObj <= eventEnd) ||
                            (meetingYear === eventStartYear && meetingMonth === eventStartMonth && meetingDay === eventStartDay)
                        ) {
                            eve.push({
                                allDay: event.IsAllDayEvent,
                                id: event.Id,
                                title: event.Summary,
                                start: eventStart,
                                end: eventEnd,
                                setEnd: eventEnd,
                                desc: event.Description,
                                email: event.Email,
                                meetingLink: event.MeetingLink,
                                Source: event.Source,
                                Transcript:''
                            });
                            handleEventParticipants(obj, event, eventId, attendes, attachments);
                        }
                    } else {
                        // Check if meetingDate matches the event's start date
                        const eventYear = eventStart.getFullYear();
                        const eventMonth = eventStart.getMonth();
                        const eventDay = eventStart.getDate();
                        if (eventYear === meetingYear && eventMonth === meetingMonth && eventDay === meetingDay) {
                            eve.push({
                                allDay: event.IsAllDayEvent,
                                id: event.Id,
                                title: event.Summary,
                                start: eventStart,
                                end: eventEnd,
                                setEnd: eventEnd,
                                desc: event.Description,
                                email: event.Email,
                                meetingLink: event.MeetingLink,
                                Source: event.Source,
                                Transcript:'',
                            });
                            handleEventParticipants(obj, event, eventId, attendes, attachments);
                        }
                    }
                });
            });

            if (eventId.length > 0) {
                dispatch({
                    type: T.ADD_LOADING
                });
debugger
                const date = `${new Date(meetingDate).getFullYear()}-${new Date(meetingDate).getMonth() + 1}-${new Date(meetingDate).getDate()}`;
                GetEventPartcipants(eventId.toString(), EmpId, date)
                    .then((resp: any) => {
                        console.log("resp", resp.result);
                        if (typeof resp === 'object' && resp !== null && 'status' in resp) {
                            resp.result.forEach((participant: any) => {
                                attendes.push(participant);
                                const obj = eve.find(x => x.id === participant.EventGuid);
                                if (obj) {
                                  obj["Transcript"] = participant.Transcript ? participant.Transcript : '';
                                }
                                
                            });
                            setParticipant(attendes);
                            setEves(eve);
                            dispatch({ type: T.REMOVE_LOADING });
                        } else {
                            console.error("Error", resp);
                            dispatch({ type: T.REMOVE_LOADING });
                        }
                    })
                    .catch(e => {
                        console.error("Fetch error:", e);
                        dispatch({ type: T.REMOVE_LOADING });
                    });
            } else {
                setParticipant(attendes);
                setEves(eve);
            }
        }
    }, [employee.events, meetingDate]);

    function handleEventParticipants(obj: any, event: any, eventId: string[], attendes: any[], attachments: any[]) {
        if (obj.Email === "Sute") {
            eventId.push(event.Id);
        } else {
            const pars: any[] = [];
            event.participants.split(',').forEach((participant: any) => {
                pars.push({ "FirstName": participant, "LastName": '', ProfileUrl: null });
            });
            if (event.AttachmentsContent.length > 0) {
                attachments = JSON.parse(event.AttachmentsContent);
            }
            if (pars.length > 0) {
                attendes.push({ "EventGuid": event.Id, "GroupName": '', "Participants": pars, "Attachments": attachments });
            }
        }
    }




    function formatAMPM(date: any) {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            return "Invalid Date";
        }
        var hours: number = date.getHours();
        var minutes: number = date.getMinutes();
        var ampm: string = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var hoursString: string = hours < 10 ? "0" + hours : hours.toString(); // Convert hours to string
        var minutesString: string = minutes < 10 ? "0" + minutes : minutes.toString(); // Convert minutes to string
        var strTime: string = hoursString + ':' + minutesString + ' ' + ampm;
        return strTime;
    }

    const DateChange = (type: any) => {
        if (type === "forward") {
            const newDate = new Date(meetingDate);
            newDate.setDate(newDate.getDate() + 1);
            setMeetingDate(newDate);
        } else {
            const newDate = new Date(meetingDate);
            newDate.setDate(newDate.getDate() - 1);
            setMeetingDate(newDate);
        }
    }

    const handleDateChange = (date: any) => {
        if (date instanceof Date) {
            setMeetingDate(date);
        } else {
            setMeetingDate(new Date());
        }
    };

    function getDaySuffix(day: any) {
        if (day >= 11 && day <= 13) {
            return "th";
        }
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    function formatDate(date: any) {
        const day = date.getDate();
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        const suffix = getDaySuffix(day);
        return `${day}${suffix} ${month} ${year}`;
    }
    // const openDatePicker = () => {
    //     if (inputRef.current) {
    //       inputRef.current.focus();
    //     }
    //   };

    const onTransClick = (script : string) => {        
        setEveScripts(script);
        setShowTrans(!showTrans);
    }

    return (
        <>
            <section className="myProfilePage position-relative d-flex mih-100">
                <div className="container-fluid pl-4 pr-4">
                    <div className="col-10 p-0 pb-2 pt-3 m-auto">
                        <div className="cRoomHeader pt-2 mb-0">
                            <div className="cRsuteLogo align-self-center">
                                <Link to="/pod"><img src="/assets/media/images/logo.png" /></Link>
                            </div>
                            <div className="cRTitle p-3">
                                <h5>Calendar</h5>
                                {/* <p>All Partners</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-10 m-auto m-0 p-0">
                        <div className="col-12 row m-0 p-0">
                            <div className="col-4 pl-0">
                                <span className="arrow" onClick={() => { DateChange("backward") }}>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path></svg>
                                </span>
                                <span className="arrow" onClick={() => { DateChange("forward") }}>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path></svg>
                                </span>
                                <button type="button" title="today" aria-pressed="false" className="color-white fc-today-button fc-button fc-button-primary" onClick={() => { setMeetingDate(new Date()) }}>
                                    today
                                </button>
                            </div>
                            <div className="col-4 mt-auto mb-auto text-center">
                                <h5 className="color-white">{Moment(meetingDate).format(formattedDate)}</h5>
                            </div>
                            {/* <div className="col-4 text-right pr-0">
                                <input className="form-control w-50 ml-auto" type="date" value={meetingDate.toISOString().substring(0, 10)}  name="date" id="date" onChange={(e)=>{handleDateChange(e)}} />
                                <label htmlFor="date" onClick={openDatePicker}>
                                <img src="/assets/media/images/calender.png" style={{ width: "40px" }} />
                                </label>                               
                            </div> */}
                            <div className="col-2 p-0 text-right my-meetings ml-auto">
                                <DatePicker className="SuteInputPrimary h40px"
                                    selected={meetingDate}
                                    showIcon
                                    toggleCalendarOnIconClick
                                    onChange={date => { handleDateChange(date) }}
                                    value={Moment(meetingDate).format(formattedDate)}
                                />
                            </div>

                        </div>
                        {eves.map((event: any) => {
                            const currentDate = new Date();
                            const formattedStartDate = formatUtcDate(event.start, "MM/DD/YYYY")
                            const formattedEndDate = formatUtcDate(event.end, "MM/DD/YYYY")

                            const eventStartDate = new Date(formattedStartDate);
                            const eventEndDate = new Date(formattedEndDate)
                            const dateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                            const sltDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());


                            return <div className="meetings-div">
                                <div className="meetings-time">
                                    {/* <p>Time</p> */}
                                    <p>{`${(event.allDay) ? 'All Day' : formatAMPM(event.start)}`}</p>
                                    <p>{`${(event.allDay) ? '' : '-'}`}</p>
                                    <p>{`${(event.allDay) ? '' : formatAMPM(event.end)}`}</p>
                                </div>
                                <div className="meeting-summary">
                                    <div className="row m-0">
                                        <div className="col-8 p-0">
                                            <p>
                                                <span className="font-bold">Title : </span>
                                                <span>{event.title}</span>
                                            </p>
                                        </div>
                                        <div className="col-4 p-0 text-right mb-left">
                                            <p>
                                                <span className="font-bold">Duration : </span>
                                                <span>{(event.allDay) ? `All Day` : ((event.end - event.start) / (1000 * 60) > 60) ? `${((event.end - event.start) / (1000 * 60)) / 60} hours` : `${(event.end - event.start) / (1000 * 60)} min`}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <p className={event.desc.length > 0 ? '' : 'd-none'}>
                                        <span className="font-bold">Description : </span>
                                        <span>
                                            {event.desc}
                                        </span>
                                    </p>

                                    {participant.map((obj: any) => {
                                        if (obj.EventGuid == event.id && obj.Participants.length > 1) {
                                            return (
                                                <>
                                                    {(obj.Participants.length > 0) ? <p>
                                                        <span className="font-bold">Participants : </span>
                                                    </p> : null}
                                                    <div className="row m-0">
                                                        {obj.Participants.length > 0 && obj.Participants.map((par: any) => {
                                                            if (par.FirstName) {
                                                                return (
                                                                    <>
                                                                        <div className="participants-div">
                                                                            <img src={`${(par.ProfileUrl != null) ? par.ProfileUrl : '/assets/media/images/sute_user_default.png'}`} />
                                                                            <span className="name">{par.FirstName}</span>
                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                    {(obj.GroupName != null && obj.GroupName.length > 0) ? <p>
                                                        <span className="font-bold">Room Name : </span>
                                                        <span>{obj.GroupName}</span>
                                                    </p> : null}
                                                    <div className="attachements row m-0">
                                                        {obj.Attachments && obj.Attachments.some((attachment: any) => attachment && attachment.FileName && attachment.FileName.length > 0) && (
                                                            <div className="col-1 mt-1">
                                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M355.9 161.1c-8.6 0-15.6 7-15.6 15.6v194.4c0 20.3-8 40.4-22 55.1-13.9 14.6-35.2 23.7-55.5 23.7h-13.6c-19.6 0-39.5-8.9-54.8-24.6-15.2-15.5-23.9-35.6-23.9-55.2V119.3c0-14.9 6.2-28.9 17.3-39.5 11.1-10.5 25.7-16.3 41-16.3 15.1 0 29.3 5.8 39.8 16.2 10.5 10.5 16.4 24.6 16.4 39.6v234.2c0 17.1-13.6 32.2-29 32.2-13.6 0-28.2-12.9-28.2-32.2V219.9c0-8.6-7-15.6-15.6-15.6s-15.6 7-15.6 15.6v133.6c0 35.5 25.5 62.3 59.4 62.3 16.5 0 31.7-6.1 42.7-17.3 11.3-11.4 17.5-27.4 17.5-45V119.3c0-23.4-9.1-45.4-25.7-61.8C274 41 252 32 228.5 32s-45.5 9-62.2 25.5c-16.7 16.5-25.9 38.4-25.9 61.9v250.8c0 28.7 12.2 57.9 32.6 78 20.9 20.6 47.9 31.9 76.1 31.9h13.6c27.6 0 55.3-11.7 75.9-32.1 10.2-10.1 18.2-21.7 23.9-34.6 5.9-13.5 8.9-27.7 8.9-42.1V176.7c.1-8.6-6.9-15.6-15.5-15.6z"></path>
                                                                </svg>
                                                            </div>
                                                        )}
                                                        <div className="col-11 p-0">
                                                            {obj.Attachments && obj.Attachments.map((attachment: any, index: number) => {
                                                                if (attachment && attachment.FileName) {
                                                                    const ft = attachment.ContentType.split('.').pop().toUpperCase();
                                                                    return (
                                                                        <a target="_blank" title={attachment.FileName} href={attachment.FilePath} style={{ color: "black" }} key={index}>
                                                                            <span className="upload-attachement">
                                                                                <span style={{ paddingRight: "5px" }}>
                                                                                    <img src={attachment.IconUrl} style={{ height: "1.5em", width: "1.5em", borderRadius: "0" }} alt={attachment.FileName} />
                                                                                </span>
                                                                                <b>{attachment.FileName}</b>
                                                                            </span>
                                                                        </a>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            );

                                        }
                                    })}

                                    <div className="col-12 text-right">
                                        {(event.meetingLink && event.meetingLink.length > 0 && (event.allDay ? (sltDate >= eventStartDate && sltDate <= eventEndDate) && sltDate >= dateOnly : eventStartDate >= dateOnly)) ? (
                                            <>
                                                {(event.meetingLink && event.meetingLink.length > 0) ?
                                                    <Link target={event.Source == 'sute' ? '_self' : '_blank'} to={event.meetingLink} state={location.pathname} className="btn btn-SeaGreen">
                                                        {
                                                            event.Source == 'sute' ? null : <img src="/assets/media/images/meet-icon.png" style={{ width: "30px", height: "28px", borderRadius: "0", verticalAlign: "bottom" }} />
                                                        }   <span>Join</span> </Link> : null}

                                                {/* <button type="button" className="btn btn-SeaGreen ml-2 p-1">
                                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="25px" width="30px" xmlns="http://www.w3.org/2000/svg"><path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"></path><path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"></path></svg>
                                      </button> */}
                                            </>
                                        ) : null}
                                        {(event.meetingLink && event.meetingLink.length > 0  && event.Source == 'sute' && event.Transcript.length > 0) ? (
                                            <button type="button" onClick={()=>{onTransClick(event.Transcript)}} className="btn btn-SeaGreen ml-2">Meeting summary</button>
                                        ) : null}
                                    </div>

                                </div>
                            </div>
                        })}
                    </div>
                </div>
                {showTrans ?
                    <TransModal show={showTrans} chatStr={eveScript}  onCloseClick={()=>{setShowTrans(!showTrans)}}></TransModal> : ''
                }
            </section>
        </>
    );
}
export default myMeetings;
