import React, { useEffect, useState } from 'react'
import { t } from '../../i18n';
import { globalStore } from '../../store/global_store';
import { removeSuccessStatus, updateGroup, DeleteGroup, getGroupsByEmpId} from "../../services/ContactsService";
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../../store/Store';
import ConfirmPopup from '../../layout/ConfirmPopup';


interface formError {
    message: string;
}
function RoomEdit(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [values, setValues] = useState<any>({ "Type": false });
    const [btnDisabled, setBtnDisabled] = useState(false);
    const orgId = localStorage.getItem('OrgGuid') as string;
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const dispatch = useDispatch();
    const empId = localStorage.getItem('EmpGuid') as string;
    const [errors, setErrors] = useState({} as formError);

    useEffect(() => {
console.log('props.Room',props.Room)
        setValues({ ...props.Room, Type: props.Room.IsPrivate });
    }, [props.Room])

    

    useEffect(() => {
        if (user.success && user.success === 'UpdateGroup') {
            globalStore.showToast({
                type: 'Room',
                title: "Update room",
                message: "Room updated successfully"
            });
            dispatch(removeSuccessStatus() as any);
            dispatch(getGroupsByEmpId(empId) as any);
            props.close();
        } else if (user.success === 'RoomDeletedSuccess') {
            globalStore.showToast({
                type: 'Room',
                title: "Room Info",
                message: "Room deleted successfully"
            });
            dispatch(removeSuccessStatus() as any);
            dispatch(getGroupsByEmpId(empId) as any);
            props.close();
        }
    }, [user.success]);

    useEffect(() => {
        if (user.error && user.error.msgType === 'RoomCreateError') {
            globalStore.showToast({
                type: 'Rooms',
                title: "Error",
                message: "Error in creating room"
            });
            dispatch(removeSuccessStatus() as any);
            // props.getOrgRooms(orgId);
        }
    }, [user.error]);
    const selectColor = (code: string) => {
        setValues({ ...values, Color: code });
    }


    const handleChange = (e: any) => {
        console.log('Previous values state:', values);

        e.persist();
        setValues((values: any) => ({
            ...values,
            [e.target.name]: e.target.value
        }));
        
        if (e.target.name === 'DisplayName' && e.target.value === '') {
            setBtnDisabled(true);
            console.log('Enter a Valid Room Name');
            //setErrors({ message: 'Enter a Valid Room Name' });
        }
         if (e.target.name === 'DisplayName' && e.target.value.length > 0) {
            setBtnDisabled(false);
            setErrors({ message: '' });
        }
 
    }

     
    
    const updateRooom = () => {
        const form = new FormData();
        form.append("DisplayName", values.DisplayName);
        form.append("Description", values.Description);
        form.append("IsPrivate", values.Type);
        form.append("Color", values.Color);
        form.append("OrgGuid", orgId);
        form.append("empGuid", empId);
        form.append("GroupGuid", values.GroupGuid);
        if (localStorage.getItem("msAccessToken"))
            form.append("teamsAccessToken", localStorage.getItem("msAccessToken") as string);
       
            props.Room.DisplayName =values.DisplayName;
            props.Room.GroupName =values.DisplayName;
        props.Room.Description = values.Description;
        props.Room.Color = values.Color;
       
       // props.toggleDescription();
        dispatch(updateGroup(form) as any);
     //   props.toggleInfo(false)
       
     }
    const deleteGroup = () => {
        console.log('Current values state:', values);

        const form = new FormData();
        form.append("groupGuid", props.Room.GroupGuid);
        if (localStorage.getItem("msAccessToken"))
            form.append("teamsAccessToken", localStorage.getItem("msAccessToken") as string);
        dispatch(DeleteGroup(form) as any);
        setDeleteConfirm(false);
        props.close()
        props.handleClose()
    }
    const errormsg = (<div className='row'> <div className="col-12"><div className="alert alert-danger text-center">
        <strong>Error!</strong> {errors.message}
    </div></div></div>);
    return (
        <div className="SideNav">
            <div className="sideNavHeader">
                <div className="CloseNav" onClick={props.close}> <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('title.close')} /> </div>
            </div>
            <div className="sideNavBody">
                <div className="row mt-4">
                    <div className="col-12 mb-2 text-light">
                        <svg onClick={props.close} enableBackground="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                            <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" />
                        </svg>{t('groupforminfo.room_edit')}</div>
                </div>
                <div className="row mt-3 small">

                    <div className="col-12 mb-2">
                        <p className="text-muted m-0">{t('createroom.room_name')}</p>
                        <input type="text" disabled={props.Room.IsDefault} className="SuteInputPrimary fs12" defaultValue={props.Room.DisplayName} name="DisplayName" onChange={(e) => handleChange(e)} placeholder="Group Name" /> </div>

                    <div className="col-12 mb-2 position-relative">
                        <p className="text-muted m-0">{t('title.description')}</p>
                        <textarea maxLength={150} onChange={(e) => handleChange(e)} name="Description" defaultValue={props.Room.Description}  className="SuteInputPrimary fs12" placeholder={t('title.description')} style={{ "height": "80px" }}></textarea>

                    </div>
                    {/* <div className="col-12 mb-2">
                        <button type="button" id="public" onClick={e => onChangeType(e, false)} className={'btn btn-sm mr-2 ' + (!values.Type ? 'btn-SeaGreen' : 'btn-outline-success')}>{t('button.public')}</button>
                        <button type="button" id="Private" onClick={e => onChangeType(e, true)} className={'btn btn-sm mr-2 ' + (values.Type ? 'btn-SeaGreen' : 'btn-outline-success')}>{t('button.private')}</button>
                    </div> */}
                    {/* <div className="col-12 mb-2">
                        <p className="text-muted m-0">{t('createroom.background_color')}</p>
                        <ul className="roomBGcolors">
                            <li onClick={() => { selectColor("#04f1b2") }} className={`${values.Color === '#04f1b2' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG1.svg" /></li>
                            <li onClick={() => { selectColor("#a58a62") }} className={`${values.Color === '#a58a62' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG2.svg" /></li>
                            <li onClick={() => { selectColor("#a56262") }} className={`${values.Color === '#a56262' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG3.svg" /></li>
                            <li onClick={() => { selectColor("#9da562") }} className={`${values.Color === '#9da562' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG4.svg" /></li>
                            <li onClick={() => { selectColor("#9662a5") }} className={`${values.Color === '#9662a5' ? 'active' : ''}`}><img src="/assets/media/svg/collaborate/roomBG5.svg" /></li>
                        </ul>
                    </div> */}
                    <div className="col-12 mb-2 mt-1">
                        <button onClick={updateRooom} disabled={btnDisabled}
                            className="btn-SeaGreen btn-block btn-sm btn fw600 fs12" style={{ backgroundColor: props.Room.Color, borderColor: props.Room.Color }}>{t('button.update_room')}</button>
                    </div>
                  {
                    props.Room.IsDefault ? '' : <div className="col-12 text-danger MemberPPHeader" onClick={() => { setDeleteConfirm(true) }}> {t('groupforminfo.delete_room')}<img className="float-right c-pointer" width="18" src="/assets/media/svg/deleteGroup.svg" />
                    </div>
                  }  
                    {deleteConfirm ? <ConfirmPopup show={deleteConfirm} close={() => { setDeleteConfirm(false) }} okHandle={deleteGroup} message={props.Room.OrgGuid === '00000000-0000-0000-0000-000000000000' ? 'Do you want to delete the room ?' : "Do you want to delete the room ? <br/>Note: You can continue your session, but you cannot access this room after session ends."} /> : ''}
                </div>
            </div>
            {errors.message && errormsg}
            {/* <div className="sideNavFooter">
                <div className="col-12 text-right mt-4"> <img className="c-pointer" src="/assets/media/svg/settings.svg" /> </div>
            </div> */}
        </div>
    );
}

export default RoomEdit;